import {Deserializable} from "./deserializable.model";

export class Idiomas_Respuesta implements Deserializable {
    DatosResult?: DatosResult_idiomas;
    Mensajes: Mensaje[];

    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}

export class Idiomas implements Deserializable {
    PkId: string;
    CodIdioma: string;
    Nombre: string;
    Activo: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class DatosResult_idiomas implements Deserializable {
  ListaIdiomas: Idiomas[]; 

    deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Mensaje implements Deserializable {
  CodigoMensaje: string;
  DescripcionMensaje: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}