import {CLienteAPi} from "./cliente-api.model";

//get
export class peticion_Divisas_Lista {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    Nombre: string;
    ISO: string;
}

//insUpd
export class peticion_Insercion_Upd_Divisas {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    pkId: string
    Nombre: string
    simbolo: string
    fValorCambio: string
    UltimaActualizacion: string
    Color: string
    PuedeSerReferencia: string
    NumeroDecimales: string
    OpsTarjeta: string
    PosicionDivisa: string
    SimboloDecimal: string
    SimboloDerecha: string
    CodISO: string
    funcion: string
}

//del
export class peticion_Del_Divisas {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    PkId: string;
}