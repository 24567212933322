<div id="popup" class="modal-overlay">
  <div class="cuadroCentral">
    <div class="close"  style="text-align: right;" (click)="close()">x</div>
    <!-- <div class="iconCierre click" (click)="close()">
      <a id="cerrar" class="iconoCerrar">
        <img src="assets/{{ claveEmpresa }}/img/body/cerrar.svg" alt="" /></a>
    </div> -->
    <ng-template #loading>
      <div class="DetalleBox__noResultsMessage">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div style="overflow: hidden; height: 51px; width: 51px;"></div>
        </div>
      </div>
    </ng-template>
    <div *ngIf="hasLoaded; else loading">

      <div class="modalInfoReserva" style="width: 90%; padding: 10px; border: solid 1px;">
        <div class="modalInfoCol1" style="width: 50%;">
          <div>
            <span>
              {{ 'LOCALIZADOR_INFOMODAL' | translate }}
            </span>
            <span>
              {{ reserva.ClaveReserva }}
            </span>
          </div>
          <div>
            <span>
              {{ 'NUMEROPEDIDO_INFOMODAL' | translate }}
            </span>
            <span>
              {{ reserva.NumeroPedido }}
            </span>
          </div>
          <div>
            <span>
              {{ 'CANTIDADTOTAL_INFOMODAL' | translate }}
            </span>
            <span>
              {{ reserva.NumeroEntradasTotal }}
            </span>
          </div>
        </div>
        <div class="modalInfoCol2" style="width: 50%;">
          <div>
            <span>
              {{ 'TOTALCOMISION_INFOMODAL' | translate }}
            </span>
            <span>
              {{  totalComision | currency: 'EUR'  }}
            </span>
          </div>
          <div>
            <span>
              {{ 'TOTALPRECIO_INFOMODAL' | translate }}
            </span>
            <span>
              {{ reserva.TotalReserva | currency: 'EUR' }}
            </span>
          </div>

        </div>
      </div>

      <div class="modalInfoEntradas" style="padding: 10px; border: solid 1px;" *ngFor="let entrada of productosResumen">
        <div class="modalInfoCol1">
          <div>
            <span>
              {{ 'ESPECTACULO_INFOMODAL' | translate }}
            </span>
            <span>
              {{ entrada.nombreEspectaculo }}
            </span>
          </div>
          <div>
            <span>
              {{ 'FECHA_INFOMODAL' | translate }}
            </span>
            <span>
              {{ entrada.fecha }}-{{ entrada.hora }}
            </span>
          </div>
          <div>
            <span>
              {{ 'TIPO_INFOMODAL' | translate }}
            </span>
            <span>
              {{ entrada.nombreEntrada }}
            </span>
          </div>
          <div>
            <span>
              {{ 'CANTIDAD_INFOMODAL' | translate }}
            </span>
            <span>
              {{ entrada.cantidad }}
            </span>
          </div>
        </div>
        <div class="modalInfoCol2" style="width: 50%;">
          <div>
            <span>
              {{ 'PRECIO_INFOMODAL' | translate }}
            </span>
            <span>
              {{ entrada.precio | currency: 'EUR' }}
            </span>
          </div>
          <div>
            <span>
              {{ 'COMISION_INFOMODAL' | translate }}
            </span>
            <span>
              {{ entrada.comision | currency: 'EUR' }}
            </span>
          </div>
          <div>
            <span>
              {{ 'IMPORTE_INFOMODAL' | translate }}
            </span>
            <span>
              {{ entrada.precioTotal | currency: 'EUR' }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
