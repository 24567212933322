import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "@sharedV11/services/authentication/authentication.service";
import { map } from "rxjs/operators";
@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(
    private _AuthenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.method === "GET") {
      return next.handle(req);
    } else {
      // Obtenemos el token
      // Importante: modificamos de forma inmutable, haciendo el clonado de la petición
      const modReq = this.addCommonData(req);
      // Pasamos al siguiente interceptor de la cadena la petición modificada
      //return next.handle(modReq);
      return next.handle(modReq).pipe(
        map((event) => {
          if (event instanceof HttpResponse) {
            if (event.body.DatosResult == null && event.body.Mensajes) {
              switch (event.body.Mensajes[0].CodigoMensaje) {
                case "-2": //Identificación Incorrecta
                  // MMM TOCOMENT this._AuthenticationService.logout();
                  break;
              }
            }
          }
          return event;
        })
      );
    }
  }

  addCommonData(req: HttpRequest<any>): HttpRequest<any> {
    if (req.body) {
      let body = req.body;
      // si tenemos un string es que viene serializado, TODO: eliminar todas las peticiones serializadas
      if (typeof body === "string") {
        body = JSON.parse(body);
      }
      const clienteAPI = JSON.parse(localStorage.getItem("currentToken")!);
      body.clienteAPI = clienteAPI;
      body["ConexionIacpos"] = localStorage.getItem("conexionIACPOS");

      // Si el sessionstorage esta vacio es que el usuario esta sin identificar(loguear)
      if (sessionStorage.length !== 0) {
        body.Usuario = JSON.parse(
          sessionStorage.getItem("currentUser")!
        ).DatosResult.NombreUsuario;
        body.Password = "";
      }

      const modReq = req.clone({
        headers: req.headers.set("Content-Type", "application/json"),
        body: body,
      });

      return modReq;
    }
    return req;
  }
}
