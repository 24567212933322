export const environment = {
    production: true,
    console: false,
    API_URL: "https://api.apiacpos.com/4_2000_2053_bridge/api/",
    CODIGO_APLICACION: "CUSTOMAN",
    ID_APLICACION: "19",
    chClaveEmpresa: "ESTEPONAEULEN",
    TICKETS_URL: "https://tickets.eulenestepona.arteyocio.com/",
    chProyectoOnLine: "VENTAONLINE",
    chEntornoConexion: "PRODUCCION",
    chNombreProyecto: "CUSTOMMAN",
    chOrigenConexion: "CUSTOMMAN",
    NUMSERIE: "CUSTO$A$SZ45$A$MMAN$A$23g!."   
};


   

