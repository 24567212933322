import { Injectable } from "@angular/core";
import esES from "../../languages/es-ES.json";
import caES from "../../languages/ca-ES.json";

@Injectable({
  providedIn: "root",
})
export class Translator {
  GetTranslations() {
    const language = localStorage.getItem("Language");

    switch (language) {
      case "es-ES":
        return esES;
      case "ca":
        return caES;
      default:
        localStorage.setItem("Language", "es-ES");
        return esES;
    }
  }
}
