import { Deserializable } from "../deserializable";
import { Message } from "../message/message";
import { DataResult_userType } from "./dataResult_userType";

export class UserType_response implements Deserializable {
    DatosResult?: DataResult_userType;
    Mensajes: Message[];

    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}