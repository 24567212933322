import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiTokenService } from 'src/app/shared/services/api/token/api-token.service';
import { Localizaciones, Paises } from '@sharedV11/classes/core/api-maestras.model';
import { ApiMaestrasService } from './api-maestras.service';

export interface MASTERS{
    paises: Paises[];
    localizaciones: Localizaciones[];
    gruposTiposEntradas: FilterField[];
    gruposTiposEntradasMotivos: FilterField[];
    tiposControl: FilterField[];
    tipoEntrada: FilterField[];
    grupoTipoBono: FilterField[];
}

export interface FilterField {
  name: string;
  id: number | string;
}

@Injectable({providedIn: 'root'})
export class MaestrasService {
  constructor(private apiMaestraService: ApiMaestrasService,
    private tokenService: ApiTokenService,
    private router: Router) { }

    private MASTERS: MASTERS={
      paises:[],
      localizaciones:[],
      gruposTiposEntradas:[],
      gruposTiposEntradasMotivos:[],
      tiposControl:[],
      tipoEntrada:[],
      grupoTipoBono:[],

    }
  
  getPaises(){
    return this.MASTERS.paises
  }

  getMasters(key: string): Array<any> {
    return this.MASTERS[key];
  }

  resolve(): Observable<any> {
    if (this.tokenService && this.tokenService.getConfig('API_URL')) {
      return forkJoin([
        this.apiMaestraService.getPaises(),
        this.apiMaestraService.getLocalizaciones(),
        this.apiMaestraService.getGruposTiposEntrada(),
        this.apiMaestraService.getTiposControl(),
        this.apiMaestraService.getTipoEntrada()
      ]
      ).pipe(map((
        [s1, s2, s3, s4, s5]
      ) =>
        this.MASTERS = {
          paises: s1,
          localizaciones: s2,
          gruposTiposEntradas: s3.map(x => ({ name: x.NombreGrupo, id: x.PkId })),
          gruposTiposEntradasMotivos: s3.filter(x => x.ConMotivo === '1').map(x => ({ name: x.NombreGrupo, id: x.PkId })),
          tiposControl: s4.map(x => ({ name: x.NombreTipoControl, id: x.CodigoTipoControl })),
          tipoEntrada: s5.map(x => ({name: x.Nombre, id: x.EntradaId})),
          grupoTipoBono: null //Porque si no explota
        }
      ));
    } else {
      this.router.navigate(['']);
      return null;
    }
    return forkJoin([
      this.apiMaestraService.getPaises(),
      this.apiMaestraService.getLocalizaciones(),
      this.apiMaestraService.getGruposTiposEntrada(),
      this.apiMaestraService.getTiposControl(),
      this.apiMaestraService.getTipoEntrada(),
      this.apiMaestraService.getGrupoTipoBono()
    ]
    ).pipe(map((
      [s1, s2, s3, s4, s5, s6]
    ) =>
      this.MASTERS = {
        paises: s1,
        localizaciones: s2,
        gruposTiposEntradas: s3.map(x => ({ name: x.NombreGrupo, id: x.PkId })),
        gruposTiposEntradasMotivos: s3.filter(x => x.ConMotivo === '1').map(x => ({ name: x.NombreGrupo, id: x.PkId })),
        tiposControl: s4.map(x => ({ name: x.NombreTipoControl, id: x.CodigoTipoControl })),
        tipoEntrada: s5.map(x => ({name: x.Nombre, id: x.EntradaId})),
        grupoTipoBono: s6.map(x => ({ name: x.NombreGrupo, id: x.PkId }))
      }
    ));
  }
}
