import { Message } from "@angular/compiler/src/i18n/i18n_ast";
import { Deserializable } from "../deserializable";
import { DatosResult_tarifas } from "./dataResult_tarifas";

export class Tarifas_response implements Deserializable {
    DatosResult?: DatosResult_tarifas;
    Mensajes: Message[];

    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}