import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
  parse(value: any): Date | null {
    if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
      const str = value.split('/');
      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);
      return new Date(year, month, date);
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
  format(date: Date, displayFormat: string): string {
    if (displayFormat === "input") {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      return this._a2digito(day) + '/' + this._a2digito(month) + '/' + year;
    } else if (displayFormat == "inputMonth") {
      let month_largo = this._mesCortos(date, displayFormat)
      let year = date.getFullYear();
      //if (/-MMM-/.test(displayFormat)) {
      return month_largo + '' + year;
      //   }
      //   else {
      //      return  this._to2digit(month) + '/' + year;}
    } else {
      return date.toDateString();
    }
  }

  private _a2digito(n: number) {
    return ('00' + n).slice(-2);
  }

  private _mesCortos(m, format) {
    var monthsShortDot = 'ene._feb._mar._abr._may._jun._jul._ago._sep._oct._nov._dic.'.split('_'),
      monthsShort = 'ene_feb_mar_abr_may_jun_jul_ago_sep_oct_nov_dic'.split('_');
    if (!m) {
      return monthsShortDot;
    } else if (/-MMM-/.test(format)) {
      return monthsShort[m.getMonth()];
    } else {
      return monthsShortDot[m.getMonth()];
    }
  }
}

export const APP_FORMATOS_FECHA =
{
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' }
  },
  display: {
    // dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    dateInput: 'input',
    // monthYearLabel: { month: 'short', year: 'numeric', day: 'numeric' },
    monthYearLabel: 'inputMonth',
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
}
