<div class="container col">

  <div class="menu-acciones-metodo">
    <div class="row flex justify-content-between" [hidden]="!editando">
      <div style="margin-left: 15px">
        <button class="btn btn-success btn-magento-naranja" (click)="guardarPopup()" [disabled]="!editorRegistroForm.valid || enviandoEmail"
          [hidden]="!permisobtnGuardar || !editando">{{translation.Guardar}}</button>
        <button class="btn btn-warning btn-magento-gris-claro" (click)="editando=false;" [hidden]="!editando">
          {{translation.Descartar}}</button>
      </div>
      <button class="btn" (click)="editando=false;" [matTooltip]="translation.Cerrar">
        <mat-icon style="font-size: 32px;">close</mat-icon>
      </button>
    </div>
    <button class="btn btn-warning btn-magento-naranja" (click)="AnadirRegistro()"
      [hidden]="!permisobtnNuevo || editando">{{translation.AnadirVigencia}}</button>
  </div>
  <alert></alert>
  <div [hidden]="editando" class="data-grid">
    <!--Filtros Activos -->
    <div *ngIf="hayFiltros(filtrosactivos)" class="customan-panel-filtros-activos">{{translation.FiltrosActivos}}:
      <ul class="custom-list-horizontal">
        <li *ngIf="(filtrosactivos.textoGeneral != null && filtrosactivos.textoGeneral.trim() !='')">
          {{translation.PalabraClave}}: <span>{{filtrosactivos.textoGeneral}}</span> &nbsp;<i
            class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('General')"></i></li>
        <li *ngIf="(filtrosactivos.Nombre != null && filtrosactivos.Nombre.trim() !='')"> {{translation.Nombre}}:
          <span>{{filtrosactivos.Nombre}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('Nombre')"></i>
        </li>
        <li *ngIf="(filtrosactivos.Activo != null && filtrosactivos.Activo.trim() !='')"> {{translation.Activo}}:
          <span>{{filtrosactivos.Activo}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('Activo')"></i>
        </li>
        <li *ngIf="(filtrosactivos.Butacas != null && filtrosactivos.Butacas.trim() !='')"> {{translation.Butacas}}:
          <span>{{filtrosactivos.Butacas}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('Butacas')"></i>
        </li>
        <li *ngIf="(filtrosactivos.MostrarInternet != null && filtrosactivos.MostrarInternet.trim() !='')">
          {{translation.MInternet}}: <span>{{filtrosactivos.MostrarInternet}}</span> &nbsp;<i
            class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('MostrarInternet')"></i></li>
        <li *ngIf="(filtrosactivos.CodigoTipoControl != null)"> {{translation.ControlAcc}}:
          <span>{{traeNombreTipoControlxID(filtrosactivos.CodigoTipoControl)}}</span> &nbsp;<i
            class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('CodigoTipoControl')"></i>
        </li>
        <li *ngIf="(filtrosactivos.Taller != null && filtrosactivos.Taller.trim() !='')"> {{translation.TallerAc}}:
          <span>{{filtrosactivos.Taller}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('Taller')"></i>
        </li>
        <li *ngIf="(filtrosactivos.TipoAforo != null && filtrosactivos.TipoAforo.trim() !='')">
          {{translation.TipoAforo}}: <span>{{filtrosactivos.TipoAforo}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('TipoAforo')"></i></li>
      </ul>
    </div>
    <!--Botonera-->
    <div class="customan-botonera-magento-tabla">
      <button class="btn btn-warning btn-magento-gris-claro" [class.filtro-activado]="panelFiltrosvisible"
        (click)="MostrarFiltros()"><i class="fa fa-filter fa-lg"></i>&nbsp;&nbsp;{{translation.Filtros}}</button>
      <!--<button *ngIf="permisobtnEliminar && model.PkId!=null && editando" class="btn btn-danger btn-magento-gris" (click)="pregborrarRegistro()">Borrar</button>-->
      <button class="btn btn-primary btn-magento-atras" (click)="MostrarColumnas()"><i
          class="fa fa-cog fa-lg"></i>&nbsp;&nbsp;{{translation.Columnas}}&nbsp;&nbsp;<i class="fa fa-sm"
          [class.fa-caret-up]="panelColumnasvisible" [class.fa-caret-down]="!panelColumnasvisible"></i></button>
    </div>
    <!--Panel de Columnas-->
    <div *ngIf="panelColumnasvisible" class="customan-panel-columnas-tabla">
      {{columnas.selectedOptions.selected.length}} {{translation.De}} {{ displayedColumns.length }}
      {{translation.Visibles}}.
      <div class="containerColumnas-tabla" cdk-scrollable>
        <mat-selection-list #columnas (selectionChange)="visibilidadColumna($event, columnas.selectedOptions.selected)"
          class="columnas-tabla-listado" [formControl]="formControlListaColumnas">
          <!--#columnas-->
          <mat-list-option class="columnas-listado-elemento" checkboxPosition="before"
            *ngFor="let columna of displayedColumns" [value]="columna">
            {{columna}}
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
    <!--Panel selector de Filtros-->
    <div *ngIf="panelSelectorFiltrosvisible" class="customan-panel-columnas-tabla">
      {{columnas.selectedOptions.selected.length}} {{translation.De}} {{ displayedFilters.length }}
      {{translation.Visibles}}.
      <div class="containerColumnas-tabla" cdk-scrollable>
        <mat-selection-list #columnas (selectionChange)="visibilidadFiltro($event, columnas.selectedOptions.selected)"
          class="columnas-tabla-listado" [formControl]="formControlListaFiltros">
          <mat-list-option class="columnas-listado-elemento" checkboxPosition="before"
            *ngFor="let filtro of displayedFilters" [value]="filtro">
            {{filtro}}
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
    <!--Panel de Filtros-->
    <div class="customan-panel-filtros-tabla">
      <div class="containerFiltros-tabla">
        <form [formGroup]="editorFiltrosForm">
          <div *ngIf="filtersToDisplay.includes(translation.Nombre)" class="grupo-filtros-campo-registro">
            <mat-form-field appearance="outline">
              <mat-label>{{translation.Nombre}}</mat-label>
              <input matInput placeholder="{{translation.FiltroDeNombre}}" formControlName="nombre"
                [value]="filtrosactivosModelo.Nombre">
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.Activo)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.Activo}}" formControlName="activo" name="tipoActivo"
                [value]="filtrosactivosModelo.Activo">
                <mat-option> --- --- </mat-option>
                <mat-option value="si">{{translation.Si}}</mat-option>
                <mat-option value="no">{{translation.No}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.Butacas)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.Butacas}}" formControlName="butacas" name="tipoButacas"
                [value]="filtrosactivosModelo.Butacas">
                <mat-option> --- --- </mat-option>
                <mat-option value="si">{{translation.Si}}</mat-option>
                <mat-option value="no">{{translation.No}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.MostrarInternet)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.MostrarInternet}}" formControlName="mostrarinternet"
                name="tipoMostrarInternet" [value]="filtrosactivosModelo.MostrarInternet">
                <mat-option> --- --- </mat-option>
                <mat-option value="si">{{translation.Si}}</mat-option>
                <mat-option value="no">{{translation.No}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.TipoControlAcceso)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.TipoControlAcceso}}" formControlName="tipoControl"
                [value]="filtrosactivosModelo.CodigoTipoControl" [compareWith]="comparaObjetosSelect">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let tipoGrupos of tiposTiposControl" [value]="tipoGrupos.pkId">
                  {{tipoGrupos.Nombre}}
                </mat-option>
              </mat-select>
              <!-- <mat-hint>{{tipoControl.value?.Descripcion}}</mat-hint> -->
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.EsUnTallerActividad)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.EsUnTallerActividad}}" formControlName="tipotaller"
                name="tipoTaller" [value]="filtrosactivosModelo.Taller">
                <mat-option> --- --- </mat-option>
                <mat-option value="si">{{translation.Si}}</mat-option>
                <mat-option value="no">{{translation.No}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.TipoAforo)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.TipoAforo}}" formControlName="tipoaforo" name="tipoAforo"
                [value]="filtrosactivosModelo.TipoAforo">
                <mat-option> --- --- </mat-option>
                <mat-option value="SF">{{translation.SesionesFijas}}</mat-option>
                <mat-option value="SI">{{translation.SesionesIrregulares}}</mat-option>
                <mat-option value="SD">{{translation.SesionesDiarias}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="customan-botonera-magento-tabla">
        <button class="btn btn-warning btn-magento-gris"
          (click)="AplicarFiltros()">{{translation.AplicarFiltros}}</button>
      </div>
    </div>
    <div>
      <mat-form-field class="customan-filtro-input">
        <input matInput placeholder="{{translation.BuscarPorPalabraClave}}"
          (keydown.enter)="applyFilter($any($event.target).value)" [value]="filtrosactivosModelo.textoGeneral">
        <i (click)="applyFilter($any($event.target).parentNode.children[0].value)" class="fa fa-search fa-lg"></i>
      </mat-form-field>
    </div>
    <!--Tabla-->
    <div class="mat-elevation-z8 overflow-table">
      <mat-form-field class="magento-acciones" floatLabel="never">
        <mat-select placeholder="{{translation.Acciones}}" [value]="accionselecionada"
          (selectionChange)="HacerAccion($event)">
          <!--[formControl]="panelAcciones"-->
          <mat-option *ngFor="let accion of Acciones" [value]="accion.funcion">
            {{accion.texto}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [showFirstLastButtons]="true" class="magento-paginator">
      </mat-paginator>

      <table mat-table [dataSource]="dataSource" matSort>
        <!-- Acciones Column -->
        <ng-container matColumnDef="Acciones">
          <th mat-header-cell *matHeaderCellDef>{{translation.Acciones}}</th>
          <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()" style="cursor: default;">
            <div class="action-container">
              <button customanTooltip="Duplicar Registro" *ngIf="permisobtnDuplicar" class="button-icon"
                (click)=duplicar(row) style="width: 34px; height: 34px;">
                <mat-icon>content_copy</mat-icon>
              </button>
              <button *ngIf="permisobtnEliminar" customanTooltip="Borrar Registro" class="button-icon"
                (click)="borrar(row.PkId)" style="width: 34px; height: 34px;">
                <mat-icon>delete_forever</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <!-- Nombre Column -->
        <ng-container matColumnDef="Nombre">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Nombre}} </th>
          <td mat-cell *matCellDef="let row"> {{row.Nombre}} </td>
        </ng-container>

        <!-- Activo Column -->
        <ng-container matColumnDef="Activo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Activo}} </th>
          <td mat-cell *matCellDef="let row"> {{intToBool(row.Activo)}} </td>
        </ng-container>

        <!-- PorDefecto Column -->
        <ng-container matColumnDef="PorDefecto">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{translation.PorDefecto}}</th>
          <td mat-cell *matCellDef="let row"> {{intToBool(row.PorDefecto)}} </td>
        </ng-container>

        <!-- Centro Column
                <ng-container matColumnDef="Grupo">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Centro </th>
                  <td mat-cell *matCellDef="let row"> {{traeNombreCentroxID(row.CentroId)}} </td>
                </ng-container> -->

        <!-- ControlarAforo Column -->
        <ng-container matColumnDef="ControlaAforo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.ControlaAforo}} </th>
          <td mat-cell *matCellDef="let row">{{intToBool(row.ControlarAforo)}} </td>
        </ng-container>

        <!-- ControlarAforoReserva Column -->
        <ng-container matColumnDef="ControlarAforoReserva">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.ControlaAforoReserva}}</th>
          <td mat-cell *matCellDef="let row"> {{intToBool(row.ControlarAforoReserva)}} </td>
        </ng-container>

        <!-- TipoAforo Column -->
        <ng-container matColumnDef="TipoAforo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{translation.TipoDeAforo}} </th>
          <td mat-cell *matCellDef="let row"> {{row.TipoAforo}} </td>
        </ng-container>

        <!-- AforoMaximo Column -->
        <ng-container matColumnDef="AforoMaximo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{translation.AforoMaximo}}</th>
          <td mat-cell *matCellDef="let row"> {{row.AforoMaximo}} </td>
        </ng-container>

        <!-- LimiteAforo Column -->
        <ng-container matColumnDef="LimiteAforo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{translation.LimiteAforo}}</th>
          <td mat-cell *matCellDef="let row"> {{row.LimiteAforo}} </td>
        </ng-container>

        <!-- HoraInicio Column -->
        <ng-container matColumnDef="HoraInicio">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{translation.HoraInicio}}</th>
          <td mat-cell *matCellDef="let row"> {{row.HoraInicio.trim().substring(10,20).trim()}} </td>
        </ng-container>

        <!-- HoraFin Column -->
        <ng-container matColumnDef="HoraFin">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{translation.HoraFin}}</th>
          <td mat-cell *matCellDef="let row"> {{row.HoraFin.trim().substring(10,20).trim()}} </td>
        </ng-container>

        <!-- MinimoAforo Column -->
        <ng-container matColumnDef="MinimoAforo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{translation.MinimoAforo}}</th>
          <td mat-cell *matCellDef="let row"> {{row.MinimoAforo}} </td>
        </ng-container>

        <!-- Taller Column -->
        <ng-container matColumnDef="Taller">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{translation.Taller}}</th>
          <td mat-cell *matCellDef="let row"> {{intToBool(row.Taller)}} </td>
        </ng-container>

        <!-- Butacas Column -->
        <ng-container matColumnDef="Butacas">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{translation.Butacas}}</th>
          <td mat-cell *matCellDef="let row"> {{intToBool(row.Butacas)}} </td>
        </ng-container>

        <!-- MostrarInternet Column -->
        <ng-container matColumnDef="MostrarInternet">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{translation.VerInternet}}</th>
          <td mat-cell *matCellDef="let row"> {{intToBool(row.MostrarInternet)}} </td>
        </ng-container>

        <!-- MaximoInternet Column -->
        <ng-container matColumnDef="MaximoInternet">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{translation.MaximoInternet}}</th>
          <td mat-cell *matCellDef="let row"> {{row.MaximoInternet}} </td>
        </ng-container>

        <!-- GrupoActividadId Column -->
        <ng-container matColumnDef="GrupoActividadId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{translation.GrupoActividadId}}</th>
          <td mat-cell *matCellDef="let row"> {{row.GrupoActividadId}} </td>
        </ng-container>

        <!-- CodigoTipoControl Column -->
        <ng-container matColumnDef="CodigoTipoControl">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{translation.TipoControlAcceso}}</th>
          <td mat-cell *matCellDef="let row"> {{traeNombreTipoControlxID(row.CodigoTipoControl)}} </td>
        </ng-container>

        <!-- Secciones Column -->
        <ng-container matColumnDef="Secciones">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{translation.Secciones}}</th>
          <td mat-cell *matCellDef="let row"> {{intToBool(row.Secciones)}} </td>
        </ng-container>

        <!-- GrupoRecintosId Column -->
        <ng-container matColumnDef="GrupoRecintosId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{translation.GrupoRecintos}}</th>
          <td mat-cell *matCellDef="let row"> {{traeNombreGRecintoxID(row.GrupoRecintosId)}} </td>
        </ng-container>

        <!-- Shape Column -->
        <ng-container matColumnDef="Shape">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{translation.Shape}}</th>
          <td mat-cell *matCellDef="let row"> {{row.Shape}} </td>
        </ng-container>

        <!-- Coords Column -->
        <ng-container matColumnDef="Coords">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{translation.Coords}}</th>
          <td mat-cell *matCellDef="let row"> {{row.Coords}} </td>
        </ng-container>

        <!-- OrdenFilas Column -->
        <ng-container matColumnDef="OrdenFilas">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{translation.OrdenFilas}}</th>
          <td mat-cell *matCellDef="let row"> {{row.OrdenFilas}} </td>
        </ng-container>

        <!-- OrdenButacas Column -->
        <ng-container matColumnDef="OrdenButacas">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{translation.OrdenButacas}}</th>
          <td mat-cell *matCellDef="let row"> {{row.OrdenButacas}} </td>
        </ng-container>

        <!-- Numeracion Column -->
        <ng-container matColumnDef="Numeracion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{translation.Numeracion}}</th>
          <td mat-cell *matCellDef="let row"> {{row.Numeracion}} </td>
        </ng-container>

        <!-- NumSesion Column -->
        <ng-container matColumnDef="NumSesion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{translation.NumSesion}}</th>
          <td mat-cell *matCellDef="let row"> {{intToBool(row.NumSesion)}} </td>
        </ng-container>

        <!-- TiempoExtra Column -->
        <ng-container matColumnDef="TiempoExtra">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{translation.TiempoExtra}}</th>
          <td mat-cell *matCellDef="let row"> {{row.TiempoExtra}} </td>
        </ng-container>

        <!-- subGrupoRecinto Column -->
        <ng-container matColumnDef="subGrupoRecinto">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{translation.SubGrupoRecinto}}</th>
          <td mat-cell *matCellDef="let row"> {{row.subGrupoRecinto}} </td>
        </ng-container>

        <!-- Columnas de Idiomas -->
        <ng-container [matColumnDef]="columna.CodIdioma + '_' + columna.textoHeader"
          *ngFor="let columna of columnasIdiomas">
          <th mat-header-cell *matHeaderCellDef> {{columna.CodIdioma + ' ' + columna.textoHeader}} </th>
          <td mat-cell *matCellDef="let row"> {{formateaIdioma(row.Idiomas, columna)}} </td>
          <!-- {{element[columna]}}-->
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" (click)="newEditar(row)">
        </tr>
      </table>

    </div>

    <span _ngcontent-c0="" class="div-separador"></span>

  </div>

  <div [hidden]="!editando" class="editor-registro">
    <!-- Nueva Maquetación -->
    <form [formGroup]="editorRegistroForm" *ngIf="editorRegistroForm">
      <div class="main-container">
        <div>
          <div class="min-height-200px">
            <!-- horizontal Basic Forms Start -->
            <div class="pd-20 card-box mb-30">
              <div class="clearfix">
                <div class="pull-left">
                  <h4 class="text-blue h4">{{translation.Recintos}}</h4>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-12 col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.Nombre}}</mat-label>
                    <input matInput formControlName="nombre" [(ngModel)]="model.Nombre" class="editor-campo-registro"
                      type="text" placeholder="{{translation.Nombre}}">
                  </mat-form-field>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-12 col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.NumeroDeProyecto}}</mat-label>
                    <input matInput formControlName="numProyecto" class="editor-campo-registro" type="text"
                      placeholder="{{translation.NumeroProyecto}}">
                  </mat-form-field>
                </div>
              </div>

              <!---------------------SWITCHES-------------------------->
              <div class="form-group row">
                <div class="col-md-6 col-sm-12">
                  <div class='grupo-editor-campo-registro'>
                    <mat-slide-toggle color="primary" formControlName="activo" [(ngModel)]="model.Activo">
                      {{translation.Activo}}
                    </mat-slide-toggle>
                  </div>
                </div>

                <div class="col-md-6 col-sm-12">
                  <div class='grupo-editor-campo-registro'>
                    <mat-slide-toggle color="primary" formControlName="pordefecto" [(ngModel)]="model.PorDefecto">
                      {{translation.PorDefecto}}
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-6 col-sm-12">
                  <div class='grupo-editor-campo-registro'>
                    <mat-slide-toggle color="primary" formControlName="talleractividad" [(ngModel)]="model.Taller">
                      {{translation.SeleccionDeHoraOpcional}}
                    </mat-slide-toggle>
                  </div>
                </div>

                <div class="col-md-6 col-sm-12">
                  <div class='grupo-editor-campo-registro'>
                    <mat-slide-toggle color="primary" formControlName="controlsesiones" [(ngModel)]="model.NumSesion">
                      {{translation.ControlDeSesiones}}
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
              <!---------------------HORAS-------------------------->

              <div class="form-group row">
                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.HoraInicio}}</mat-label>
                    <input matInput formControlName="horaInicio" class="editor-campo-registro time-picker"
                      placeholder="{{translation.SeleccionaHoraInicio}}" type="time" [(ngModel)]="model.HoraInicio">
                  </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.HoraFin}}</mat-label>
                    <input matInput formControlName="horaFin" class="editor-campo-registro time-picker"
                      placeholder="{{translation.SeleccionaHoraFin}}" type="time" [(ngModel)]=" model.HoraFin">
                  </mat-form-field>
                </div>
              </div>

              <!---------------------DOS SWITCHES-------------------------->
              <div class="form-group row">
                <div class="col-md-4 col-sm-12">
                  <div class='grupo-editor-campo-registro'>
                    <mat-slide-toggle color="primary" formControlName="controlaraforo" (click)="toggleAforoMaximo()"
                      [(ngModel)]="model.ControlarAforo">
                      {{translation.ControlarAforo}}
                    </mat-slide-toggle>
                  </div>
                </div>

                <div class="col-md-4 col-sm-12">
                  <div class='grupo-editor-campo-registro'>
                    <mat-slide-toggle color="primary" formControlName="controlarafororeserva"
                      (click)="toggleAforoInternet()" [(ngModel)]="model.ControlarAforoReserva">
                      {{translation.ControlaAforoOnline}}
                    </mat-slide-toggle>
                  </div>
                </div>

                <div class="col-md-4 col-sm-12">
                  <div class='grupo-editor-campo-registro'>
                    <mat-slide-toggle color="primary" formControlName="mostrarinternet"
                      [(ngModel)]="model.MostrarInternet">
                      {{translation.MostrarOnline}}
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>

              <!---------------------TIPO AFORO SELECT-------------------------->
              <div class="form-group row">
                <div class="col-md-12 col-sm-12">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{translation.TipoAforo}}</mat-label>
                    <mat-select formControlName="tipoaforo" [value]="model.TipoAforo">
                      <mat-option> --- --- </mat-option>
                      <mat-option *ngFor="let tipoaforo of globales.tiposAforo" [value]="tipoaforo.id">
                        {{tipoaforo.value}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="editorRegistroForm.controls['tipoaforo'].hasError('required')">
                      {{translation.PorFavorSeleccioneUnTipoDeAforo}}</mat-error>
                  </mat-form-field>
                </div>
              </div>

              <!---------------------CAMPOS AFORO NUMEROS-------------------------->
              <div class="form-group row">
                <div class="col-md-6 col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.AforoMaximo}}</mat-label>
                    <input matInput formControlName="aforomaximo" [(ngModel)]="model.AforoMaximo"
                      (change)="validarAforoInternet()" [ngClass]="{ 'is-invalid': f.aforomaximo.errors }"
                      class="editor-campo-registro" type="number">
                    <div *ngIf="f.aforomaximo.errors" class="invalid-feedback">
                      <div *ngIf="f.aforomaximo.errors.pattern">{{translation.NumericoMaximoSieteDigitos}}</div>
                    </div>
                  </mat-form-field>
                </div>

                <div class="col-md-6 col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.AforoMinimo}}</mat-label>
                    <input matInput formControlName="aforominimo" [(ngModel)]="model.MinimoAforo"
                      [ngClass]="{ 'is-invalid': f.aforominimo.errors }" class="editor-campo-registro" type="number">
                    <div *ngIf="f.aforominimo.errors" class="invalid-feedback">
                      <div *ngIf="f.aforominimo.errors.pattern">{{translation.NumericoMaximoSieteDigitos}}</div>
                    </div>
                  </mat-form-field>
                </div>

              </div>

              <div class="form-group row">

                <div class="col-md-6 col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.LimiteAforo}}</mat-label>
                    <input matInput formControlName="limiteaforo" [(ngModel)]="model.LimiteAforo"
                      [ngClass]="{ 'is-invalid': f.limiteaforo.errors }" class="editor-campo-registro" type="number">
                    <div *ngIf="f.limiteaforo.errors" class="invalid-feedback">
                      <div *ngIf="f.limiteaforo.errors.pattern">{{translation.NumericoMaximoSeisDigitos}}</div>
                    </div>
                  </mat-form-field>
                </div>

                <div class="col-md-6 col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.AforoInternet}}</mat-label>
                    <input matInput formControlName="aforointernet" [(ngModel)]="model.MaximoInternet"
                      (change)="validarAforoInternet()" [ngClass]="{ 'is-invalid': f.aforointernet.errors }"
                      class="editor-campo-registro" type="number">
                    <div *ngIf="f.aforointernet.errors" class="invalid-feedback">
                      <div *ngIf="f.aforointernet.errors.pattern">{{translation.NumericoMaximoSieteDigitos}}</div>
                    </div>
                  </mat-form-field>
                </div>

              </div>

              <div class="form-group row">
                <div class="col-md-12 col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.TiempoExtraMin}}</mat-label>
                    <input matInput formControlName="tiempoextra" [(ngModel)]="model.TiempoExtra"
                      [ngClass]="{ 'is-invalid': f.tiempoextra.errors }" class="editor-campo-registro" type="number">
                    <div *ngIf="f.tiempoextra.errors" class="invalid-feedback">
                      <div *ngIf="f.tiempoextra.errors.pattern">{{translation.NumericoMaximoCincoDigitos}}</div>
                    </div>
                  </mat-form-field>
                </div>
              </div>


              <div class="form-group row">
                <div class="col-sm-12 col-md-12">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{translation.TipoControl}}</mat-label>
                    <mat-select placeholder="{{translation.TipoControlAcceso}}" [formControl]="tipoControlTipoControl"
                      name="tipoUsuario" [value]="model.CodigoTipoControl">
                      <mat-option>--</mat-option>
                      <mat-option *ngFor="let tipoGrupos of tiposTiposControl" [value]="tipoGrupos.pkId">
                        {{tipoGrupos.Nombre}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <!---------------------ENTRADAS-------------------------->
              <div class="form-group row">
                <div class="input-lista-editor mat-elevation-z8" style="height: 150px;">
                  <mat-list class="w33 izquierda" dense>
                    <p mat-subheader class="list-header">{{translation.EntradasAsignadas}}</p>
                    <mat-list-item *ngFor="let entradai of daTiposEntrada(model.Taller)"
                      (click)="desasignarTipoentrada(entradai, $event)">
                      <span mat-line>{{entradai.Nombre}} <mat-icon>arrow_forward_ios</mat-icon> </span>
                    </mat-list-item>
                  </mat-list>
                  <div class="w33 alinear-contenido-centrado">
                    <mat-icon (click)="asignarTodas()">arrow_back_ios</mat-icon> {{translation.Todos}} <mat-icon
                      (click)="deasignarTodas()">arrow_forward_ios</mat-icon>
                  </div>
                  <mat-list class="w33 derecha" dense>
                    <p mat-subheader class="list-header">{{translation.EntradasSinAsignar}}</p>
                    <mat-list-item *ngFor="let entradad of filtrarTiposEntrada(model.Taller)"
                      (click)="asignarTipoentrada(entradad, $event)">
                      <span mat-line>
                        <mat-icon>arrow_back_ios</mat-icon> {{entradad.Nombre}}
                      </span>
                    </mat-list-item>
                  </mat-list>
                </div>
              </div>

              <div class="clearfix">
                <div class="pull-left">
                  <h4 class="text-blue h4">{{translation.Idiomas}}</h4>
                </div>
              </div>

              <!---------------------IDIOMAS-------------------------->
              <div *ngFor="let idioma of editorListaIdiomas">
                <div *ngIf="editorRegistroForm.value.mostrarinternet || editorRegistroForm.value.controlarafororeserva">
                  <div class="form-group row">
                    <div class="col-md-6 col-sm-12">
                      <mat-form-field appearance="outline">
                        <mat-label>{{translation.NombreInternet}}
                          ({{idioma.CodIdioma}})</mat-label>
                        <input matInput class="editor-campo-registro" type="text" placeholder="Nombre*"
                          [value]="idioma.Nombre" (change)="cambiaNombre($any($event.target).value, idioma.CodIdioma)">
                      </mat-form-field>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <mat-form-field appearance="outline">
                        <mat-label>{{translation.URL}} ({{idioma.CodIdioma}})</mat-label>
                        <input matInput class="editor-campo-registro" type="text" placeholder="URL" [value]="idioma.URL"
                          (change)="cambiaURL($any($event.target).value, idioma.CodIdioma)">
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-12 col-sm-12">
                      <mat-form-field appearance="outline">
                        <mat-label>{{translation.Descripcion}}
                          ({{idioma.CodIdioma}})</mat-label>
                        <input matInput class="editor-campo-registro" type="text"
                          placeholder="{{translation.Descripcion}}" [value]="idioma.Descripcion"
                          (change)="cambiaDescripcion($any($event.target).value, idioma.CodIdioma)">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>

              <!---------------------EMAIL-------------------------->
              <div class="form-group row">
                <div class="col-sm-12 col-md-12">
                  <mat-form-field appearance="fill">
                    <mat-label>{{translation.Email}}</mat-label>
                    <textarea matInput formControlName="enviarEmail" placeholder="Lista de eMails"></textarea>
                  </mat-form-field>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-12 col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.ControlExterno}}</mat-label>
                    <input matInput formControlName="controlExterno" class="editor-campo-registro" type="text"
                      placeholder="Control externo">
                  </mat-form-field>
                </div>
              </div>

              <div class="form-group row">

                <div class="col-sm-12 col-md-12">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{translation.GrupoActividad}}</mat-label>
                    <mat-select placeholder="Grupo Actividad" formControlName="grupoActividad" name="grupoActividad"
                      [value]="model.GrupoActividadId">
                      <mat-option>--</mat-option>
                      <mat-option *ngFor="let actividad of tiposGruposActividades" [value]="actividad.PkId">
                        {{actividad.Nombre}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

              </div>


              <div class="form-group row">

                <div class="col-sm-12 col-md-12">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{translation.GrupoRecinto}}</mat-label>
                    <mat-select placeholder="Grupo Actividad" formControlName="tipoControlGruposRecinto"
                      name="tipoUsuario" [value]="model.GrupoRecintosId">
                      <mat-option>--</mat-option>
                      <mat-option *ngFor="let tipoGrupos of gruposRecintos" [value]="tipoGrupos.PkId">
                        {{tipoGrupos.Nombre}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

              </div>


              <div class="form-group row">

                <div class="col-sm-12 col-md-12">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{translation.SubGrupoRecinto}}</mat-label>
                    <mat-select placeholder="Grupo Actividad" formControlName="subGrupoRecinto" name="subGrupoRecinto">
                      <mat-option>--</mat-option>
                      <mat-option *ngFor="let recinto of subGruposRecintos" [value]="recinto.PkId">
                        {{recinto.Nombre}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

              </div>


              <div class="form-group">
                <div class="row">
                  <div class="col-md-6 col-sm-12">
                    <div class="custom-control custom-checkbox mb-5">
                      <mat-slide-toggle color="primary" formControlName="butacas" [(ngModel)]="model.Butacas"
                        (change)="cambiaButacas()">
                        {{translation.Butacas}}
                      </mat-slide-toggle>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row" *ngIf="butacasCheck">
                <div class="col-sm-12 col-md-12">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{translation.FormaDelRecinto}}</mat-label>
                    <mat-select placeholder="{{translation.FormaDelRecinto}}" formControlName="forma" name="tipoForma">
                      <mat-option> --- --- </mat-option>
                      <mat-option value="POLY">{{translation.Poligono}}</mat-option>
                      <mat-option value="RECT">{{translation.Rectangulo}}</mat-option>
                      <mat-option value="CIRCLE">{{translation.Circulo}}</mat-option>
                      <mat-option value="PATH">{{translation.Trayectoria}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div class="form-group row" *ngIf="butacasCheck">
                <div class="col-sm-12 col-md-4">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.Coordenadas}}</mat-label>
                    <input matInput formControlName="coordenadas" [(ngModel)]="model.Coords"
                      class="editor-campo-registro" type="text" placeholder="">
                  </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-4">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{translation.OrdenFilas}}</mat-label>
                    <mat-select placeholder="{{translation.OrdenFilas}}" formControlName="ordenfilas"
                      name="tipoOrdenFilas" [value]="model.OrdenFilas">
                      <mat-option> --- --- </mat-option>
                      <mat-option value="ASC">{{translation.Abajo}}</mat-option>
                      <mat-option value="DES">{{translation.Arriba}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>


                <div class="col-sm-12 col-md-4">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{translation.OrdenButacas}}</mat-label>
                    <mat-select placeholder="{{translation.OrdenButacas}}" formControlName="ordenbutacas"
                      name="tipoOrdenButacas" [value]="model.OrdenButacas">
                      <mat-option> --- --- </mat-option>
                      <mat-option value="ASC">{{translation.Izquierda}}</mat-option>
                      <mat-option value="DES">{{translation.Derecha}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

              </div>

              <div class="collapse collapse-box" id="horizontal-basic-form1">
                <div class="code-box">
                  <div class="clearfix">
                    <a href="javascript:;" class="btn btn-primary btn-sm code-copy pull-left"
                      data-clipboard-target="#horizontal-basic"><i class="fa fa-clipboard"></i> Copy Code</a>
                    <a href="#horizontal-basic-form1" class="btn btn-primary btn-sm pull-right" rel="content-y"
                      data-toggle="collapse" role="button"><i class="fa fa-eye-slash"></i> Hide Code</a>
                  </div>
                </div>
              </div>
              <!-- horizontal Basic Forms End -->
            </div>
          </div>
        </div>

        <!-- Medium modal -->

        <button class="btn btn-success btn-magento-naranja" (click)="guardarPopup()"
          [disabled]="!editorRegistroForm.valid"
          [hidden]="!permisobtnGuardar || !editando">{{translation.Guardar}}</button>

      </div>
    </form>
    <!-- Fin Nueva Maquetacion -->
  </div>
</div>