import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { DatePipe } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  Input,
  LOCALE_ID,
  OnInit,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { MatPaginatorIntlEses } from "@otrosProveedoresV6/ES-es.paginator";
import { TipoEntrada } from "@sharedV11/classes/clients/client/tipos-entrada-Respuesta.model";
import { CalendarioTipoIVA } from "@sharedV11/classes/core/calendarioTipoIVA";
import { GruposInternet } from "@sharedV11/classes/core/gruposInternet";
import { Idiomas } from "@sharedV11/classes/core/idiomas";
import { TipoAbonado } from "@sharedV11/classes/core/tipoAbonado";
import { TipoIVA } from "@sharedV11/classes/core/tipoIVA";
import {
  APP_FORMATOS_FECHA,
  AppDateAdapter,
} from "@sharedV11/classes/dataAdapter/date-format.adapter";
import { IdiomaGR } from "@sharedV11/classes/tarifaResponse/idiomaGR";
import { Tarifas } from "@sharedV11/classes/tarifaResponse/tarifas";
import { traspasomodelo } from "@sharedV11/classes/tarifas/traspasomodelo";
import { AlertService } from "@sharedV11/services/alerts/alert/alert.service";
import { HttpErrorsService } from "@sharedV11/services/alerts/errors/http-errors.service";
import { SnackbarInfoService } from "@sharedV11/services/alerts/snackbar-info/snackbar-info.service";
import { ConectarApiService } from "@sharedV11/services/api/connection/conectar-api.service";
import { GlobalService } from "@sharedV11/services/global/global.service";
import { GlobalServicCentros } from "@sharedV11/services/global/global.service centros";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { Translator } from "@sharedV11/services/translator/translator.service";
import { DualListComponent } from "angular-dual-listbox";
import moment from "moment";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: "app-edit-tarifa",
  templateUrl: "./edit-tarifa.component.html",
  styleUrls: ["./edit-tarifa.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_FORMATOS_FECHA,
    },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlEses },
    { provide: LOCALE_ID, useValue: "es" },
    { provide: MAT_DATE_LOCALE, useValue: "es" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("1000ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class EditTarifaComponent implements OnInit {
  loading = true;
  @Input() pkId: string;
  @Input() mode: string;
  @Input() modificar: boolean;

  translation;

  editorRegistroForm: FormGroup;

  tarifa: Tarifas;

  tiposIdiomas: Array<Idiomas> = [];
  tiposEntradas: Array<TipoEntrada> = [];
  tiposIVA: Array<TipoIVA> = [];
  calendarioTiposIVA: Array<CalendarioTipoIVA> = [];
  tiposAbonados: Array<TipoAbonado> = [];
  tiposGInternet: Array<GruposInternet> = [];
  tiposIVACalendario: any[] = [];

  ProductosPaquete: any;
  ProductosEntrada: any;

  TarifaPromocion: any;
  platSelected: any;

  editorListaIdiomas: Array<IdiomaGR>;
  model = new traspasomodelo();

  TaqAutSelected: boolean;
  PVKSelected: boolean;
  tipoProductoAbonado: boolean;
  tipoProductoPaquete: boolean;
  tipoProductoEntrada: boolean;

  fecha_minHasta = new Date();
  fechaPC_maxHasta: Date;
  fechaFV_minDesde = new Date();
  fechaFV_minHasta = new Date();
  fechaFV_maxDesde: Date;

  TieneAdmon: string;
  TieneTaquillaAutomatica: string;
  TieneInternet: string;
  AdminSelected: boolean;
  IntSelected: boolean;
  ProductosAbono: any;

  // autocompletar
  filteredEntradas: Observable<any>;
  filteredProductos: Observable<any>;
  filteredInternet: Observable<any>;

  // divisas
  NumeroDecimales: any;
  PosicionDivisa: any;
  SimboloDecimal: any;
  SimboloDerecha: any;
  simbolo: any;
  // tabla
  columnasTabla;
  columnasVanila;
  expandedElement: any | null;
  // disable internet y administracion
  disableAdministracion = false;
  disableInternet = false;

  //centros
  centrosSinAsignar = [];
  centrosAsignados = [];
  tipoCentros: any[] = [];
  //centros
  format = {
    add: "Añadir",
    remove: "Borrar",
    all: "Todos",
    none: "Ninguno",
    direction: DualListComponent.LTR,
    draggable: true,
    locale: "es",
  };

  //
  closeExpanded() {
    this.expandedElement = null;
  }

  constructor(
    private translator: Translator,
    private formBuilder: FormBuilder,
    private _cds: ChangeDetectorRef,
    private panelService: PanelService,
    private servicioAPI: ConectarApiService,
    public global: GlobalService,
    private alertaServicio: AlertService,
    private httpErrorService: HttpErrorsService,
    private snackvar_service: SnackbarInfoService,
    private gsCentros: GlobalServicCentros
  ) {}
  ngAfterViewInit() {
    this.actualizarPlataformasSeleccionadas();
    this._cds.detectChanges();
  }
  ngOnInit(): void {
    /* filtros tipo entrada - GetTipoEntrada
     tipo de promocion, - ObtenerCustomanTipoBono
     tipo de abonado - ObtenerTiposAbonado
      */
    this.loadCentros();
    this.loadData();
    this.translation = this.translator.GetTranslations();
    this.columnasTabla = [
      "Nombre Entrada",
      "Porcentaje",
      "Cantidad",
      "Tipo IVA",
      "PVP",
      "Base imponible",
      "Tipo IVA comisión",
      "PVP comisión",
      "Base imponible comisión",
      " ",
    ];
    this.columnasVanila = [
      "nombreEntrada",
      "porcentaje",
      "cantidad",
      "tipoIva",
      "pvp",
      "baseImponible",
      "tipoIvaComision",
      "pvpComision",
      "baseImponibleComision",
      " ",
    ];
    this.getLocalStorage();
    this.generateFormGroup();

    this.getLocalStrorage();

    this.getTiposEntradas();
    this.getTiposAbonados();
    this.servicioAPI.getTiposBonoAsync().then(
      (res) => {
        this.tipoProductoPaquete = false;
        if (res.DatosResult !== null) {
          this.ProductosPaquete = res.DatosResult.lista;
          res.DatosResult.lista.forEach((element) => {
            if (
              element.bALaVenta === "1" &&
              this.tipoProductoPaquete === false
            ) {
              this.tipoProductoPaquete = true;
            }
          });
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    //Ponemos los idiomas a reset
  }

  async loadCentros() {
    await this.gsCentros.getCentros();
  }

  getLocalStrorage() {
    // divisas from localstorage
    //this.NumeroDecimales = localStorage.getItem('NumeroDecimales');
    this.NumeroDecimales = 4;
    this.PosicionDivisa = localStorage.getItem("PosicionDivisa");
    this.SimboloDecimal = localStorage.getItem("SimboloDecimal");
    this.SimboloDerecha = localStorage.getItem("SimboloDerecha");
    this.simbolo = localStorage.getItem("simbolo");

    this.TieneInternet = localStorage.getItem("TieneInternet");
    this.TieneTaquillaAutomatica = localStorage.getItem(
      "TieneTaquillaAutomatica"
    );
    this.TieneAdmon = localStorage.getItem("TieneAdmon");
  }
  getLocalStorage() {
    // divisas from localstorage
    this.NumeroDecimales = localStorage.getItem("NumeroDecimales");
    this.PosicionDivisa = localStorage.getItem("PosicionDivisa");
    this.SimboloDecimal = localStorage.getItem("SimboloDecimal");
    this.SimboloDerecha = localStorage.getItem("SimboloDerecha");
    this.simbolo = localStorage.getItem("simbolo");

    this.TieneInternet = localStorage.getItem("TieneInternet");
    this.TieneTaquillaAutomatica = localStorage.getItem(
      "TieneTaquillaAutomatica"
    );
    this.TieneAdmon = localStorage.getItem("TieneAdmon");
  }
  get f() {
    return this.editorRegistroForm.controls;
  } //Editor
  minimoValidator() {
    this.editorRegistroForm.controls["minimo"].setValidators([
      Validators.pattern("^-?[0-9]*$"),
      Validators.min(0),
      Validators.max(parseInt(this.editorRegistroForm.get("maximo").value)),
    ]);
  }

  generateFormGroup() {
    this.editorRegistroForm = this.formBuilder.group({
      /// Campos despues de rework
      activa: [""],
      tipoControlPlataforma: ["", [Validators.required]],
      tipoControlproductoAsociado: ["", [Validators.required]],
      tipoControlproductoAsociadoEntrada: [""],
      tipoControlproductoAsociadoPaquete: [""],
      tipoControlproductoAsociadoAbono: [""],
      tipoControlDivisas: [""],
      chPrefijo: [""],
      tipoGrupoInternet: [""],
      agrupada: [""],
      fechaDesdeVisita: ["", [Validators.required]],
      fechaHastaVisita: [
        "",
        [Validators.required, this.isAfterDate("fechaDesdeVisita")],
      ],
      fechaDesdeCompra: ["", [Validators.required]],
      fechaHastaCompra: [
        "",
        [Validators.required, this.isAfterDate("fechaDesdeCompra")],
      ],
      sinFechaFin: [false],
      horarioDeVisitaInicio: [""],
      horarioDeVisitaFin: ["", [this.isAfterDate("horarioDeVisitaInicio")]],
      tipoControlDias: [""],
      tipoIVA: ["0"],
      pvp: [parseFloat("0").toFixed(this.NumeroDecimales), [Validators.min(0)]],
      bimp: [
        parseFloat("0").toFixed(this.NumeroDecimales),
        [Validators.min(0)],
      ],
      desglosarGastosGestion: [""],
      tipoControlTIVAComision: ["0"],
      pvpc: [
        parseFloat("0").toFixed(this.NumeroDecimales),
        [Validators.min(0)],
      ],
      bimpc: [
        parseFloat("0").toFixed(this.NumeroDecimales),
        [Validators.min(0)],
      ],
      minimo: [0, [Validators.pattern("^-?[0-9]*$"), Validators.min(0)]],
      maximo: [0, [Validators.pattern("^-?[0-9]*$"), Validators.min(0)]],
      orden: [0, [Validators.pattern("^[0-9]*$")]],
    });
  }

  ///Validadores

  isAfterDate(inputCheck): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      // if (control.value !== undefined && (isNaN(control.value) || control.value < min || control.value > max)) {
      if (control.value !== undefined) {
        if (moment(control.value, "h:mm:ss").isValid()) {
          let momentValue = moment(control.value, "h:mm:ss");
          let fechaDesde = this.editorRegistroForm.get(inputCheck).value;
          if (moment(fechaDesde, "h:mm:ss").isValid()) {
            if (moment(fechaDesde, "h:mm:ss").isAfter(momentValue)) {
              return { afterDate: true };
            }
          }
        }
      }
      return null;
    };
  }

  reloadVal(input) {
    // this.clearValidators(input);
    //this.editorRegistroForm.get(input).setValidators([Validators.required,this.isAfterDate]);
    this.editorRegistroForm.get(input).updateValueAndValidity();
  }
  save() {
    this.loading = false;
    this.panelService.setDiscardChanges(true);

    this.panelService.setSavePanel(null);
  }
  destroy() {
    this.panelService.setDiscardChanges(true);
    this.panelService.setClosePanel(null);
  }
  discard() {
    //this.changeStatusClientCode(true)
    this.newEditar(this.tarifa);
    //this.changeStatusClientCode(false)
  }
  submit() {
    this.editorRegistroForm.markAllAsTouched();

    // let client:Cliente=this.editorRegistroForm.value;
    if (this.editorRegistroForm.valid) {
      this.manageApi(this.mode);
    }
  }
  manageForm(pkId, mode) {
    //this.changeStatusClientCode(true)

    if (mode == "I") {
      this.tarifa = this.editorRegistroForm.value;
      this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
      this.centrosAsignados = this.gsCentros.daTiposCentros(this.tarifa);
      this.loading = false;
      this.valuedChanged();
      //this.changeStatusClientCode(false)
    }
    if (pkId != null) {
      // this.findClient(pkId)
      this.findTarifa(pkId);
    }
  }
  manageApi(mode) {
    //this.changeStatusClientCode(true)
    this.tarifa.CentrosIds = this.gsCentros.obtenercentrosAsociadosconOrden(
      this.centrosAsignados
    );

    this.loading = true;
    switch (mode) {
      case "I":
        //insert
        //this.f["Codigo"].setValue("0");
        //this.RegistrarModificar = "0"
        this.newGuardar();
        break;
      case "U":
        //this.RegistrarModificar = "1"
        //update
        this.newGuardar();

        break;
      case "D":
        //duplicate
        //this.RegistrarModificar = "0"
        // this.f["Codigo"].setValue("0");
        this.newGuardar();

        break;

      default:
        break;
    }
    //this.changeStatusClientCode(false)
  }

  newGuardar() {
    if (this.editorRegistroForm.value.tipoControlPlataforma === null) {
      this.editorRegistroForm.value.tipoControlPlataforma = [];
    }
    if (this.editorRegistroForm.value.tipoControlDias === null) {
      this.editorRegistroForm.value.tipoControlDias = [];
    }

    // const d: Date = this.editorRegistroForm.get('fechaDesdeVisita').value;

    // this.fechaFrontToFechaApi(d);

    const tipoControlproductoAsociado =
      this.editorRegistroForm.get("tipoControlproductoAsociado").value === "2";
    let bimpEmpty;
    let bimpcEmpty;
    if (tipoControlproductoAsociado) {
      bimpEmpty = 0;
      bimpcEmpty = 0;
      this.TarifaPromocion.forEach((tarifa) => {
        bimpEmpty += parseInt(
          this.editorRegistroForm.get("baseImponible" + tarifa.pkId).value
        );
        bimpcEmpty += parseInt(
          this.editorRegistroForm.get("baseImponibleComision" + tarifa.pkId)
            .value
        );
      });
    }

    let idiomasSave = [];

    this.tiposIdiomas.forEach((idioma) => {
      idiomasSave.push({
        Nombre: this.editorRegistroForm.get("nombre" + idioma.CodIdioma).value,
        Descripcion: this.editorRegistroForm.get(
          "Descripcion" + idioma.CodIdioma
        ).value,
        CodIdioma: idioma.CodIdioma,
      });
    });

    if (this.editorRegistroForm.value.tipoControlproductoAsociado === "1") {
      if (
        this.editorRegistroForm.value.tipoControlproductoAsociadoEntrada.pkId
      ) {
        this.editorRegistroForm
          .get("tipoControlproductoAsociadoEntrada")
          .setValue(
            this.editorRegistroForm.value.tipoControlproductoAsociadoEntrada
              .pkId
          );
      }
    } else if (
      this.editorRegistroForm.value.tipoControlproductoAsociado === "2"
    ) {
      if (
        this.editorRegistroForm.value.tipoControlproductoAsociadoPaquete.pkId
      ) {
        this.editorRegistroForm
          .get("tipoControlproductoAsociadoPaquete")
          .setValue(
            this.editorRegistroForm.value.tipoControlproductoAsociadoPaquete
              .pkId
          );
      }
    }

    if (this.AdminSelected || this.IntSelected) {
      if (this.editorRegistroForm.value.tipoGrupoInternet.pkId) {
        this.editorRegistroForm
          .get("tipoGrupoInternet")
          .setValue(this.editorRegistroForm.value.tipoGrupoInternet.pkId);
      }
    }

    let body = {
      pkId: this.pkId !== "" ? this.pkId : "0",
      Internet: this.editorRegistroForm.value.tipoControlPlataforma.includes(
        "2"
      )
        ? "1"
        : "0",
      Taquilla: this.editorRegistroForm.value.tipoControlPlataforma.includes(
        "1"
      )
        ? "1"
        : "0",
      TaqAutomatica:
        this.editorRegistroForm.value.tipoControlPlataforma.includes("3")
          ? "1"
          : "0",
      IntAdmon: this.editorRegistroForm.value.tipoControlPlataforma.includes(
        "4"
      )
        ? "1"
        : "0",
      AlaVenta: this.editorRegistroForm.value.activa === true ? "1" : "0",
      iTipoEntradaId:
        this.editorRegistroForm.value.tipoControlproductoAsociado === "1"
          ? this.editorRegistroForm.value.tipoControlproductoAsociadoEntrada
          : "0",
      iTipoPromocionId:
        this.editorRegistroForm.value.tipoControlproductoAsociado === "2"
          ? this.editorRegistroForm.value.tipoControlproductoAsociadoPaquete
          : "0",
      NoFecFinVisita:
        this.editorRegistroForm.value.sinFechaFin === true ? "1" : "0",
      PrecioPVP: this.editorRegistroForm.value.pvp,
      PorcIva: tipoControlproductoAsociado
        ? "0"
        : this.getPorcIva(this.editorRegistroForm.value.tipoIVA), // falla
      Comision: this.editorRegistroForm.value.pvpc,
      PorIvaComision: tipoControlproductoAsociado
        ? "0"
        : this.getPorcIva(
            this.editorRegistroForm.value.tipoControlTIVAComision
          ), // falla
      iDivisaId:
        this.editorRegistroForm.value.tipoControlDivisas !== ""
          ? this.editorRegistroForm.value.tipoControlDivisas
          : this.global.divisapordefectoSistema,
      iTipoAbonadoId:
        this.editorRegistroForm.value.tipoControlproductoAsociado === "3"
          ? this.editorRegistroForm.value.tipoControlproductoAsociadoAbono
          : "0",
      Agrupar: this.editorRegistroForm.value.agrupada === true ? "1" : "0",
      Orden: this.editorRegistroForm.value.orden,
      Minimo: this.editorRegistroForm.value.minimo,
      Maximo: this.editorRegistroForm.value.maximo,
      iGrupoInternetId: this.IntSelected
        ? this.editorRegistroForm.value.tipoGrupoInternet
        : this.AdminSelected
        ? this.editorRegistroForm.value.tipoGrupoInternet
        : "",
      FecIniVisita: moment(
        this.editorRegistroForm.get("fechaDesdeVisita").value
      ).format("DD/MM/YYYY 0:00:00"),
      FecFinVisita:
        this.editorRegistroForm.value.sinFechaFin === true
          ? ""
          : moment(
              this.editorRegistroForm.get("fechaHastaVisita").value
            ).format("DD/MM/YYYY 0:00:00"),
      FecCompraDesde: moment(
        this.editorRegistroForm.get("fechaDesdeCompra").value
      ).format("DD/MM/YYYY 0:00:00"),
      FecCompraHasta: moment(
        this.editorRegistroForm.get("fechaHastaCompra").value
      ).format("DD/MM/YYYY 0:00:00"),
      HoraIni: this.editorRegistroForm.value.horarioDeVisitaInicio,
      HoraFin: this.editorRegistroForm.value.horarioDeVisitaFin,
      iTipoIvaId: tipoControlproductoAsociado
        ? "0"
        : this.editorRegistroForm.value.tipoIVA,
      iTipoIvaComisionId: tipoControlproductoAsociado
        ? "0"
        : this.editorRegistroForm.value.tipoControlTIVAComision,
      BaseImponible:
        this.editorRegistroForm.value.tipoControlproductoAsociado === "2"
          ? "0"
          : this.editorRegistroForm.value.bimp,
      ComisionBI:
        this.editorRegistroForm.value.tipoControlproductoAsociado === "2"
          ? "0"
          : this.editorRegistroForm.value.bimpc,
      DesglosarComision:
        this.editorRegistroForm.value.desglosarGastosGestion === true
          ? "1"
          : "0",
      Lunes: this.editorRegistroForm.value.tipoControlDias.includes("1")
        ? "1"
        : "0",
      Martes: this.editorRegistroForm.value.tipoControlDias.includes("2")
        ? "1"
        : "0",
      Miercoles: this.editorRegistroForm.value.tipoControlDias.includes("3")
        ? "1"
        : "0",
      Jueves: this.editorRegistroForm.value.tipoControlDias.includes("4")
        ? "1"
        : "0",
      Viernes: this.editorRegistroForm.value.tipoControlDias.includes("5")
        ? "1"
        : "0",
      Sabado: this.editorRegistroForm.value.tipoControlDias.includes("6")
        ? "1"
        : "0",
      Domingo: this.editorRegistroForm.value.tipoControlDias.includes("7")
        ? "1"
        : "0",
      chPrefijo: this.editorRegistroForm.value.chPrefijo,
      Idiomas: idiomasSave,
      Funcion: this.mode == "U" ? "U" : "I",
      AsumeIncrementoIVA: "",
      CentrosIds: this.gsCentros.obtenercentrosAsociadosconOrden(
        this.centrosAsignados
      ),
    };

    if (this.editorRegistroForm.value.tipoControlproductoAsociado === "2") {
      let bodyTarifaPromocion = [];
      this.TarifaPromocion.forEach((element) => {
        const idiomas = [];
        let IvaComision = "0";
        let PorcIva = "0";

        PorcIva = this.getPorcIva(
          this.editorRegistroForm.get("tipoIva" + element.pkId).value
        );
        IvaComision = this.getPorcIva(
          this.editorRegistroForm.get("tipoIvaComision" + element.pkId).value
        );

        this.tiposIdiomas.forEach((idioma) => {
          idiomas.push({
            Nombre: this.editorRegistroForm.get(
              "nombre" + idioma.CodIdioma + element.pkId
            ).value,
            Descripcion: this.editorRegistroForm.get(
              "Descripcion" + idioma.CodIdioma + element.pkId
            ).value,
            CodIdioma: idioma.CodIdioma,
          });
        });
        let newTarifa = {
          pkId: element.pkId,
          iTarifaId: this.pkId !== "" ? this.pkId : "0",
          iTipoEntradaId: element.iTipoEntradaId,
          PorcIva: PorcIva, // iva comision ej 21%
          iTipoBonoEntradaId: element.iTipoBonoEntradaId
            ? element.iTipoBonoEntradaId
            : element.iTipoBonoEntradasId,
          iTipoIvaId: this.editorRegistroForm.get("tipoIva" + element.pkId)
            .value, // iva ID
          Precio: this.editorRegistroForm.get("pvp" + element.pkId).value,
          BaseImponible: this.editorRegistroForm.get(
            "baseImponible" + element.pkId
          ).value,
          NumEntradas: element.iNumEntradas
            ? element.iNumEntradas
            : element.NumEntradas,
          NomTipoEntrada: element.NomTipoEntrada,
          Porcentaje: element.rPorcentaje
            ? element.rPorcentaje.replace(",", ".")
            : element.Porcentaje.replace(",", "."), // porcentaje que sale en la tabla
          TipoIVAComisionId: this.editorRegistroForm.get(
            "tipoIvaComision" + element.pkId
          ).value, // iva comision ID
          IvaComision: IvaComision, // numero de IVA comision ej 10%
          ComisionBI: this.editorRegistroForm.get(
            "baseImponibleComision" + element.pkId
          ).value,
          Comision: this.editorRegistroForm.get("pvpComision" + element.pkId)
            .value,
          Idiomas: idiomas,
          rPorcentaje: element.rPorcentaje,
        };
        bodyTarifaPromocion.push(newTarifa);
      });
      body["TarifaPromocion"] = bodyTarifaPromocion;
    }

    this.servicioAPI.InsertarActualizarCustomanTarifas(body).subscribe(
      (res) => {
        if (res.DatosResult != null) {
          this.alertaServicio.success("Datos Guardados Correctamente");
          this.save();
          this.destroy();
        } else {
          this.loading = false;
          //this.alertaServicio.error(res.Mensajes[0].DescripcionMensaje);
          this.snackvar_service.openSnackBar(
            res.Mensajes[0].DescripcionMensaje,
            "",
            "red-snackbar"
          );
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }

  loadData() {
    //Cargamos Datos
    let resultado: boolean = false;
    var tiposUsuariotmp;

    this.servicioAPI.getIdiomas().subscribe(
      (idiomas) => {
        if (
          idiomas.DatosResult &&
          idiomas.DatosResult.ListaIdiomas.length > 0
        ) {
          //this.editorListaIdiomas = idiomas.DatosResult.ListaIdiomas
          this.tiposIdiomas = idiomas.DatosResult.ListaIdiomas;
          this.tiposIdiomas.forEach((idioma) => {
            this.editorRegistroForm.addControl(
              "nombre" + idioma.CodIdioma,
              new FormControl("")
            );
            this.editorRegistroForm.addControl(
              "Descripcion" + idioma.CodIdioma,
              new FormControl("")
            );
          });

          this.editorListaIdiomas = new Array<IdiomaGR>();
          this.tiposIdiomas.forEach((idioma) => {
            this.editorListaIdiomas.push(<IdiomaGR>{
              CodIdioma: idioma.CodIdioma,
              Nombre: idioma.CodIdioma,
              Descripcion: idioma.CodIdioma,
            });
          });
        } else {
          this.tiposIdiomas = [];
          resultado = false;
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    this.servicioAPI.getTiposEntradas().subscribe(
      (tiposEntradas) => {
        if (
          tiposEntradas.DatosResult &&
          tiposEntradas.DatosResult.Lista.length > 0
        ) {
          this.tiposEntradas = tiposEntradas.DatosResult.Lista.filter((x) =>
            this.global.aBooleano(x.ALaVenta)
          );
        } else {
          this.tiposEntradas = [];
          resultado = false;
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    this.servicioAPI.getTiposIVA().subscribe(
      (tipoIVA) => {
        if (
          tipoIVA.DatosResult &&
          tipoIVA.DatosResult.ListaTipoIva.length > 0
        ) {
          this.tiposIVA = tipoIVA.DatosResult.ListaTipoIva;
        } else {
          this.tiposIVA = [];
          resultado = false;
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    this.servicioAPI.getCalendariosTiposIVA().subscribe(
      (tiposCalendarioIVA) => {
        if (
          tiposCalendarioIVA.DatosResult &&
          tiposCalendarioIVA.DatosResult.DatosListas.length > 0
        ) {
          this.calendarioTiposIVA = tiposCalendarioIVA.DatosResult.DatosListas;
        } else {
          this.calendarioTiposIVA = [];
          resultado = false;
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    this.servicioAPI.getTiposAbonado(null, null, "1").subscribe(
      (tiposAbonado) => {
        if (
          tiposAbonado.DatosResult &&
          tiposAbonado.DatosResult.ListaTipoAbonado.length > 0
        ) {
          this.tiposAbonados = tiposAbonado.DatosResult.ListaTipoAbonado;
        } else {
          this.tiposAbonados = [];
          resultado = false;
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    this.servicioAPI.getGruposInternetTarifas().subscribe(
      (gi) => {
        if (gi.DatosResult && gi.DatosResult.listaGrupoInternet.length > 0) {
          this.tiposGInternet = gi.DatosResult.listaGrupoInternet;
        } else {
          this.tiposGInternet = [];
          resultado = false;
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }
  valuedChanged() {
    this.editorRegistroForm.valueChanges.subscribe((value) => {
      if (this.tarifa != null) {
        this.panelService.setDiscardChanges(false);
        if (JSON.stringify(value) === JSON.stringify(this.tarifa)) {
          this.panelService.setDiscardChanges(true);
        }
      }
    });
  }

  async findTarifa(pkId): Promise<any> {
    // after ngoninit

    let ListaTarifa: Tarifas[];

    // let filtroTarifa: peticion_Tarifas_Lista = new peticion_Tarifas_Lista();
    let filtroTarifa: any = {};
    /*  if (this.filtrosactivosModelo.AlaVenta != null && this.filtrosactivosModelo.AlaVenta.toLowerCase().trim() == "si") { filtroTarifa.alaVenta = '1'; } else { this.filtrosactivosModelo.AlaVenta != null && this.filtrosactivosModelo.AlaVenta.trim() != "" ? filtroTarifa.alaVenta = '0' : filtroTarifa.alaVenta = null; }
     if (this.filtrosactivosModelo.Taquilla != null && this.filtrosactivosModelo.Taquilla.toLowerCase().trim() == "si") { filtroTarifa.taquilla = '1'; } else { this.filtrosactivosModelo.Taquilla != null && this.filtrosactivosModelo.Taquilla.trim() != "" ? filtroTarifa.taquilla = '0' : filtroTarifa.taquilla = null; }
     if (this.filtrosactivosModelo.Internet != null && this.filtrosactivosModelo.Internet.toLowerCase().trim() == "si") { filtroTarifa.internet = '1'; } else { this.filtrosactivosModelo.Internet != null && this.filtrosactivosModelo.Internet.trim() != "" ? filtroTarifa.internet = '0' : filtroTarifa.internet = null; }
     if (this.filtrosactivosModelo.Automatica != null && this.filtrosactivosModelo.Automatica.toLowerCase().trim() == "si") { filtroTarifa.taquillaAutomatica = '1'; } else { this.filtrosactivosModelo.Automatica != null && this.filtrosactivosModelo.Automatica.trim() != "" ? filtroTarifa.taquillaAutomatica = '0' : filtroTarifa.taquillaAutomatica = null; }
     filtroTarifa.feciniCompra = this.filtrosactivosModelo.desdeC;
     filtroTarifa.fecFinCompra = this.filtrosactivosModelo.hastaC;
     filtroTarifa.fecIniVisita = this.filtrosactivosModelo.desdeV;
     filtroTarifa.fecFinVisita = this.filtrosactivosModelo.hastaV;
     filtroTarifa.tipoEntradaId = this.filtrosactivosModelo.TEntradaId;
     filtroTarifa.filtro = this.filtrosactivosModelo.textoGeneral;*/
    filtroTarifa.pagina = 1;
    filtroTarifa.numeroRegistros = 10;
    filtroTarifa.pkId = pkId;

    let paginator = {
      page: 1,
      pagelements: 10,
      orderby: "pkId",
      ordertype: "ASC",
    };
    let filters = [{ name: "pkId", value: pkId, translation: "", db: "" }];
    await this.servicioAPI.getTarifasAsync(paginator, filters).then(
      (data) => {
        var globalInterno = this.global;
        let respuestaAPI = data;
        if (respuestaAPI.DatosResult != null) {
          //let listatemp:  Map<string, Tarifas>;
          //s
          //ListaTarifa = this.mapDiccionario2Array(respuestaAPI.DatosResult.ListaTarifas);
          ListaTarifa = respuestaAPI.DatosResult.ListaTarifas;
          this.newEditar(ListaTarifa[0]);
          this.tarifa = this.editorRegistroForm.value;
          this.tarifa.CentrosIds = ListaTarifa[0].CentrosIds;
          this.valuedChanged();
          this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
          this.centrosAsignados = this.gsCentros.daTiposCentros(this.tarifa);

          this.loading = false;
        } else {
          this.alertaServicio.error(
            respuestaAPI.Mensajes[0].DescripcionMensaje
          );
        }
        if (this.mode != "U") {
          this.pkId = "0";
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    return ListaTarifa;
  }

  getTiposEntradas() {
    this.servicioAPI.GetTipoEntrada().subscribe(
      (res) => {
        this.tipoProductoEntrada = false;
        if (res.DatosResult !== null) {
          this.ProductosEntrada = res.DatosResult.Lista;
          //this.ProductosEntradaStatic = res.DatosResult.Lista;
          res.DatosResult.Lista.forEach((element) => {
            if (
              element.ALaVenta === "True" &&
              this.tipoProductoEntrada === false
            ) {
              this.tipoProductoEntrada = true;
            }
          });
        }
        this.manageForm(this.pkId, this.mode);
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }
  getTiposAbonados() {
    this.servicioAPI.getTiposAbonado().subscribe(
      (res) => {
        this.tipoProductoAbonado = false;
        if (res.DatosResult !== null) {
          if (res.DatosResult != undefined) {
            this.ProductosAbono = res.DatosResult.lista;

            res.DatosResult.lista.forEach((element) => {
              if (
                element.bALaVenta === "1" &&
                this.tipoProductoAbonado === false
              ) {
                this.tipoProductoAbonado = true;
              }
            });
          }
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }
  //TRY MAP dictionary 2 Array
  mapDiccionario2Array<T>(diccionario: Map<string, T>): Array<T> {
    let arrayLista = new Array<T>();
    try {
      for (var element in diccionario) {
        arrayLista.push(diccionario[element]);
      }
      //diccionario.forEach(element => {
      //  arrayLista.push(element);
      //});
      return arrayLista;
    } catch (Error) {
      alert(Error.message);
    } finally {
      return arrayLista;
    }
  }
  actualizarProductoSeleccionado() {
    this.platSelected =
      this.editorRegistroForm.controls["tipoControlproductoAsociado"].value;
    if (this.platSelected == "1") {
      this.clearValidators("tipoControlproductoAsociadoPaquete");

      this.editorRegistroForm.controls[
        "tipoControlproductoAsociadoEntrada"
      ].setValidators([Validators.required]);
      this.editorRegistroForm.controls[
        "tipoControlproductoAsociadoEntrada"
      ].updateValueAndValidity();
      this.editorRegistroForm.controls["tipoControlTIVAComision"].setValidators(
        [Validators.required]
      );
      this.editorRegistroForm.controls[
        "tipoControlTIVAComision"
      ].updateValueAndValidity();
      this.editorRegistroForm.controls["tipoIVA"].setValidators([
        Validators.required,
      ]);
      this.editorRegistroForm.controls["tipoIVA"].updateValueAndValidity();
    } else if (this.platSelected == "2") {
      //clear tipo entrada
      this.clearValidators("tipoControlproductoAsociadoEntrada");

      this.clearValidators("tipoControlTIVAComision");

      this.clearValidators("tipoIVA");

      this.editorRegistroForm.controls[
        "tipoControlproductoAsociadoPaquete"
      ].setValidators([Validators.required]);
      this.editorRegistroForm.controls[
        "tipoControlproductoAsociadoPaquete"
      ].updateValueAndValidity();
    } else {
      this.editorRegistroForm.controls["tipoControlTIVAComision"].setValidators(
        [Validators.required]
      );
      this.editorRegistroForm.controls["tipoIVA"].setValidators([
        Validators.required,
      ]);
    }

    this.filteredEntradas = this.editorRegistroForm.controls[
      "tipoControlproductoAsociadoEntrada"
    ].valueChanges.pipe(
      startWith(""),
      map((value) => (typeof value === "string" ? value : value.TextoPantalla)),
      map((TextoPantalla) =>
        TextoPantalla
          ? this._filter(TextoPantalla)
          : this.ProductosEntrada.slice()
      )
    );

    this.filteredProductos = this.editorRegistroForm.controls[
      "tipoControlproductoAsociadoPaquete"
    ].valueChanges.pipe(
      startWith(""),
      map((value) =>
        typeof value === "string" ? value : value.chTextoPantalla
      ),
      map((chTextoPantalla) =>
        chTextoPantalla
          ? this._filterPaquete(chTextoPantalla)
          : this.ProductosPaquete.slice()
      )
    ); // herere
  }
  clearValidators(input) {
    this.editorRegistroForm.controls[input].clearValidators();
    this.editorRegistroForm.controls[input].updateValueAndValidity();
  }
  private _filter(TextoPantalla: string) {
    const filterValue = TextoPantalla.toLowerCase();

    return this.ProductosEntrada.filter(
      (option) => option.TextoPantalla.toLowerCase().indexOf(filterValue) === 0
    );
  }
  private _filterPaquete(chTextoPantalla: string) {
    const filterValue = chTextoPantalla.toLowerCase();

    return this.ProductosPaquete.filter(
      (option) =>
        option.chTextoPantalla.toLowerCase().indexOf(filterValue) === 0
    );
  }

  actualizarProductoPaquete(paqueteId) {
    // al elegir paquete
    if (paqueteId === "") this.TarifaPromocion = null;

    this.ProductosPaquete.forEach((element) => {
      if (element.pkId === paqueteId) {
        // API CALL
        this.servicioAPI.getTiposBonoAsync(paqueteId).then((res) => {
          var item = res.DatosResult.lista[0].TipoBonoEntadas;
          this.TarifaPromocion = [];
          item.forEach((element) => {
            let entrada = {
              pkId: element.iTipoBonoEntradasId,
              NomTipoEntrada: element.NombreTipoEntrada,
              NombreTipoEntrada: element.NombreTipoEntrada,
              dtFechaFin: element.dtFechaFin,
              dtFechaInicio: element.dtFechaInicio,
              iNumEntradas: element.iNumEntradas,
              iTipoBonoEntradasId: element.iTipoBonoEntradasId,
              iTipoBonoId: element.iTipoBonoId,
              iTipoEntradaId: element.iTipoEntradaId,
              rPorcentaje: element.rPorcentaje,
              TipoIVA: "0",
              PorcIva: "0",
              Precio: "0",
              BaseImponible: "0",
              TipoIVAComision: "0",
              PorcIvaComision: "0",
              Comision: "0",
              ComisionBI: "0",
              /* falta idiomas */
            };
            this.TarifaPromocion.push(entrada);
          });

          this.f["pvp"].setValue(parseFloat("0").toFixed(this.NumeroDecimales));
          this.f["pvpc"].setValue(
            parseFloat("0").toFixed(this.NumeroDecimales)
          );
          // importantt
          this.TarifaPromocion.forEach((tarifa) => {
            // al elegir tipo de paquete crear campos tabla

            // campos tabla
            this.editorRegistroForm.addControl(
              "pvp" + tarifa.pkId,
              new FormControl(parseFloat("0").toFixed(this.NumeroDecimales), [
                Validators.min(0),
              ])
            );
            this.editorRegistroForm.addControl(
              "pvpComision" + tarifa.pkId,
              new FormControl(parseFloat("0").toFixed(this.NumeroDecimales), [
                Validators.min(0),
              ])
            );
            this.editorRegistroForm.addControl(
              "tipoIva" + tarifa.pkId,
              new FormControl("0")
            );
            this.editorRegistroForm.addControl(
              "tipoIvaComision" + tarifa.pkId,
              new FormControl("0")
            );
            this.editorRegistroForm.addControl(
              "baseImponible" + tarifa.pkId,
              new FormControl(parseFloat("0").toFixed(this.NumeroDecimales), [
                Validators.min(0),
              ])
            );
            this.editorRegistroForm.addControl(
              "baseImponibleComision" + tarifa.pkId,
              new FormControl(parseFloat("0").toFixed(this.NumeroDecimales), [
                Validators.min(0),
              ])
            );
            this.editorListaIdiomas.forEach((idioma) => {
              // campos idoma dentro tabla (solo idiomas de cada paquete)
              this.editorRegistroForm.addControl(
                "nombre" + idioma.CodIdioma + tarifa.pkId,
                new FormControl("")
              );
              this.editorRegistroForm.addControl(
                "Descripcion" + idioma.CodIdioma + tarifa.pkId,
                new FormControl("")
              );
            });
          });
        });
      }
    });
    /*this.editorListaIdiomas.forEach(idioma => { // campos idioma FUERA TABLA (todos los idiomas posibles)
      this.editorRegistroForm.addControl('nombre' + idioma.CodIdioma, new FormControl(''));
      this.editorRegistroForm.addControl('Descripcion' + idioma.CodIdioma, new FormControl(''));
    });*/
  }
  displayFn(entrada) {
    return entrada && entrada.TextoPantalla ? entrada.TextoPantalla : "";
  }

  displayFnProducto(producto) {
    return producto && producto.chTextoPantalla ? producto.chTextoPantalla : "";
  }

  displayFnInternet(internet) {
    return internet && internet.Nombre ? internet.Nombre : "";
  }
  cambiapvpDesdeTabla(tipoPvp) {
    let pvp;
    let totalPvp = 0;
    this.TarifaPromocion.forEach((tarifa) => {
      pvp = this.editorRegistroForm.get(
        (tipoPvp === "pvp" ? "pvp" : "pvpComision") + tarifa.pkId
      );

      const numEntradas = tarifa.iNumEntradas
        ? tarifa.iNumEntradas
        : tarifa.NumEntradas;
      totalPvp += pvp.value * numEntradas;

      this.cambiarBaseImponibleDesdeTabla(tipoPvp, tarifa);
    });

    // pvp.setValue(parseFloat(pvp.value).toFixed(this.NumeroDecimales));
    this.editorRegistroForm.controls[tipoPvp].setValue(
      totalPvp.toFixed(this.NumeroDecimales)
    );
    //this.editorRegistroForm.controls[pvp].setValue(pvp.value.toFixed(this.NumeroDecimales));
  }
  cambiarBaseImponibleDesdeTabla(tipoPvp, tarifa) {
    let pvp = this.editorRegistroForm.get(
      (tipoPvp === "pvp" ? "pvp" : "pvpComision") + tarifa.pkId
    );
    let tipoIva = this.editorRegistroForm.get(
      tipoPvp == "pvp"
        ? "tipoIva" + tarifa.pkId
        : "tipoIvaComision" + tarifa.pkId
    ).value;

    if (tipoIva == "0") {
      this.editorRegistroForm.controls[
        tipoPvp == "pvp"
          ? "baseImponible" + tarifa.pkId
          : "baseImponibleComision" + tarifa.pkId
      ].setValue(parseFloat(pvp.value).toFixed(this.NumeroDecimales));
      pvp.setValue(parseFloat(pvp.value).toFixed(4));
    } else {
      this.calendarioTiposIVA.forEach((iva) => {
        if (iva.id === tipoIva) {
          let bip = (pvp.value / (1 + parseInt(iva.Iva) / 100)).toFixed(4);

          this.editorRegistroForm.controls[
            tipoPvp == "pvp"
              ? "baseImponible" + tarifa.pkId
              : "baseImponibleComision" + tarifa.pkId
          ].setValue(parseFloat(bip).toFixed(this.NumeroDecimales));
          return;
        }
      });
      pvp.setValue(parseFloat(pvp.value).toFixed(4));
    }
    /* this.tiposIVA.forEach(iva => {
      if (iva.pkId === tipoIva) {
        tipoIva = iva.Descripcion.split(' ')[1];
        tipoIva = tipoIva.slice(0, tipoIva.length - 1)
        
        let ratio = (1 / parseInt(tipoIva)) + 1;
        
        let bip = parseFloat((pvp / ratio).toFixed(this.NumeroDecimales));
        this.editorRegistroForm.controls[tipoPvp == 'pvp' ? 'baseImponible' + tarifa.pkId : 'baseImponibleComision' + tarifa.pkId].setValue(bip);
        return;
      }
    }); */
  }
  revesBaseImponibleDesdeTabla(tipoBase, element) {
    let base = this.editorRegistroForm.get(tipoBase + element.pkId);
    if (base && base.value !== "0") {
      //let tipoIva = this.editorRegistroForm.get('tipoIva' + element.pkId).value;
      let tipoIva = this.editorRegistroForm.get(
        tipoBase == "baseImponible"
          ? "tipoIva" + element.pkId
          : "tipoIvaComision" + element.pkId
      ).value;

      if (tipoIva != 0) {
        this.calendarioTiposIVA.forEach((iva) => {
          if (iva.TipoIvaId === tipoIva) {
            let pvp = (
              base.value +
              (base.value * parseInt(iva.Iva)) / 100
            ).toFixed(this.NumeroDecimales);
            this.editorRegistroForm.controls[
              (tipoBase == "baseImponible" ? "pvp" : "pvpComision") +
                element.pkId
            ].setValue(parseFloat(pvp).toFixed(this.NumeroDecimales));
            return;
          }
        });
      } else {
        this.editorRegistroForm.controls[
          (tipoBase == "baseImponible" ? "pvp" : "pvpComision") + element.pkId
        ].setValue(parseFloat(base.value).toFixed(this.NumeroDecimales));
      }
      /* this.tiposIVA.forEach(iva => {
        if (iva.pkId === tipoIva) {
          tipoIva = iva.Descripcion.split(' ')[1];
          tipoIva = tipoIva.slice(0, tipoIva.length - 1)
          let ratio = (1 / parseInt(tipoIva)) + 1;
          let pvp = parseFloat((base * ratio).toFixed(this.NumeroDecimales));
          this.editorRegistroForm.controls[(tipoBase == 'baseImponible' ? 'pvp' : 'pvpComision') + element.pkId].setValue(pvp);
          return;
        }
      }); */
    }
    base.setValue(parseFloat(base.value).toFixed(this.NumeroDecimales));
    this.cambiapvpDesdeTabla("pvp");
    this.cambiapvpDesdeTabla("pvpc");
  }
  // FUNCIONES TABLA
  cambiapvpTabla(tipoPvp) {
    let pvp = this.editorRegistroForm.get(tipoPvp).value;
    this.TarifaPromocion.forEach((tarifa) => {
      const campopor = tarifa.rPorcentaje
        ? tarifa.rPorcentaje.replace(/,/g, ".")
        : tarifa.Porcentaje.replace(/,/g, ".");
      const porcentaje = (parseFloat(pvp) * parseFloat(campopor)) / 100.0;

      const numEntradas = tarifa.iNumEntradas
        ? tarifa.iNumEntradas
        : tarifa.NumEntradas;

      this.editorRegistroForm.controls[
        (tipoPvp === "pvp" ? "pvp" : "pvpComision") + tarifa.pkId
      ].setValue((porcentaje / parseInt(numEntradas)).toFixed(4));
    });
    this.cambiapvpDesdeTabla(tipoPvp);
  }
  cambiabaseImp(tipoBase, evento?) {
    // platSelected
    let base = this.editorRegistroForm.get(tipoBase);
    if (
      (base && base.value !== "0") ||
      (evento && evento.target.classList.value.indexOf("is-invalid") == -1)
    ) {
      let tipoIva = this.editorRegistroForm.get(
        tipoBase == "bimp" ? "tipoIVA" : "tipoControlTIVAComision"
      ).value;
      if (tipoIva != 0) {
        this.calendarioTiposIVA.forEach((iva) => {
          if (iva.TipoIvaId === tipoIva) {
            tipoIva = parseFloat(1 + "," + iva.Iva);
            let pvp = (
              base.value +
              (base.value * parseInt(iva.Iva)) / 100
            ).toFixed(this.NumeroDecimales);
            this.editorRegistroForm.controls[
              tipoBase == "bimp" ? "pvp" : "pvpc"
            ].setValue(parseFloat(pvp).toFixed(this.NumeroDecimales));
            base.setValue(parseFloat(base.value).toFixed(this.NumeroDecimales));
            return;
          }
        });
        /* this.tiposIVA.forEach(iva => {
          if (iva.pkId === tipoIva) {
            tipoIva = iva.Descripcion.split(' ')[1];
            tipoIva = tipoIva.slice(0, tipoIva.length - 1)
            let ratio = (1 / parseInt(tipoIva)) + 1;
            let pvp = parseFloat((base * ratio).toFixed(this.NumeroDecimales));
            this.editorRegistroForm.controls[tipoBase == 'bimp' ? 'pvp' : 'pvpc'].setValue(pvp);
            return;
          }
        }); */
      } else {
        this.editorRegistroForm.controls[
          tipoBase == "bimp" ? "pvp" : "pvpc"
        ].setValue(parseFloat(base.value).toFixed(this.NumeroDecimales));
      }
    }
    base.setValue(parseFloat(base.value).toFixed(this.NumeroDecimales));
  }
  cambiapvp(tipoPvp, evento?) {
    // platSelected
    let pvp = this.editorRegistroForm.get(tipoPvp);
    if (
      (pvp && pvp.value !== "0") ||
      (evento && evento.target.classList.value.indexOf("is-invalid") == -1)
    ) {
      let tipoIva = this.editorRegistroForm.get(
        tipoPvp == "pvp" ? "tipoIVA" : "tipoControlTIVAComision"
      ).value;
      if (tipoIva == "0") {
        // si iva es 0 base imponible sera pvp
        this.editorRegistroForm.controls[
          tipoPvp == "pvp" ? "bimp" : "bimpc"
        ].setValue(parseFloat(pvp.value).toFixed(this.NumeroDecimales));
        pvp.setValue(parseFloat(pvp.value).toFixed(this.NumeroDecimales));
      } else {
        // iva no es 0 se calcula base imponible
        this.calendarioTiposIVA.forEach((iva) => {
          if (iva.TipoIvaId === tipoIva) {
            let bip = (pvp.value / (1 + parseInt(iva.Iva) / 100)).toFixed(
              this.NumeroDecimales
            );

            this.editorRegistroForm.controls[
              tipoPvp == "pvp" ? "bimp" : "bimpc"
            ].setValue(parseFloat(bip).toFixed(this.NumeroDecimales));
            pvp.setValue(parseFloat(pvp.value).toFixed(this.NumeroDecimales));
            return;
          }
        });
      }
    }
  }
  CambioDiasSemanaMultiple(evento) {
    //En principio no lo vamos a usar
  }
  //Para que funcione le mat select cuando al option le metes objetos en lugar de strings simples
  comparaObjetosSelect(o1: any, o2: any) {
    return o1 && o2
      ? o1.id === o2.id || o1 === o2.id || o1.id === o2
      : o1 === o2;
  }
  aFecha(fecha: string): Date {
    //
    //
    if (fecha == null || fecha.length == 0) {
      return null;
    } else {
      var parts = fecha.split("/");
      return new Date(
        parseInt(parts[2].substring(0, 4)),
        parseInt(parts[1]) - 1,
        parseInt(parts[0])
      );
    }
  }
  getHoraFin() {
    //
    return this.model.HoraFin;
  }

  getHoraInicio() {
    //
    return this.model.HoraIni;
  }
  getFechaFCFin(): Date {
    if (
      this.model.FecCompraHasta == null ||
      this.model.FecCompraHasta == undefined
    ) {
      return this.aFecha(this.model.FecCompraHasta);
    } else {
      return this.aFecha(this.model.FecCompraHasta);
    }
    if (this.model.FecCompraHasta > this.model.FecFinVisita) {
      return this.aFecha(this.model.FecFinVisita);
    }
    /* return this.aFecha(this.model.FecCompraHasta); */
  }
  cambiaFChasta(evento) {
    /* 
    this.fecha_maxDesde = evento.value;
    this.fechaPC_maxHasta = evento.value;
    this.model.FecCompraHasta = new DatePipe('es').transform(evento.value, 'dd/MM/yyyy').trim();

    /*this.editorRegistroForm.patchValue({
      fechaHastaCompra: new DatePipe('es').transform(evento.value, 'dd/MM/yyyy').trim()
    });*/
  }
  getFechaFCInicio(): Date {
    return this.aFecha(this.model.FecCompraDesde);
  }
  getFechaFVFin(): Date {
    return this.aFecha(this.model.FecFinVisita);
  }
  getFechaFVInicio(): Date {
    return this.aFecha(this.model.FecIniVisita);
  }
  cambiaFCdesde(evento) {
    //
    this.fecha_minHasta = evento.value;
    this.model.FecCompraDesde = new DatePipe("es")
      .transform(evento.value, "dd/MM/yyyy")
      .trim();

    /*this.editorRegistroForm.patchValue({
      fechaDesdeCompra: new DatePipe('es').transform(evento.value, 'dd/MM/yyyy').trim()
    });*/
  }
  cambiaFVhasta(evento) {
    /* 
    this.fechaFV_maxDesde = evento.value;
    //this.fecha_maxDesde = evento.value;
    //this.fecha_maxHasta = evento.value;
    this.model.FecFinVisita = new DatePipe('es').transform(evento.value, 'dd/MM/yyyy').trim();
    /*if (this.model.FecFinVisita < this.model.FecCompraHasta) {
      this.model.FecCompraHasta = this.model.FecFinVisita;
    }*/
    /*this.editorRegistroForm.patchValue({
      fechaHastaVisita: new DatePipe('es').transform(evento.value, 'dd/MM/yyyy').trim()
    });*/
  }
  cambiaFVdesde(evento) {
    this.fechaFV_minHasta = evento.value;
    //this.fecha_minHasta = evento.value;
    this.model.FecIniVisita = new DatePipe("es")
      .transform(evento.value, "dd/MM/yyyy")
      .trim();

    /*this.editorRegistroForm.patchValue({
      fechaDesdeVisita: new DatePipe('es').transform(evento.value, 'dd/MM/yyyy').trim()
    });*/
  }
  sinFechaFin() {
    const activado = this.editorRegistroForm.get("sinFechaFin").value;
    if (!activado) {
      this.editorRegistroForm.controls["fechaHastaVisita"].setValidators([
        Validators.required,
      ]);
      this.editorRegistroForm.controls[
        "fechaHastaVisita"
      ].updateValueAndValidity();
    } else {
      this.editorRegistroForm.controls["fechaHastaVisita"].clearValidators();
      this.editorRegistroForm.controls[
        "fechaHastaVisita"
      ].updateValueAndValidity();
      this.editorRegistroForm.controls["fechaHastaVisita"].setValue("");
    }
  }
  cambiaDescripcion(evento, idioma) {
    this.editorListaIdiomas.find((x) => x.CodIdioma == idioma).Descripcion =
      evento;
    //
  }
  cambiaNombre(evento, idioma) {
    this.editorListaIdiomas.find((x) => x.CodIdioma == idioma).Nombre = evento;
    //
  }
  actualizarPlataformasSeleccionadas() {
    // 1 - Punto Venta Kore
    // 2 - Internet
    // 3 - Taquilla Automática
    // 4 - Administración

    /*  this.disableAdministracion = false;
     this.disableInternet = false;
     const tipoPlataforma = this.editorRegistroForm.get('tipoControlPlataforma').value;
     tipoPlataforma.forEach(tipo => {
       if (tipo === "2") {
         this.disableAdministracion = true;
         return;
       }
       if (tipo === "4") {
         this.disableInternet = true;
         return;
       }
     }) */

    this.PVKSelected = this.editorRegistroForm.controls[
      "tipoControlPlataforma"
    ].value.includes("1")
      ? true
      : false;
    this.IntSelected = this.editorRegistroForm.controls[
      "tipoControlPlataforma"
    ].value.includes("2")
      ? true
      : false;
    this.TaqAutSelected = this.editorRegistroForm.controls[
      "tipoControlPlataforma"
    ].value.includes("3")
      ? true
      : false;
    this.AdminSelected = this.editorRegistroForm.controls[
      "tipoControlPlataforma"
    ].value.includes("4")
      ? true
      : false;

    this.campoGrupoInternet();

    if (
      this.editorRegistroForm.controls["tipoControlPlataforma"].value.includes(
        "2"
      ) ||
      this.editorRegistroForm.controls["tipoControlPlataforma"].value.includes(
        "4"
      )
    ) {
      this.filteredInternet = this.editorRegistroForm.controls[
        "tipoGrupoInternet"
      ].valueChanges.pipe(
        startWith(""),
        map((value) => {
          if (!value) {
            return "";
          }
          return typeof value === "string" ? value : value.Nombre;
        }),
        map((Nombre) =>
          Nombre ? this._filterInternet(Nombre) : this.tiposGInternet.slice()
        )
      );
    }
  }
  campoGrupoInternet() {
    const activado =
      this.editorRegistroForm
        .get("tipoControlPlataforma")
        .value.includes("2") ||
      this.editorRegistroForm.controls["tipoControlPlataforma"].value.includes(
        "4"
      );
    if (activado) {
      this.editorRegistroForm.controls["tipoGrupoInternet"].setValidators([
        Validators.required,
      ]);
      this.editorRegistroForm.controls[
        "tipoGrupoInternet"
      ].updateValueAndValidity();
      if (this.tiposIdiomas.length > 0) {
        this.editorRegistroForm.controls[
          "nombre" + this.tiposIdiomas[0].CodIdioma
        ].setValidators([Validators.required]);
        this.editorRegistroForm.controls[
          "nombre" + this.tiposIdiomas[0].CodIdioma
        ].updateValueAndValidity();
      }
    } else {
      this.editorRegistroForm.controls["tipoGrupoInternet"].clearValidators();
      this.editorRegistroForm.controls[
        "tipoGrupoInternet"
      ].updateValueAndValidity();
      if (this.tiposIdiomas.length > 0) {
        this.editorRegistroForm.controls[
          "nombre" + this.tiposIdiomas[0].CodIdioma
        ].clearValidators();
        this.editorRegistroForm.controls[
          "nombre" + this.tiposIdiomas[0].CodIdioma
        ].updateValueAndValidity();
      }
    }
  }
  private _filterInternet(Nombre: string) {
    const filterValue = Nombre.toLowerCase();

    return this.tiposGInternet.filter(
      (option) => option.Nombre.toLowerCase().indexOf(filterValue) === 0
    );
  }

  newEditar(item) {
    // editar tarifa
    // this.editorRegistroForm.reset();
    Object.keys(this.editorRegistroForm.controls).forEach((key) => {
      this.editorRegistroForm.get(key).reset();
    });
    this.editorRegistroForm.controls["chPrefijo"].setValue(item.chPrefijo);
    this.editorRegistroForm.controls["tipoIVA"].setValue("0");
    this.editorRegistroForm.controls["pvp"].setValue(0);
    this.editorRegistroForm.controls["bimp"].setValue(0);
    this.editorRegistroForm.controls["tipoControlTIVAComision"].setValue("0");
    this.editorRegistroForm.controls["pvpc"].setValue(0);
    this.editorRegistroForm.controls["bimpc"].setValue(0);
    this.editorRegistroForm.controls["minimo"].setValue(0);
    this.editorRegistroForm.controls["maximo"].setValue(0);
    this.editorRegistroForm.controls["orden"].setValue(0);
    this.editorRegistroForm.get("fechaDesdeVisita").reset();
    this.editorRegistroForm.get("fechaHastaVisita").reset();
    this.editorRegistroForm.get("fechaDesdeCompra").reset();
    this.editorRegistroForm.get("fechaHastaCompra").reset();

    this.editorRegistroForm
      .get("agrupada")
      .setValue(this.global.aBooleano(item.Agrupar));
    this.editorRegistroForm
      .get("desglosarGastosGestion")
      .setValue(this.global.aBooleano(item.DesglosarComision));

    this.model.pkId = item.pkId;
    this.servicioAPI.getTiposIVA().subscribe((tipoIVA) => {
      this.tiposIVA = tipoIVA.DatosResult.ListaTipoIva;

      this.TarifaPromocion = item.TarifaPromocion;

      //this.editorListaIdiomas = [];
      // importantt editar
      //idiomas first add Validator.required
      let flag = true;
      this.editorListaIdiomas.forEach((idioma) => {
        let idiomaObj = item.Idiomas.find(
          (i) => i.CodIdioma == idioma.CodIdioma
        );

        if (this.editorRegistroForm.get("nombre" + idioma.CodIdioma)) {
          // form field ya existe
          this.editorRegistroForm.controls[
            "nombre" + idioma.CodIdioma
          ].setValue(idiomaObj ? idiomaObj.Nombre : "");
        }
        if (this.editorRegistroForm.get("Descripcion" + idioma.CodIdioma)) {
          // form field ya existe
          this.editorRegistroForm.controls[
            "Descripcion" + idioma.CodIdioma
          ].setValue(idiomaObj ? idiomaObj.Descripcion : "");
        }
      });

      // campos tabla
      this.TarifaPromocion.forEach((tarifa) => {
        // al elegir tipo de paquete crear campos tabla

        this.editorRegistroForm.addControl(
          "pvp" + tarifa.pkId,
          new FormControl(
            parseFloat(tarifa.Precio.replace(",", ".")).toFixed(4),
            [Validators.min(0)]
          )
        );
        this.editorRegistroForm.addControl(
          "pvpComision" + tarifa.pkId,
          new FormControl(
            parseFloat(tarifa.Comision.replace(",", ".")).toFixed(
              this.NumeroDecimales
            ),
            [Validators.min(0)]
          )
        );
        this.editorRegistroForm.addControl(
          "tipoIva" + tarifa.pkId,
          new FormControl(tarifa.iTipoIvaId)
        );
        this.editorRegistroForm.addControl(
          "tipoIvaComision" + tarifa.pkId,
          new FormControl(tarifa.TipoIVAComisionId)
        );
        this.editorRegistroForm.addControl(
          "baseImponible" + tarifa.pkId,
          new FormControl(
            parseFloat(tarifa.BaseImponible.replace(",", ".")).toFixed(
              this.NumeroDecimales
            ),
            [Validators.min(0)]
          )
        );
        this.editorRegistroForm.addControl(
          "baseImponibleComision" + tarifa.pkId,
          new FormControl(
            parseFloat(tarifa.ComisionBI.replace(",", ".")).toFixed(
              this.NumeroDecimales
            ),
            [Validators.min(0)]
          )
        );
        this.editorListaIdiomas.map((idioma) => {
          // campos idoma dentro tabla (solo idiomas de cada paquete)

          this.editorRegistroForm.addControl(
            "nombre" + idioma.CodIdioma + tarifa.pkId,
            new FormControl(idioma.Nombre)
          );
          this.editorRegistroForm.addControl(
            "Descripcion" + idioma.CodIdioma + tarifa.pkId,
            new FormControl(idioma.Descripcion)
          );
        });
      });

      // CAMPOS INDIVIDUALES PVP COMISION...
      this.f["tipoIVA"].setValue(item.iTipoIvaId);
      this.f["tipoControlTIVAComision"].setValue(item.iTipoIvaComisionId);
      // pvps
      if (typeof item.PrecioPVP === "string" && item.PrecioPVP.includes(",")) {
        // si tiene decimales
        let pvp = parseFloat(item.PrecioPVP).toFixed(this.NumeroDecimales);
        this.f["pvp"].setValue(
          parseFloat(item.PrecioPVP.replace(",", ".")).toFixed(
            this.NumeroDecimales
          )
        );
        this.model.PrecioPVP = parseFloat(item.PrecioPVP.replace(",", "."));
      } else if (
        typeof item.PrecioPVP === "string" &&
        !item.PrecioPVP.includes(",")
      ) {
        // no tiene decimales

        this.f["pvp"].setValue(
          parseFloat(item.PrecioPVP.replace(",", ".")).toFixed(
            this.NumeroDecimales
          )
        );
        this.model.PrecioPVP = parseFloat(item.PrecioPVP);
      } else {
        this.f["pvp"].setValue(
          parseFloat(item.PrecioPVP.replace(",", ".")).toFixed(
            this.NumeroDecimales
          )
        );
        this.model.PrecioPVP = item.PrecioPVP;
      }

      if (typeof item.Comision === "string" && item.Comision.includes(",")) {
        // si tiene decimales
        this.f["pvpc"].setValue(
          parseFloat(item.Comision.replace(",", ".")).toFixed(
            this.NumeroDecimales
          )
        );
        this.model.Comision = parseFloat(item.Comision.replace(",", "."));
      } else if (
        typeof item.Comision === "string" &&
        !item.Comision.includes(",")
      ) {
        // no tiene decimales
        this.f["pvpc"].setValue(
          parseFloat(item.Comision.replace(",", ".")).toFixed(
            this.NumeroDecimales
          )
        );
        this.model.Comision = item.Comision;
      } else {
        this.f["pvpc"].setValue(
          parseFloat(item.Comision.replace(",", ".")).toFixed(
            this.NumeroDecimales
          )
        );
        this.model.Comision = item.Comision;
      }
      // bimps
      this.f["bimp"].setValue(
        parseFloat(item.BaseImponible.replace(",", ".")).toFixed(
          this.NumeroDecimales
        )
      );
      this.model.BaseImponible = parseFloat(
        item.BaseImponible.replace(",", ".")
      );
      this.f["bimpc"].setValue(
        parseFloat(item.ComisionBI.replace(",", ".")).toFixed(
          this.NumeroDecimales
        )
      );
      this.model.ComisionBI = parseFloat(item.ComisionBI.replace(",", "."));
      /*   if(item.Idiomas.length>0){
        this.editorListaIdiomas = item.Idiomas;
      } */
      this.tiposGInternet.forEach((i) => {
        if (i.pkId == item.iGrupoInternetId) {
          this.editorRegistroForm.get("tipoGrupoInternet").setValue(i);
        }
      });
      // this.model.iGrupoInternetId = item.iGrupoInternetId;

      let diasList = [];

      if (item.Lunes === "1") {
        diasList.push("1");
        this.model.Lunes = true;
      } else {
        this.model.Lunes = false;
      }
      if (item.Martes === "1") {
        diasList.push("2");
        this.model.Martes = true;
      } else {
        this.model.Martes = false;
      }
      if (item.Miercoles === "1") {
        diasList.push("3");
        this.model.Miercoles = true;
      } else {
        this.model.Miercoles = false;
      }
      if (item.Jueves === "1") {
        diasList.push("4");
        this.model.Jueves = true;
      } else {
        this.model.Jueves = false;
      }
      if (item.Viernes === "1") {
        diasList.push("5");
        this.model.Viernes = true;
      } else {
        this.model.Viernes = false;
      }
      if (item.Sabado === "1") {
        diasList.push("6");
        this.model.Sabado = true;
      } else {
        this.model.Sabado = false;
      }
      if (item.Domingo === "1") {
        diasList.push("7");
        this.model.Domingo = true;
      } else {
        this.model.Domingo = false;
      }

      let plataformas = [];
      if (item.Taquilla === "True") {
        plataformas.push("1");
      }
      if (item.Internet === "True") {
        plataformas.push("2");
      }
      if (item.TaqAutomatica === "True") {
        plataformas.push("3");
      }
      if (item.IntAdmon === "True") {
        plataformas.push("4");
      }

      let tipoProducto;
      if (item.iTipoEntradaId !== "0") {
        tipoProducto = "1";
      } else if (item.iTipoPromocionId !== "0") {
        tipoProducto = "2";
      } else if (item.iTipoAbonadoId !== "0") {
        tipoProducto = "3";
      }
      this.f["activa"].setValue(item.AlaVenta === "True" ? true : false);
      this.f["tipoControlPlataforma"].setValue(plataformas);
      this.f["tipoControlproductoAsociado"].setValue(tipoProducto);
      // select autocomplete
      this.ProductosEntrada.forEach((e) => {
        if (e.EntradaId === item.iTipoEntradaId) {
          this.f["tipoControlproductoAsociadoEntrada"].setValue(e);
        }
      });
      if (this.ProductosPaquete) {
        this.ProductosPaquete.forEach((p) => {
          if (p.pkId === item.iTipoPromocionId) {
            this.f["tipoControlproductoAsociadoPaquete"].setValue(p);
          }
        });
      }
      this.f["tipoControlproductoAsociadoAbono"].setValue(item.iTipoAbonadoId);
      this.actualizarProductoSeleccionado();
      this.actualizarPlataformasSeleccionadas();
      this.f["tipoControlDivisas"].setValue(item.iDivisaId);
      //
      this.f["fechaDesdeVisita"].setValue(this.aFecha(item.FecIniVisita));
      this.f["fechaHastaVisita"].setValue(this.aFecha(item.FecFinVisita));
      this.f["fechaDesdeCompra"].setValue(this.aFecha(item.FecCompraDesde));
      this.f["fechaHastaCompra"].setValue(this.aFecha(item.FecCompraHasta));
      //this.model.FecIniVisita = item.FecIniVisita.split(' ')[0];
      //this.model.FecFinVisita = item.FecFinVisita.split(' ')[0];
      //this.model.FecCompraDesde = item.FecCompraDesde.split(' ')[0];
      //this.model.FecCompraHasta = item.FecCompraHasta.split(' ')[0];
      this.f["sinFechaFin"].setValue(
        item.NoFecFinVisita === "True" ? true : false
      );
      this.sinFechaFin();
      this.model.HoraIni = this.global.anadirCeroHoraLeft(
        item.HoraIni.substring(10, 20).trim()
      );
      this.f["horarioDeVisitaInicio"].setValue(
        this.global.anadirCeroHoraLeft(item.HoraIni.substring(10, 20).trim())
      );
      this.model.HoraFin = this.global.anadirCeroHoraLeft(
        item.HoraFin.substring(10, 20).trim()
      );
      this.f["horarioDeVisitaFin"].setValue(
        this.global.anadirCeroHoraLeft(item.HoraFin.substring(10, 20).trim())
      );
      this.model.chPrefijo = item.chPrefijo;
      this.f["tipoControlDias"].setValue(diasList);

      this.f["minimo"].setValue(item.Minimo);
      this.f["maximo"].setValue(item.Maximo);
      this.f["orden"].setValue(item.Orden);
      this.actualizarPlataformasSeleccionadas();

      this.editorRegistroForm.get("fechaDesdeVisita").setErrors(null);
      this.editorRegistroForm.get("fechaDesdeCompra").setErrors(null);
      this.alertaServicio.limpiar();
      Object.keys(this.editorRegistroForm.controls).forEach((field) => {
        // {1}
        const control = this.editorRegistroForm.get(field); // {2}
        control.markAsTouched({ onlySelf: true }); // {3}
      });
    });
  }
  getPorcIva_old(id) {
    switch (id) {
      case "0":
        return "0";
      case "1":
        return "21";
      case "2":
        return "10";
      default:
        return "0";
    }
  }

  getPorcIva(TipoIvaId) {
    let porciva = "0";
    this.calendarioTiposIVA.forEach((iva) => {
      if (iva.TipoIvaId === TipoIvaId) {
        porciva = iva.Iva;
      }
    });
    return porciva;
  }

  closeInputFilter(enventValues) {
    this.editorRegistroForm.get(enventValues.name).setValue(enventValues.value);
    if (enventValues.name == "tipoControlproductoAsociadoPaquete") {
      this.actualizarProductoPaquete(enventValues.value);
    }
  }

  submitInterval() {
    let thisobj = this;

    setTimeout(function () {
      thisobj.submit();
    }, 700);
  }
}
