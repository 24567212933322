import { ChangeDetectorRef, Component } from "@angular/core";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { SuperClassComponent } from "@sharedV11/super-class-edit/super-class/super-class.component";
import { EditClientTypeComponent } from "./edit-client-type/edit-client-type.component";

@Component({
  selector: "app-client-type",
  templateUrl: "./client-type.component.html",
  styleUrls: ["./client-type.component.scss"],
})
export class ClientTypeComponent extends SuperClassComponent {
  constructor(
    protected panelService: PanelService,
    protected cdRef: ChangeDetectorRef
  ) {
    super(panelService, cdRef);
  }

  openPanel() {
    this.customanpanel = EditClientTypeComponent;
    this.editChangeCss();
    this.cdRef.detectChanges();
  }
}
