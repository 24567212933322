import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertService } from '../../services/alerts/alert/alert.service';

@Component({
  selector: 'alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  mensaje: any;

  constructor(private alertaServicio: AlertService) { }

  ngOnInit() {
    this.subscription = this.alertaServicio.getMessage().subscribe(mensaje => {
        this.mensaje = mensaje;
    });
}
  ngOnDestroy() {
    this.subscription.unsubscribe();
}

  actualizar(str) {
   this.mensaje.text = str;
  }
}
