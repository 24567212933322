<div id="component">
  <app-buttons
    (open)="openPanel()"
    [buttonsWrap]="'add'"
    [addbut]="addbut"
  ></app-buttons>

  <app-filter [tableName]="'grupoTipoBono'"></app-filter>

  <app-table
    [tableName]="'grupoTipoBono'"
    (addbut)="addbut = $event"
  ></app-table>
</div>
<app-panelcustoman
  *ngIf="customanpanel !== undefined"
  [ConfigPanel]="customanpanel"
  [mode]="'I'"
>
</app-panelcustoman>
