<div class="container col">
  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults" color="warn"></mat-spinner>
  </div>

  <div class="menu-acciones-metodo">
    <div class="row flex justify-content-between" [hidden]="!editando">
      <div style="margin-left: 15px">
        <button class="btn btn-success btn-magento-naranja" (click)="guardar()"
          [disabled]="!editorRegistroForm.valid"
          [hidden]="!permisobtnGuardar || !editando">{{translation.Guardar}}</button>
        <button class="btn btn-warning btn-magento-gris-claro" (click)="editando=false;" [hidden]="!editando">
          {{translation.Descartar}}</button>
      </div>
      <button class="btn" (click)="editando=false;" [matTooltip]="translation.Cerrar">
        <mat-icon style="font-size: 32px;">close</mat-icon>
      </button>
    </div>
    <button class="btn btn-warning btn-magento-naranja" (click)="AnadirRegistro()"
      [hidden]="!permisobtnNuevo || editando">{{translation.AnadirTipoDeSesion}}</button>
  </div>

  <alert></alert>
  <div [hidden]="editando" class="data-grid">
    <!--Filtros Activos -->
    <div *ngIf="hayFiltros(filtrosactivos)" class="customan-panel-filtros-activos">{{translation.FiltrosActivos}}:
      <ul class="custom-list-horizontal">
        <li *ngIf="(filtrosactivos.textoGeneral != null && filtrosactivos.textoGeneral.trim() !='')">
          {{translation.PalabraClave}}: <span>{{filtrosactivos.textoGeneral}}</span> &nbsp;<i
            class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('General')"></i></li>
        <li *ngIf="(filtrosactivos.Nombre != null && filtrosactivos.Nombre.trim() !='')"> {{translation.Nombre}}:
          <span>{{filtrosactivos.Nombre}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('Nombre')"></i>
        </li>
        <li *ngIf="(filtrosactivos.CodigoTipoRecinto != null)"> {{translation.Recin}}:
          <span>{{traeNombreRecintoxID(filtrosactivos.CodigoTipoRecinto)}}</span> &nbsp;<i
            class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('CodigoTipoRecinto')"></i>
        </li>
        <li
          *ngIf="((filtrosactivos.desde != null || filtrosactivos.hasta != null) && (filtrosactivos.desde.trim() !='' ||filtrosactivos.hasta.trim() !='' ))">
          {{translation.Fecha}}: <span>{{filtrosactivos.desde}} {{translation.A}} {{filtrosactivos.hasta}} </span>
          &nbsp;<i class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('fecha')"></i></li>
        <li *ngIf="(filtrosactivos.lunes != null && filtrosactivos.lunes.trim() !='')"> {{translation.Lunes}}:
          <span>{{filtrosactivos.lunes}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('lunes')"></i>
        </li>
        <li *ngIf="(filtrosactivos.martes != null && filtrosactivos.martes.trim() !='')"> {{translation.Martes}}:
          <span>{{filtrosactivos.martes}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('martes')"></i>
        </li>
        <li *ngIf="(filtrosactivos.miercoles != null && filtrosactivos.miercoles.trim() !='')">
          {{translation.Miercoles}}: <span>{{filtrosactivos.miercoles}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('miercoles')"></i>
        </li>
        <li *ngIf="(filtrosactivos.jueves != null && filtrosactivos.jueves.trim() !='')"> {{translation.Jueves}}:
          <span>{{filtrosactivos.jueves}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('jueves')"></i>
        </li>
        <li *ngIf="(filtrosactivos.viernes != null && filtrosactivos.viernes.trim() !='')"> {{translation.Viernes}}:
          <span>{{filtrosactivos.viernes}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('viernes')"></i>
        </li>
        <li *ngIf="(filtrosactivos.sabado != null && filtrosactivos.sabado.trim() !='')"> {{translation.Sabado}}:
          <span>{{filtrosactivos.sabado}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('sabado')"></i>
        </li>
        <li *ngIf="(filtrosactivos.domingo != null && filtrosactivos.domingo.trim() !='')"> {{translation.Domingo}}:
          <span>{{filtrosactivos.domingo}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('domingo')"></i>
        </li>
        <li *ngIf="(filtrosactivos.evento != null && filtrosactivos.evento.trim() !='')"> {{translation.Evento}}:
          <span>{{filtrosactivos.evento}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('evento')"></i>
        </li>
      </ul>
    </div>
    <!--Botonera-->
    <div class="customan-botonera-magento-tabla">
      <button class="btn btn-warning btn-magento-gris-claro" [class.filtro-activado]="panelFiltrosvisible"
        (click)="MostrarFiltros()"><i class="fa fa-filter fa-lg"></i>&nbsp;&nbsp;Filtros
      </button>
      <!--<button *ngIf="permisobtnEliminar && model.PkId!=null && editando" class="btn btn-danger btn-magento-gris" (click)="pregborrarRegistro()">Borrar</button>-->
      <button class="btn btn-primary btn-magento-atras" (click)="MostrarColumnas()"><i
          class="fa fa-cog fa-lg"></i>&nbsp;&nbsp;Columnas&nbsp;&nbsp;<i class="fa fa-sm"
          [class.fa-caret-up]="panelColumnasvisible" [class.fa-caret-down]="!panelColumnasvisible"></i>
      </button>
    </div>
    <!--Panel de Columnas-->
    <div *ngIf="panelColumnasvisible" class="customan-panel-columnas-tabla">
      {{columnas.selectedOptions.selected.length}} {{translation.De}} {{ displayedColumns.length }}
      {{translation.Visibles}}.
      <div class="containerColumnas-tabla" cdk-scrollable>
        <mat-selection-list #columnas (selectionChange)="visibilidadColumna($event, columnas.selectedOptions.selected)"
          class="columnas-tabla-listado" [formControl]="formControlListaColumnas">
          <mat-list-option class="columnas-listado-elemento" checkboxPosition="before"
            *ngFor="let columna of displayedColumns" [value]="columna" selected="true">
            {{columna}}
          </mat-list-option>
        </mat-selection-list>

      </div>
    </div>
    <!--Panel selector de Filtros-->
    <div *ngIf="panelSelectorFiltrosvisible" class="customan-panel-columnas-tabla">
      {{columnas.selectedOptions.selected.length}} {{translation.De}} {{ displayedFilters.length }}
      {{translation.Visibles}}
      <div class="containerColumnas-tabla" cdk-scrollable>
        <mat-selection-list #columnas (selectionChange)="visibilidadFiltro($event, columnas.selectedOptions.selected)"
          class="columnas-tabla-listado" [formControl]="formControlListaFiltros">
          <mat-list-option class="columnas-listado-elemento" checkboxPosition="before"
            *ngFor="let filtro of displayedFilters" [value]="filtro">
            {{filtro}}
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
    <!--Panel de Filtros-->
    <div class="customan-panel-filtros-tabla">
      <div class="containerFiltros-tabla">
        <form [formGroup]="editorFiltrosForm">
          <div class=row>
            <div class="col-sm-5 col-xs-12">
              <div *ngIf="filtersToDisplay.includes(translation.NombreTipoSesion)" class="col-sm-12">
                <mat-form-field appearance="outline">
                  <mat-label>{{translation.NombreTipoSesion}}</mat-label>
                  <input matInput placeholder="{{translation.FiltroDeNombreDeTipoDeSesion}}" formControlName="nombre"
                    [value]="filtrosactivosModelo.Nombre">
                </mat-form-field>
              </div>
              <div *ngIf="filtersToDisplay.includes(translation.Recinto)" class="col-sm-12">
                <mat-form-field class="input-select" appearance="outline">
                  <mat-select placeholder="{{translation.Recinto}}" formControlName="tipoControl"
                    [value]="filtrosactivosModelo.CodigoTipoRecinto" [compareWith]="comparaObjetosSelect">
                    <mat-option>--</mat-option>
                    <mat-option *ngFor="let tipoGrupos of filtrarRecintosUsados(tiposRecintos)"
                      [value]="tipoGrupos.PkId">
                      {{tipoGrupos.Nombre}}
                    </mat-option>
                  </mat-select>
                  <!-- <mat-hint>{{tipoControl.value?.Descripcion}}</mat-hint> -->
                </mat-form-field>
              </div>
              <div *ngIf="filtersToDisplay.includes(translation.FechaInicio)" class="col-sm-12">
                <mat-form-field appearance="outline">
                  <mat-label><b>{{translation.FechaInicio}}</b> &nbsp; <i>{{translation.Desde}}</i></mat-label>
                  <input matInput [matDatepicker]="UOdesde" placeholder="{{translation.Desde}}"
                    formControlName="UOdesde" (value)="aFecha(filtrosactivosModelo.desde)">
                  <mat-datepicker-toggle matSuffix [for]="UOdesde"></mat-datepicker-toggle>
                  <mat-datepicker #UOdesde disabled="false"></mat-datepicker>
                </mat-form-field>
              </div>
              <div *ngIf="filtersToDisplay.includes(translation.FechaInicio)" class="col-sm-12">
                <mat-form-field appearance="outline">
                  <mat-label><b>{{translation.FechaInicio}}</b> &nbsp; <i>{{translation.Hasta}}</i></mat-label>
                  <input matInput [matDatepicker]="UOhasta" placeholder="{{translation.Hasta}}"
                    formControlName="UOhasta" (value)="aFecha(filtrosactivosModelo.hasta)" >
                  <mat-datepicker-toggle matSuffix [for]="UOhasta"></mat-datepicker-toggle>
                  <mat-datepicker #UOhasta disabled="false"></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <!--Dias de la semana-->
            <div class="col-sm-7 col-xs-12">
              <div class="row">
                <div *ngIf="filtersToDisplay.includes(translation.Lunes)" class="col-sm-5">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-select placeholder="{{translation.Acceso}} {{translation.Lunes}}" formControlName="lunes"
                      name="tipoLunes" [value]="filtrosactivosModelo.lunes">
                      <mat-option> --- ---</mat-option>
                      <mat-option value="si">{{translation.Si}}</mat-option>
                      <mat-option value="no">{{translation.No}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div *ngIf="filtersToDisplay.includes(translation.Martes)" class="col-sm-5">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-select placeholder="{{translation.Acceso}} {{translation.Martes}}" formControlName="martes"
                      name="tipoMartes" [value]="filtrosactivosModelo.martes">
                      <mat-option> --- ---</mat-option>
                      <mat-option value="si">{{translation.Si}}</mat-option>
                      <mat-option value="no">{{translation.No}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div *ngIf="filtersToDisplay.includes(translation.Miercoles)" class="col-sm-5">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-select placeholder="{{translation.Acceso}} {{translation.Miercoles}}"
                      formControlName="miercoles" name="tipoMiercoles" [value]="filtrosactivosModelo.miercoles">
                      <mat-option> --- ---</mat-option>
                      <mat-option value="si">{{translation.Si}}</mat-option>
                      <mat-option value="no">{{translation.No}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div *ngIf="filtersToDisplay.includes(translation.Jueves)" class="col-sm-5">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-select placeholder="{{translation.Acceso}} {{translation.Jueves}}" formControlName="jueves"
                      name="tipoJueves" [value]="filtrosactivosModelo.jueves">
                      <mat-option> --- ---</mat-option>
                      <mat-option value="si">{{translation.Si}}</mat-option>
                      <mat-option value="no">{{translation.No}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div *ngIf="filtersToDisplay.includes(translation.Viernes)" class="col-sm-5">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-select placeholder="{{translation.Acceso}} {{translation.Viernes}}" formControlName="viernes"
                      name="tipoViernes" [value]="filtrosactivosModelo.viernes">
                      <mat-option> --- ---</mat-option>
                      <mat-option value="si">{{translation.Si}}</mat-option>
                      <mat-option value="no">{{translation.No}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div *ngIf="filtersToDisplay.includes(translation.Sabado)" class="col-sm-5">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-select placeholder="{{translation.Acceso}} {{translation.Sabado}}" formControlName="sabado"
                      name="tipoSabado" [value]="filtrosactivosModelo.sabado">
                      <mat-option> --- ---</mat-option>
                      <mat-option value="si">{{translation.Si}}</mat-option>
                      <mat-option value="no">{{translation.No}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div *ngIf="filtersToDisplay.includes(translation.Domingo)" class="col-sm-5">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-select placeholder="{{translation.Acceso}} {{translation.Domingo}}" formControlName="domingo"
                      name="tipoDomingo" [value]="filtrosactivosModelo.domingo">
                      <mat-option> --- ---</mat-option>
                      <mat-option value="si">{{translation.Si}}</mat-option>
                      <mat-option value="no">{{translation.No}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div *ngIf="filtersToDisplay.includes(translation.EventoEspecial)" class="col-sm-5">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-select placeholder="{{translation.EventoEspecial}}" formControlName="evento" name="tipoEvento"
                      [value]="filtrosactivosModelo.evento">
                      <mat-option> --- ---</mat-option>
                      <mat-option value="si">{{translation.Si}}</mat-option>
                      <mat-option value="no">{{translation.No}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <!--Fin Dias Semana-->
            </div>
          </div>
        </form>
      </div>
      <div class="customan-botonera-magento-tabla">
        <button class="btn btn-warning btn-magento-gris"
          (click)="AplicarFiltros()">{{translation.AplicarFiltros}}</button>
      </div>
    </div>
    <div>
      <mat-form-field class="customan-filtro-input">
        <input matInput placeholder="{{translation.BuscarPorPalabraClave}}"
          (keydown.enter)="applyFilter($any($event.target).value)" [value]="filtrosactivosModelo.textoGeneral">
        <i (click)="applyFilter($any($event.target).parentNode.children[0].value)" class="fa fa-search fa-lg"></i>
      </mat-form-field>
    </div>
    <!--Tabla-->

    <div class="mat-elevation-z8 overflow-table">

      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [showFirstLastButtons]="true" #paginatorPrincipal>
      </mat-paginator>
      <table mat-table [dataSource]="dataSource" matSort #sortPrincipal="matSort">

        <!-- Acciones Column -->
        <ng-container matColumnDef="Acciones">
          <th mat-header-cell *matHeaderCellDef>{{translation.Acciones}}</th>
          <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()" style="cursor: default;">
            <div class="action-container">
              <button customanTooltip="Duplicar Registro" *ngIf="permisobtnDuplicar" class="button-icon"
                (click)=duplicar(row) style="width: 34px; height: 34px;">
                <mat-icon>content_copy</mat-icon>
              </button>
              <button *ngIf="permisobtnEliminar" customanTooltip="Borrar Registro" class="button-icon"
                (click)="borrar(row.pkId)" style="width: 34px; height: 34px;">
                <mat-icon>delete_forever</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <!-- Codigo Column -->
        <ng-container matColumnDef="Recinto">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.NombreRecinto}} </th>
          <td mat-cell *matCellDef="let row"> {{traeNombreRecintoxID(row.Recinto)}} </td>
        </ng-container>

        <!-- Nombre Column -->
        <ng-container matColumnDef="Nombre">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.TipoDeSesion}} </th>
          <td mat-cell *matCellDef="let row"> {{row.Descripcion}} </td>
        </ng-container>

        <!-- Descripcion Column -->
        <ng-container matColumnDef="Desde">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Desde}} </th>
          <td mat-cell *matCellDef="let row">
            {{row.FechaInicio}} </td>
        </ng-container>

        <!-- PorDefecto Column -->
        <ng-container matColumnDef="Hasta">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Hasta}} </th>
          <td mat-cell *matCellDef="let row">
            {{row.FechaFin}} </td>
        </ng-container>

        <!-- Comprobaciones Column
                <ng-container matColumnDef="Comprobaciones">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Comprobaciones </th>
                  <td mat-cell *matCellDef="let row"> {{row.CompHoraria.trim().toLowerCase() == 'true'?'hora':''}}{{row.PermisoSinFecha.trim().toLowerCase() == 'true'?' sinfech':''}}{{row.CompDiaria.trim().toLowerCase() == 'true'?' diaria':''}}
                    {{row.CompDiaSemana.trim().toLowerCase() == 'true'?'diaSemana':''}}{{row.CompIntervalo.trim().toLowerCase() == 'true'?' intervalo':''}}{{row.CompPasoPrevio.trim().toLowerCase() == 'true'?' pasoprevio':''}}
                    {{row.CompCalendario.trim().toLowerCase() == 'true'?'calend':''}}{{row.CompCalendHorario.trim().toLowerCase() == 'true'?' calHorario':''}}{{row.DenegarAcceso.trim().toLowerCase() == 'true'?' denegar':''}}
                  </td>
                </ng-container> -->

        <!-- Irregular Column -->
        <ng-container matColumnDef="Irregular">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Irregular}} </th>
          <td mat-cell *matCellDef="let row"> {{row.chTipoSesion == 'SI' ? 'Sí' : 'No'}} </td>
        </ng-container>

        <!-- Aforos Column -->
        <ng-container matColumnDef="Aforo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.AforoMaximoMinimoInternet}} </th>
          <td mat-cell *matCellDef="let row"> {{row.AforoMaximo + ' / ' + row.MinimoAforo + ' / ' + row.MaximoInternet}}
          </td>
        </ng-container>

        <!-- Intervalo Column -->
        <ng-container matColumnDef="Intervalo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Intervalo}} </th>
          <td mat-cell *matCellDef="let row"> {{row.Intervalo}} </td>
        </ng-container>

        <!-- Duracion Column -->
        <ng-container matColumnDef="Duracion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Duracion}} </th>
          <td mat-cell *matCellDef="let row"> {{row.Duracion}} </td>
        </ng-container>

        <!-- Dias Semana Column -->
        <ng-container matColumnDef="Semana">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.DiasSemana}} </th>
          <td mat-cell *matCellDef="let row"> {{row.Lunes.trim().toLowerCase() == '1' ? 'lun' : ''}}
            {{row.Martes.trim().toLowerCase() == '1' ? ' mar' : ''}}
            {{row.Miercoles.trim().toLowerCase() == '1' ? ' mier' : ''}}
            {{row.Jueves.trim().toLowerCase() == '1' ? 'jue' : ''}}
            {{row.Viernes.trim().toLowerCase() == '1' ? ' vie' : ''}}
            {{row.Sabado.trim().toLowerCase() == '1' ? ' sab' : ''}}
            {{row.Domingo.trim().toLowerCase() == '1' ? ' dom' : ''}}
            {{row.Evento.trim().toLowerCase() == '1' ? ' EV' : ''}}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" (click)="editar(row)">
        </tr>
      </table>

    </div>

    <span _ngcontent-c0="" class="div-separador"></span>

  </div>
  <!------------------------------------------------------------------>
  <!------------------------------------------------------------------>
  <!------------------------------------------------------------------>
  <!------------------------------------------------------------------>
  <!------------------------------------------------------------------>
  <!------------------------------------------------------------------>
  <!------------------------------------------------------------------>
  <!--foro online muestra idionas espacio. control aforo no muestra. nO OBLIGATORIO-->
  <div class="main-container" [hidden]="!editando">
    <form [formGroup]="editorRegistroForm">
      <div class="xs-pd-20-10">
        <div class="min-height-200px">
          <div class="card-box mb-30">
            <div class="clearfix">
              <div class="pull-left">
                <h4 class="text-blue h4">{{translation.TiposDeSesion}}</h4>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-12">
                <mat-form-field class="input-select" appearance="outline">
                  <mat-label>{{translation.Recinto}}</mat-label>
                  <mat-select placeholder="{{translation.Recinto}}" formControlName="recinto" name="tipoRecinto"
                    [value]="model.Recinto" #listaRecintos [disabled]="this.model.pkId != null"
                    (selectionChange)="CambioRecintoSeleccionado($event)">
                    <mat-option *ngFor="let tiporecinto of filtrarRecintosSinButacas(tiposRecintos)"
                      [value]="tiporecinto.PkId">
                      {{tiporecinto.Nombre}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="form-group row col-md-4">
              <div class="clearfix">
                <div class="pull-left" *ngFor="let tipoAforo of globals.tiposAforo">
                  <h5 class="h5" *ngIf="this.recintoSeleccionado.TipoAforo === tipoAforo.id">
                    {{this.translation.TipoAforo}}: {{tipoAforo.value}}</h5>
                  <!--<h5 class="h5">{{this.translation.TipoAforo}}: {{traeTipoAforoRecintoxID(this.recintoSeleccionado.TipoAforo)}}</h5>-->
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="custom-checkbox col-md-4">
                <p>{{this.translation.ControlaAforo}}:
                  {{this.recintoSeleccionado.ControlarAforo == '0' ? 'NO' : this.recintoSeleccionado.ControlarAforo ==
                  '1' ? 'SI' : null}}</p>
                <!--<mat-checkbox color="primary" (click)="clickAforo()" formControlName="controlaaforo"
                    [(ngModel)]="controlaaforo">
                    {{this.translation.ControlaAforo}}</mat-checkbox>-->
              </div>
              <div class="custom-checkbox col-md-4">
                <p>{{this.translation.ControlaAforoOnline}}:
                  {{this.recintoSeleccionado.ControlarAforoReserva == '0' ? 'NO' :
                  this.recintoSeleccionado.ControlarAforoReserva == '1' ? 'SI' : null}}</p>
                <!--<mat-checkbox color="primary" (click)="clickAforoOnline()" formControlName="controlaaforoonline"
                    [(ngModel)]="controlaaforoonline">
                    {{this.translation.ControlaAforoOnline}}</mat-checkbox>-->
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-12">
                <mat-form-field appearance="outline">
                  <mat-label>{{translation.Nombre}}</mat-label>
                  <label>
                    <input matInput [(ngModel)]="model.Descripcion" formControlName="nombre" type="text"
                      placeholder="Nombre">
                  </label>
                </mat-form-field>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-12">
                <mat-form-field class="input-select" appearance="outline">
                  <mat-label>{{translation.IdiomaDeActividad}}</mat-label>
                  <mat-select placeholder="{{translation.IdiomaDeActividad}}" formControlName="idioma" name="idioma"
                    [(ngModel)]="CodIdioma" #listaRecintos [disabled]="!this.recintoSeleccionado">
                    <!--(selectionChange)=""-->
                    <mat-option *ngFor="let idioma of this.tiposIdiomas" [value]="idioma.CodIdioma">
                      {{idioma.Nombre}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div *ngIf="clickAforoOnline()">

              <div class="clearfix">
                <div class="pull-left">
                  <h4 class="text-blue h4">{{translation.IdiomasDelEspacio}}</h4>
                </div>
              </div>
              <div class="form-group" *ngFor="let idioma of this.tiposIdiomas">
                <div class=" col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.NombreInternet}} en {{idioma.Nombre}}</mat-label>
                    <label>
                      <input matInput type="text" formControlName="nombreInternet_{{idioma.PkId}}"
                        placeholder="{{translation.NombreInternet}} en {{idioma.Nombre}}">
                    </label>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-12 col-md-4">
                <mat-form-field appearance="outline">
                  <mat-label>{{translation.AforoMaximo}}</mat-label>
                  <label>
                    <input matInput type="text" [(ngModel)]="this.model.AforoMaximo" placeholder="Aforo Máximo"
                      formControlName="aforomaximo">
                  </label>
                </mat-form-field>
              </div>
              <div class="col-sm-12 col-md-4">
                <mat-form-field appearance="outline">
                  <mat-label>{{translation.AforoMinimo}}</mat-label>
                  <label>
                    <input matInput type="text" [(ngModel)]="this.model.MinimoAforo" placeholder="Aforo Mínimo"
                      formControlName="aforominimo">
                  </label>
                </mat-form-field>
              </div>
              <div class="col-sm-12 col-md-4">
                <mat-form-field appearance="outline">
                  <mat-label>{{translation.AforoInternet}}</mat-label>
                  <label>
                    <input matInput [(ngModel)]="this.model.MaximoInternet" formControlName="aforointernet" type="text"
                      placeholder="Aforo internet">
                  </label>
                </mat-form-field>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-12">
                <mat-form-field appearance="outline">
                  <mat-label>{{translation.NumeroGrupos}}</mat-label>
                  <label>
                    <input matInput type="text" placeholder="Número grupos" formControlName="numerogrupos"
                      [(ngModel)]="model.NumeroGrupos">
                  </label>
                </mat-form-field>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-12 col-md-6">
                <mat-form-field appearance="outline">
                  <mat-label>{{translation.FechaDesde}}</mat-label>
                  <input matInput formControlName="fechaDesde" [(ngModel)]="model.FechaInicio" [matDatepicker]="dpdesde"
                    placeholder="{{translation.FechaDesde}}" [min]="fecha_minDesde" [max]="fecha_maxDesde"
                    (dateChange)="cambiadesde($event)" [value]="getFechaInicio()">
                  <mat-datepicker-toggle matSuffix [for]="dpdesde"></mat-datepicker-toggle>
                  <mat-datepicker #dpdesde></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-sm-12 col-md-6">
                <mat-form-field appearance="outline">
                  <mat-label>{{translation.FechaHasta}}</mat-label>
                  <input matInput formControlName="fechaHasta" [(ngModel)]="model.FechaFin" [matDatepicker]="dphasta"
                    placeholder="{{translation.FechaHasta}}" [min]="fecha_minHasta" [max]="fecha_maxHasta"
                    (dateChange)="cambiahasta($event)" [value]="getFechaFin()">
                  <mat-datepicker-toggle matSuffix [for]="dphasta"></mat-datepicker-toggle>
                  <mat-datepicker #dphasta></mat-datepicker>
                </mat-form-field>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-12 col-md-6">
                <mat-form-field appearance="outline">
                  <mat-label>{{translation.HoraInicio}}</mat-label>
                  <input matInput formControlName="horainicio" type="time" [(ngModel)]="model.HoraInicio"
                    placeholder="{{translation.SeleccionaHoraDesde}}" (change)="cambiaHoradesde($event)">
                  <div *ngIf="f.horainicio.errors" class="invalid-feedback">
                    <div *ngIf="f.horainicio.hasError('noenRango')">{{translation.NoConcuerdaConElHorarioDelRecinto}}(
                      {{recintoSeleccionado.MaximoInternet}} )
                    </div>
                  </div>
                </mat-form-field>
              </div>
              <div class="col-sm-12 col-md-6">
                <mat-form-field appearance="outline">
                  <mat-label>{{translation.HoraFin}}</mat-label>
                  <input matInput formControlName="horafin" type="time"
                    placeholder="{{translation.SeleccionaHoraHasta}}" (change)="cambiaHorahasta($event)"
                    [(ngModel)]="model.HoraFin">
                  <div *ngIf="f.horafin.errors" class="invalid-feedback">
                    <div *ngIf="f.horafin.hasError('noenRango')">{{translation.NoConcuerdaConElHorarioDelRecinto}}(
                      {{recintoSeleccionado.MaximoInternet}} )
                    </div>
                  </div>
                </mat-form-field>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-12 col-md-4">
                <mat-form-field class="input-select" appearance="outline">
                  <mat-label>{{translation.IntervaloAforo}}</mat-label>
                  <mat-select placeholder="{{translation.IntervaloAforo}}" formControlName="intervaloaforo"
                    name="tipoIntervalo" [value]="model.Intervalo" #listaIntervalos
                    (selectionChange)="cambiarDuracionDisabled($event)" [(ngModel)]="model.Intervalo">
                    <mat-option *ngFor="let tipointervalo of arr_intervalos_aforo"
                      [value]="tipointervalo.minutos.toString()">
                      {{tipointervalo.texto}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-sm-12 col-md-4">
                <mat-form-field appearance="outline">
                  <mat-label>{{translation.Duracion}} {{translation.Hor}}</mat-label>
                  <input matInput formControlName="duracionhora" placeholder="{{translation.Hor}}"
                    [(ngModel)]="DuracionHoras">
                </mat-form-field>
              </div>
              <div class="col-sm-12 col-md-4">
                <mat-form-field appearance="outline">
                  <mat-label>{{translation.Duracion}} {{translation.Min}}</mat-label>
                  <input matInput formControlName="duracionminuto" placeholder="{{translation.Min}}"
                    [(ngModel)]="DuracionMinutos">
                </mat-form-field>
              </div>

              <div class="col-sm-12">
                <mat-form-field class="input-select" appearance="outline">
                  <mat-select placeholder="{{translation.DiasConAcceso}}" formControlName="tipoControlDias"
                    name="tipoDias" multiple [compareWith]="comparaObjetosSelect"
                    (selectionChange)="CambioDiasSemanaMultiple($event)">
                    <mat-option *ngFor="let dia of globales.diasSemana" [value]="dia.id">
                      {{dia.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>


            <div class="form-group">
              <div class="row">
                <div class="custom-checkbox col-md-4">
                  <mat-checkbox formControlName="evento" [(ngModel)]="model.Evento" color="primary">
                    {{translation.EventoEspecial}}</mat-checkbox>
                </div>
              </div>
            </div>


            <div class="collapse collapse-box" id="horizontal-basic-form1">
              <div class="code-box">
                <div class="clearfix">
                  <a href="javascript:;" class="btn btn-primary btn-sm code-copy pull-left"
                    data-clipboard-target="#horizontal-basic"><i class="fa fa-clipboard"></i> Copy Code</a>
                  <a href="#horizontal-basic-form1" class="btn btn-primary btn-sm pull-right" rel="content-y"
                    data-toggle="collapse" role="button"><i class="fa fa-eye-slash"></i> Hide Code</a>
                </div>
              </div>
            </div>
          </div>
          <!-- horizontal Basic Forms End -->
        </div>
      </div>
    </form>

    <div>
      <button class="btn btn-success btn-magento-naranja" (click)="pregBorrarSesiones()"
        [disabled]="!editorRegistroForm.valid"
        [hidden]="!permisobtnGuardar || !editando">{{translation.Guardar}}</button>
      <button class="btn btn-warning btn-magento-gris-claro" (click)="editando=false;" [hidden]="!editando">
        {{translation.Descartar}}</button>
    </div>
  </div>
</div>