import {CLienteAPi} from "./cliente-api.model";
import {IdiomaGR} from "./grupos-recintos-Respuesta.model"

//get
export class peticion_TipoIVA_Lista {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;

    pkId: string;
    Nombre: string;
    Descripcion: string;
}

//insUpd
export class peticion_Insercion_Upd_TipoIVA {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;

    pkId: string;
    Nombre: string;
    Descripcion: string;

    Idiomas: IdiomaGR[];

    Funcion: string;
}

//del
export class peticion_Del_TipoIVA {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    PkId: string;
}

