<div class="container col">
  <div class="menu-acciones-metodo">
    <button class="btn btn-primary btn-magento-atras" (click)="editando=false;" [hidden]="!editando"><i
        class="fa fa-arrow-left fa-lg"></i>&nbsp;&nbsp;{{translation.Atras}}</button>
    <button *ngIf="permisobtnEliminar && model.pkId!=null && editando" class="btn btn-danger btn-magento-gris"
      (click)="pregborrarRegistro()">{{translation.Borrar}}</button>
    <button *ngIf="permisobtnDuplicar && model.pkId!=null && editando" class="btn btn-warning btn-magento-gris"
      (click)="duplicarRegistro()">{{translation.DuplicarRegistro}}</button>
    <button class="btn btn-success btn-magento-naranja" (click)="guardar()" [disabled]="!compruebadrops()"
      [hidden]="!permisobtnGuardar || !editando">{{translation.Guardar}}</button>
    <button class="btn btn-warning btn-magento-naranja" (click)="AnadirRegistro()"
      [hidden]="!permisobtnNuevo || editando">{{translation.AnadirGrupoInternet}}</button>
  </div>
  <alert></alert>
  <div [hidden]="editando" class="data-grid">
    <!--Filtros Activos -->
    <div *ngIf="hayFiltros(filtrosactivos)" class="customan-panel-filtros-activos">{{translation.FiltrosActivos}}:
      <ul class="custom-list-horizontal">
        <li *ngIf="(filtrosactivos.textoGeneral != null && filtrosactivos.textoGeneral.trim() !='')">
          {{translation.PalabraClave}}: <span>{{filtrosactivos.textoGeneral}}</span> &nbsp;<i
            class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('General')"></i></li>
        <li *ngIf="(filtrosactivos.Nombre != null && filtrosactivos.Nombre.trim() !='')"> {{translation.Nombre}}:
          <span>{{filtrosactivos.Nombre}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('Nombre')"></i></li>
        <li *ngIf="(filtrosactivos.ValidaGratuita != null && filtrosactivos.ValidaGratuita.trim() !='')">
          {{translation.ValGrat}}: <span>{{filtrosactivos.ValidaGratuita}}</span> &nbsp;<i
            class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('ValidaGratuita')"></i></li>
        <li *ngIf="(filtrosactivos.CodigoTipoReserva != null)"> {{translation.TReserva}}:
          <span>{{traeNombreReservaxID(filtrosactivos.CodigoTipoReserva)}}</span> &nbsp;<i
            class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('TipoReserva')"></i></li>
        <li *ngIf="(filtrosactivos.EnlaceMenu != null)"> {{translation.EMenu}}:
          <span>{{traeEnlacesMenuxID(filtrosactivos.EnlaceMenu)}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('EnlaceMenu')"></i></li>
      </ul>
    </div>
    <!--Botonera-->
    <div class="customan-botonera-magento-tabla">
      <button class="btn btn-warning btn-magento-gris-claro" [class.filtro-activado]="panelFiltrosvisible"
        (click)="MostrarFiltros()"><i class="fa fa-filter fa-lg"></i>&nbsp;&nbsp;{{translation.Filtros}}</button>
      <!--<button *ngIf="permisobtnEliminar && model.PkId!=null && editando" class="btn btn-danger btn-magento-gris" (click)="pregborrarRegistro()">Borrar</button>-->
      <button class="btn btn-primary btn-magento-atras" (click)="MostrarColumnas()"><i
          class="fa fa-cog fa-lg"></i>&nbsp;&nbsp;{{translation.Columnas}}&nbsp;&nbsp;<i class="fa fa-sm"
          [class.fa-caret-up]="panelColumnasvisible" [class.fa-caret-down]="!panelColumnasvisible"></i></button>
    </div>
    <!--Panel de Columnas-->
    <div *ngIf="panelColumnasvisible" class="customan-panel-columnas-tabla">
      {{columnas.selectedOptions.selected.length}} {{translation.De}} {{ displayedColumns.length }}
      {{translation.Visibles}}.
      <div class="containerColumnas-tabla" cdk-scrollable>
        <mat-selection-list #columnas (selectionChange)="visibilidadColumna($event, columnas.selectedOptions.selected)"
          class="columnas-tabla-listado" [formControl]="formControlListaColumnas">
          <!--#columnas-->
          <mat-list-option class="columnas-listado-elemento" checkboxPosition="before"
            *ngFor="let columna of displayedColumns" [value]="columna">
            {{columna}}
          </mat-list-option>
        </mat-selection-list>

      </div>
    </div>
    <!--Panel selector de Filtros-->
    <div *ngIf="panelSelectorFiltrosvisible" class="customan-panel-columnas-tabla">
      {{columnas.selectedOptions.selected.length}} {{translation.De}} {{ displayedFilters.length }}
      {{translation.Visibles}}.
      <div class="containerColumnas-tabla" cdk-scrollable>
        <mat-selection-list #columnas (selectionChange)="visibilidadFiltro($event, columnas.selectedOptions.selected)"
          class="columnas-tabla-listado" [formControl]="formControlListaFiltros">
          <mat-list-option class="columnas-listado-elemento" checkboxPosition="before"
            *ngFor="let filtro of displayedFilters" [value]="filtro">
            {{filtro}}
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
    <!--Panel de Filtros-->
    <div class="customan-panel-filtros-tabla">
      <div class="containerFiltros-tabla">
        <form [formGroup]="editorFiltrosForm">
          <div *ngIf="filtersToDisplay.includes(translation.Nombre)" class="grupo-filtros-campo-registro">
            <mat-form-field appearance="outline">
              <mat-label>{{translation.Nombre}}</mat-label>
              <input matInput placeholder="{{translation.FiltroDeNombre}}" formControlName="nombre"
                [value]="filtrosactivosModelo.Nombre">
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.ValidaGratuitas)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.ValidaGratuitas}}" formControlName="validargratuita"
                name="tipoActivo" [(value)]="filtrosactivosModelo.ValidaGratuita">
                <mat-option> --- --- </mat-option>
                <mat-option value="si">{{translation.Si}}</mat-option>
                <mat-option value="no">{{translation.No}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.TipoDeReserva)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.TipoDeReserva}}" formControlName="tipoControl"
                [(value)]="filtrosactivosModelo.CodigoTipoReserva" [compareWith]="comparaObjetosSelect">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let tipoGrupos of tiposTipoReserva" [value]="tipoGrupos.pkId">
                  {{tipoGrupos.Nombre}}
                </mat-option>
              </mat-select>
              <!-- <mat-hint>{{tipoControl.value?.Descripcion}}</mat-hint> -->
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.EnlacesMenu)" class="grupo-filtros-campo-registro">
            <!---->
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.EnlacesMenu}}" formControlName="tipoControlEM"
                [(value)]="filtrosactivosModelo.EnlaceMenu" [compareWith]="comparaObjetosSelect">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let tipoGrupos3 of tiposEnlaceMenu" [value]="tipoGrupos3.pkid">
                  {{tipoGrupos3.chNombre}}
                </mat-option>
              </mat-select>
              <!-- <mat-hint>{{tipoControl.value?.Descripcion}}</mat-hint> -->
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="customan-botonera-magento-tabla">
        <button class="btn btn-warning btn-magento-gris" (click)="AplicarFiltros()">Aplicar Filtros</button>
      </div>
    </div>
    <div>
      <mat-form-field class="customan-filtro-input">
        <input matInput placeholder="{{translation.BuscarPorPalabraClave}}"
          (keydown.enter)="applyFilter($any($event.target).value)" [value]="filtrosactivosModelo.textoGeneral">
        <i (click)="applyFilter($any($event.target).parentNode.children[0].value)" class="fa fa-search fa-lg"></i>
      </mat-form-field>
    </div>
    <!--Tabla-->
    <div class="mat-elevation-z8">
      
      <mat-paginator #paginatorPrincipal [pageSizeOptions]="[10, 25, 100]" [showFirstLastButtons]="true"
        class="magento-paginator"></mat-paginator>

      <table mat-table [dataSource]="dataSource" matSort>
        <!-- Acciones Column -->
        <ng-container matColumnDef="Acciones">
          <th mat-header-cell *matHeaderCellDef>{{translation.Acciones}}</th>
          <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()" style="cursor: default;">
            <div class="action-container">
              <button customanTooltip="Duplicar Registro" *ngIf="permisobtnDuplicar" class="button-icon"
                (click)=duplicar(row) style="width: 34px; height: 34px;">
                <mat-icon>content_copy</mat-icon>
              </button>
              <button *ngIf="permisobtnEliminar" customanTooltip="Borrar Registro" class="button-icon"
                (click)="borrar(row.PkId)" style="width: 34px; height: 34px;">
                <mat-icon>delete_forever</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>
        <!-- Nombre Column -->
        <ng-container matColumnDef="Nombre">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Nombre}} </th>
          <td mat-cell *matCellDef="let row"> {{row.Nombre}} </td>
        </ng-container>

        <!-- Descripcion Column * -->
        <ng-container matColumnDef="Descripcion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Descripcion}} </th>
          <td mat-cell *matCellDef="let row"> {{row.Descripcion}} </td>
        </ng-container>

        <!-- Minimo Column * -->
        <ng-container matColumnDef="Minimo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Minimo}} </th>
          <td mat-cell *matCellDef="let row"> {{row.Minimo}} </td>
        </ng-container>
        <!-- Maximo Column * -->
        <ng-container matColumnDef="Maximo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Maximo}} </th>
          <td mat-cell *matCellDef="let row"> {{row.Maximo}} </td>
        </ng-container>
        <!-- Orden Column * -->
        <ng-container matColumnDef="Orden">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Orden}} </th>
          <td mat-cell *matCellDef="let row"> {{row.Orden}} </td>
        </ng-container>

        <!-- Idioma Column -->
        <ng-container matColumnDef="Idioma">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Idioma}} </th>
          <td mat-cell *matCellDef="let row"> {{traeIdiomaID(row.Idioma)}} </td>
        </ng-container>

        <!-- Tipo Reserva Column -->
        <ng-container matColumnDef="TReserva">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.TipoReserva}} </th>
          <td mat-cell *matCellDef="let row"> {{traeNombreReservaxID(row.TipoReservaId)}} </td>
        </ng-container>

        <!-- ValidarGratuitas Column -->
        <ng-container matColumnDef="ValidarGratuitas">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.ValidarGratuitas}} </th>
          <td mat-cell *matCellDef="let row"> {{row.ValidarGratuitas}} </td>
        </ng-container>

        <!-- Enlace Menu Column -->
        <ng-container matColumnDef="EMenu">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.EnlacesMenu}} </th>
          <td mat-cell *matCellDef="let row"> {{traeEnlacesMenuxGInternetID(row.pkId)}} </td>
        </ng-container>

        <!-- Columnas de Idiomas -->
        <ng-container [matColumnDef]="columna.CodIdioma + '_' + columna.textoHeader"
          *ngFor="let columna of columnasIdiomas">
          <th mat-header-cell *matHeaderCellDef> {{columna.CodIdioma + ' ' + columna.textoHeader}} </th>
          <td mat-cell *matCellDef="let row"> {{formateaIdioma(row.Idiomas, columna)}} </td>
          <!-- {{element[columna]}}-->
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" (click)="editar(row)">
        </tr>
      </table>
      <!-- <mat-paginator
        (page)="paginatorPrincipal.pageIndex = $event.pageIndex; paginatorPrincipal._emitPageEvent($event.pageIndex)"
        [pageSize]="paginatorPrincipal.pageSize" [pageIndex]="paginatorPrincipal.pageIndex"
        [length]="paginatorPrincipal.length" [pageSizeOptions]="paginatorPrincipal.pageSizeOptions"
        [showFirstLastButtons]="true">
      </mat-paginator> -->
    </div>

    <span _ngcontent-c0="" class="div-separador"></span>

  </div>

  <div [hidden]="!editando" class="editor-registro">
    <form [formGroup]="editorRegistroForm">
      <div class='grupo-editor-campo-registro'>
        <label>{{translation.Nombre}}</label><input formControlName="nombre" class="editor-campo-registro form-control"
          [(ngModel)]="model.Nombre" [ngClass]="{ 'is-invalid': f.nombre.errors }" />
        <div *ngIf="f.nombre.errors" class="invalid-feedback">
          <div *ngIf="f.nombre.errors.required">{{translation.SeRequiereNombre}}</div>
        </div>
      </div>
      <div class='grupo-editor-campo-registro'>
        <label>{{translation.Descripcion}}</label><input formControlName="descripcion"
          class="editor-campo-registro form-control" [(ngModel)]="model.Descripcion"
          [ngClass]="{ 'is-invalid': f.descripcion.errors }">
        <div *ngIf="f.descripcion.errors" class="invalid-feedback">
          <div *ngIf="f.descripcion.errors.required">{{translation.SeRequiereDescripcion}}</div>
        </div>
      </div>

      <div class='grupo-editor-campo-registro'>
        <label>{{translation.Prefijo}}</label><input formControlName="chCodigo"
          class="editor-campo-registro form-control" [(ngModel)]="model.chCodigo" />
      </div>

      <div class=grupo-editor-campo-registro>
        <label>{{translation.Maximo}}</label><input formControlName="maximo" class="editor-campo-registro form-control"
          [(ngModel)]="model.Maximo" [ngClass]="{ 'is-invalid': f.maximo.errors }">
        <div  class="invalid-feedback">
          <div >valor numérico</div>
        <!-- <div *ngIf="f.maximo.errors" class="invalid-feedback">
          <div *ngIf="f.maximo.pattern">valor numérico</div> -->
        </div>
      </div>

      <div class=grupo-editor-campo-registro>
        <label>{{translation.Minimo}}</label><input formControlName="minimo" class="editor-campo-registro form-control"
          [(ngModel)]="model.Minimo" [ngClass]="{ 'is-invalid': f.minimo.errors }">
        <div  class="invalid-feedback">
        <!-- <div *ngIf="f.minimo.errors" class="invalid-feedback"> -->
          <div >{{translation.ValorNumerico}}</div>
          <!-- <div *ngIf="f.minimo.pattern">{{translation.ValorNumerico}}</div> -->
        </div>
      </div>

      <div class=grupo-editor-campo-registro>
        <label>{{translation.Orden}}</label><input formControlName="orden" class="editor-campo-registro form-control"
          [(ngModel)]="model.Orden" [ngClass]="{ 'is-invalid': f.orden.errors }">
        <div  class="invalid-feedback">
        <!-- <div *ngIf="f.orden.errors" class="invalid-feedback"> -->
          <div >{{translation.ValorNumerico}}</div>
          <!-- <div *ngIf="f.orden.pattern">{{translation.ValorNumerico}}</div> -->
        </div>
      </div>

      <div class=grupo-editor-campo-registro>
        <label>{{translation.Idioma}}</label>
        <mat-form-field class="input-select">
          <mat-select placeholder="{{translation.Idioma}}" [formControl]="tipodidiomacontrol" name="tipodidiomacontrol"
            [(ngModel)]="model.Idioma" required>
            <mat-option>--</mat-option>
            <mat-option *ngFor="let tipoGrupos1 of tiposIdiomas" [value]="tipoGrupos1.CodIdioma">
              {{tipoGrupos1.CodIdioma}}
            </mat-option>
          </mat-select>
          <!-- <mat-error *ngIf="tipoGrupoEntradaControl.hasError('required')">Por favor Seleccione una categoria</mat-error> -->
        </mat-form-field>
      </div>

      <div class=grupo-editor-campo-registro>
        <label>{{translation.TipoDeReserva}}</label>
        <mat-form-field class="input-select">
          <mat-select placeholder="{{translation.TipoDeReserva}}" [formControl]="tipodereservacontrol"
            name="tipoTipoReservaControl" [(ngModel)]="model.TipoReservaId" required>
            <mat-option>--</mat-option>
            <mat-option *ngFor="let tipoGrupos1 of tiposTipoReserva" [value]="tipoGrupos1.pkId">
              {{tipoGrupos1.Nombre}}
            </mat-option>
          </mat-select>
          <!-- <mat-error *ngIf="tipoGrupoEntradaControl.hasError('required')">Por favor Seleccione una categoria</mat-error> -->
        </mat-form-field>
      </div>

      <div class=grupo-editor-campo-registro>
        <label>{{translation.ValidarGratuitas}}</label><input formControlName="validargratuita" type="checkbox"
          class="editor-campo-registro-check form-check-input form-control" [(ngModel)]="model.ValidarGratuitas">
      </div>

      <div class=grupo-editor-campo-registro>
        <label>{{translation.EnlaceMenu}}</label>
        <mat-form-field class="input-select">
          <mat-select placeholder="{{translation.TipoDeEnlaceMenu}}" [formControl]="enlacemenucontrol"
            name="tipoenlaceMenuControl" [(ngModel)]="model.EnlaceMenuId" required>
            <mat-option>--</mat-option>
            <mat-option *ngFor="let tipoGrupos2 of tiposEnlaceMenu" [value]="tipoGrupos2.pkid.toString().trim()">
              {{tipoGrupos2.chDescripcion}}
            </mat-option>
          </mat-select>
          <!-- <mat-error *ngIf="tipoGrupoEntradaMotivo.hasError('required')">Por favor Seleccione una categoria</mat-error> -->
        </mat-form-field>
      </div>

      <!--Idiomas-->
      <div class="grupo-editor-campo-registro" *ngFor="let idioma of editorListaIdiomas">
        <label>{{translation.NombreInternet}} ({{idioma.CodIdioma}})</label>
        <div class="input-idiomas-editor mat-elevation-z8">
          <mat-form-field>
            <input matInput placeholder="{{translation.Nombre}}"
              (change)="cambiaNombre($any($event.target).value, idioma.CodIdioma)" [value]="idioma.Nombre">
            <mat-hint>{{translation.Nombre}} {{idioma.CodIdioma}}</mat-hint>
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="{{translation.Descripcion}}"
              (change)="cambiaDescripcion($any($event.target).value, idioma.CodIdioma)" [value]="idioma.Descripcion">
            <mat-hint>{{translation.Descripcion}} {{idioma.CodIdioma}}</mat-hint>
          </mat-form-field>
        </div>
      </div>

    </form>
  </div>
</div>