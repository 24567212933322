import { SelectionModel } from "@angular/cdk/collections";
import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

import { first } from "rxjs/operators";

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

import {
  GrupoTipoBono,
  grupo_tipo_bono_Respuesta,
} from "../../_modelos/grupo-tipo-bono-Respuesta.model";

import { ActivatedRoute, Router } from "@angular/router";
import { Globales } from "@clasesV6/globales";
import { Translator } from "src/app/shared/services/translator/translator.service";
import { ConectarAPIService } from "../../_API/conectar-api.service";
import { AutentificacionService } from "../../_otrosproveedores/autentificacion.service";
import { AlertaService } from "../../shared/_alerta/alerta.service";
import { DialogoPopUp } from "../../shared/_alerta/popup.service";

export interface DialogData {
  titulo: string;
  texto: string;
  eleccion: string;
  ok: string;
  ko: string;
}

export interface AccionesDialogo {
  accion: string;
  texto: string;
  funcion: string;
}

export class traspasomodelo {
  PkId: string;
  NombreGrupo: string;
  Descripcion: string;
}

export class filtrosActivos {
  Nombre: string;
  Descripcion: string;
  textoGeneral: string;
}

@Component({
  selector: "app-grupo-tipo-bono",
  templateUrl: "./grupo-tipo-bono.component.html",
  styleUrls: ["./grupo-tipo-bono.component.css"],
})
export class GrupoTipoBonoComponent implements OnInit {
  displayedColumns: string[] = ["Acciones", "NombreGrupo", "Descripcion"];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  displayedFilters: string[] = ["Nombre", "Descripcion"];
  filtersToDisplay: string[] = this.displayedFilters.slice();

  dataSource: MatTableDataSource<GrupoTipoBono>;
  seleccion = new SelectionModel<GrupoTipoBono>(true, []);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  respuestaAPI: grupo_tipo_bono_Respuesta;
  editando: boolean = false;
  leyendo: boolean = true;
  mensaje_dialogo: string;
  eleccion_dialogo: string;
  titulo_dialogo: string;
  ok_dialogo: string;
  ko_dialogo: string;
  editorRegistroForm: FormGroup;
  editorFiltrosForm: FormGroup;
  permisoFormulario: boolean = true;
  permisobtnNuevo: boolean = true;
  permisobtnGuardar: boolean = false;
  permisobtnEliminar: boolean = false;
  permisobtnDuplicar: boolean = false;
  Acciones: Array<AccionesDialogo>;
  accionselecionada: string = null;
  panelFiltrosvisible: boolean = false;
  panelColumnasvisible: boolean = false;
  formControlListaColumnas: FormControl;
  filtrosactivosModelo: filtrosActivos = new filtrosActivos();
  filtrosactivos: filtrosActivos = Object.assign({}, this.filtrosactivosModelo);
  translation: any;
  panelSelectorFiltrosvisible: boolean = false;
  formControlListaFiltros: FormControl;

  constructor(
    private servicioAPI: ConectarAPIService,
    private alertaServicio: AlertaService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private autentificador: AutentificacionService,
    private router: Router,
    private _ruta: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private translator: Translator
  ) {}

  @HostBinding("class.col") true;

  ngAfterViewInit() {
    //this.initializeForms()
    this.cdRef.detectChanges();
  }
  async ngOnInit() {
    this.translation = this.translator.GetTranslations();
    this.displayedFilters = [
      this.translation.Nombre,
      this.translation.Descripcion,
    ];
    this.filtersToDisplay = this.displayedFilters.slice();

    //Comprobamos logueo en QueryString
    this.autentificador.verificarAccesoQueryString(this._ruta).then(
      //montamos los segmentos de la Ruta uno a uno porque la URL contiene los parametros y no nos sirve
      (res) => {
        let ruta = "/";
        this._ruta.snapshot.pathFromRoot[
          this._ruta.snapshot.pathFromRoot.length - 1
        ].url.forEach((elemento) => {
          ruta = ruta + elemento.path + "/";
        });
        ruta = ruta.substring(0, ruta.length - 1);
        this.permisoFormulario = this.autentificador.visualizacionAccion(
          ruta,
          "VER"
        );
        if (!this.permisoFormulario) {
          this.router.navigate(["/portada"]);
        }
        this.permisobtnNuevo = this.autentificador.visualizacionAccion(
          ruta,
          "NUEVO"
        );
        this.permisobtnGuardar = this.autentificador.visualizacionAccion(
          ruta,
          "MODIFICAR"
        );
        this.permisobtnEliminar = this.autentificador.visualizacionAccion(
          ruta,
          "ELIMINAR"
        );
        this.permisobtnDuplicar = this.autentificador.visualizacionAccion(
          ruta,
          "DUPLICAR"
        );
        //Creamos el desplegable de acciones
        this.Acciones = new Array<AccionesDialogo>();
        if (this.permisobtnEliminar) {
          this.Acciones.push({
            accion: "borrar",
            texto: this.translation.Borrar,
            funcion: "BorrarSeleccionados",
          });
        }
      }
    );
    this.cargarDatos();
    this.resetearFiltrosActivos();

    this.editorRegistroForm = this.formBuilder.group({
      nombre: ["", Validators.required],
      descripcion: [""],
    });

    //TODO  Validators
    this.editorFiltrosForm = this.formBuilder.group({
      nombre: [""],
      descripcion: [""],
    });

    this.formControlListaColumnas = new FormControl(this.displayedColumns);
  }

  // un getter para facilitar el acceso a los campos del form
  get f() {
    return this.editorRegistroForm.controls;
  } //Editor
  get fFiltro() {
    return this.editorFiltrosForm.controls;
  } //Filtros

  model = new traspasomodelo();

  AnadirRegistro() {
    this.model = new traspasomodelo();
    this.editando = true;
    this.alertaServicio.limpiar();
  }

  editar(item) {
    let objtraspaso = new traspasomodelo();
    objtraspaso.PkId = item.PkId;
    objtraspaso.NombreGrupo = item.NombreGrupo;
    objtraspaso.Descripcion = item.Descripcion;
    this.model = objtraspaso;
    this.editando = true;
    this.alertaServicio.limpiar();
    this.cdRef.detectChanges();
  }

  guardar() {
    let insertando = this.model.PkId == null;
    let globales: Globales = new Globales();
    if (!this.editorRegistroForm.invalid) {
      //Comprobamos que el Pkid no sea null (estariamos insertando)
      let objtraspaso = new traspasomodelo();
      if (this.model.PkId != null) {
        objtraspaso = this.dataSource.data
          .map<traspasomodelo>(function (item) {
            return {
              PkId: item.PkId,
              NombreGrupo: item.NombreGrupo,
              Descripcion: item.Descripcion,
              deserialize: null,
            };
          })
          .find((item) => item.PkId == this.model.PkId);
      }
      objtraspaso.NombreGrupo = this.model.NombreGrupo;
      objtraspaso.Descripcion = this.model.Descripcion;

      //Salvar a API
      this.servicioAPI
        .insUpdGrupoTipoBono(
          this.model.PkId,
          this.model.NombreGrupo,
          this.model.Descripcion
        )
        .pipe(first())
        .subscribe(
          (data) => {
            this.respuestaAPI = new grupo_tipo_bono_Respuesta().deserialize(
              data
            );
            if (this.respuestaAPI.DatosResult != null) {
              this.alertaServicio.success("Datos Guardados Correctamente");
              let max = Math.max.apply(
                Math,
                this.dataSource.data.map(function (item) {
                  return item.PkId;
                })
              );
              //Hacemos un push al data para que se muestre
              if (insertando) {
                this.model.PkId = "" + (max + 1);
                let pusheddata = this.dataSource.data;
                pusheddata.push(this.modeloaclaseAPI(this.model));
                this.dataSource.data = pusheddata;
              } else {
                this.cargarDatos();
              }
              this.editando = false;
            } else {
              this.alertaServicio.error(
                this.respuestaAPI.Mensajes[0].DescripcionMensaje
              );
              this.editando = false;
            }
          },
          (error) => {
            this.alertaServicio.error(error);
            this.editando = false;
          }
        );
    }
  }

  duplicarRegistro() {
    //this.model = new CustomanUsuario();
    if (this.model.PkId != null) {
      this.model.PkId = null; //si no estamos ya con uno nuevo, ponemos el Pkid a null y lo trataremos como insercion.
    }
  }

  pregborrarRegistro() {
    //Borrar
    if (this.model.PkId != null) {
      //Si no estamos añadiendo
      this.titulo_dialogo = this.translation.Borrar;
      this.mensaje_dialogo =
        this.translation.EstaSeguroQueDeseaBorrarEsteRegistro;
      this.ok_dialogo = this.translation.Si;
      this.ko_dialogo = this.translation.Cancelar;
      this.openDialog();
    }
  }

  async applyFilter(filterValue: string) {
    this.alertaServicio.limpiar();
    await this.AplicarFiltros();
    this.dataSource.filter = filterValue.trim().toLowerCase();

    this.filtrosactivosModelo.textoGeneral = filterValue.trim();
    this.filtrosactivos = Object.assign({}, this.filtrosactivosModelo);

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.alertaServicio.limpiar();
  }

  //Acciones
  HacerAccion(any) {
    this.alertaServicio.limpiar();
    if (any != null && any != undefined) {
      if (
        this.seleccion.selected == null ||
        this.seleccion.selected.length == 0
      ) {
        this.titulo_dialogo = this.translation.Error;
        this.mensaje_dialogo =
          this.translation.NoHayRegistrosSeleccionadosParaEstaAccion;
        this.ok_dialogo = this.translation.Ok;
        this.openDialogSeleccionVacia();
      } else {
        this.titulo_dialogo = this.translation.ConfirmarBorrado;
        this.mensaje_dialogo =
          this.translation.VaAProcederAlBorradoDe +
          this.seleccion.selected.length.toString() +
          this.translation.RegistrosDeseaConfirmarElBorrado;
        this.ok_dialogo = this.translation.Si;
        this.ko_dialogo = this.translation.No;
        this.openDialogBorradoSeleccionadas();
      }
    }
  }

  MostrarColumnas() {
    this.alertaServicio.limpiar();
    this.panelColumnasvisible = !this.panelColumnasvisible;
    //Cambiar el boton
    this.formControlListaColumnas = new FormControl(this.columnsToDisplay);
  }

  visibilidadColumna(evento, seleccioncolumnas) {
    if (evento != null && seleccioncolumnas != null) {

      if (!evento.option.selected) {
        //Borramos si no nos quedamos sin columnas
        if (this.columnsToDisplay.length) {
          this.columnsToDisplay.splice(
            this.columnsToDisplay.findIndex((x) => x === evento.option.value),
            1
          );
        }
      } else {
        //Añadimos
        this.columnsToDisplay = [];
        for (let columna of this.displayedColumns) {
          if (seleccioncolumnas.findIndex((x) => x.value === columna) >= 0) {
            this.columnsToDisplay.push(columna);
          }
        }
      }
    }
  }

  //Click en el boton de aplicar Filtros a los datos
  async AplicarFiltros(filtro?: filtrosActivos) {
    this.alertaServicio.limpiar();
    //CArgamos los datos de muevo solo porsiacaso
    await this.cargarDatosAsync().then(
      (respuesta) => {
        let siono = new Boolean(respuesta);
        if (siono) {
          let datostemporales = this.dataSource.data;
          //aplicamos los filtros sobre los datos temporales
          if (filtro != null) {
            if (filtro.Nombre != null && filtro.Nombre.trim() != "") {
              datostemporales = datostemporales.filter(
                (x) => x.NombreGrupo.indexOf(filtro.Nombre.trim()) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.Nombre = filtro.Nombre.trim();
            }
            this.fFiltro["nombre"].setValue(filtro.Nombre.trim());

            if (filtro.Descripcion != null && filtro.Descripcion.trim() != "") {
              datostemporales = datostemporales.filter(
                (x) => x.Descripcion.indexOf(filtro.Descripcion.trim()) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.Descripcion = filtro.Descripcion.trim();
            }
            this.fFiltro["descripcion"].setValue(filtro.Descripcion.trim());
          } else {
            if (
              this.fFiltro["nombre"].value != null &&
              this.fFiltro["nombre"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.NombreGrupo.indexOf(this.fFiltro["nombre"].value.trim()) >=
                  0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.Nombre = this.fFiltro["nombre"].value;
            }

            if (
              this.fFiltro["descripcion"].value != null &&
              this.fFiltro["descripcion"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.Descripcion.indexOf(
                    this.fFiltro["descripcion"].value.trim()
                  ) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.Descripcion =
                this.fFiltro["descripcion"].value;
            }
          }

          this.dataSource.data = datostemporales;

          if (
            this.filtrosactivosModelo.textoGeneral != null &&
            this.filtrosactivosModelo.textoGeneral.trim() != ""
          ) {
            this.dataSource.filter = this.filtrosactivosModelo.textoGeneral
              .trim()
              .toLowerCase();
          } else {
            //Solo borramos el input text, el filtro se actualiza Ok
            //this.filtrogeneral.nativeElement.value = '';
          }
          this.filtrosactivos = Object.assign({}, this.filtrosactivosModelo);

          if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
          }
        } else {
          //No hemos podido cargar los datos
        }
      },
      (error) => {
        //No hemos podido cargar los datos
      }
    );
  }

  //Para resetear filtros, se pasa el filtro o se ejecuta sin parametro y se resetean todos
  resetearFiltrosActivos(filtro?: string) {
    this.filtrosactivosModelo.Nombre =
      filtro == null || filtro == "Nombre"
        ? ""
        : this.filtrosactivosModelo.Nombre;
    this.filtrosactivosModelo.Descripcion =
      filtro == null || filtro == "Descripcion"
        ? ""
        : this.filtrosactivosModelo.Descripcion;
    this.filtrosactivosModelo.textoGeneral =
      filtro == null || filtro == "General"
        ? ""
        : this.filtrosactivosModelo.textoGeneral;
    this.filtrosactivos = Object.assign({}, this.filtrosactivosModelo);
    this.AplicarFiltros(this.filtrosactivos);
    if (this.editorFiltrosForm != null) {
      this.editorFiltrosForm.updateValueAndValidity({ onlySelf: true });
      this.editorFiltrosForm.markAsPristine();
    }
  }

  hayFiltros(filtros: filtrosActivos): boolean {
    if (
      (filtros.Nombre != null && filtros.Nombre.trim() != "") ||
      (filtros.Descripcion != null && filtros.Descripcion.trim() != "") ||
      (filtros.textoGeneral != null && filtros.textoGeneral.trim() != "")
    )
      return true;
    else return false;
  }

  //Para que funcione le mat select cuando al option le metes objetos en lugar de strings simples
  comparaObjetosSelect(o1: any, o2: any) {
    if ((o1 == null && o2 == null) || o1.Pkid == o2.Pkid) return true;
    else return false;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.seleccion.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.seleccion.clear()
      : this.dataSource.data.forEach((row) => this.seleccion.select(row));
  }
  ///Validador

  ///Funcionalidades

  cargarDatos() {
    //Cargamos Datos
    this.servicioAPI
      .getGrupoTipoBono()
      .pipe(first())
      .subscribe(
        (data) => {
          this.respuestaAPI = new grupo_tipo_bono_Respuesta().deserialize(data);
          if (this.respuestaAPI.DatosResult != null) {
            this.dataSource = new MatTableDataSource(
              this.respuestaAPI.DatosResult.customanGrupoTipoBono
            );
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          } else {
            this.alertaServicio.error(
              this.respuestaAPI.Mensajes[0].DescripcionMensaje
            );
          }
        },
        (error) => {
          this.alertaServicio.error(error);
        }
      );
  }

  async cargarDatosAsync(): Promise<boolean> {
    //Cargamos Datos
    let resultado: boolean = false;
    await this.servicioAPI.getGrupoTipoBonoAsync().then(
      (data) => {
        this.respuestaAPI = new grupo_tipo_bono_Respuesta().deserialize(data);
        if (this.respuestaAPI.DatosResult != null) {
          this.dataSource = new MatTableDataSource(
            this.respuestaAPI.DatosResult.customanGrupoTipoBono
          );
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        } else {
          this.alertaServicio.error(
            this.respuestaAPI.Mensajes[0].DescripcionMensaje
          );
        }
      },
      (error) => {
        this.alertaServicio.error(error);
        resultado = false;
      }
    );
    return resultado;
  }

  //Cambiar el modelo API (string) a modelo con boolean para los checks
  modeloaclaseAPI(item: traspasomodelo): GrupoTipoBono {
    let respuesta = new GrupoTipoBono();
    respuesta.PkId = item.PkId;
    respuesta.NombreGrupo = item.NombreGrupo;
    respuesta.Descripcion = item.Descripcion;
    return respuesta;
  }

  //Se podria instanciar este openDialog si requiriesemos mas popups (este seria openDialogBorrar)
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: "250px",
      data: {
        titulo: this.titulo_dialogo,
        texto: this.mensaje_dialogo,
        eleccion: this.eleccion_dialogo,
        ok: this.ok_dialogo,
        ko: this.ko_dialogo,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.eleccion_dialogo = result;
      if (result == "SI") {
        //Salvar a API
        this.servicioAPI
          .delGrupoTipoBono(this.model.PkId)
          .pipe(first())
          .subscribe(
            (data) => {
              this.respuestaAPI = new grupo_tipo_bono_Respuesta().deserialize(
                data
              );
              if (this.respuestaAPI.DatosResult != null) {
                this.alertaServicio.success("Datos Borrados Correctamente");
                //this.dataSource.data.splice(,1)
                this.dataSource.data = this.dataSource.data.filter(
                  (item) => item.PkId !== this.model.PkId
                );
              } else {
                this.alertaServicio.error(
                  this.respuestaAPI.Mensajes[0].DescripcionMensaje
                );
              }
            },
            (error) => {
              this.alertaServicio.error(error);
            }
          );
        this.editando = false;
      }
    });
  }

  //Instancia para Accionse sobre seleccion vacia
  openDialogSeleccionVacia(): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: "250px",
      data: {
        titulo: this.titulo_dialogo,
        texto: this.mensaje_dialogo,
        eleccion: this.eleccion_dialogo,
        ok: this.ok_dialogo,
        ko: null,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.accionselecionada = null;
    });
  }

  //Instancia Borrado Seleccionadas
  openDialogBorradoSeleccionadas(): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: "250px",
      data: {
        titulo: this.titulo_dialogo,
        texto: this.mensaje_dialogo,
        eleccion: this.eleccion_dialogo,
        ok: this.ok_dialogo,
        ko: this.ko_dialogo,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.eleccion_dialogo = result;
      if (result == "SI") {
        var resultado: string = "";
        this.seleccion.selected.forEach((element) => {
          //Recorremos los seleccionados y vamos borrando
          this.servicioAPI.delGrupoTipoBonoAsync(element.PkId).then(
            (data) => {
              this.respuestaAPI = new grupo_tipo_bono_Respuesta().deserialize(
                data
              );
              if (this.respuestaAPI.DatosResult != null) {
                this.dataSource.data = this.dataSource.data.filter(
                  (item) => item.PkId !== element.PkId
                );
              } else {
                resultado +=
                  this.respuestaAPI.Mensajes[0].DescripcionMensaje + " <BR />";
              }
            },
            (error) => {
              resultado += error + " <BR />";
            }
          );
        });
        if (resultado != null && resultado.length > 0) {
          this.alertaServicio.error(resultado);
        } else {
          this.alertaServicio.success("Datos Borrados Correctamente");
        }

        //End For
      }
      //F Si
      this.seleccion.clear();
      this.accionselecionada = null;
    });
    //F Subscribe
  }

  duplicar(row: traspasomodelo) {
    this.model = row; // cambiar por los datos de la fila
    if (this.model.PkId != null) {
      this.model.PkId = null; //si no estamos ya con uno nuevo, ponemos el Pkid a null y lo trataremos como insercion.
    }
    this.editando = true;
    this.alertaServicio.limpiar();
  }

  borrar(pkId: string): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: "250px",
      data: {
        titulo: this.translation.BorrarCliente,
        texto: this.translation.EstaSeguroQueDeseaBorrarElRegistro,
        eleccion: this.translation.Patatas,
        ok: this.translation.Confirmar,
        ko: this.translation.Cancelar,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === "SI") {
        this.servicioAPI
          .delGrupoTipoBono(pkId)
          .pipe(first())
          .subscribe(
            (data) => {
              this.respuestaAPI = new grupo_tipo_bono_Respuesta().deserialize(
                data
              );
              if (this.respuestaAPI.DatosResult != null) {
                this.alertaServicio.success("Datos Borrados Correctamente");
                //this.dataSource.data.splice(,1)
                this.dataSource.data = this.dataSource.data.filter(
                  (item) => item.PkId !== pkId
                );
              } else {
                this.alertaServicio.error(
                  this.respuestaAPI.Mensajes[0].DescripcionMensaje
                );
              }
            },
            (error) => {
              this.alertaServicio.error(error);
            }
          );
        this.editando = false;
      }
    });
  }

  visibilidadFiltro(evento, seleccionfiltros) {
    if (evento != null && seleccionfiltros != null) {
      if (!evento.option.selected) {
        //Borramos si no nos quedamos sin filtros
        if (this.filtersToDisplay.length) {
          this.filtersToDisplay.splice(
            this.filtersToDisplay.findIndex((x) => x === evento.option.value),
            1
          );
        }
      } else {
        //Añadimos
        this.filtersToDisplay = [];
        for (let filtro of this.displayedFilters) {
          if (seleccionfiltros.findIndex((x) => x.value === filtro) >= 0) {
            this.filtersToDisplay.push(filtro);
          }
        }
      }
    }
  }

  MostrarFiltros() {
    this.alertaServicio.limpiar();
    this.panelSelectorFiltrosvisible = !this.panelSelectorFiltrosvisible;
    this.panelColumnasvisible = false;
    this.formControlListaFiltros = new FormControl(this.filtersToDisplay);
  }
}
