import { Deserializable } from "../../deserializable";
import { Message } from "../../message/message";
import { Data_usuario_result } from "./data_usuario_result";

export class Usuario_response implements Deserializable {
  DatosResult?: Data_usuario_result;
  Mensajes: Message[];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
