import { Component, ElementRef, Input, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ModalService } from '../servicios/modal.service';
import { Router } from '@angular/router';
//import * as moment from 'moment'; //VO
import moment from 'moment';
import { ButacasService } from '../servicios/butacas.service';

@Component({
  selector: 'iacpos-modal-info-reserva',
  templateUrl: './modal-info-reserva.component.html',
  styleUrls: ['./modal-info-reserva.component.css']
})
export class ModalInfoReservaComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() id: string;
  private element: any;
  claveEmpresa: string;
  ruta: string;

  productosResumen = [];
  reserva;
  totalComision = 0;
  numeroPedido;
  localizador;

  hasLoaded = false;

  reservasYaCargadas= [];

  constructor(
    public router: Router,
    private modalService: ModalService,
    private el: ElementRef,
    private butacasService: ButacasService
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    this.ruta = this.router.url;
    const modal = this;


    // ensure id attribute exists
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener('click', function(e: any) {
      if (e.target.className === 'iacpos-modal-info-reserva') {
        modal.close();
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
    this.claveEmpresa = this.butacasService.getConfig('chClaveEmpresa');





  }

  private fillProductosResumen(reserva: any) {
    const cantidadProductos: number = +reserva.ProductosCompra.length;

    for (let indexProd = 0; indexProd < cantidadProductos; indexProd++) {
      const productoItem = reserva.ProductosCompra[indexProd];

      const cantidadButacas: number = +productoItem.butacas.length;
      const idProducto: number = +productoItem.ProductoId;
      const nombreProducto: string = productoItem.NombreProducto;
      const pvpProducto: number = Number(productoItem.PvpProductoUd);
      const totalProducto: number = Number(productoItem.PvpProductoUd) + Number(productoItem.Comision);
      const comision: number = Number(productoItem.Comision);

      for (let indexBut = 0; indexBut < cantidadButacas; indexBut++) {

        const productoButacaItem = productoItem.butacas[indexBut];

        const butaca = {
          idEspectaculo: +productoButacaItem.EspectaculoId,
          nombreEspectaculo: productoButacaItem.NombreEspectaculo,
          precio: pvpProducto,
          precioTotal: totalProducto,
          idEntrada: idProducto,
          nombreEntrada: nombreProducto,
          comision: comision,
          fecha: moment(productoButacaItem.FechaSesion, 'YYYY/MM/DD').format('DD/MM/YYYY'),
          hora: moment(productoButacaItem.HoraSesion, 'HH:mm:ss').format('HH:mm'),
          cantidad: 1
        };
        this.totalComision += comision;
        const cantidadProdResumen: number = this.productosResumen.length;
        let actualizo = false;

        for (let indexProdResumen = 0; indexProdResumen < cantidadProdResumen; indexProdResumen++) {
          const productoResumenItem = this.productosResumen[indexProdResumen];

          if (productoResumenItem.idEspectaculo === butaca.idEspectaculo &&
            productoResumenItem.fecha === butaca.fecha &&
            productoResumenItem.hora === butaca.hora &&
            ((productoResumenItem.idEntrada === butaca.idEntrada || productoResumenItem.nombreEntrada === butaca.nombreEntrada)
              &&
              productoResumenItem.precio === butaca.precio &&
              productoResumenItem.comision === butaca.comision)
          ) {
            ++this.productosResumen[indexProdResumen].cantidad;
            this.productosResumen[indexProdResumen].precioTotal += butaca.precioTotal;
            this.productosResumen[indexProdResumen].comision += butaca.comision;
            actualizo = true;
            break;
          }
        }

        if (!actualizo) {
          this.productosResumen.push(butaca);
        }

      }
    }
    this.hasLoaded = true;
  }

  ngAfterViewInit(): void {
  }

  // remove self from modal service when directive is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  // open modal
  open(localizador, numeroPedido): void {
    this.numeroPedido = numeroPedido;
    this.localizador = localizador;

    this.element.style.display = 'block';
    document.body.classList.add('infoReserva');

    const reservaCache = this.reservasYaCargadas.filter(e => e.NumeroPedido == numeroPedido && e.ClaveReserva == localizador);

    if (reservaCache && reservaCache.length > 0) { // CACHE DE TRAER RESERVA
      this.reserva = reservaCache[0];
      this.fillProductosResumen(this.reserva);
    } else {
      this.butacasService
        .traerReserva(this.numeroPedido, this.localizador, 1)
        .subscribe((reserva: any) => {
          if (reserva) {
          this.reservasYaCargadas.push(reserva);
          this.reserva = reserva;
          this.fillProductosResumen(reserva);
          } else {
            this.close();
          }
        });
    }
  }

  // close modal
  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('infoReserva');
    this.numeroPedido = undefined;
    this.localizador = undefined;
    this.productosResumen = [];
    this.reserva = undefined;
    this.totalComision = 0;
    this.hasLoaded = false;
  }


}
