import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy
} from '@angular/core';
import * as d3 from 'd3';
import { InfoMapasService } from '../../servicios/info-mapas.service';
import { CompileShallowModuleMetadata } from '@angular/compiler';
import { Subscriber, Subscription } from 'rxjs';
import { stringify } from 'querystring';

@Component({
  selector: 'iacpos-minimapa-recinto',
  templateUrl: './minimapa-recinto.component.html',
  styleUrls: ['./minimapa-recinto.component.css']
})
export class MinimapaRecintoComponent implements OnChanges, OnInit, OnDestroy {
  width: number;
  height: number;
  altoanchoSubs: Subscription;
  // tamaño del minimapa
  boxWidth = 1720;
  boxHeight = 920;

  svg: any;

  currentZoom: number;
  d3FirstEvent: any;

  mapFirstCoord = {
    x: 0,
    y: 0
  };

  @Input() nombreRecinto: string;
  @Input() mapaZoomTransformation: Event;
  @Input() contextual: boolean;

  @Output() eventMinimapTransformation = new EventEmitter<Event>();

  private rectZoom: any;

  constructor(private infoMapasService: InfoMapasService) {}

  ngOnInit() {
    this.altoanchoSubs = this.infoMapasService.altoAncho.subscribe((data: any) => {
      this.boxHeight = data[0];
      this.boxWidth = data[1];
      this.setSvgSize();
      
    });

   
  }
  resetSalt() {
    this.infoMapasService.saltCoord.x = 0;
    this.infoMapasService.saltCoord.y = 0;
  }

  setSvgSize(): void {
    this.infoMapasService.getSVG(this.nombreRecinto).subscribe((data: string) => {
        /*  const svgMock = d3
          .select('#minimapa')
          .attr('preserveAspectRatio', 'xMidYMid meet')
          .attr('viewBox', '0 0 ' + this.boxWidth + ' ' + this.boxHeight)
          //.attr('viewBox', '0 0 0 0')
          .attr('width', this.infoMapasService.boxWidth)
          .attr('height', this.infoMapasService.boxHeight);
        const g = svgMock.append('g');
        g.attr('id', 'mmRecinto');
        */
        const rec = document.getElementById('mmRecinto');
        if (rec) {
          // rec.insertAdjacentHTML('beforeend', data);
          const rect = rec.getBoundingClientRect();
          this.width = this.boxWidth;
          this.height = this.boxHeight;
          if (rect.width === 0 && rect.height === 0) {
            this.width = 1365;
            this.height = 704;
          }
        }
        this.setRectZoom();

        // antigua funcion agregarSVG (comentada mas abajo)
        // const rec2 = document.getElementById('mmRecinto');
        // if (rec2) {
        //   rec.insertAdjacentHTML('beforeend', data);
        // }

        this.agregarEstructura();
      });
  }

  setRectZoom(): void {
    this.rectZoom = d3
      .zoom()
      .scaleExtent([0.3, 1])
      .extent([[0, 0], [this.width, this.height]])
/*       .filter(() => {
        return true;
        //MMM return !d3.event.mouseup;
      }) */
      .on('zoom', (event) => {
        const transformed = event.transform;

        if (!this.infoMapasService.lastMapPressed) {
          this.infoMapasService.lastMapPressed = 'minmap';
        }

        if (this.infoMapasService.lastMapPressed !== 'minmap') {
          this.infoMapasService.saltCoord.x = this.infoMapasService.rectTranslation.x;
          this.infoMapasService.saltCoord.y = this.infoMapasService.rectTranslation.y;
          this.infoMapasService.lastMapPressed = 'minmap';
        }

        this.infoMapasService.rectTranslation.x = transformed.x;
        this.infoMapasService.rectTranslation.y = transformed.y;
        this.infoMapasService.rectTranslation.k = transformed.k;

        transformed.x = transformed.x * transformed.k;
        transformed.y = transformed.y * transformed.k;

        let translateX =
          transformed.x / Math.pow(transformed.k, 2) + this.infoMapasService.saltCoord.x * 2;
        let translateY =
          transformed.y / Math.pow(transformed.k, 2) + this.infoMapasService.saltCoord.y * 2;

        translateX = translateX < 0 ? 0 : translateX;
        translateY = translateY < 0 ? 0 : translateY;

        const x = transformed.k;
        const Xmax = -1374.666 + 144841374.666 / (1 + Math.pow(x / 0.000008775183, 0.9932579));
        const Ymax = (-1368.486 + 74428388.486 / (1 + Math.pow(x / 0.00001871062, 1.001608))) / 2;

        translateX = translateX > Xmax ? Xmax : translateX;
        translateY = translateY > Ymax ? Ymax : translateY;
        this.infoMapasService.selectionRect.attr(
          'transform',
          ' scale(' +
          transformed.k +
          ') translate(' +
          translateX +
          ', ' +
          (translateY - this.height * 0.4) +
          ')'
        );
        this.infoMapasService.container.node().__zoom.k = transformed.k;
        this.infoMapasService.container.node().__zoom.x = translateX * transformed.k;
        this.infoMapasService.container.node().__zoom.y = translateY * transformed.k;
        this.eventMinimapTransformation.emit(transformed);
      });
  }

  // drag map
  ngOnChanges(changes: SimpleChanges) {
    if (this.infoMapasService.selectionRect) {
      const zoom = d3
        .zoom()
        .scaleExtent([0, 1])
        .on('zoom', () => {
          if (!this.infoMapasService.lastMapPressed) {
            this.infoMapasService.lastMapPressed = 'map';
          }
          if (this.infoMapasService.lastMapPressed !== 'map') {
            this.infoMapasService.saltCoord.x = this.infoMapasService.rectTranslation.x;
            this.infoMapasService.saltCoord.y = this.infoMapasService.rectTranslation.y;
            this.infoMapasService.lastMapPressed = 'map';
          }
          let transformed;
          if (this.infoMapasService.rectTranslation) {
            transformed = {
              k: 1 / this.infoMapasService.rectTranslation.k,
              x:
                this.infoMapasService.rectTranslation.x -
                this.infoMapasService.rectTranslation.x * 2,
              y:
                this.infoMapasService.rectTranslation.y -
                this.infoMapasService.rectTranslation.y * 2
            };
          } else {
            transformed = {
              k: 1,
              x: 0,
              y: 0
            };
          }
          this.infoMapasService.selectionRect.attr(
            'transform',
            ' scale(' +
              transformed.k +
              ') translate(' +
              transformed.x +
              ', ' +
              (transformed.y - this.height * 0.4) +
              ')'
          );
          this.infoMapasService.container.node().__zoom.k = transformed.k;
          this.infoMapasService.container.node().__zoom.x = transformed.x;
          this.infoMapasService.container.node().__zoom.y = transformed.y;
        });
      this.infoMapasService.rectTranslation = changes.mapaZoomTransformation.currentValue;
      this.infoMapasService.selectionRect.call(zoom.transform);
    }
  }

  /*   private agregarSVG = () => {
    this.infoMapasService.getSVG(this.nombreRecinto).subscribe((data: string) => {
      const rec = document.getElementById('mmRecinto');
      if (rec) {
        rec.insertAdjacentHTML('beforeend', data);
      }
    });
  } */

  agregarEstructura() {
    this.svg = d3
      .select('#minimapa')
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr('viewBox', '-20 0 ' + this.boxWidth + ' ' + this.boxHeight)
      /*.attr('viewBox', '0 -30 700 550')*/
      .attr('width', this.infoMapasService.boxWidth)
      .attr('height', this.infoMapasService.boxHeight);

    // const g = this.svg.append('g');

    this.infoMapasService.container = this.svg.append('g').call(this.rectZoom);

    // g.attr('id', 'mmRecinto');

    this.infoMapasService.selectionRect = this.infoMapasService.container
      .append('rect')
      .attr('width', this.width)
      .attr('height', this.height * 1.75)
      .attr('x', '0')
      .attr('y', '0')
      .attr('class', ['cursor__move'])
      .style('fill', 'rgba(64,64,64, 0.4)')
      .style('stroke', 'black')
      .style('stroke-width', 4)
      .style('stroke-alignment', 'outer')
      .style('pointer-events', 'all')
      .on('mousedown', () => {
        this.resetSalt();
      })
      .on('touchend', () => {
        this.resetSalt();
      });

    this.infoMapasService.selectionRect.raise();
    if (this.contextual) d3.select("#minimapadiv").style("display","none"); 
  }

  ngOnDestroy() {
    this.altoanchoSubs.unsubscribe();
  }
}
