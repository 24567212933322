import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { userResponse } from "@sharedV11/classes/userResponse/user-response";
import { Translator } from "@sharedV11/services/translator/translator.service";
import { DialogData } from "@sharedV6/_alerta/popup.service";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  loginForm!: FormGroup;
  translation: any;
  submitted: boolean = false;
  loading: boolean = false;
  usuarioenSession: userResponse;
  returnUrl: string;

  errores = {
    "0": "EmailNoRegistrado",
    "-1": "MasUsuariosConEsteEmail",
    "-2": "IdentificacionIncorrecta",
    "-5": "NoPermisos",
  };
  Copyright = "";
  constructor(
    private translator: Translator,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.initializeForm();
    this.initializeTranslate();
  }

  initializeForm() {
    this.loginForm = new FormGroup(
      {
        password: new FormControl("", [
          Validators.required,
          Validators.minLength(8),
        ]),
        confirm: new FormControl("", [
          Validators.required,
          Validators.minLength(8),
        ]),
      },
      (loginForm: FormGroup) => {
        return PasswordValidator.areEqualFields(loginForm);
      }
    );
  }
  initializeTranslate() {
    this.translation = this.translator.GetTranslations();
  }

  // un getter para facilitar el acceso a los campos del form
  get f() {
    return this.loginForm.controls;
  }
}
export class PasswordValidator {
  static areEqual(formGroup: FormGroup) {
    let value;
    let valid = true;
    for (let key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        let control: FormControl = <FormControl>formGroup.controls[key];

        if (value === undefined) {
          value = control.value;
        } else {
          if (value !== control.value) {
            valid = false;
            break;
          }
        }
      }
    }

    if (valid) return null;

    return {
      areEqual: true,
    };
  }

  static areEqualFields(formGroup: FormGroup) {
    let valid = {};

    if (formGroup.get("password").value !== formGroup.get("confirm").value)
      valid = false;

    if (valid) return null;

    return {
      areEqualFields: true,
    };
  }
}
