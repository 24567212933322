import { Deserializable } from "../deserializable";

export class SinButaca_show implements Deserializable {
    pkId:                    string;
    Recinto:                  string;
    Descripcion:             string;
    Fecha:                  string;
    HoraInicio:                  string;
    AforoInicial:                   string;
    Reservadas:           string;
    Disponibles:        string;
    NumSesion:             string;
    Activa:              string;

  
    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }


    assign(param,data){
      this[param] = data
    }

  }
