import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../services/authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  params:any;

  constructor(
    private auth:AuthenticationService,
    private router:Router
    ){    
      if (router.getCurrentNavigation()) this.params=router.getCurrentNavigation().extractedUrl.queryParamMap.get("auth")

    }
  canActivate(

    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
     
      let logged=this.auth.verificar()
      if(logged){
        if (this.params) {
      
          this.router.navigate(["/login"],{queryParams:{auth:this.params}});
          
        }else{
          this.router.navigate(["/login"]);
        }
        return false
      }
    return true;
  }
  
}
