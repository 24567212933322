import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
@Component({
  selector: 'app-super-class',
  templateUrl: './super-class.component.html',
  styleUrls: ['./super-class.component.scss']
})
export class SuperClassComponent implements OnInit {
  public ngUnsubscribe = new Subject();

  customanpanel: any;
  addbut;

  constructor(
    protected panelService: PanelService,
    protected cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.closepanel();
  }

  openPanel() {
    this.editChangeCss();
    this.cdRef.detectChanges();
  }

  closepanel() {
    this.panelService.closePanel$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((close) => {
        this.customanpanel = undefined;
        this.closePanelChangeCss();
      });
  }

  editChangeCss() {
    let classElem;
    classElem = document.getElementsByClassName("component");
    for (let i = 0; i < classElem.length; i++) {
      classElem[i].style.display = "none";
    }
  }

  closePanelChangeCss() {
    let classElem;

    classElem = document.getElementsByClassName("component");
    for (let i = 0; i < classElem.length; i++) {
      classElem[i].style.display = "block";
    }
  }
}
