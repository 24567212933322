import { Message } from "@angular/compiler/src/i18n/i18n_ast";
import { Deserializable } from "../deserializable";
import { DatosResult_internetgroups } from "./dataResult_internetgroups";

export class InternetGroups_response implements Deserializable {
    DatosResult?: DatosResult_internetgroups;
    Mensajes: Message[];

    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}