import { Deserializable } from "@sharedV11/classes/deserializable";

export class TiposEntrada_Respuesta implements Deserializable {
    DatosResult?: DatosResult_TiposEntrada;
    Mensajes: Mensaje[];

    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}

export class TipoEntrada implements Deserializable {
  
  EntradaId: string;
  Nombre: string;
  NombreGrupo: string;
  Descripcion: string;
  PrecioUnitario: string;
  DivisaId: string;
  ALaVenta: string;
  FormatoId: string;
  TextoPantalla: string;
  CondicionesVenta: string;
  TextoImpresion: string;
  TextoDisplay: string;
  CodigoBarras: string;
  IVA: string;
  BaseImponible: string;
  TipoAbreviado: string;
  MaximoPorDia: string;
  GrupoId: string;
  ImprimirTicket: string;
  ControlarAcceso: string;
  CodigoTipoControl: string;
  TeclaRapida: string;
  GrupoMotivoId: string;
  ImpresoraSecundaria: string;
  TicketVendido: string;
  ImprimirPrecio: string;
  TipoButaca: string;
  CuentaVisitante: string;
  CaracteristicaId: string;
  ArgumentosCaracteristica: string;
  TipoButacaId: string;
  BarCodeInternet: string;
  RecintosAsociados: string;
  ActividadesAsociadas:string; 

  // Nuevos campos
  TipoProducto: string;
  GrupoAsociado: string;
  TipoVisita: string;
  TipoNivel: string;
  Categoria: string;
  TEDatos: string;
  PosicionEnBotonera: string;
  ConfiguracionAdicional: string;
  pkId: any;
  CentrosIds: string;
  CentroCosteId: string;
  CanalId: string;
  
  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class DatosResult_TiposEntrada implements Deserializable {
  Lista: TipoEntrada[];
  customanGrupoTipoEntrada: TipoEntrada[];

    deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Mensaje implements Deserializable {
  CodigoMensaje: string;
  DescripcionMensaje: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

//Botonera
export class Botonera {
  TeclaRapida: string;
  NombreTipo: string;
  idEntrada: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class DatosResult_Botonera {
  Lista: Botonera[];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class RespuestaBotonera {
  DatosResult: DatosResult_Botonera;
  Mensajes: Mensaje[];
  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

