import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiTokenService } from '@sharedV11/services/api/token/api-token.service';
import { GlobalService } from '@sharedV11/services/global/global.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class EntryTypeService {

  constructor(
    private tokenService: ApiTokenService,
    private http: HttpClient,
    private global: GlobalService,
  ) { }
  private extractData(res: Response) {
    let body = res;
    return body || {};
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption


      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  GetTiposEntradaDatos() {
    const body = {
      "CodigoApp": 'CUSTOMAN',
      "Id": ''
    };
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Configuracion/GetTiposEntradaDatos ', body).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }

  GetGruposAsociados() {
    const body = {
      "CodigoApp": 'CUSTOMAN',
      "Id": ''
    };
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Configuracion/GetGruposAsociados ', body).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  };

  GetTiposNiveles() {
    const body = {
      "CodigoApp": 'CUSTOMAN',
      "Id": ''
    };
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Configuracion/GetTiposNiveles ', body).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  };

  GetTiposProductos() {
    const body = {
      "CodigoApp": 'CUSTOMAN',
      "Id": ''
    };
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Configuracion/GetTiposProductos ', body).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  };

  ObtenerCustomanTipoVisita() {
    const body = {
      "CodigoApp": 'CUSTOMAN',
      "Id": ''
    };
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanTipoVisita ', body).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  };

  ObtenerCustomanTipoPublico() {
    const body = {
      "CodigoApp": 'CUSTOMAN',
      "Id": ''
    };
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanTipoPublico', body).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  };

  
  ObtenerCustomanTipoVisitante() {
    const body = {
      "CodigoApp": 'CUSTOMAN',
      "Id": ''
    };
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanTipoVisitante', body).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  };

  ObtenerCustomanNivel() {
    const body = {
      "CodigoApp": 'CUSTOMAN',
      "Id": ''
    };
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanNiveles ', body).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  };

  ObtenerCustomanCategoria() {
    const body = {
      "CodigoApp": 'CUSTOMAN',
      "Id": ''
    };
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanCategoria ', body).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  };

  ObtenerCustomanCentroCoste() {
    const body = {
      "CodigoApp": 'CUSTOMAN',
      "Id": '',
      "CentroCosteId": "",
      "CentroCoste": "",
      "CodCentroCoste": "",
      "CuentaCentroCoste": "",
      "Seleccionable": "",
    };
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanCentroCoste ', body).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  };

  ObtenerCustomanCanalesSub() {
    const body = {
      "CodigoApp": 'CUSTOMAN',
      "Id": '',
      "IdCanal": "",
      "Canal": "",
      "Seleccionable": "",
      "IdSubCanal": "",
      "SubCanal": "",
      "CodSubCanal": ""
    };
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanCanales ', body).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  };

  getCentros(nombre?: string, clave?: string, activo?: string): Observable<any> {
    // Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO

    if (nombre != null) { peticion.Nombre = nombre; } else { peticion.Nombre = ""; }
    if (clave != null) { peticion.Clave = clave; } else { peticion.Clave = ""; }
    if (activo != null) { peticion.Activo = activo; } else { peticion.Activo = ""; }
    // Enviamos la peticion
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCentros', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }

  async getCentrosAsync(nombre?: string, clave?: string, activo?: string): Promise<any> {
    // Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO

    if (nombre != null) { peticion.Nombre = nombre; } else { peticion.Nombre = ""; }
    if (clave != null) { peticion.Clave = clave; } else { peticion.Clave = ""; }
    if (activo != null) { peticion.Activo = activo; } else { peticion.Activo = ""; }
    // Enviamos la peticion
    const response = await this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCentros', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    ).toPromise();
    return response;
  }

  async getTiposControlAsync(nombre?: string): Promise<any> {
    //Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO

    if (nombre != null) { peticion.Nombre = nombre; } else { peticion.Nombre = ""; }
    //Enviamos la peticion
    let respuesta =
      await this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanTiposControl', JSON.stringify(peticion), httpOptions).pipe(
        map(data => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      ).toPromise();
    return respuesta;
  }

  async getGruposTiposEntradaAsync(nombre?: string, descripcion?: string): Promise<any> {
    //Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    if (nombre != null) { peticion.NombreGrupo = nombre; } else { peticion.NombreGrupo = ""; }
    if (descripcion != null) { peticion.chDescripcion = descripcion; } else { peticion.chDescripcion = ""; }

    //Enviamos la peticion
    let respuesta =
      await this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanGrupoTipoEntrada', JSON.stringify(peticion), httpOptions).pipe(
        map(data => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      ).toPromise();
    return respuesta;
  }
  async getRecintosAsync(nombre?: string, grupo?: string, activo?: string, idioma?: string): Promise<any> {
    // Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO

    if (nombre != null) { peticion.Nombre = nombre; } else { peticion.Nombre = ""; }
    if (grupo != null) { peticion.Grupo = grupo; } else { peticion.Grupo = ""; }
    if (activo != null) { peticion.Activo = activo; } else { peticion.Activo = ""; }
    if (idioma != null) { peticion.Idioma = idioma; } else { peticion.Idioma = ""; }
    // Enviamos la peticion
    let respuesta =
      await this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerRecintos', JSON.stringify(peticion), httpOptions).pipe(
        map(data => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      ).toPromise();
    return respuesta;
  }
  async getTiposButacaAsync(id?: string): Promise<any> {
    // Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO

    if (id != null) { peticion.id = id; } else { peticion.id = ""; }
    // Enviamos la peticion
    let respuesta =
      await this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/GetTipoButaca', JSON.stringify(peticion), httpOptions).pipe(
        map(data => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      ).toPromise();
    return respuesta;
  }

  getTiposEntradas(pagination, filters): Observable<any> {
    // Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    if (pagination) {
      peticion.paginator = pagination
      peticion.filters = filters
    }
    /*  if (nombre != null) { peticion.Nombre = nombre; } else { peticion.Nombre = ""; }
     if (id != null) { peticion.id = id; } else { peticion.id = ""; }
     if (recinto != null) { peticion.Recinto = recinto; } else { peticion.Recinto = null; }
     if (talleractividad != null) { peticion.TallerActividad = talleractividad; } else { peticion.TallerActividad = null; }
     if (grupotipoentrada != null) { peticion.GrupoTipoEntrada = grupotipoentrada; } else { peticion.GrupoTipoEntrada = null; }
     if (tipomotivo != null) { peticion.TipoMotivo = tipomotivo; } else { peticion.TipoMotivo = null; }
     if (controlacceso != null) { peticion.ControlAcceso = controlacceso; } else { peticion.ControlAcceso = null; }
     if (alaventa != null && alaventa.trim() != '') {
       alaventa.toLowerCase().trim() == 'si' ?
         peticion.ALaVenta = '1' : peticion.ALaVenta = '0';
     } else { peticion.ALaVenta = null; }
     if (filtro != null) { peticion.Filtro = filtro; } else { peticion.Filtro = null; }
 
  */
    //Enviamos la peticion
    let respuesta = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/GetTipoEntrada', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return respuesta;
  }
  async getTiposEntradasAsync(pagination, filters): Promise<any> {
    // Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    if (pagination) {
      peticion.paginator = pagination
      peticion.filters = filters
    }
    /*  if (nombre != null) { peticion.Nombre = nombre; } else { peticion.Nombre = ""; }
     if (id != null) { peticion.id = id; } else { peticion.id = ""; }
     if (recinto != null) { peticion.Recinto = recinto; } else { peticion.Recinto = null; }
     if (talleractividad != null) { peticion.TallerActividad = talleractividad; } else { peticion.TallerActividad = null; }
     if (grupotipoentrada != null) { peticion.GrupoTipoEntrada = grupotipoentrada; } else { peticion.GrupoTipoEntrada = null; }
     if (tipomotivo != null) { peticion.TipoMotivo = tipomotivo; } else { peticion.TipoMotivo = null; }
     if (controlacceso != null) { peticion.ControlAcceso = controlacceso; } else { peticion.ControlAcceso = null; }
     if (alaventa != null && alaventa.trim() != '') {
       alaventa.toLowerCase().trim() == 'si' ?
         peticion.ALaVenta = '1' : peticion.ALaVenta = '0';
     } else { peticion.ALaVenta = null; }
     if (filtro != null) { peticion.Filtro = filtro; } else { peticion.Filtro = null; }
 
  */
    //Enviamos la peticion
    let respuesta =
      await this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/GetTipoEntrada', JSON.stringify(peticion), httpOptions).pipe(
        map(data => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      ).toPromise();
    return respuesta;
  }
  /// F Tipos ENTRADAS
  /// Botonera
  //Get
  async getBotoneraAsync(id?: string, maximo?: string): Promise<any> {
    //Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO

    //if(id != null) {peticion.id = id;} else {peticion.id ="";}
    if (maximo != null) { peticion.Max = maximo; } else { peticion.Max = null; }
    //Enviamos la peticion
    let respuesta =
      await this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/GetTipoEntradaBotonera', JSON.stringify(peticion), httpOptions).pipe(
        map(data => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      ).toPromise();
    return respuesta;
  }

  insUpdTiposEntradas(tiposentradas,mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = tiposentradas;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsUpdTipoEntrada', JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('InsUpdTipoEntrada'))
    );
  }
}
