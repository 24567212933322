import {CLienteAPi} from "./cliente-api.model";

//getGruposClientes
export class peticion_GruposClientes_Lista {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    Nombre: string;
}

//insUpdGruposClientes
export class peticion_Insercion_Upd_GruposClientes {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    PkId: string;
    CodigoGruposClientes: string;
    NombreGruposClientes: string;
    Funcion: string;
}

//delGruposClientes
export class peticion_Del_GruposClientes {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    PkId: string;
}