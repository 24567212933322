import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MaestrasService } from "@sharedV11/services/core/maestras.service";
import { ChangePasswordComponent } from "./components/change-password/change-password.component";
import { ClientGroupComponent } from "./components/clients/client-group/client-group.component";
import { ClientTypeComponent } from "./components/clients/client-type/client-type.component";
import { ClientV11Component } from "./components/clients/client-v11/client-v11.component";
import { FormasPagoV11Component } from "./components/clients/formas-pago-v11/formas-pago-v11.component";
import { CodigoPromocionalComponent } from "./components/codigos-promocionales/codigo-promocional/codigo-promocional.component";
import { TipoCodigoPromocionesComponent } from "./components/codigos-promocionales/tipo-codigo-promociones/tipo-codigo-promociones.component";
import { EntryTypeComponent } from "./components/entradaspromociones/entry-type/entry-type.component";
import { GrupoTipoBonoV11Component } from "./components/entradaspromociones/grupo-tipo-bono-v11/grupo-tipo-bono-v11.component";
import { GruposTipoEntradaV11Component } from "./components/entradaspromociones/grupos-tipo-entrada-v11/grupos-tipo-entrada-v11.component";
import { ItemsMotivosGruposV11Component } from "./components/entradaspromociones/items-motivos-grupos-v11/items-motivos-grupos-v11.component";
import { TipoBonoComponent } from "./components/entradaspromociones/tipobono/tipobono.component";
import { EspectaculosComponent } from "./components/espacios/espectaculos/espectaculos.component";
import { SesionesConButacasComponent } from "./components/espacios/sesionesconbutacas/sesionesconbutacas.component";
import { LoginComponent } from "./components/login/login.component";
import { CategoriasComponent } from "./components/mestres/categorias/categorias.component";
import { DiascierreComponent } from "./components/mestres/diascierre/diascierre.component";
import { TpvsComponent } from "./components/mestres/tpvs/tpvs.component";
import { TypeAccessControlComponent } from "./components/mestres/type-access-control/type-access-control.component";
import { PortadaComponent } from "./components/portada/portada.component";
import { GrupoRecintosComponent } from "./components/recintos/grupo-recintos/grupo-recintos.component";
import { GruposActividadTallerV11Component } from "./components/recintos/grupos-actividad-taller-v11/grupos-actividad-taller-v11.component";
import { RecintosVisitaComponent } from "./components/recintos/recintos-visita/recintos-visita.component";
import { SinButacasComponent } from "./components/recintos/sinbutacas/sinbutacas.component";
import { SessionTypeComponent } from "./components/recintos/tipossesion/sessiontype.component";
import { InternetGroupComponent } from "./components/tarifas/internet-groups/internet-group.component";
import { TarifaV11Component } from "./components/tarifas/tarifa-v11/tarifa-v11.component";
import { TiposreservaComponent } from "./components/tarifas/tiposreserva/tiposreserva.component";
import { UserProfileV11Component } from "./components/user/user-profile-v11/user-profile-v11.component";
import { UserV11Component } from "./components/user/user-v11/user-v11.component";
import { AuthGuard } from "./shared/guards/auth/auth.guard";
import { LoginGuard } from "./shared/guards/login/login.guard";
import { HomeLayoutComponent } from "./shared/layouts/home-layout/home-layout.component";
const routes: Routes = [
  // Normales con redireccion de layout

  {
    path: "",
    component: HomeLayoutComponent,
    data: { SectionTitle: "Inicio" },
    canActivate: [AuthGuard],
    resolve: { maestras: MaestrasService },
    // resolve: { conf: ConfResolver },
    children: [
      {
        path: "portada",
        component: PortadaComponent,
        data: { SectionTitle: "Inicio" },
      },

      {
        path: "Recintos/RecintosVisitasV11",
        component: RecintosVisitaComponent,
        data: {
          SectionTitle: "Recintos Visita",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Recintos", urlcrumb: "/portada" },
            { crumb: "Recintos", urlcrumb: "" },
          ],
        },
      },

      {
        path: "Recintos/GruposRecintosV11",
        component: GrupoRecintosComponent,
        data: {
          SectionTitle: "Grupos Recintos",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Recintos", urlcrumb: "/portada" },
            { crumb: "GrupoRecinto", urlcrumb: "" },
          ],
        },
      },
      {
        path: "Recintos/GruposActividadTallerV11",
        component: GruposActividadTallerV11Component,
        data: {
          SectionTitle: "Grupos Actividad Taller",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Recintos", urlcrumb: "/portada" },
            { crumb: "Grupos Actividades Taller", urlcrumb: "" },
          ],
        },
      },
      {
        path: "EntradasPromociones/TipoPromocionV11",
        component: TipoBonoComponent,
        data: {
          SectionTitle: "Tipo Promoción",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Entradas Promociones", urlcrumb: "" },
            { crumb: "Tipo Promoción", urlcrumb: "" },
          ],
        },
      },
      {
        path: "Maestros/Tpvs",
        component: TpvsComponent,
        data: {
          SectionTitle: "TPVs",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Maestros", urlcrumb: "" },
            { crumb: "TPVs", urlcrumb: "" },
          ],
        },
      },
      {
        path: "Maestros/Festivos",
        component: DiascierreComponent,
        data: {
          SectionTitle: "Festivos",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Tarifas", urlcrumb: "" },
            { crumb: "Festivos", urlcrumb: "" },
          ],
        },
      },
      {
        path: "Tarifas/tiposreserva",
        component: TiposreservaComponent,
        data: {
          SectionTitle: "Tipos Reserva",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Tarifas", urlcrumb: "" },
            { crumb: "Tipos Reserva", urlcrumb: "" },
          ],
        },
      },
      //V11
      {
        path: "Usuario/Usuarios",
        component: UserV11Component,
        data: {
          SectionTitle: "Usuarios",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Usuarios", urlcrumb: "" },
            { crumb: "Usuario", urlcrumb: "" },
          ],
        },
      },
      {
        path: "Usuario/TiposUsuarios",
        component: UserProfileV11Component,
        data: {
          SectionTitle: "Usuarios",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Usuarios", urlcrumb: "/portada" },
            { crumb: "Tipos de Usuario", urlcrumb: "" },
          ],
        },
      },
      {
        path: "Tarifas/GruposInternet",
        component: InternetGroupComponent,
        data: {
          SectionTitle: "Tarifas",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Tarifas", urlcrumb: "" },
            { crumb: "Grupos de Internet", urlcrumb: "" },
          ],
        },
      },
      {
        path: "Tarifas/TipoReserva",
        component: TiposreservaComponent,
        data: {
          SectionTitle: "Tipos Reserva",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Tarifas", urlcrumb: "" },
            { crumb: "Tipos Reserva", urlcrumb: "" },
          ],
        },
      },
      {
        path: "Tarifas/Tarifa",
        component: TarifaV11Component,
        data: {
          SectionTitle: "Tarifas",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Tarifas", urlcrumb: "" },
            { crumb: "Tarifa", urlcrumb: "" },
          ],
        },
      },
      {
        path: "EntradasPromociones/TipoEntradaV11",
        component: EntryTypeComponent,
        data: {
          SectionTitle: "EntradasyPromociones",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Entradas Promociones", urlcrumb: "" },
            { crumb: "Tipo Entrada", urlcrumb: "" },
          ],
        },
      },

      {
        path: "EntradasPromociones/ItemsMotivosGrupo",
        component: ItemsMotivosGruposV11Component,
        data: {
          SectionTitle: "ItemsMotivosGrupo",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Items Motivos de Grupo", urlcrumb: "" },
            { crumb: "Items Motivos de Grupo V11", urlcrumb: "" },
          ],
        },
      },
      {
        path: "EntradasPromociones/GrupoTipoBoboV11",
        component: GrupoTipoBonoV11Component,
        data: {
          SectionTitle: "GrupoTipoBonoV11",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Grupos Tipo Bono", urlcrumb: "" },
            { crumb: "Grupos Tipo Bono V11", urlcrumb: "" },
          ],
        },
      },
      //V6
      /*       { path: 'Maestros/TiposControl', component: TiposControlComponent,
      data: {SectionTitle : 'Maestros', breadcrumbs:[{crumb: 'Inicio', urlcrumb:'/portada'},{crumb: 'Maestros', urlcrumb:''}, {crumb: 'Tipos de Control', urlcrumb:''}]} 
      }, */
      //V11
      {
        path: "Maestros/Categorias",
        component: CategoriasComponent,
        data: {
          SectionTitle: "Maestros",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Maestros", urlcrumb: "" },
            { crumb: "Categorias", urlcrumb: "" },
          ],
        },
      },
      {
        path: "Tarifas/InternetGroups",
        component: InternetGroupComponent,
        data: {
          SectionTitle: "Grupos Internet",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Tarifas", urlcrumb: "/portada" },
            { crumb: "Grupos de Internet", urlcrumb: "" },
          ],
        },
      },
      {
        path: "EntradasPromociones/GrupoTipoEntrada",
        component: GruposTipoEntradaV11Component,
        data: {
          SectionTitle: "EntradasyPromociones",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Entradas Promociones", urlcrumb: "" },
            { crumb: "Grupo Tipo Entrada", urlcrumb: "" },
          ],
        },
      },

      {
        path: "EntradasPromociones/GrupoTipoBono",
        component: GrupoTipoBonoV11Component,
        data: {
          SectionTitle: "EntradasyPromociones",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Entradas Promociones", urlcrumb: "" },
            { crumb: "Grupo Tipo Bono", urlcrumb: "" },
          ],
        },
      },
      {
        path: "EntradasPromociones/TipoEntrada",
        component: EntryTypeComponent,
        data: {
          SectionTitle: "EntradasyPromociones",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Entradas Promociones", urlcrumb: "" },
            { crumb: "Tipo Entrada", urlcrumb: "" },
          ],
        },
      },
      {
        path: "EntradasPromociones/TipoPromocion",
        component: TipoBonoComponent,
        data: {
          SectionTitle: "EntradasyPromociones",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Entradas Promociones", urlcrumb: "" },
            { crumb: "Tipo Promoción", urlcrumb: "" },
          ],
        },
      },
      {
        path: "EntradasPromociones/ItemMotivoGrupo",
        component: ItemsMotivosGruposV11Component,
        data: {
          SectionTitle: "EntradasyPromociones",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Entradas Promociones", urlcrumb: "" },
            { crumb: "Item Motivo Grupo", urlcrumb: "" },
          ],
        },
      },
      {
        path: "Recintos/GrupoActividadTaller",
        component: GruposActividadTallerV11Component,
        data: {
          SectionTitle: "Recintos",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Recintos", urlcrumb: "" },
            { crumb: "Grupos Actividades Taller", urlcrumb: "" },
          ],
        },
      },
      {
        path: "Recintos/GrupoRecinto",
        component: GrupoRecintosComponent,
        data: {
          SectionTitle: "Recintos",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Recintos", urlcrumb: "" },
            { crumb: "Grupo Recinto", urlcrumb: "" },
          ],
        },
      },
      {
        path: "Recintos/Recinto",
        component: RecintosVisitaComponent,
        data: {
          SectionTitle: "Recintos",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Recintos", urlcrumb: "" },
            { crumb: "Recinto", urlcrumb: "" },
          ],
        },
      },
      {
        path: "Recintos/TipoSesion",
        component: SessionTypeComponent,
        data: {
          SectionTitle: "Recintos",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Recintos", urlcrumb: "" },
            { crumb: "chTipoSesion", urlcrumb: "" },
          ],
        },
      },
      {
        path: "Recintos/SesionesSinButaca",
        component: SinButacasComponent,
        data: {
          SectionTitle: "Recintos",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Recintos", urlcrumb: "" },
            { crumb: "SinButaca", urlcrumb: "" },
          ],
        },
      },
      {
        path: "Clientes/Cliente",
        component: ClientV11Component,
        data: {
          SectionTitle: "Clientes",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Clientes", urlcrumb: "" },
            { crumb: "Cliente", urlcrumb: "" },
          ],
        },
      },
      {
        path: "Clientes/FormasPago",
        component: FormasPagoV11Component,
        data: {
          title: "Formas de Pago",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Clientes", urlcrumb: "" },
            { crumb: "Formas de Pago", urlcrumb: "" },
          ],
        },
      },
      {
        path: "Clientes/GrupoCliente",
        component: ClientGroupComponent,
        data: {
          title: "Grupos de Clientes",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Clientes", urlcrumb: "" },
            { crumb: "Grupo Cliente", urlcrumb: "" },
          ],
        },
      },
      {
        path: "Clientes/TipoCliente",
        component: ClientTypeComponent,
        data: {
          title: "Tipos de Clientes",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Clientes", urlcrumb: "" },
            { crumb: "TipoCliente", urlcrumb: "" },
          ],
        },
      },
      {
        path: "CodigosPromocionales/TipoCodigoPromocional",
        component: TipoCodigoPromocionesComponent,
        data: {
          SectionTitle: "CodigosPromocionales",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "CodigosPromocionales", urlcrumb: "" },
            { crumb: "TipoCodigoPromocional", urlcrumb: "" },
          ],
        },
      },
      {
        path: "CodigosPromocionales/CodigoPromocional",
        component: CodigoPromocionalComponent,
        data: {
          SectionTitle: "CodigosPromocionales",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "CodigosPromocionales", urlcrumb: "" },
            { crumb: "CodigosPromocionales", urlcrumb: "" },
          ],
        },
      },
      /*     { path: 'Maestros/TypeAccessControlV11', component: TypeAccessControlComponent,
      data: {SectionTitle : 'Maestros', breadcrumbs:[{crumb: 'Inicio', urlcrumb:'/portada'},{crumb: 'Maestros', urlcrumb:''}, {crumb: 'Tipos de Control', urlcrumb:''}]}
    }, */
      {
        path: "Maestros/TiposControl",
        component: TypeAccessControlComponent,
        data: {
          SectionTitle: "Maestros",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Maestros", urlcrumb: "" },
            { crumb: "Tipos de Control", urlcrumb: "" },
          ],
        },
      },
      {
        path: "EntradasyPromociones/GruposTipoDeEntradaV11",
        component: GruposTipoEntradaV11Component,
        data: {
          SectionTitle: "GruposEntrada",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "GruposEntrada", urlcrumb: "" },
            { crumb: "Grupos Tipo De EntradaV11", urlcrumb: "" },
          ],
        },
      },
      {
        path: "Recintos/Espectaculos",
        component: EspectaculosComponent,
        data: {
          SectionTitle: "Espacios",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Recintos", urlcrumb: "" },
            { crumb: "Espectaculos", urlcrumb: "" },
          ],
        },
      },
      {
        path: "Recintos/SesionesConButaca",
        component: SesionesConButacasComponent,
        data: {
          SectionTitle: "Espacios",
          breadcrumbs: [
            { crumb: "Inicio", urlcrumb: "/portada" },
            { crumb: "Recintos", urlcrumb: "" },
            { crumb: "Sesiones con Butacas", urlcrumb: "" },
          ],
        },
      },
    ],
  },
  { path: "login", component: LoginComponent, canActivate: [LoginGuard] },
  {
    path: "newPassword",
    component: ChangePasswordComponent,
    canActivate: [LoginGuard],
  },

  // Login sin layout especial
  { path: "", pathMatch: "full", redirectTo: "/login" },
  // cualquier otra cosa redirige a home
  { path: "**", redirectTo: "/login" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
