import {Deserializable} from "./deserializable.model";

export class GruposRecintos_Respuesta implements Deserializable {
    DatosResult?: DatosResult_gruposrecintos;
    Mensajes: Mensaje[];

    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}

export class GruposRecintos implements Deserializable {
  PkId: string;
  Nombre: string;
  Activo: string;
  CentroId: string;
  subGrupo: string;
  Idiomas: IdiomaGR[];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
//^
//v
export class IdiomaGR implements Deserializable {
  CodIdioma: string;
  Nombre: string;
  Descripcion: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class IdiomaconURL extends IdiomaGR implements Deserializable
{
    URL: string;    
    //chCodigo: string;
    
    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}
export class DatosResult_gruposrecintos implements Deserializable {
  ListaGrupoRecintos: GruposRecintos[]; 

    deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Mensaje implements Deserializable {
  CodigoMensaje: string;
  DescripcionMensaje: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}