import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { User } from "@sharedV11/classes/users/user/user";
import { EditUserService } from "@sharedV11/services/components/edit_user/edit-user.service";
import { Translator } from "@sharedV11/services/translator/translator.service";

import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { UserType_response } from "@sharedV11/classes/userType/UserType_response";
import { UserType } from "@sharedV11/classes/userType/user_type";
import { HttpErrorsService } from "@sharedV11/services/alerts/errors/http-errors.service";
import { SnackbarInfoService } from "@sharedV11/services/alerts/snackbar-info/snackbar-info.service";
import { ConectarApiService } from "@sharedV11/services/api/connection/conectar-api.service";
import { GlobalServicCentros } from "@sharedV11/services/global/global.service centros";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { DualListComponent } from "angular-dual-listbox";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-edit-user",
  templateUrl: "./edit-user.component.html",
  styleUrls: ["./edit-user.component.scss"],
})
export class EditUserComponent implements OnInit {
  loading = true;
  private ngUnsubscribe = new Subject();

  @Input() pkId: string;
  @Input() mode: string;
  @Input() modificar: boolean;

  translation: any;

  editUserForm: FormGroup;
  userTypes: UserType[] = [];

  user: User;
  flagInput = true;

  removable = true;
  public separatorKeysCodes = [ENTER, COMMA];
  emailList = [];

  //centros
  centrosSinAsignar = [];
  centrosAsignados = [];
  centrosasociados: string[] = [];
  tipoCentros: any[] = [];
  CentrosAPI: any;
  format: {
    add: any;
    remove: any;
    all: any;
    none: any;
    direction: any;
    draggable: boolean;
    locale: string;
  };
  //centros

  constructor(
    private editUserService: EditUserService,
    private fb: FormBuilder,
    private translator: Translator,
    private cdRef: ChangeDetectorRef,
    private panelService: PanelService,
    private snackvar_service: SnackbarInfoService,
    private httpErrorService: HttpErrorsService,
    public servicioAPI: ConectarApiService,
    private gsCentros: GlobalServicCentros
  ) {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    this.loadCentros();
    this.initializeTranslate();
    this.initializeFormWithoutData();
    this.getUserType();
    this.format = {
      add: this.translation.Anadir,
      remove: this.translation.Borrar,
      all: this.translation.Todos,
      none: this.translation.Ninguno,
      direction: DualListComponent.LTR,
      draggable: true,
      locale: "es",
    };

    // this.pkId="4"
  }

  loadCentros() {
    this.gsCentros.getCentros();
  }

  initializeTranslate() {
    this.translation = this.translator.GetTranslations();
  }

  get formControls() {
    return this.editUserForm.controls;
  }

  getUserType() {
    this.editUserService
      .getTiposUsuario()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (userTypes) => {
          let respuestaAPI: UserType_response;
          respuestaAPI = new UserType_response().deserialize(userTypes);
          this.userTypes = respuestaAPI.DatosResult.tipoUsuarios;

          this.manageForm(this.pkId, this.mode);
        },

        (error) => {
          this.httpErrorService.identificarErrores(error);
          this.manageForm(this.pkId, this.mode);
        }
      );
  }

  manageForm(pkId, mode) {
    if (mode == "I") {
      this.user = this.editUserForm.value;
      this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
      this.loading = false;
      this.valuedChanged();
    }
    if (pkId != null) {
      this.finduser(pkId);
    }
  }

  finduser(pkId) {
    this.editUserService
      .findUserByPkId(pkId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (userApi) => {
          // let user:User =  userApi["DatosResult"]["customanUsuarios"][0];
          let user: User = userApi["DatosResult"]["customanUsuarios"][0];

          if (user != null) {
            this.changeValueForm(user);
            this.user = this.editUserForm.value;
            this.user.CentrosIds = user.CentrosIds;
            this.valuedChanged();
            this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
            this.centrosAsignados = this.gsCentros.daTiposCentros(this.user);
            //centros en cargar datos
            /*             this.servicioAPI.getCentros().subscribe(data => {
              this.CentrosAPI = data;
              this.tipoCentros = [];
              if (this.CentrosAPI.DatosResult != null) {
                if (this.CentrosAPI.DatosResult.ListaCentros != null) {
                  this.tipoCentros = this.CentrosAPI.DatosResult.ListaCentros;
                  this.centrosSinAsignar = this.filtrarTiposCentros();
                  this.centrosAsignados = this.daTiposCentros();
                }
              }
            },
              error => { this.httpErrorService.identificarErrores(error); }
            ); */
            //centros
            this.loading = false;
          }
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  initializeFormWithData(user: User) {
    this.editUserForm = this.fb.group({
      pkId: [user.pkId],
      Tipo: [user.Tipo],
      Personal: [user.Personal],
      Nombre: [user.Nombre],
      Empleado: [user.Empleado],
      Conectado: [this.stringToBoolean(user.Conectado)],
      CentrosIds: [user.CentrosIds],
      /* Clave: [user.Clave], */
      Baja: [this.stringToBoolean(user.Baja)],
    });
  }

  changeValueForm(user: User) {
    this.formControls["pkId"].setValue(user.pkId);
    this.formControls["Tipo"].setValue(user.Tipo);
    this.formControls["Personal"].setValue(user.Personal);
    this.formControls["Nombre"].setValue(user.Nombre);
    this.formControls["Empleado"].setValue(user.Empleado);
    this.formControls["Conectado"].setValue(
      this.stringToBoolean(user.Conectado)
    );
    this.formControls["CentrosIds"].setValue(
      this.stringToBoolean(user.CentrosIds)
    );
    /* this.formControls["Clave"].setValue(user.Clave) */
    this.formControls["Baja"].setValue(this.stringToBoolean(user.Baja));
    if (user.Email) {
      this.emailList = user.Email.split(";");
    }

    let inputTipo = <HTMLInputElement>document.getElementById("Tipo");
    if (inputTipo)
      (<HTMLInputElement>document.getElementById("Tipo")).value =
        this.userTypes.find((find) => find.pkId == user.Tipo).Nombre;
  }
  initializeFormWithoutData() {
    this.editUserForm = this.fb.group({
      pkId: [""],
      Tipo: ["", Validators.required],
      Personal: [
        "",
        [Validators.required, Validators.pattern(/^-?\d{1,9}(\.\d{1,2})?$/)],
      ],
      Nombre: ["", [Validators.required, Validators.pattern(/[A-Za-z]/)]],
      Empleado: [""],
      Conectado: [false],
      Baja: [false],
      Email: [""],
      CentrosIds: [""],
      /*       Clave: ["", Validators.required], */
    });
  }

  stringToBoolean(value) {
    if (typeof value !== "boolean") {
      if (value.toLowerCase() == "true") {
        value = true;
      } else {
        value = false;
      }
    }
    return value;
  }

  submitInterval() {
    let thisobj = this;

    setTimeout(function () {
      thisobj.submit();
    }, 1200);
  }
  submit() {
    this.editUserForm.markAllAsTouched();
    if (this.editUserForm.valid) {
      this.editUserForm
        .get("CentrosIds")
        .setValue(
          this.gsCentros.obtenercentrosAsociadosconOrden(this.centrosAsignados)
        );
      this.loadDataToApi();
      let user: User = this.editUserForm.value;
      this.manageApi(user, this.mode);
    }
  }

  destroy() {
    this.panelService.setDiscardChanges(true);
    this.panelService.setClosePanel(null);
  }

  save() {
    this.loading = false;
    this.panelService.setDiscardChanges(true);

    this.panelService.setSavePanel(null);
  }
  discard() {
    this.changeValueForm(this.user);
  }
  loadDataToApi() {
    let emails = "";

    this.emailList.map((data, index) => {
      emails += index == 0 ? data : ";" + data;
    });
    this.editUserForm.get("Email").setValue(emails);
  }
  manageApi(user: User, mode) {
    this.loading = true;
    this.user = user;

    switch (mode) {
      case "I":
        //insert
        user.pkId = "0";
        this.InsUsuario(this.user);
        break;
      case "U":
        //update
        this.UpdUsuario(this.user);

        break;
      case "D":
        //duplicate
        user.pkId = "0";
        this.InsUsuario(this.user);

        break;

      default:
        break;
    }
  }
  UpdUsuario(user) {
    this.editUserService
      .updUsuario(user, "U")
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          this.nextWindow(response);
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }
  InsUsuario(user) {
    this.editUserService
      .insUsuario(user, "I")
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          this.nextWindow(response);
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  nextWindow(response: any) {
    const err = this.existErrors();
    if (!this.existErrors()) {
      this.save();
      this.destroy();
      if (response.Mensajes.length > 0) {
        response.Mensajes.forEach((element) => {
          this.snackvar_service.openSnackBar(
            element.DescripcionMensaje,
            "",
            "red-snackbar",
            element.CodigoMensaje
          );
        });
      } else {
        this.snackvar_service.openSnackBar(
          this.translation["MensajeSuccessSnackBar"],
          "",
          "green-snackbar"
        );
      }
    }
  }

  notEmptyFields(user: User): boolean {
    let res = true;
    if (
      user.Nombre.length === 0 ||
      user.Empleado.length === 0 ||
      user.Clave.length === 0
    ) {
      res = false;
    }
    return res;
  }

  valuedChanged() {
    this.editUserForm.valueChanges.subscribe((value) => {
      if (this.user != null) {
        this.panelService.setDiscardChanges(false);
        if (JSON.stringify(value) === JSON.stringify(this.user)) {
          this.panelService.setDiscardChanges(true);
        }
      }
    });
  }

  validateFields(field: string) {
    return this.isValidAnyField(field);
  }

  isValidAnyField(field: string) {
    let fc_pv = this.formControls;
    return fc_pv[field].errors != null || fc_pv[field].invalid ? true : false;
  }

  getErrorMessage(field: string) {
    let fc_pv = this.editUserForm.get(field);
    if (fc_pv.errors) {
      if (fc_pv.errors.required) {
        return this.translation["CampoRequerido"];
      } else if (field === "Empleado") {
        if (fc_pv.hasError("pattern")) {
          return this.translation["SoloNumeros"];
        }
      } else if (field === "Nombre") {
        if (fc_pv.hasError("pattern")) {
          return this.translation["SoloLetras"];
        }
      }
    }
    return "Error";
  }

  existErrors() {
    return this.formControls.Tipo.errors ||
      this.formControls.Personal.errors ||
      this.formControls.Nombre.errors ||
      this.formControls.Empleado.errors ||
      this.formControls.Email.errors
      ? true
      : false;
  }

  /* insertDataInForm(){
    this.formControls["Tipo"].setValue((<HTMLInputElement>document.getElementById("Tipo-hidden")).value)
    
  }
  insertDataInObj(){
    this.user.Tipo =  (<HTMLInputElement>document.getElementById("Tipo-hidden")).value
    
  } */
  closeInputFilter(enventValues) {
    //this.insertDataInForm()
    this.editUserForm.get(enventValues.name).setValue(enventValues.value);

    // this.f[enventValues.name].value == enventValues.value ? this.panelService.setDiscardChanges(true):this.panelService.setDiscardChanges(false)
  }

  add(event): void {
    if (event.value) {
      if (this.validateEmail(event.value)) {
        if (this.emailList.findIndex((find) => find == event.value) == -1)
          this.emailList.push(event.value);
      } else {
        //this.emailList.push({ value: event.value, invalid: true });
        //this.rulesForm.controls['emails'].setErrors({'incorrectEmail': true});
      }
    }
    if (event.input) {
      event.input.value = "";
    }
  }

  removeEmail(data: any): void {
    if (this.emailList.indexOf(data) >= 0) {
      this.emailList.splice(this.emailList.indexOf(data), 1);
    }
  }
  private validateEmail(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  //centros
  /*     filtrarTiposCentros() {
      // return this.tipoCentros.filter(x => x.Activo == "True");
      let respuesta = [];
      let Centros = this.tipoCentros.filter(x => x.Activo == "True");
      let tmpCentrosAsociados = this.centrosasociados;
      if (Centros != null && Centros.length > 0) {
        respuesta = Centros.filter(function (item) {
          return tmpCentrosAsociados.findIndex(function (itemSub) { return itemSub == item.pkId }) === -1;
        });
      }
      else {
        respuesta = this.tipoCentros.filter(x => x.Activo == "True");
      }
      return respuesta;
    }
  
    comparaObjetosSelect(o1: any, o2: any) {
      return o1 && o2 ? o1.id === o2.id || o1 === o2.id || o1.id === o2 : o1 === o2;
    }
    asignarTipoCentro(item, evento) {
      this.centrosasociados.push(item.pkId);
    }
    desasignarTipoCentro(item, evento) {
      let arrtemp = [];
      arrtemp = this.centrosasociados.filter(function (x) { return x != item.pkId });
      this.centrosasociados = arrtemp;
  
    }
  
    deasignarTodosCentros() {
      this.centrosasociados = [];
    }
    asignarTodosCentros() {
      this.centrosasociados = this.tipoCentros.filter(x => x.Activo == "True").map(function (x) { return x.pkId });
    }
  
    obtenercentrosAsociadosconOrden(): string {
      let respuesta: string = '';
      let elemento: string;
      let count = 0;
      if (this.centrosAsignados) {
  
        this.centrosAsignados.forEach(centro => {
          //let orden = this.getOrdenRecinto(actividad);
          elemento = centro.pkId + ',';
          // elemento = element + ":" + (this.ordenRecintosAsociados[count] == null ? '0' : this.ordenRecintosAsociados[count]) + ",";
          respuesta = respuesta + elemento;
          count++;
        });
      }
      return respuesta.substring(0, respuesta.length - 1);
    }
  
    daTiposCentros(): any[] {
      let respuesta = [];
      if (this.tipoCentros != null) {
        if (this.user.CentrosIds) {
          let arrayCentrosString = this.user.CentrosIds.split(",")

          let allCentros = this.tipoCentros.filter(x => x.Activo == "True");
  
          let array = arrayCentrosString.map(centropkId => {
            let act = allCentros.find(find => find.pkId == centropkId)
            respuesta.push(act)
          })

        }
      }
      return respuesta;
    } */
  //centros
}
