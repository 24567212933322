import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import {
  TipoBono,
  tipoBonoEntradaPlantilla,
} from "@sharedV11/classes/entradas-y-promociones/tipo-entrada/tipobono";
import { DialogoPopUp } from "@sharedV11/components/alert/dialog/popup.service";
import { TableInteractiveComponent } from "@sharedV11/components/tables/table-interactive/table-interactive.component";
import { AlertService } from "@sharedV11/services/alerts/alert/alert.service";
import { HttpErrorsService } from "@sharedV11/services/alerts/errors/http-errors.service";
import { SnackbarInfoService } from "@sharedV11/services/alerts/snackbar-info/snackbar-info.service";
import { ConectarApiService } from "@sharedV11/services/api/connection/conectar-api.service";
import { EntryTypeService } from "@sharedV11/services/components/entradas-y-promociones/entry-type/entry-type.service";
import { BonoTypeService } from "@sharedV11/services/components/entradas-y-promociones/tipobono/bono-type.service";
import { GlobalService } from "@sharedV11/services/global/global.service";
import { GlobalServicCentros } from "@sharedV11/services/global/global.service centros";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { Translator } from "@sharedV11/services/translator/translator.service";
import { DualListComponent } from "angular-dual-listbox";

@Component({
  selector: "app-edit-tipobono",
  templateUrl: "./edit-tipobono.component.html",
  styleUrls: ["./edit-tipobono.component.scss"],
})
export class EditTipoBonoComponent implements OnInit {
  loading = true;
  //loaded = false;
  @Input() pkId: string;
  @Input() mode: string;
  @Input() modificar: boolean;
  @ViewChild("ti_tipoBono") ti_tipoBono: TableInteractiveComponent;
  //dataSourceTarifaPromocionEntrada: MatTableDataSource<tipoBonoEntradaPlantilla>;

  translation;

  editorRegistroForm: FormGroup;

  bonoType: TipoBono = null;
  GrupoMotivosId: any;
  gruposTipoBono: any;
  gruposTiposProductos: any;
  gruposAsociados: any;
  tiposvisitas: any;
  tiposnivel: any;
  tiposcontrol: any;
  categoriaDatos: any;
  TEDatos: any;
  tiposEntrada: any;

  /////
  permiteBorrar: boolean = true;
  elementosBorrados: string[] = [];
  promocionEntradaFormGroup: FormArray = this.formBuilder.array([]);
  tiposBonoEntrada: tipoBonoEntradaPlantilla[];

  formchanges: boolean = false;
  columnsToDisplayTarifaEntrada: string[];

  tablavalida;
  tiposBotoneraEntrada;

  //centros
  centrosSinAsignar = [];
  centrosAsignados = [];
  tipoCentros: any[] = [];
  //centros
  format = {
    add: "Añadir",
    remove: "Borrar",
    all: "Todos",
    none: "Ninguno",
    direction: DualListComponent.LTR,
    draggable: true,
    locale: "es",
  };
  eltope: number = 0;

  /////
  constructor(
    private translator: Translator,
    private formBuilder: FormBuilder,
    private _cds: ChangeDetectorRef,
    private panelService: PanelService,
    private servicioAPI: ConectarApiService,
    private BonoTypeService: BonoTypeService,
    public global: GlobalService,
    public dialog: MatDialog,
    private snackvar_service: SnackbarInfoService,
    private alertaServicio: AlertService,
    private httpErrorService: HttpErrorsService,
    private gsCentros: GlobalServicCentros,
    private entryTypeService: EntryTypeService
  ) {}

  ngAfterViewInit() {
    //setTimeout(function() {this.loaded=true;this._cds.detectChanges()},10000)
  }

  ngOnInit(): void {
    this.loadCentros();
    this.translation = this.translator.GetTranslations();
    this.columnsToDisplayTarifaEntrada = [
      "Acciones",
      "NombreTipoEntrada",
      "iNumEntradas",
      "rPorcentaje",
      "dtFechaInicio",
      "dtFechaFin",
    ];

    this.generateForm();
    this.cargarDatos();
    this.cargarTiposControl();
    this.cargarBotonera();
  }

  async loadCentros() {
    await this.gsCentros.getCentros();
  }

  save(cerrarpanel?) {
    this.loading = false;
    this.panelService.setDiscardChanges(true);
    this.panelService.setSavePanel(cerrarpanel);
    document.getElementById("eltope").scrollTop = this.eltope;
  }

  destroy() {
    this.panelService.setDiscardChanges(true);
    this.panelService.setClosePanel(null);
  }

  discard() {
    this.setValuesForm(this.bonoType);
  }

  submit(cerrarpanel: boolean = true) {
    var fechasvacias = false;
    this.eltope = document.getElementById("eltope").scrollTop;

    var porcentajeTotal = 0;
    this.ti_tipoBono.dataSource.data.forEach((item) => {
      if (item.rPorcentaje == "") item.rPorcentaje = 0;
      if (item) porcentajeTotal += parseFloat(item.rPorcentaje);
      if (item.dtFechaFin == "" || item.dtFechaInicio == "")
        fechasvacias = true;
    });

    if (!fechasvacias && porcentajeTotal > 0 && porcentajeTotal === 100) {
      if (
        this.ti_tipoBono.dataSource.data &&
        this.ti_tipoBono.dataSource.data.length > 0
      ) {
        this.editorRegistroForm.markAllAsTouched();
        // let client:Cliente=this.editorRegistroForm.value;
        if (this.editorRegistroForm.valid) {
          //this.editorRegistroForm.get("GrupoMotivoId").setValue(this.editorRegistroForm.get("GrupoMotivoId").value)
          //this.bonoType = this.editorRegistroForm.value;
          //this.bonoType["grupoMotivoId"] = this.editorRegistroForm.get("GrupoMotivoId").value;

          this.editorRegistroForm.markAllAsTouched();
          let tipoBono: TipoBono = this.editorRegistroForm.value;
          //tipoBono["TipoBonoEntadas"]=this.ti_tipoBono.dataSource.data;

          this.manageApi(tipoBono, this.mode, cerrarpanel);
        }
      } else {
        this.snackvar_service.openSnackBar(
          this.translation.Necesitaalmenosunaentrada,
          "",
          "red-snackbar"
        );
      }
    } else {
      const dialogRef = this.dialog.open(DialogoPopUp, {
        width: "250px",
        data: {
          titulo: this.translation.PorcentajeIncorrecto,
          texto: this.translation.ElPorcentajeHaDeSerDelCienPorciento,
          ok: this.translation.Confirmar,
        },
      });
    }
  }

  get f() {
    return this.editorRegistroForm.controls;
  } //Editor
  get chTipoValidez() {
    return this.editorRegistroForm.get("chTipoValidez");
  }
  get iValidez() {
    return this.editorRegistroForm.get("iValidez");
  }
  get iGrupoId() {
    return this.editorRegistroForm.get("iGrupoId");
  }

  manageForm(pkId, mode) {
    //this.changeStatusClientCode(true)

    if (mode == "I") {
      this.bonoType = this.editorRegistroForm.value;
      this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
      this.centrosAsignados = this.gsCentros.daTiposCentros(this.bonoType);
      this.loading = false;
      this.valuedChanged();
      //this.changeStatusClientCode(false)
    }

    if (pkId != null) {
      // this.findClient(pkId)
      this.findBonoType(pkId);
    }
  }

  findBonoType(pkId) {
    let paginator = {
      page: 1,
      pagelements: 10,
      orderby: "pkId",
      ordertype: "ASC",
    };
    let filters = [{ name: "pkId", value: this.pkId, translation: "", db: "" }];
    this.BonoTypeService.getTiposBono(paginator, filters).subscribe(
      (response) => {
        if (response.DatosResult != null) {
          this.bonoType = response.DatosResult.lista[0];
          let CentrosIds = this.bonoType.CentrosIds;
          this.setValuesForm(this.bonoType);
          //this.dataSourceTarifaPromocionEntrada = new MatTableDataSource(this.bonoType.TipoBonoEntadas);
          this.bonoType = this.editorRegistroForm.value;
          this.bonoType.CentrosIds = CentrosIds;
          this.valuedChanged();
          this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
          this.centrosAsignados = this.gsCentros.daTiposCentros(this.bonoType);
          this.loading = false;
        } else {
          this.destroy();
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }

  setEntradasDataTable() {
    //this.bonoType.TipoBonoEntadas = [];
    this.bonoType.Entradas = [];

    this.ti_tipoBono.dataSource.data.forEach((element, i) => {
      //if (element instanceof FormGroup) {
      let tipoBono = new tipoBonoEntradaPlantilla();
      if (element.pkId && element.pkId != "0") tipoBono.pkId = element.pkId;
      else tipoBono.pkId = "0";
      tipoBono.iTipoEntradaId = element.TipoEntradaId;
      tipoBono.NombreTipoEntrada = element.NombreTipoEntrada;
      tipoBono.iNumEntradas = element.iNumEntradas;
      tipoBono.dtFechaInicio = this.fechaFrontToFechaApiTable(
        element.dtFechaInicio.value.format("DD/MM/YYYY")
      );
      tipoBono.dtFechaFin = this.fechaFrontToFechaApiTable(
        element.dtFechaFin.value.format("DD/MM/YYYY")
      );
      if (element.rPorcentaje == "") tipoBono.rPorcentaje = "0";
      else tipoBono.rPorcentaje = element.rPorcentaje;
      tipoBono.iTipoBonoEntradasId = element.iTipoBonoEntradasId;
      tipoBono.iTipoBonoId = this.bonoType.pkId;
      //this.bonoType.TipoBonoEntadas.push(tipoBono);
      this.bonoType.Entradas.push(tipoBono);
      //}
    });
  }

  validateEntradas(promocionEntradaFormGroup) {
    let ret = true;

    if (promocionEntradaFormGroup)
      promocionEntradaFormGroup.controls.forEach((element) => {
        if (
          element instanceof FormGroup &&
          element.value["iTipoEntradaId"] == ""
        )
          ret = false;
        if (element instanceof FormGroup && element.value["iNumEntradas"] == "")
          ret = false;
        if (
          element instanceof FormGroup &&
          element.value["dtFechaInicio"] == ""
        )
          ret = false;
        if (element instanceof FormGroup && element.value["dtFechaFin"] == "")
          ret = false;
      });
    return ret;
  }
  insertDataInObj() {
    (this.bonoType.pkId = this.pkId ? this.pkId : "0"),
      (this.bonoType.CodigoBarras =
        this.global.aBooleano(this.bonoType.CodigoBarras) == true ? "1" : "0");
    this.bonoType.BarCodeInternet =
      this.global.aBooleano(this.bonoType.BarCodeInternet) == true ? "1" : "0";
    this.bonoType.bALaVenta =
      this.global.aBooleano(this.bonoType.bALaVenta) == true ? "1" : "0";
    this.bonoType.bImprimirPrecio =
      this.global.aBooleano(this.bonoType.bImprimirPrecio) == true ? "1" : "0";
    this.bonoType.bImprimirTicket =
      this.global.aBooleano(this.bonoType.bImprimirTicket) == true ? "1" : "0";

    this.bonoType.bImpresionConjunta =
      this.global.aBooleano(this.bonoType.bImpresionConjunta) == true
        ? "1"
        : "0";
    this.bonoType.bControlarAcceso =
      this.global.aBooleano(this.bonoType.bControlarAcceso) == true ? "1" : "0";

    //this.bonoType.chTeclaRapida = this.global.aBooleano(this.bonoType.chTeclaRapida)==true?"1":"0";
    this.bonoType.bImprimirPromo =
      this.global.aBooleano(this.bonoType.bImprimirPromo) == true ? "1" : "0";
  }
  manageApi(tipoBono: TipoBono, mode, cerrarpanel?) {
    this.bonoType = tipoBono;
    this.bonoType.CentrosIds = this.gsCentros.obtenercentrosAsociadosconOrden(
      this.centrosAsignados
    );
    this.loading = true;
    this.insertDataInObj();
    //this.changeStatusClientCode(true)
    switch (mode) {
      case "I":
        //insert
        //this.f["Codigo"].setValue("0");
        //this.RegistrarModificar = "0"
        //this.newGuardar()
        this.bonoType.pkId = "0";
        //this.setDataSourceTarifasPromocionEntrada();
        this.setEntradasDataTable();
        this.insTipoBonos(this.bonoType, cerrarpanel);
        break;
      case "U":
        //this.RegistrarModificar = "1"
        //update
        //this.newGuardar()
        //this.setDataSourceTarifasPromocionEntrada();
        this.setEntradasDataTable();

        this.UpdTipoBonos(this.bonoType, cerrarpanel);
        break;
      case "D":
        //duplicate
        //this.RegistrarModificar = "0"
        // this.f["Codigo"].setValue("0");
        //this.newGuardar()
        this.mode = "I";
        this.bonoType.pkId = "0";

        //this.setDataSourceTarifasPromocionEntrada();
        this.setEntradasDataTable();
        //this.setEntradasData();
        this.bonoType.Entradas.map((entrada) => {
          entrada.pkId = "0";
        });
        this.insTipoBonos(this.bonoType, cerrarpanel);
        break;

      default:
        break;
    }
    //this.changeStatusClientCode(false)
  }

  insTipoBonos(tbono, cerrarpanel?) {
    //this.servicioAPI.insTipoBono(this.bonoType, this.mode).subscribe(response => {
    this.servicioAPI.insTipoBono(tbono, this.mode).subscribe(
      (response) => {
        //this.ti_tipoBono.reloadTable();
        this.nextWindow(response, cerrarpanel);
        //this.save();
        //this.destroy();
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }

  UpdTipoBonos(tbono, cerrarpanel?) {
    this.servicioAPI.updTipoBono(this.bonoType, this.mode).subscribe(
      (response) => {
        if (response.DatosResult != null) {
          this.nextWindow(response, cerrarpanel);
        } else {
          this.snackvar_service.openSnackBar(
            response.Mensajes[0].DescripcionMensaje,
            "",
            "red-snackbar"
          );
          this.nextWindow(response, cerrarpanel);
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }

  nextWindow(response: any, cerrarpanel?) {
    if (response.Mensajes.length > 0) {
      response.Mensajes.forEach((element) => {
        this.snackvar_service.openSnackBar(
          element.DescripcionMensaje,
          "",
          "red-snackbar",
          element.CodigoMensaje
        );
      });
    } else {
      this.snackvar_service.openSnackBar(
        this.translation["MensajeSuccessSnackBar"],
        "",
        "green-snackbar"
      );
    }

    this.save(cerrarpanel);
    if (cerrarpanel && cerrarpanel == true) this.destroy();
  }

  existErrors() {
    return this.editorRegistroForm.get("pkId").errors ? true : false;
  }

  valuedChanged() {
    this.editorRegistroForm.valueChanges.subscribe((value) => {
      if (this.bonoType != null) {
        this.panelService.setDiscardChanges(false);
        if (JSON.stringify(value) === JSON.stringify(this.bonoType)) {
          this.panelService.setDiscardChanges(true);
        }
      }
    });
  }

  generateForm() {
    this.editorRegistroForm = this.formBuilder.group({
      chNombrePromo: ["", Validators.required],
      chCodigo: [""],
      chDescripcionNombre: [""],
      chTextoPantalla: [""],
      chTextoImpresion: [""],
      chTextoDisplay: [""],
      chTipoValidez: ["", Validators.required],
      iGrupoId: ["", Validators.required],
      GrupoMotivoId: [""],
      iTipoProductoId: [""],
      iGrupoAsociadoId: [""],
      iValidez: ["0", Validators.required],
      iTipoVisitaId: [""],
      iTipoNivelId: [""],
      iCategoriaId: [""],
      iTEDatosId: [""],
      bALaVenta: [""],
      bImprimirPromo: [""],
      bImprimirPrecio: [""],
      bImprimirTicket: [""],
      chTeclaRapida: [""],
      CodigoBarras: [""],
      BarCodeInternet: [""],
      bImpresionConjunta: [""],
      siCodigoTipoControl: [""],
      bControlarAcceso: [""],
      iNominar: ["0"],
      Entradas: this.promocionEntradaFormGroup,
    });

    const asociarCentrosCosteCanales = JSON.parse(
      localStorage.getItem("AsociarCentrosCosteCanales")
    );
    const ObligatorioCentrosDeCoste = JSON.parse(
      localStorage.getItem("ObligatorioCentrosDeCoste")
    );
    const ObligatorioCanalesEntradas = JSON.parse(
      localStorage.getItem("ObligatorioCanalesEntradas")
    );
    if (asociarCentrosCosteCanales == "1") {
      if (ObligatorioCentrosDeCoste == "1") {
        this.editorRegistroForm.addControl(
          "centroCosteId",
          new FormControl("", Validators.required)
        );
      } else {
        this.editorRegistroForm.addControl(
          "centroCosteId",
          new FormControl("")
        );
      }

      if (ObligatorioCanalesEntradas == "1") {
        this.editorRegistroForm.addControl(
          "canalId",
          new FormControl("", Validators.required)
        );
      } else {
        this.editorRegistroForm.addControl("canalId", new FormControl(""));
      }
    }
  }
  setValuesForm(bonoType) {
    this.f["chCodigo"].setValue(bonoType.chCodigo);
    this.f["chNombrePromo"].setValue(bonoType.chNombrePromo);
    this.f["chDescripcionNombre"].setValue(bonoType.chDescripcionNombre);
    this.f["chTextoPantalla"].setValue(bonoType.chTextoPantalla);
    this.f["chTextoImpresion"].setValue(bonoType.chTextoImpresion);
    this.f["chTextoDisplay"].setValue(bonoType.chTextoDisplay);
    this.f["chTipoValidez"].setValue(bonoType.chTipoValidez);
    this.f["iGrupoId"].setValue(bonoType.iGrupoId);
    this.f["GrupoMotivoId"].setValue(bonoType.GrupoMotivoId);
    this.f["iTipoProductoId"].setValue(bonoType.iTipoProductoId);
    this.f["iGrupoAsociadoId"].setValue(bonoType.iGrupoAsociadoId);
    this.f["iValidez"].setValue(bonoType.iValidez);
    this.f["iTipoVisitaId"].setValue(bonoType.iTipoVisitaId);
    this.f["iTipoNivelId"].setValue(bonoType.iTipoNivelId);
    this.f["iCategoriaId"].setValue(bonoType.iCategoriaId);
    this.f["iTEDatosId"].setValue(bonoType.iTEDatosId);
    this.f["bImprimirPromo"].setValue(
      this.global.aBooleano(bonoType.bImprimirPromo)
    );
    this.f["bALaVenta"].setValue(this.global.aBooleano(bonoType.bALaVenta));
    this.f["bImprimirPrecio"].setValue(
      this.global.aBooleano(bonoType.bImprimirPrecio)
    );
    this.f["bImprimirTicket"].setValue(
      this.global.aBooleano(bonoType.bImprimirTicket)
    );
    this.f["chTeclaRapida"].setValue(bonoType.chTeclaRapida);
    this.f["CodigoBarras"].setValue(
      this.global.aBooleano(bonoType.CodigoBarras)
    );
    this.f["BarCodeInternet"].setValue(
      this.global.aBooleano(bonoType.BarCodeInternet)
    );
    this.f["bImpresionConjunta"].setValue(
      this.global.aBooleano(bonoType.bImpresionConjunta)
    );
    this.f["bControlarAcceso"].setValue(
      this.global.aBooleano(bonoType.bControlarAcceso)
    );
    this.f["iNominar"].setValue(
      bonoType.iNominar ? bonoType.iNominar.toString() : ""
    );
    this.f["siCodigoTipoControl"].setValue(bonoType.siCodigoTipoControl);

    bonoType.Entradas = [];

    //bonoType.TipoBonoEntadas.splice(1);
    this.tiposBonoEntrada = bonoType.TipoBonoEntadas;

    for (let i = 0; bonoType.TipoBonoEntadas.length > i; i++) {
      if (bonoType.TipoBonoEntadas[i].rPorcentaje == "") {
        bonoType.TipoBonoEntadas[i].rPorcentaje = "0";
      }
      //bonoType.Entradas.push(bonoType.TipoBonoEntadas[i]);
      var promocionEntradaFormControls = new FormGroup({
        pkId: new FormControl(bonoType.TipoBonoEntadas[i].pkId),
        iTipoBonoEntradasId: new FormControl(
          bonoType.TipoBonoEntadas[i].iTipoBonoEntradasId
        ),
        iTipoEntradaId: new FormControl(
          bonoType.TipoBonoEntadas[i].iTipoEntradaId
        ),
        NombreTipoEntrada: new FormControl(
          bonoType.TipoBonoEntadas[i].NombreTipoEntrada
        ),
        iNumEntradas: new FormControl(bonoType.TipoBonoEntadas[i].iNumEntradas),
        dtFechaInicio: new FormControl(
          this.fechaApiToFechaFront(bonoType.TipoBonoEntadas[i].dtFechaInicio)
        ),
        dtFechaFin: new FormControl(
          this.fechaApiToFechaFront(bonoType.TipoBonoEntadas[i].dtFechaFin)
        ),
        rPorcentaje: new FormControl(bonoType.TipoBonoEntadas[i].rPorcentaje),
      });

      this.promocionEntradaFormGroup.controls.push(
        promocionEntradaFormControls
      );
    }
    //this._cds.detectChanges();

    if (this.mode == "D") this.f["chCodigo"].setValue("");
    //this.setDataSourceTarifasPromocionEntrada();
  }

  async cargarTiposControl() {
    let resultado: boolean = false;

    await this.entryTypeService.getTiposControlAsync().then(
      (data) => {
        if (data.DatosResult != null) {
          this.tiposcontrol = data.DatosResult.customanTiposControl;
        } else {
          this.alertaServicio.error(data.Mensajes[0].DescripcionMensaje);
          resultado = false;
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
        resultado = false;
      }
    );
  }

  cargarDatos() {
    //Cargamos Datos
    let resultado: boolean = false;

    this.BonoTypeService.getGruposTiposEntrada().subscribe(
      (data) => {
        this.GrupoMotivosId = data.DatosResult.customanGrupoTipoEntrada.filter(
          (filter) => filter.ConMotivo == 1
        );
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    this.BonoTypeService.GetGrupoTipoBono().subscribe(
      (data) => {
        this.gruposTipoBono = data.DatosResult.customanGrupoTipoBono;
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    this.BonoTypeService.GetTiposProductos().subscribe(
      (data) => {
        this.gruposTiposProductos = [];
        if (data.DatosResult)
          this.gruposTiposProductos = data.DatosResult.ListadoTiposProductos;
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    this.BonoTypeService.GetGruposAsociados().subscribe(
      (data) => {
        this.gruposAsociados = [];
        if (data.DatosResult)
          this.gruposAsociados = data.DatosResult.ListadoGruposAsociados;
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    this.BonoTypeService.GetTipoVisitas().subscribe(
      (data) => {
        this.tiposvisitas = [];
        if (data.DatosResult) this.tiposvisitas = data.DatosResult.lista;
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    this.BonoTypeService.GetTipoNiveles().subscribe(
      (data) => {
        this.tiposnivel = [];
        if (data.DatosResult)
          this.tiposnivel = data.DatosResult.ListadoTiposNiveles;
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    this.BonoTypeService.GetCategorias().subscribe(
      (data) => {
        this.categoriaDatos = data.DatosResult.lista;
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    this.BonoTypeService.GetTEDatos().subscribe(
      (data) => {
        if (data.DatosResult)
          this.TEDatos = data.DatosResult.ListadoTiposEntradaDatos;
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    this.BonoTypeService.GetTipoEntrada().subscribe(
      (data) => {
        this.tiposEntrada = data.DatosResult.Lista;
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );
    this.manageForm(this.pkId, this.mode);
    return resultado;
  }

  async cargarBotonera(): Promise<boolean> {
    let resultado: boolean = false;
    await this.BonoTypeService.getBotoneraAsync(
      null,
      this.global.maxBotonesRapidosTPV.toString().trim()
    ).then(
      (data) => {
        if (data.DatosResult != null) {
          this.tiposBotoneraEntrada = data.DatosResult.Lista;
        } else {
          this.alertaServicio.error(
            "Botonera: " + data.Mensajes[0].DescripcionMensaje
          );
          resultado = false;
        }
      },
      (error) => {
        //this.alertaServicio.error('Botonera: ' + error);
        this.httpErrorService.identificarErrores(error);
        resultado = false;
      }
    );
    return resultado;
  }

  CambioTeclaRapida(ev) {
    let v = ev.value;
    let c = this.tiposBotoneraEntrada.find((f) => f.TeclaRapida == v);
    if (c && c.NombreTipo.trim() != "")
      this.f["chTeclaRapida"].setValue(this.bonoType.chTeclaRapida);
  }

  iValidezaCero(ev, form) {
    if (ev.value) form.patchValue({ iValidez: 0 });
  }

  cambiaNombre(evento) {
    //Si los modelos de Display, Taquilla y Imprimir estan vacios les pongo el nombre del evento.

    if (
      this.f["chTextoDisplay"].value == null ||
      this.f["chTextoDisplay"].value.trim() == ""
    ) {
      this.f["chTextoDisplay"].setValue(evento.target.value);
    }
    if (
      this.f["chTextoPantalla"].value == null ||
      this.f["chTextoPantalla"].value.trim() == ""
    ) {
      this.f["chTextoPantalla"].setValue(evento.target.value);
    }
    if (
      this.f["chTextoImpresion"].value == null ||
      this.f["chTextoImpresion"].value.trim() == ""
    ) {
      this.f["chTextoImpresion"].setValue(evento.target.value);
    }
  }
  comparaObjetosSelect(o1: any, o2: any) {
    return o1 && o2
      ? o1.id === o2.id || o1 === o2.id || o1.id === o2
      : o1 === o2;
  }

  validacion() {
    const separador = document.getElementsByClassName("separadorLateral");
    const campos = document.getElementsByClassName("editor-campo-registro");
    for (let i = 0; i < campos.length; i++) {
      if (campos[i].classList.contains("ng-valid")) {
        if (campos[i].classList.contains("editor-campo-registro-check")) {
          separador[i].setAttribute("style", "border-color:#002c76");
        } else {
          separador[i].setAttribute("style", "border-color:green");
        }
      } else {
        separador[i].setAttribute("style", "border-color:#002c76");
      }
    }
  }

  // formato front: 2020-10-14 (yyyy-mm-dd)
  // formato back:  31/12/2900 0:00:00 (dd/mm/yyyy 0:00:00)
  fechaApiToFechaFront(fecha: string) {
    if (fecha) {
      let split1 = fecha.split(" ");
      let split2 = split1[0].split("/");
      let ans = split2[2] + "-" + split2[1] + "-" + split2[0];
      return ans;
    } else {
      return fecha;
    }
  }

  fechaFrontToFechaApi(fecha: string) {
    let split1 = fecha.split("-");
    let ans = split1[2] + "/" + split1[1] + "/" + split1[0] + " 0:00:00";
    return ans;
  }

  fechaFrontToFechaApiTable(fecha: string) {
    let ans = fecha + " 0:00:00";
    return ans;
  }

  cabgetNombreGrupoById(id: string) {
    // TODO: falta acceso a la tabla tbGrupoTipoBono.PkId
  }

  isnovalid() {}

  validateTable(promocionEntradaFormGroup) {
    let ret = true;
    if (this.hasTableRows()) {
      if (promocionEntradaFormGroup)
        promocionEntradaFormGroup.controls.forEach((element) => {
          if (
            element instanceof FormGroup &&
            element.value["iTipoEntradaId"] == ""
          )
            ret = false;
          if (
            element instanceof FormGroup &&
            element.value["iNumEntradas"] == ""
          )
            ret = false;
          if (
            element instanceof FormGroup &&
            element.value["dtFechaInicio"] == ""
          )
            ret = false;
          if (element instanceof FormGroup && element.value["dtFechaFin"] == "")
            ret = false;
        });
    } else {
      ret = false;
    }
    return ret;
  }

  hasTableRows() {
    let ret = false;
    if (
      !this.promocionEntradaFormGroup ||
      this.promocionEntradaFormGroup.length < 1
    )
      ret = false;
    else ret = true;

    return ret;
  }

  closeInputFilter(enventValues) {
    this.editorRegistroForm.get(enventValues.name).setValue(enventValues.value);
  }

  closeInputFilterInArray(enventValues, i) {
    this.editorRegistroForm.controls["Entradas"]["controls"][i]
      .get("iTipoEntradaId")
      .setValue(enventValues.value);
    this.editorRegistroForm.controls["Entradas"]["controls"][i]
      .get("iTipoBonoEntradasId")
      .setValue(enventValues.value);
  }
}
