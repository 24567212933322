import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Grupos_actividad_taller, peticion_Del_GruposActividadTaller, Request_GrupoActividadTaller_InsUp, Request_GrupoActividadTaller_list } from '@sharedV11/classes/recintos/grupos-actividad-taller/grupos-actividad-taller';
import { ApiTokenService } from '@sharedV11/services/api/token/api-token.service';
import { GlobalService } from '@sharedV11/services/global/global.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IdiomaGR } from '../../../classes/tarifaResponse/idiomaGR';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class GruposActividadTallerService {

  constructor(
    private http: HttpClient,
    private tokenService: ApiTokenService,
    private global: GlobalService
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  findGrupoActividadTallerPkId(pkId: string): Observable<any> {

    let peticion: Request_GrupoActividadTaller_list = new Request_GrupoActividadTaller_list();
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Nombre = "";
    peticion.Descripcion = "";
    peticion.pkId = pkId;
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanGruposActividadTaller', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }

/*   insUpgGrupoActividadTaller(grupo: Grupos_actividad_taller) {
    let peticion: Request_GrupoActividadTaller_InsUp = new Request_GrupoActividadTaller_InsUp();

    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.pkId = grupo.pkId;
    (grupo.pkId === "0") ? peticion.Funcion = 'I' : peticion.Funcion = 'U';
    peticion.Nombre = grupo.Nombre;
    peticion.Descripcion = grupo.Descripcion;


    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarActualizarCustomanGruposActividadTaller', JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('insUpgGrupoActividadTaller'))
    )
  } */
  insGruposGrupoActividadTaller(actividadtaller,mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = actividadtaller;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarCustomanGruposActividadTaller ', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }
  updGruposGrupoActividadTaller(actividadtaller,mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = actividadtaller;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ActualizarCustomanGruposActividadTaller', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }
  insUpdGruposGrupoActividadTaller(actividadtaller,mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = actividadtaller;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarActualizarCustomanGruposActividadTaller', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }
  delGruposActividadTaller(pkId: string): Observable<any> {
    let peticion: peticion_Del_GruposActividadTaller = new peticion_Del_GruposActividadTaller();
    peticion.clienteAPI = this.global.clienteapi; // TODO

    peticion.pkId = pkId;
    //peticion.PkId = pkId;

    // Enviamos la peticion
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/EliminarCustomanGruposActividadTaller', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }
}
