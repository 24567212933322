import { Component } from '@angular/core';
import { ApiTokenService } from './shared/services/api/token/api-token.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'customan-angular11';

  constructor(
    private tokenService: ApiTokenService
  ) {
    this.tokenService.getConexionIACPOS();

  }


}
