<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div [ngClass]="{ zonaoculta: loading === true }">
  <app-buttons
    (save)="submit()"
    (close)="destroy()"
    (discard)="discard()"
    [buttonsWrap]="'panel'"
    [modificar]="modificar"
  ></app-buttons>
  <div class="editor-registro">
    <div class="divForm">
      <form [formGroup]="editFormaPago" (onSubmit)="submit()">
        <div class="form-group row">
          <div class="col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ translation.Nombre }}</mat-label>
              <input
                matInput
                formControlName="Denominacion"
                class="editor-campo-registro"
              />
              <mat-error *ngIf="validateFields('Denominacion')">
                {{ getErrorMessage("Denominacion") }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ translation.Descripcion }}</mat-label>
              <input
                matInput
                formControlName="Descripcion"
                class="editor-campo-registro"
              />
              <mat-error *ngIf="validateFields('Descripcion')">
                {{ getErrorMessage("Descripcion") }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ translation.DiasPrimerVencimiento }}</mat-label>
              <input
                matInput
                formControlName="siDiasPrimerVto"
                class="editor-campo-registro"
              />
              <mat-error *ngIf="validateFields('siDiasPrimerVto')">
                {{ getErrorMessage("siDiasPrimerVto") }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ translation.NumVencimiento }}</mat-label>
              <input
                matInput
                formControlName="siNumVencimientos"
                class="editor-campo-registro"
              />
              <mat-error *ngIf="validateFields('siNumVencimientos')">
                {{ getErrorMessage("siNumVencimientos") }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-12 col-md-12">
            <mat-form-field appearance="outline">
              <mat-label>{{ translation.Codigo }}</mat-label>
              <input
                matInput
                formControlName="iCodigoFormaPago"
                class="editor-campo-registro"
              />
              <mat-error *ngIf="validateFields('iCodigoFormaPago')">
                {{ getErrorMessage("iCodigoFormaPago") }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ translation.FOP }}</mat-label>
              <input
                matInput
                formControlName="chFOP"
                class="editor-campo-registro"
              />
              <mat-error *ngIf="validateFields('chFOP')">
                {{ getErrorMessage("chFOP") }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ translation.FOP1 }}</mat-label>
              <input
                matInput
                formControlName="chFOP1"
                class="editor-campo-registro"
              />
              <mat-error *ngIf="validateFields('chFOP1')">
                {{ getErrorMessage("chFOP1") }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div style="margin: 20px 5px 0 5px; text-align: center">
          <mat-label>{{ translation.Centros }}</mat-label>

          <customan-dual-list
            [source]="centrosSinAsignar"
            [(destination)]="centrosAsignados"
            key="pkId"
            display="Nombre"
            [format]="format"
            [filter]="true"
          >
          </customan-dual-list>
        </div>
      </form>
    </div>
  </div>
</div>
