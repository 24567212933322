import {CLienteAPi} from "./cliente-api.model";

//getTiposReserva
export class peticion_Tipos_Reserva_Lista {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    Nombre: string;
    Descripcion: string;
}

//insUpdTiposReserva
export class peticion_Insercion_Upd_TiposReserva {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    
    pkId: string;
    Nombre: string;
    Descripcion: string;
    IntervaloControl: string;
    LimiteIntervalo: string;
    deBaja: string;
    ControlHorario: string;
    TiempoLimite: string;
    MostrarHorario: string;
    ControlarAforo: string;
    IncluirLLegada: string;
    Internet: string;
    MaximoInternet: string;
    MinimoInternet: string;
    dePago: string;

    Funcion: string;
}

//delTiposReserva
export class peticion_Del_TiposReserva {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    PkId: string;
}