import {Deserializable} from "./deserializable.model";

export class TipoVisita_Respuesta implements Deserializable {
    DatosResult?: DatosResult_TipoVisita;
    Mensajes: Mensaje[];

    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}

export class TipoVisita implements Deserializable {
  Id: string;
  Nombre: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class DatosResult_TipoVisita implements Deserializable {
  DatosListas: TipoVisita[];
  ListadoTiposProductos: any;
  lista: any;

    deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Mensaje implements Deserializable {
  CodigoMensaje: string;
  DescripcionMensaje: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

