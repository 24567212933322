import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslatePipe } from "./translate.pipe";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { FlexLayoutModule } from "@angular/flex-layout";

import { MaterialModule } from "./material/material.module";
import { MyCurrencyPipe } from "./my-currency.pipe";
import { SuperClassEditComponent } from "./super-class-edit/super-class-edit/super-class-edit.component";
import { SuperClassComponent } from "./super-class-edit/super-class/super-class.component";

@NgModule({
  declarations: [
    TranslatePipe,
    MyCurrencyPipe,
    SuperClassEditComponent,
    SuperClassComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
  ],

  exports: [TranslatePipe, MyCurrencyPipe, FlexLayoutModule],
})
export class SharedModule {
  // constructor() {
  //   library.add(fas, far, fab);
  // }
  constructor() {}
}
