import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface dataWait {
  mensaje: string;
}

@Component({
  selector: "app-apiwait",
  templateUrl: "./api_wait.component.html",
  styleUrls: ["./api_wait.component.scss"],
})
export class ApiWaitComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ApiWaitComponent>,
    @Inject(MAT_DIALOG_DATA) public data: dataWait
  ) {}

  ngOnInit(): void {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
