<!-- <div class="cuadroCentral" *ngIf="butacaMarcada; else loading">
<div class="izquierda">
  <p>{{ 'SELECCIONE_TIPO_ENTRADA' | translate}}</p>
</div>
<div class="derecha">
  <p>{{ butacaMarcada.info.infoGeneral.nombreRecinto}} </p>
</div>
<div>
  <table class="centro">
    <tr class="tituloTabCent">
      <td>{{ 'ENTRADA' | translate}}</td>
      <td>{{ 'CANTIDAD' | translate }}</td>
      <td>{{ 'PRECIO' | translate}}</td>
    </tr>
    <ng-container>
      <tr>
        <td> {{butacaMarcada.tipoSeleccionado.NombreEntrada}}
          <span class="descrip"> <br> {{ butacaMarcada.tipoSeleccionado.DescripcionEntrada }}
          </span></td>
          <td><input [(ngModel)]="cantidad" min="1" id="cantidad" type="number"></td>
        <td>{{ butacaMarcada.tipoSeleccionado.PrecioInternet | currency:'EUR' }}<button (click)="seleccionarButacas()">{{ 'SELECCIONAR' | translate}}</button></td>
      </tr>
    </ng-container>
  </table>
</div>
</div> -->
<div *ngIf="datosButacaActualizados; else loading">

  <div class="form-group">
    <div class="selectorNoNumeradas">
      <label [class]="''+[+itemTarifa.Habilitado !== 1 ? 'deshabilitado' : '' ]" *ngIf="!variosTiposDeButaca" for="cantidad"
        >{{ butacaMarcada.tipoSeleccionado.NombreEntrada }}
        <div class="precioTarifasNoNumeradas">
          {{ butacaMarcada.tipoSeleccionado.PrecioInternet | currency: 'EUR' }}
        </div></label
      >

      <label  [class]="''+[+itemTarifa.Habilitado !== 1 ? 'deshabilitado' : '' ]" *ngIf="variosTiposDeButaca" for="cantidad">{{ butacaMarcada.tipoSeleccionado.NombreEntrada }} - {{ 'TIPO_BUTACA_MODAL_NO_NUM' | translate}}
        {{this.butacaMarcada.tipoSeleccionado.NombreTipo}}
        <div class="precioTarifasNoNumeradas">
          {{ butacaMarcada.tipoSeleccionado.PrecioInternet | currency: 'EUR' }}
        </div></label>
      <button id="restaInput" (click)="restaCantidad($event)" [disabled]="+itemTarifa.Habilitado !== 1 || (componenteActual || enProceso)">-</button>
      <input
        disabled
        class="form-control"
        [value]="cantidad"
        min="1"
        id="cantidad"
        type="number"
      />
      <button id="sumaInput" (click)="sumaCantidad($event)"  [disabled]="+itemTarifa.Habilitado !== 1 || (componenteActual || enProceso || limiteMaximo)">+</button>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="DetalleBox__noResultsMessage">
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</ng-template>
<!-- <h4>{{ 'SELECTORENTRADASNONUMERADAS' | translate}}</h4>
  <br>
  <div class="form-group">
		<div class="selectorNoNumeradas">
		  <label for="cantidad">{{ 'NUMENTRADAS' | translate }}<div class="precioTarifasNoNumeradas">Precio</div></label>
		  <button id="restaInput">-</button>
		  <input [(ngModel)]="cantidad" min="1" id="cantidad" type="number">
		  <button id="sumaInput">+</button>
		  </div>
  </div> -->
