import { Deserializable } from "@sharedV11/classes/deserializable";
import { IdiomaGR } from "@sharedV11/classes/tarifaResponse/idiomaGR";

export class Recintos implements Deserializable {

    pkId: string;
    NumProj: string;
    Nombre: string;
    Activo: string;
    ControlarAforo: string;
    ControlarAforoReserva: string;
    TipoAforo: string;
    AforoMaximo: string;
    LimiteAforo: string;
    HoraInicio: string;
    HoraFin: string;
    PorDefecto: string;
    MinimoAforo: string;
    Taller: string;
    Butacas: string;
    MostrarInternet: string;
    MaximoInternet: string;
    GrupoActividadId: string;
    CodigoTipoControl: string;
    Secciones: string;
    GrupoRecintosId: string;
    Shape: string;
    Coords: string;
    OrdenFilas: string;
    OrdenButacas: string;
    Numeracion: string;
    NumSesion: string;
    TiempoExtra: string;
    SubGrupoRecinto: string;
    ControlExterno: string;
    EmailEnvioBD: string;
    TiposEntrada:string[];
    Idiomas: IdiomaGR[];
    CentrosIds: string;
  
    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
  }