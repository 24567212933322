import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiTokenService } from '@sharedV11/services/api/token/api-token.service';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import * as data from "@sharedV11/jsons/user/userProfile.json"
import { GlobalService } from '@sharedV11/services/global/global.service';
import { catchError, map } from 'rxjs/operators';
import { Request_userTypes_list } from '@sharedV11/classes/userRequest/users/userProfile/request_userTypes_list';
import { UserProfile } from '@sharedV11/classes/users/userProfile/userProfile';
import { Request_insertion_upd_userTypes } from '@sharedV11/classes/userRequest/users/userProfile/request_insertion_upd_userTypes';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};


@Injectable({
  providedIn: 'root'
})
export class EditUserProfileService {



  constructor(
    private http: HttpClient,
    private tokenService: ApiTokenService,
    private global: GlobalService,

  ) { }

  findUserByPkId(pkId: string): Observable<any> {

    let peticion: Request_userTypes_list = new Request_userTypes_list();
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Nombre = "";
    peticion.Descripcion = "";
    peticion.pkId = pkId
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanTipoUsuarios', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  private extractData(res: Response) {
    let body = res;
    return body || {};
  }
  /* 
    findUserByPkIdLocal(pkId:string): Observable<any> {
      return of(data["default"])
    } */


  insUpdTiposUsuario(userProfile: UserProfile): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion: Request_insertion_upd_userTypes = new Request_insertion_upd_userTypes();
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Nombre = userProfile.Nombre;
    peticion.Descripcion = userProfile.Descripcion == null ? '' : userProfile.Descripcion;
    peticion.Privilegios = userProfile.Privilegios == null ? '' : userProfile.Privilegios.toString();
    if (userProfile.pkId != null && userProfile.pkId != "0") {
      peticion.Funcion = "U";
      peticion.pkId = userProfile.pkId;
    }
    else {
      peticion.Funcion = "I";
      peticion.pkId = "0"
    }
    //Enviamos la peticion
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarCustomanActualizarTipoUsuarios', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }

  
  insTiposUsuario(userProfile: UserProfile): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion: Request_insertion_upd_userTypes = new Request_insertion_upd_userTypes();
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Nombre = userProfile.Nombre;
    peticion.Descripcion = userProfile.Descripcion == null ? '' : userProfile.Descripcion;
    peticion.Privilegios = userProfile.Privilegios == null ? '' : userProfile.Privilegios.toString();
    peticion.pkId = "0";
    peticion.CentrosIds = userProfile.CentrosIds;

    //Enviamos la peticion
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarCustomanTipoUsuarios', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }

  async insTiposUsuarioAsync(userProfile: UserProfile): Promise<any> {
    //Montamos la peticion insercion/Update
    let peticion: Request_insertion_upd_userTypes = new Request_insertion_upd_userTypes();
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Nombre = userProfile.Nombre;
    peticion.Descripcion = userProfile.Descripcion == null ? '' : userProfile.Descripcion;
    peticion.Privilegios = userProfile.Privilegios == null ? '' : userProfile.Privilegios.toString();
    peticion.pkId = "0";
    peticion.CentrosIds = userProfile.CentrosIds;

    //Enviamos la peticion

    let respuesta =
    await this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarCustomanTipoUsuarios', JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('getTiposControl'))
    ).toPromise();
    return respuesta;
  }

  updTiposUsuario(userProfile: UserProfile): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion: Request_insertion_upd_userTypes = new Request_insertion_upd_userTypes();
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Nombre = userProfile.Nombre;
    peticion.Descripcion = userProfile.Descripcion == null ? '' : userProfile.Descripcion;
    peticion.Privilegios = userProfile.Privilegios == null ? '' : userProfile.Privilegios.toString();
    peticion.pkId = userProfile.pkId;
    peticion.CentrosIds = userProfile.CentrosIds;

    //Enviamos la peticion
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ActualizarCustomanTipoUsuarios ', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }
}
