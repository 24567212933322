export class Tpvs {
    pkId:               string;
    Nombre:             string;
    Descripcion:        string;
    LugarId:              string;
    NombrePC:           string;
    DeBaja:             string;
    NumTotTickets:      string;
    Estado:             string;
    DivisaPrimaria:     string;
    DireccionIP:        string;
    VMPath:             string;
    Asignado:           string;
    CentrosIds:         string;

    GruposTipoEntradas: any;
    GruposTipoBono: any;
    GruposTipoAbonados: any;
    Centros: any;
  
  }