import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Resolve, Router } from '@angular/router';

import { FormaPago } from '@sharedV11/classes/userRequest/clientes/formas-pago-Respuesta.model';
import { TiposCliente } from '@sharedV11/classes/userRequest/clientes/tipos-cliente-Respuesta.model';
import { ConectarApiService } from '@sharedV11/services/api/connection/conectar-api.service';
import { ClienteUI } from '@sharedV11/classes/clients/client/client.interface';
import { MaestrasService } from '@sharedV11/services/core/maestras.service';
import { ApiClientesService } from './api-clientes.service';
import { Paises } from '@sharedV11/classes/core/api-maestras.model';
import { AutentificacionService } from './autentificacion.service';
import { GrupoClientes } from '@sharedV11/classes/clients/client/grupos-cliente-Respuesta.model';
import { ClienteMapper } from '@sharedV11/classes/clients/client/client.mapper';
import { HttpErrorsService } from '@sharedV11/services/alerts/errors/http-errors.service';

interface DatosListas {
  Id: string;
  Nombre: string;
}

@Injectable({ providedIn: 'root' })
export class ClientService implements Resolve<any> {

  private RUTA = '/Clientes/Cliente';

  formaPago: FormaPago[];
  grupoClientes: GrupoClientes[];
  tiposCliente: TiposCliente[];
  paises: Paises[] = this.maestrasService.getMasters('paises');
  zonaComercial: DatosListas[];

  private paisesMap: { [key: string]: Paises };
  private tiposClienteMap: { [key: string]: TiposCliente };
  private formaPagoMap: { [key: string]: FormaPago };

  constructor(private servicioAPI: ConectarApiService,
    private clienteService: ApiClientesService,
    private autentificador: AutentificacionService,
    private router: Router,
    private maestrasService: MaestrasService,
    private httpErrorService: HttpErrorsService,
  ) {
    this.paisesMap = this.arrayToObject(this.paises, 'CountryCode');
  }


  cargarDatos(pagination, ordenation, filtros): Observable<any> {
    return this.clienteService.getClientes(pagination, ordenation, filtros)
      .pipe(map(data => {
        const ndata = {
          ResultadoBuscadorClientes: data.ResultadoBuscadorClientes.map(x => {
            let found = null;
            if (x.Cliente) {
              found = this.tiposClienteMap[x.Cliente.TipoClienteId];
              x.Cliente.TipoCliente = found ? found.Nombre : '';

              found = this.formaPagoMap[x.Cliente.TipoPagoId];
              x.Cliente.TipoPago = found ? found.Denominacion : '';
            }
            if (x.DatosComunes) {
              found = this.paisesMap[x.DatosComunes.Pais];
              x.DatosComunes.Pais = found ? found.NombrePais : x.DatosComunes.Pais;
            }
            if (x.ClienteAdicional) {
              found = this.paisesMap[x.ClienteAdicional.Pais];
              x.ClienteAdicional.Pais = found ? found.NombrePais : x.ClienteAdicional.Pais;
            }
            return x;
          })
        };
        return Object.assign(data, ndata);
      },
        error => { this.httpErrorService.identificarErrores(error); }
      ));
  }

  getClientById(id: string): Observable<any> {
    return this.clienteService.getClientDetail(id);
  }

  altaBajaCliente(pkid: string, fecha_baja?: string): Observable<any> {
    return this.clienteService.altaBajaCliente(pkid, fecha_baja);
  }

  addClient(f: ClienteUI, CodigoCliente?: string): Observable<any> {
    return this.clienteService.insUpdCliente(ClienteMapper.converToAPI(f), CodigoCliente);
  }

  delClient(CodigoCliente: number, email: string): Observable<any> {
    return this.clienteService.delCliente(CodigoCliente, email);
  }

  resolve(): Observable<any> {
    return forkJoin([
      this.servicioAPI.getFormasPago().pipe(map(data => {
        if (data.DatosResult) {
          return data.DatosResult.customanFormaPago;
        } else {
          return [];
        }
      })),
      this.servicioAPI.getGruposCliente().pipe(map(data => {
        if (data.DatosResult) {
          return data.DatosResult.customanGrupoCliente;
        } else {
          return [];
        }
      })),
      this.servicioAPI.getTiposCliente().pipe(map(data => {
        if (data.DatosResult) {
          return data.DatosResult.tipoClientes;
        } else {
          return [];
        }
      })),
      this.servicioAPI.getZonaComercial().pipe(map(data => {
        if (data) {
          return data.DatosListas;
        } else {
          return [];
        }
      }))
    ]
    ).pipe(tap((
      [s1, s2, s3, s4]
    ) => {
      this.formaPago = s1;
      this.grupoClientes = s2;
      this.tiposCliente = s3;
      this.zonaComercial = s4;

      this.formaPagoMap = this.arrayToObject(this.formaPago, 'pkId');
      this.tiposClienteMap = this.arrayToObject(this.tiposCliente, 'Codigo');

    }));
  }

  async checkPermission(ruta): Promise<{ VER: boolean, NUEVO: boolean, MODIFICAR: boolean, DUPLICAR: boolean, ELIMINAR: boolean }> {
    // Comprobamos logueo en QueryString
    return this.autentificador.verificarAccesoQueryString(ruta).then(
      // montamos los segmentos de la Ruta uno a uno porque la URL contiene los parametros y no nos sirve
      () => {
        const permisos = {
          VER: false,
          NUEVO: false,
          MODIFICAR: false,
          DUPLICAR: false,
          ELIMINAR: false
        };
        permisos.VER = this.autentificador.visualizacionAccion(this.RUTA, 'VER');

        if (!permisos.VER) {
          //this.router.navigate(['/portada']);
        }
        permisos.NUEVO = this.autentificador.visualizacionAccion(this.RUTA, 'NUEVO');
        permisos.MODIFICAR = this.autentificador.visualizacionAccion(this.RUTA, 'MODIFICAR');
        permisos.DUPLICAR = this.autentificador.visualizacionAccion(this.RUTA, 'DUPLICAR');
        permisos.ELIMINAR = this.autentificador.visualizacionAccion(this.RUTA, 'ELIMINAR');

        return permisos;
      }
    );
  }

  private arrayToObject = (array, key) =>
    array.reduce((obj, item) => {
      obj[item[key]] = item;
      return obj;
    }, {})
}

