export class Client_show {
    pkId:               string;
    Nombre:            string;
    Email:              string;
    NombreComercial:    string;
    Domicilio:          string;
    Localidad:          string;
    CodPostal:          string;
    Provincia:          string;
    Pais:               string;
    Telefono:           string;
    CIFoDNI:            string;
    TipoCliente:        string;
    TipoPago:           string;
    Codigo:      string;
  
 

  }