import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { ApiTokenService } from '../../api/token/api-token.service';
import { GlobalService } from '@sharedV11/services/global/global.service';
import { catchError, map } from 'rxjs/operators';
import { Request_items_tipo_codigo_promociones } from '@sharedV11/classes/tipoCodigoPromociones/tipoCodigoPromociones/request_items_tipo_codigo_promociones';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class EditTipoCodigoPromocionesService {

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }
  constructor(
    private http: HttpClient,
    private tokenService: ApiTokenService,
    private global:GlobalService,


    ) { }

    private handleError<T>(operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
  
        // TODO: send the error to remote logging infrastructure
        console.error(error); // log to console instead
  
        // TODO: better job of transforming error for user consumption
  
        // Let the app keep running by returning an empty result.
        return of(result as T);
      };
    }

    getTiposCodigoPromocional(pagination?, filters?): Observable<any> {
      let peticion: any = {};
      peticion.clienteAPI = this.global.clienteapi; // TODO
      if (pagination) {
        peticion.paginator = pagination
        peticion.filters = filters
      }
  
      //const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanCategoria ', JSON.stringify(peticion), httpOptions).pipe(
        //const response = this.http.get<any>('/api/tbTiposCodigosPromocionales').pipe(map(data => { 
        const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanTipoCodigoPromocional',JSON.stringify(peticion),httpOptions).pipe(map(data => {

          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
      return response;
  
    }

    getTiposCodigoPromocionalByPkId(pkId): Observable<any> {
      let peticion: any = {};
      peticion.clienteAPI = this.global.clienteapi; // TODO
      peticion.pkId = pkId;
      //const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanCategoria ', JSON.stringify(peticion), httpOptions).pipe(
        //const response = this.http.get<any>('/api/tbTiposCodigosPromocionales').pipe(map(data => { 
        const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanTipoCodigoPromocional',JSON.stringify(peticion),httpOptions).pipe(map(data => {

          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
      return response;
  
    }

    async getTiposCodigoPromocionalByPkIdAsync(pkId): Promise<any> {
      let peticion: any = {};
      peticion.clienteAPI = this.global.clienteapi; // TODO
      peticion.pkId = pkId;
      //Enviamos la peticion
      let respuesta =
        await this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanTipoCodigoPromocional',JSON.stringify(peticion),httpOptions).pipe(
          map(data => {
            return data;
          }),
          catchError((error: HttpErrorResponse) => {
            return throwError(error);
          })
        ).toPromise();
      return respuesta;
    }

    getCodigoPromocionalByPkId(pkId): Observable<any> {
      let peticion: any = {};
      peticion.clienteAPI = this.global.clienteapi; // TODO
      peticion.pkId = pkId;
      //const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanCategoria ', JSON.stringify(peticion), httpOptions).pipe(
        //const response = this.http.get<any>('/api/tbTiposCodigosPromocionales').pipe(map(data => { 
        const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanCodigoPromocional',JSON.stringify(peticion),httpOptions).pipe(map(data => {

          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
      return response;
  
    }

  findTipoPromoByPkId(pkId?:string): Observable<any> {
    //let peticion: Request_items_tipo_codigo_promociones = new Request_items_tipo_codigo_promociones();
    return of({"DatosResult":{"tipoCodigoPromo":[{
      pkId: "1",
      Nombre: "ejemplo",
      chNombre: "ejemplo1",
      chDescripcion: "Descripción...",
      Descripcion: "Descripción...",
      bDeBaja: 0,
      iCodigoCliente: [0,1,2]
    }
  ],"TotalResultados":1
}})
  }

/* 
  findUserByPkIdLocal(pkId:string): Observable<any> {
    return of(data["default"])
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  } */

  insTipoCodigoPromo(categoria,mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = categoria;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.ConexionIacpos = parseInt(localStorage.getItem('conexionIACPOS'));
    peticion.Funcion = mode;
    //Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarCustomanTipoCodigoPromocional', JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('InsertarCustomanTipoPromo '))
    );
  }
  updTipoCodigoPromo(categoria,mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = categoria;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ActualizarCustomanTipoCodigoPromocional', JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('ActualizarCustomanTipoPromo '))
    );
  }


  delTipoPromo(pkId: string): Observable<any> {
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO

    peticion.pkId = pkId;
    //peticion.PkId = pkId;

    // Enviamos la peticion
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/EliminarCustomanTipoCodigoPromocional', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }


  delCodigoPromocional(pkId: string): Observable<any> {
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO

    peticion.pkId = pkId;
    //peticion.PkId = pkId;

    // Enviamos la peticion
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/EliminarCustomanCodigoPromocional', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }

  getTipoPromo(nombre?: string, descripcion?: string): Observable<any> {    

    //Montamos la peticion Listado de Tipos Promo
    //let peticion: Request_items_tipo_codigo_promociones = new Request_items_tipo_codigo_promociones();
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    if (nombre != null) { peticion.Nombre = nombre; } else { peticion.Nombre = ""; }
    if (descripcion != null) { peticion.Descripcion = descripcion; } else { peticion.Descripcion = ""; }
    //Enviamos la peticion
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanTipoPromo', JSON.stringify(peticion), httpOptions).pipe( 
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }
}
