<mat-card class="duallist-card">
  <mat-card-header>
    <mat-card-title color="primary">{{ listLabel }}</mat-card-title>
  </mat-card-header>
  <div class="dual-list">
    <div
      class="listbox"
      [ngStyle]="{
        order: direction() ? 1 : 2,
        'margin-left': direction() ? 0 : '10px'
      }"
    >
      <button
        mat-raised-button
        color="primary"
        name="addBtn"
        class="btn-block"
        (click)="moveItem(available, confirmed)"
        [ngClass]="direction() ? 'point-right' : 'point-left'"
        [disabled]="available.pick.length === 0"
      >
        {{ format.add }}
      </button>

      <form *ngIf="filter" class="filter">
        <mat-form-field appearance="legacy" style="width: 100%">
          <input
            matInput
            class="form-control"
            name="filterSource"
            [(ngModel)]="available.picker"
            (ngModelChange)="onFilter(available)"
          />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </form>

      <div class="record-picker">
        <ul
          [ngStyle]="{ 'max-height': height, 'min-height': height }"
          [ngClass]="{ over: available.dragOver }"
          (drop)="drop($event, confirmed)"
          (dragover)="allowDrop($event, available)"
          (dragleave)="dragLeave()"
        >
          <li
            *ngFor="let item of available.sift; let idx = index"
            (click)="
              disabled ? null : selectItem(available.pick, item);
              shiftClick($event, idx, available, item)
            "
            [ngClass]="{
              selected: isItemSelected(available.pick, item),
              disabled: disabled
            }"
            [draggable]="!disabled && format.draggable"
            (dragstart)="drag($event, item, available)"
            (dragend)="dragEnd(available)"
          >
            <label>{{ item._name }}</label>
          </li>
        </ul>
      </div>

      <div class="button-bar">
        <button
          mat-raised-button
          color="accent"
          class="pull-left"
          (click)="selectAll(available)"
          [disabled]="disabled || isAllSelected(available)"
        >
          {{ format.all }}
        </button>
        <button
          mat-raised-button
          color="accent"
          class="pull-right"
          (click)="selectNone(available)"
          [disabled]="!isAnySelected(available)"
        >
          {{ format.none }}
        </button>
      </div>
    </div>

    <div
      class="listbox"
      [ngStyle]="{
        order: direction() ? 2 : 1,
        'margin-left': direction() ? '10px' : 0
      }"
    >
      <button
        mat-raised-button
        color="primary"
        name="removeBtn"
        class="btn-block"
        (click)="moveItem(confirmed, available)"
        [ngClass]="direction() ? 'point-left' : 'point-right'"
        [disabled]="confirmed.pick.length === 0"
      >
        {{ format.remove }}
      </button>

      <form *ngIf="filter" class="filter">
        <mat-form-field appearance="legacy" style="width: 100%">
          <input
            matInput
            class="form-control"
            name="filterDestination"
            [(ngModel)]="confirmed.picker"
            (ngModelChange)="onFilter(confirmed)"
          />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </form>

      <div class="record-picker">
        <ul
          [ngStyle]="{ 'max-height': height, 'min-height': height }"
          [ngClass]="{ over: confirmed.dragOver }"
          (drop)="drop($event, available)"
          (dragover)="allowDrop($event, confirmed)"
          (dragleave)="dragLeave()"
        >
          <li
            #itmConf
            *ngFor="let item of confirmed.sift; let idx = index"
            (click)="
              disabled ? null : selectItem(confirmed.pick, item);
              shiftClick($event, idx, confirmed, item)
            "
            [ngClass]="{
              selected: isItemSelected(confirmed.pick, item),
              disabled: disabled
            }"
            [draggable]="!disabled && format.draggable"
            (dragstart)="drag($event, item, confirmed)"
            (dragend)="dragEnd(confirmed)"
          >
            <div fxLayout="row" fxLayoutAlign="space-between">
              <label>{{ item._name }}</label>
              <button
                *ngIf="copyList && copyList.length > 0"
                mat-icon-button
                aria-label="Example icon button with a vertical three dot icon"
                [matMenuTriggerFor]="listanmenu"
                (click)="verlista(copyList[idx])"
              >
                <mat-icon>more_vert</mat-icon>
              </button>

              <mat-menu
                #listanmenu
                dir="ltr"
                [overlapTrigger]="true"
                xPosition="before"
                yPosition="below"
                class="usermenu"
              >
                <!-- 						<mat-nav-list>
						  <mat-list-item>
							<a (click)="clipboard(copyList[idx])">Copiar enlace en  </a>
						  </mat-list-item>
						</mat-nav-list> -->
                <ng-container *ngIf="copyList && copyList.length > 0">
                  <mat-nav-list *ngFor="let item2 of copyList[idx]">
                    <mat-list-item
                      (click)="clipboard(item2['url'], item2['urlparams'])"
                    >
                      <mat-icon>filter_none</mat-icon>
                      <a [matTooltip]="item2['grupo'] + ' ' + item2['idioma']"
                        >&nbsp;{{ item2["idioma"] }}</a
                      >
                    </mat-list-item>
                  </mat-nav-list>
                  <div
                    id="myclipboard"
                    style="opacity: 0; font-size: 1px; line-height: 2px"
                  ></div>
                </ng-container>
              </mat-menu>
            </div>
          </li>
        </ul>
      </div>

      <div class="button-bar">
        <button
          mat-raised-button
          color="accent"
          class="pull-left"
          (click)="selectAll(confirmed)"
          [disabled]="disabled || isAllSelected(confirmed)"
        >
          {{ format.all }}
        </button>
        <button
          mat-raised-button
          color="accent"
          class="pull-right"
          (click)="selectNone(confirmed)"
          [disabled]="!isAnySelected(confirmed)"
        >
          {{ format.none }}
        </button>
      </div>
    </div>
  </div>
</mat-card>
