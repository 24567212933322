import {Deserializable} from "./deserializable.model";
import {IdiomaGR, IdiomaconURL} from "./grupos-recintos-Respuesta.model"

export class GruposInternet_Respuesta implements Deserializable {
    DatosResult?: DatosResult_gruposinternet;
    Mensajes: Mensaje[];

    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}

export class GruposInternet implements Deserializable {

  pkId: string;
  Nombre: string;
  Descripcion: string;
  chCodigo: string;
  Minimo: string;
  Maximo: string;
  Orden: string;
  Idioma: string;
  TipoReservaId: string;
  ValidarGratuitas: string;
  Idiomas: IdiomaconURL[];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}


export class DatosResult_gruposinternet implements Deserializable {
  listaGrupoInternet: GruposInternet[]; 

    deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Mensaje implements Deserializable {
  CodigoMensaje: string;
  DescripcionMensaje: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}