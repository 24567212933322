import { Deserializable } from "@sharedV11/classes/deserializable";

export class formas_pago_Respuesta implements Deserializable {
    DatosResult?: DatosResult_formasPago;
    Mensajes: Mensaje[];

    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}

export class FormaPago implements Deserializable {
    PkId: string;
    Denominacion: string;
    Descripcion: string;
    siNumVencimientos: string;
    siDiasPrimerVto: string;
    rDescProntoPago: string;
    rAumentoRecargo: string;
    siVencimiento: string;
    iCodigoFormaPago: string;
    chFOP: string;
    chFOP1: string;
    iFacturar: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class DatosResult_formasPago implements Deserializable {
    customanFormaPago: FormaPago[];

    deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Mensaje implements Deserializable {
  CodigoMensaje: string;
  DescripcionMensaje: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}