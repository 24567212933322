import { ChangeDetectorRef, Component } from "@angular/core";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { SuperClassComponent } from "@sharedV11/super-class-edit/super-class/super-class.component";
import { EditGruposTipoEntradaV11Component } from "./edit-grupos-tipo-entrada-v11/edit-grupos-tipo-entrada-v11.component";

@Component({
  selector: "app-grupos-tipo-entrada-v11",
  templateUrl: "./grupos-tipo-entrada-v11.component.html",
  styleUrls: ["./grupos-tipo-entrada-v11.component.scss"],
})
export class GruposTipoEntradaV11Component extends SuperClassComponent {
  constructor(
    protected panelService: PanelService,
    protected cdRef: ChangeDetectorRef
  ) {
    super(panelService, cdRef);
  }
  openPanel() {
    this.customanpanel = EditGruposTipoEntradaV11Component;
    this.editChangeCss();
    this.cdRef.detectChanges();
  }
}
