import {CLienteAPi} from "./cliente-api.model";

//get
export class peticion_TipoAbonado_Lista {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;

    pkId: string;
    Codigo:string
    Nombre: string;
    Alaventa: string;
    idGrupo: string;
}

//insUpd
export class peticion_Insercion_Upd_TipoAbonado {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;

    PkId: string;
    Codigo: string;
    Nombre: string;
    Importe: string;
    TipoIva: string;
    Descripcion: string;
    AlaVenta: string;
    idDivisa: string;
    idTipoventa: string;
    idGrupo: string;
    TeclaRapida: string;
    ImprimirTicked: string;
    TickedVendido: string;
    SiCodigoTipoControl: string;
    idProductoBase: string;
    idTipoOperacion: string;

    Funcion: string;
}

//del
export class peticion_Del_TipoAbonado {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    PkId: string;
}

