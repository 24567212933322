<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div [ngClass]="{ zonaoculta: loading === true }">
  <app-buttons
    (save)="submit()"
    (close)="destroy()"
    (discard)="discard()"
    [buttonsWrap]="'panel'"
    [modificar]="modificar"
  ></app-buttons>

  <div class="divForm">
    <form [formGroup]="editInternetGroupForm" (onSubmit)="submit()">
      <div class="form-group">
        <div class="col-sm-12 col-md-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ translation.Nombre }}</mat-label>
            <input matInput formControlName="Nombre" />
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-6">
          <mat-form-field appearance="outline">
            <mat-select
              placeholder="{{ translation.Idioma }}"
              formControlName="Idioma"
            >
              <mat-option
                *ngFor="let idioma of idiomasApp"
                [value]="idioma.CodIdioma"
              >
                {{ idioma.Nombre }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-12 col-md-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ translation.Descripcion }}</mat-label>
            <input matInput formControlName="Descripcion" />
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ translation.Orden }}</mat-label>
            <input matInput formControlName="Orden" />
          </mat-form-field>
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-12 col-md-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ translation.Maximo }}</mat-label>
            <input type="number" matInput formControlName="Maximo" />
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ translation.Minimo }}</mat-label>
            <input type="number" matInput formControlName="Minimo" />
          </mat-form-field>
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-12 col-md-6">
          <app-input-filter
            *ngIf="internetgroup && TiposReservas && TiposReservas.length > 0"
            formControlText="TipoReservaId"
            [label]="translation.TipoReserva"
            [placeHolderTranslation]="translation.TipoReserva"
            [list]="TiposReservas"
            [disabled]="TiposReservas.length < 1"
            textShow="Nombre"
            dataInput="pkId"
            required="true"
            [data]="internetgroup.TipoReservaId"
            (close)="closeInputFilter($event)"
          ></app-input-filter>
          <!-- <mat-form-field appearance="outline">
        <mat-label>{{translation.TipoReserva}}</mat-label>
        <mat-select formControlName="TipoReservaId">
          <mat-option *ngFor="let reservaTipo of TiposReservas" [value]="reservaTipo.pkId">
            {{reservaTipo.Nombre}}
          </mat-option>
        </mat-select>
    </mat-form-field> -->
        </div>
        <div class="col-sm-12 col-md-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ translation.ValidarGratuitas }}</mat-label>
            <mat-select formControlName="ValidarGratuitas">
              <mat-option value="0">{{ translation.No }}</mat-option>
              <mat-option value="1">{{ translation.Si }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="form-group" class="col-sm-12">
        <div style="margin: 20px 5px 0 5px; text-align: center">
          <customan-dual-list
            [source]="source"
            [destination]="confirmados"
            [copyList]="cplist"
            key="pkId"
            display="Nombre"
            [filter]="filter"
            [format]="format"
          ></customan-dual-list>
        </div>

        <div style="margin: 20px 5px 0 5px; text-align: center">
          <mat-label>{{ translation.Centros }}</mat-label>

          <customan-dual-list
            [source]="centrosSinAsignar"
            [(destination)]="centrosAsignados"
            key="pkId"
            display="Nombre"
            [format]="format"
            [filter]="filter"
          >
          </customan-dual-list>
        </div>
      </div>

      <div *ngFor="let idioma of editorListaIdiomas" class="form-group">
        <div class="col-sm-12">
          <mat-form-field appearance="outline">
            <mat-label
              >{{ translation.NombreInternet }} ({{
                idioma.CodIdioma
              }})</mat-label
            >
            <input
              matInput
              type="text"
              placeholder="{{ translation.Nombre }} en {{ idioma.Nombre }}"
              [value]="idioma.Nombre"
              (change)="
                cambiaNombre($any($event.target).value, idioma.CodIdioma)
              "
              formControlName="{{ 'nombre' + idioma.CodIdioma }}"
            />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label
              >{{ translation.Descripcion }} ({{ idioma.CodIdioma }})</mat-label
            >
            <textarea
              matInput
              type="text"
              placeholder="{{ translation.Descripcion }} {{ idioma.Nombre }}"
              [value]="idioma.Descripcion"
              formControlName="{{ 'descripcion' + idioma.CodIdioma }}"
            ></textarea>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label
              >{{ translation.Descripcion }} 1 ({{
                idioma.CodIdioma
              }})</mat-label
            >
            <textarea
              matInput
              type="text"
              placeholder="{{ translation.Descripcion }} {{ idioma.Nombre }}"
              [value]="idioma.Descripcion1"
              formControlName="{{ 'descripcion1' + idioma.CodIdioma }}"
            ></textarea>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label
              >{{ translation.Descripcion }} 2 ({{
                idioma.CodIdioma
              }})</mat-label
            >
            <textarea
              matInput
              type="text"
              placeholder="{{ translation.Descripcion }} {{ idioma.Nombre }}"
              [value]="idioma.Descripcion2"
              formControlName="{{ 'descripcion2' + idioma.CodIdioma }}"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</div>
