import {CLienteAPi} from "./cliente-api.model";
import {IdiomaGR} from "./grupos-recintos-Respuesta.model"
import {tarifaPromocion} from "./tarifas-Respuesta.model"

//get
export class peticion_Tarifas_Lista {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;

    fecIniVisita: string;
    fecFinVisita: string;
    feciniCompra: string;
    fecFinCompra: string;
    alaVenta: string;
    tipoBusqueda: string;
    descripcion: string;
    taquilla: string;
    internet: string;
    taquillaAutomatica: string;
    tipoEntradaId: string;
    numeroRegistros: string;
    pagina: string;
    filtro: string;
}

//insUpd
export class peticion_Insercion_Upd_Tarifas {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;

    PkId: string;
    Internet: string;
    Taquilla: string;
    TaqAutomatica: string;
    AlaVenta: string;
    iTipoEntradaId: string;
    iTipoPromocionId: string;
    FecIniVisita: string;
    FecFinVisita: string;
    NoFecFinVisita: string;
    PrecioPVP: string;
    PorcIva: string;
    Comision: string;
    PorIvaComision: string;
    iDivisaId: string;
    iTipoAbonadoId: string;
    Agrupar: string;
    Orden: string;
    Minimo: string;
    Maximo: string;
    iGrupoInternetId: string;
    FecCompraDesde: string;
    FecCompraHasta: string;
    HoraIni: string;
    HoraFin: string;
    iTipoIvaId: string;
    iTipoIvaComisionId: string;
    BaseImponible: string;
    ComisionBI: string;
    DesglosarComision: string;
    Lunes: string;
    Martes: string;
    Miercoles: string;
    Jueves: string;
    Viernes: string;
    Sabado: string;
    Domingo: string;
    NombreEnTaquilla: string;
    Idiomas: IdiomaGR[];
    TarifaPromocion: tarifaPromocion[];
    AsumeIncrementoIVA: string;
    chPrefijo: string;

    Funcion: string;
}

//del
export class peticion_Del_Tarifas {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    PkId: string;
}

