import {Deserializable} from "./deserializable.model";

export class tipos_usuario_Respuesta implements Deserializable {
    DatosResult?: DatosResult_tiposUsuario;
    Mensajes: Mensaje[];

    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}

export class TipoUsuario implements Deserializable {
    pkId: string;
    Nombre: string;
    Descripcion: string;
    Privilegios: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class DatosResult_tiposUsuario implements Deserializable {
    tipoUsuarios: TipoUsuario[];

    deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Mensaje implements Deserializable {
  CodigoMensaje: string;
  DescripcionMensaje: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}