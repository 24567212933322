<div class="container col">
  <!--Spinner-->
  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults" color="warn"></mat-spinner>
  </div>


  <div class="menu-acciones-metodo">
    <div class="row flex justify-content-between" [hidden]="!editando">
      <div style="margin-left: 15px">
        <button class="btn btn-success btn-magento-naranja" (click)="newGuardar()"
          [disabled]="!editorRegistroForm.valid"
          [hidden]="!permisobtnGuardar || !editando">{{translation.Guardar}}</button>
        <button class="btn btn-warning btn-magento-gris-claro" (click)="editando=false;" [hidden]="!editando">
          {{translation.Descartar}}</button>
      </div>
      <button class="btn" (click)="editando=false;" [matTooltip]="translation.Cerrar">
        <mat-icon style="font-size: 32px;">close</mat-icon>
      </button>
    </div>
    <button class="btn btn-warning btn-magento-naranja" (click)="AnadirRegistro()"
      [hidden]="!permisobtnNuevo || editando">{{translation.AnadirSesion}}</button>
  </div>

  <!-- <div class="menu-acciones-metodo">
    <button class="btn btn-primary btn-magento-atras" (click)="editando=false;" [hidden]="!editando"><i
        class="fa fa-arrow-left fa-lg"></i>&nbsp;&nbsp;{{translation.Atras}}</button>
    <button *ngIf="permisobtnEliminar && model.pkId!=null && editando" class="btn btn-danger btn-magento-gris"
      (click)="pregborrarRegistro()">{{translation.Borrar}}</button>
    <button *ngIf="permisobtnDuplicar && model.pkId!=null && editando" class="btn btn-warning btn-magento-gris"
      (click)="duplicarRegistro()">{{translation.DuplicarRegistro}}</button>
    <button class="btn btn-success btn-magento-naranja" (click)="newGuardar()" [disabled]="!editorRegistroForm.valid"
      [hidden]="!permisobtnGuardar || !editando">{{translation.Guardar}}</button>
    <button class="btn btn-warning btn-magento-naranja" (click)="AnadirRegistro()"
      [hidden]="!permisobtnNuevo || editando">{{translation.AnadirSesion}}</button>
  </div> -->
  <alert></alert>
  <div [hidden]="editando" class="data-grid">
    <!--Filtros Activos -->
    <div *ngIf="hayFiltros(filtrosactivos)" class="customan-panel-filtros-activos">{{translation.FiltrosActivos}}:
      <ul class="custom-list-horizontal">
        <li *ngIf="(filtrosactivos.textoGeneral != null && filtrosactivos.textoGeneral.trim() !='')">
          {{translation.PalabraClave}}: <span>{{filtrosactivos.textoGeneral}}</span> &nbsp;<i
            class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('General')"></i></li>
        <li *ngIf="(filtrosactivos.Nombre != null && filtrosactivos.Nombre.trim() !='')"> {{translation.Nombre}}:
          <span>{{filtrosactivos.Nombre}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('Nombre')"></i>
        </li>
        <li *ngIf="(filtrosactivos.CodigoTipoRecinto != null)"> {{translation.Recin}}:
          <span>{{traeNombreRecintoxID(filtrosactivos.CodigoTipoRecinto)}}</span> &nbsp;<i
            class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('CodigoTipoRecinto')"></i>
        </li>
        <li *ngIf="(filtrosactivos.CodigoTipoSesion != null)"> {{translation.Tsesion}}:
          <span>{{traeNombreTipoSesionxID(filtrosactivos.CodigoTipoSesion)}}</span> &nbsp;<i
            class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('CodigoTipoSesion')"></i>
        </li>
        <li
          *ngIf="((filtrosactivos.desde != null || filtrosactivos.hasta != null) && (filtrosactivos.desde.trim() !='' ||filtrosactivos.hasta.trim() !='' ))">
          {{translation.Fecha}}: <span>{{filtrosactivos.desde}} {{translation.A}} {{filtrosactivos.hasta}} </span>
          &nbsp;<i class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('fecha')"></i></li>
        <li *ngIf="(filtrosactivos.Activa != null && filtrosactivos.Activa.trim() !='')"> {{translation.Activa}}:
          <span>{{filtrosactivos.Activa}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('Activa')"></i>
        </li>
      </ul>
    </div>
    <!--Botonera-->
    <div class="customan-botonera-magento-tabla">
      <button class="btn btn-warning btn-magento-gris-claro" [class.filtro-activado]="panelFiltrosvisible"
        (click)="MostrarFiltros()"><i class="fa fa-filter fa-lg"></i>&nbsp;&nbsp;{{translation.Filtros}}</button>
      <!--<button *ngIf="permisobtnEliminar && model.PkId!=null && editando" class="btn btn-danger btn-magento-gris" (click)="pregborrarRegistro()">Borrar</button>-->
      <button class="btn btn-primary btn-magento-atras" (click)="MostrarColumnas()"><i
          class="fa fa-cog fa-lg"></i>&nbsp;&nbsp;{{translation.Columnas}}&nbsp;&nbsp;<i class="fa fa-sm"
          [class.fa-caret-up]="panelColumnasvisible" [class.fa-caret-down]="!panelColumnasvisible"></i></button>
    </div>
    <!--Panel de Columnas-->
    <div *ngIf="panelColumnasvisible" class="customan-panel-columnas-tabla">
      {{columnas.selectedOptions.selected.length}} {{translation.De}} {{ displayedColumns.length }}
      {{translation.Visibles}}.
      <div class="containerColumnas-tabla" cdk-scrollable>
        <mat-selection-list #columnas (selectionChange)="visibilidadColumna($event, columnas.selectedOptions.selected)"
          class="columnas-tabla-listado" [formControl]="formControlListaColumnas">
          <!--#columnas-->
          <mat-list-option class="columnas-listado-elemento" checkboxPosition="before"
            *ngFor="let columna of displayedColumns" [value]="columna">
            {{columna}}
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
    <!--Panel selector de Filtros-->
    <div *ngIf="panelSelectorFiltrosvisible" class="customan-panel-columnas-tabla">
      {{columnas.selectedOptions.selected.length}} {{translation.De}} {{ displayedFilters.length }}
      {{translation.Visibles}}.
      <div class="containerColumnas-tabla" cdk-scrollable>
        <mat-selection-list #columnas (selectionChange)="visibilidadFiltro($event, columnas.selectedOptions.selected)"
          class="columnas-tabla-listado" [formControl]="formControlListaFiltros">
          <mat-list-option class="columnas-listado-elemento" checkboxPosition="before"
            *ngFor="let filtro of displayedFilters" [value]="filtro">
            {{filtro}}
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
    <!--Panel de Filtros-->
    <div class="customan-panel-filtros-tabla">
      <div class="containerFiltros-tabla">
        <form [formGroup]="editorFiltrosForm">
          <div *ngIf="filtersToDisplay.includes(translation.NombreDeLaSesion)" class="grupo-filtros-campo-registro">
            <mat-form-field appearance="outline">
              <mat-label>{{translation.NombreDeLaSesion}}</mat-label>
              <input matInput placeholder="Filtro de Nombre de la sesión" formControlName="nombre"
                [value]="filtrosactivosModelo.Nombre">
            </mat-form-field>
          </div>

          <div *ngIf="filtersToDisplay.includes(translation.Recinto)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.Recinto}}" formControlName="tipoControl"
                [value]="filtrosactivosModelo.CodigoTipoRecinto" [compareWith]="comparaObjetosSelect">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let tipoGrupos of tiposRecintos" [value]="tipoGrupos.PkId">
                  {{tipoGrupos.Nombre}}
                </mat-option>
              </mat-select>
              <!-- <mat-hint>{{tipoControl.value?.Descripcion}}</mat-hint> -->
            </mat-form-field>
          </div>

          <div *ngIf="filtersToDisplay.includes(translation.TipoSesion)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.TipoSesion}}" formControlName="tipoControltSesion"
                [value]="filtrosactivosModelo.CodigoTipoSesion" [compareWith]="comparaObjetosSelect">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let tipoGrupos2 of tiposTiposSesion" [value]="tipoGrupos2.pkId">
                  {{tipoGrupos2.Descripcion}}
                </mat-option>
              </mat-select>
              <!-- <mat-hint>{{tipoControl.value?.Descripcion}}</mat-hint> -->
            </mat-form-field>
          </div>

          <div *ngIf="filtersToDisplay.includes(translation.FechaInicio)" class="grupo-filtros-campo-registro">
            <mat-form-field appearance="outline">
              <mat-label><b>{{translation.FechaInicio}}</b> &nbsp; <i>{{translation.Desde}}</i></mat-label>
              <input matInput [matDatepicker]="UOdesde" placeholder="{{translation.Desde}}" formControlName="UOdesde"
                (value)="aFecha(filtrosactivosModelo.desde)" >
              <mat-datepicker-toggle matSuffix [for]="UOdesde"></mat-datepicker-toggle>
              <mat-datepicker #UOdesde disabled="false"></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label><b>{{translation.FechaInicio}}</b> &nbsp; <i>{{translation.Hasta}}</i></mat-label>
              <input matInput [matDatepicker]="UOhasta" placeholder="{{translation.Hasta}}" formControlName="UOhasta"
                (value)="aFecha(filtrosactivosModelo.hasta)" >
              <mat-datepicker-toggle matSuffix [for]="UOhasta"></mat-datepicker-toggle>
              <mat-datepicker #UOhasta disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>

          <div *ngIf="filtersToDisplay.includes(translation.Activa)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.Activa}}" formControlName="activa" name="tipoActivo"
                [value]="filtrosactivosModelo.Activa">
                <mat-option> --- --- </mat-option>
                <mat-option value="si">{{translation.Si}}</mat-option>
                <mat-option value="no">{{translation.No}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </form>
      </div>
      <div class="customan-botonera-magento-tabla">
        <button class="btn btn-warning btn-magento-gris"
          (click)="AplicarFiltros()">{{translation.AplicarFiltros}}</button>
      </div>
    </div>
    <div>
      <mat-form-field class="customan-filtro-input">
        <input matInput placeholder="{{translation.BuscarPorPalabraClave}}"
          (keydown.enter)="applyFilter($any($event.target).value)" [value]="filtrosactivosModelo.textoGeneral">
        <i (click)="applyFilter($any($event.target).parentNode.children[0].value)" class="fa fa-search fa-lg"></i>
      </mat-form-field>
    </div>
    <!--Tabla-->

    <div class="mat-elevation-z8 overflow-table">
      <mat-form-field class="magento-acciones" floatLabel="never">
        <mat-select placeholder="{{translation.Acciones}}" [value]="accionselecionada"
          (selectionChange)="HacerAccion($event)">
          <!--[formControl]="panelAcciones"-->
          <mat-option *ngFor="let accion of Acciones" [value]="accion.funcion">
            {{accion.texto}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-paginator [length]="resultsLength" [showFirstLastButtons]="true" [pageSizeOptions]="[10, 25, 100]"
        #paginatorPrincipal></mat-paginator>
      <table mat-table [dataSource]="dataSource" matSort #sortPrincipal="matSort">

        <!-- Acciones Column -->
        <ng-container matColumnDef="Acciones">
          <th mat-header-cell *matHeaderCellDef>{{translation.Acciones}}</th>
          <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()" style="cursor: default;">
            <div class="action-container">

              <button customanTooltip="Duplicar Registro" *ngIf="permisobtnDuplicar" class="button-icon"
                (click)=duplicar(row) style="width: 34px; height: 34px;">
                <mat-icon>content_copy</mat-icon>
              </button>

              <button *ngIf="permisobtnEliminar" customanTooltip="Borrar Registro" class="button-icon"
                (click)="borrar(row.pkId)" style="width: 34px; height: 34px;">
                <mat-icon>delete_forever</mat-icon>
              </button>

            </div>
          </td>
        </ng-container>

        <!-- Codigo Column -->
        <ng-container matColumnDef="Recinto">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.NombreRecinto}} </th>
          <td mat-cell *matCellDef="let row"> {{traeNombreRecintoxID(row.Recinto)}} </td>
        </ng-container>

        <!-- Nombre Column -->
        <ng-container matColumnDef="Nombre">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.NombreSesion}} </th>
          <td mat-cell *matCellDef="let row"> {{row.Descripcion}} </td>
        </ng-container>

        <!-- Descripcion Column -->
        <ng-container matColumnDef="Fecha">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Fecha}} </th>
          <td mat-cell *matCellDef="let row"> {{row.Fecha.trim().substring(0,10)}} </td>
        </ng-container>

        <!-- Aforos Column -->
        <ng-container matColumnDef="Horario">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.HoraInicioFin}} </th>
          <td mat-cell *matCellDef="let row">
            {{row.HoraInicio.trim().substring(10,20) + ' / ' + row.HoraFin.trim().substring(10,20)}} </td>
        </ng-container>
        <!-- PorDefecto Column
                <ng-container matColumnDef="Hasta">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Hasta </th>
                    <td mat-cell *matCellDef="let row"> {{row.FechaFin.trim().substring(0,10) + row.HoraFin.trim().substring(10,20)}} </td>
                </ng-container> -->

        <!-- Comprobaciones Column
                <ng-container matColumnDef="Comprobaciones">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Comprobaciones </th>
                  <td mat-cell *matCellDef="let row"> {{row.CompHoraria.trim().toLowerCase() == 'true'?'hora':''}}{{row.PermisoSinFecha.trim().toLowerCase() == 'true'?' sinfech':''}}{{row.CompDiaria.trim().toLowerCase() == 'true'?' diaria':''}}
                    {{row.CompDiaSemana.trim().toLowerCase() == 'true'?'diaSemana':''}}{{row.CompIntervalo.trim().toLowerCase() == 'true'?' intervalo':''}}{{row.CompPasoPrevio.trim().toLowerCase() == 'true'?' pasoprevio':''}}
                    {{row.CompCalendario.trim().toLowerCase() == 'true'?'calend':''}}{{row.CompCalendHorario.trim().toLowerCase() == 'true'?' calHorario':''}}{{row.DenegarAcceso.trim().toLowerCase() == 'true'?' denegar':''}}
                  </td>
                </ng-container> -->

        <!-- Irregular Column -->
        <ng-container matColumnDef="Irregular">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Irregular}} </th>
          <td mat-cell *matCellDef="let row"> {{row.TipoSesion=='SI'?'Sí':'No'}} </td>
        </ng-container>

        <!-- Aforos Column -->
        <ng-container matColumnDef="Aforo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.AforoMaximoInternet}} </th>
          <td mat-cell *matCellDef="let row"> {{row.MaximoAforo + ' / ' + row.MaximoInternet}} </td>
        </ng-container>

        <!-- Resrvadas/Vendidas Column -->
        <ng-container matColumnDef="Usadas">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.ReservasVendidasVOnline}} </th>
          <td mat-cell *matCellDef="let row"> {{row.Reservadas + ' / ' + row.Vendidas + ' / ' + row.VendidasInternet}}
          </td>
        </ng-container>

        <!-- Disponible Column -->
        <ng-container matColumnDef="Disponible">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.DisponibleTotalOnline}} </th>
          <td mat-cell *matCellDef="let row"> {{showDisponible(row)}} </td>
        </ng-container>

        <!-- NumSesion Column -->
        <ng-container matColumnDef="NumSesion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.NumeroSesion}} </th>
          <td mat-cell *matCellDef="let row"> {{row.NumSesion}} </td>
        </ng-container>

        <!-- Activa Column -->
        <ng-container matColumnDef="Activo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Activa}} </th>
          <td mat-cell *matCellDef="let row"> {{row.Activa}} </td>
        </ng-container>

        <!-- ControlaHorario Column -->
        <ng-container matColumnDef="ControlaHorario">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.ControlaHorario}} </th>
          <td mat-cell *matCellDef="let row"> {{row.ControlaHorario}} </td>
        </ng-container>

        <!-- Bloqueada Column -->
        <ng-container matColumnDef="Bloqueada">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Bloqueada}} </th>
          <td mat-cell *matCellDef="let row"> {{row.SesionBloqueada}} </td>
        </ng-container>
 
        <!-- Didactica Column -->
        <ng-container matColumnDef="Didactica">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Didactica}} </th>
          <td mat-cell *matCellDef="let row"> {{row.Didactica}} </td>
        </ng-container>

        <!-- Creado Column -->
        <ng-container matColumnDef="Creado">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Creado}} </th>
          <td mat-cell *matCellDef="let row"> {{row.Creado}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" (click)="newEditar(row)">
        </tr>
      </table>

    </div>

    <span _ngcontent-c0="" class="div-separador"></span>

  </div>

  <div [hidden]="!editando" class="editor-registro">

    <!-------------------------------------------------------------->
    <!-------------------------------------------------------------->
    <!-------------------------------------------------------------->
    <!-------------------------------------------------------------->
    <!-------------------------------------------------------------->
    <!-------------------------------------------------------------->
    <!-------------------------------------------------------------->
    <!-------------------------------------------------------------->
    <!-- Nueva Maquetacion -->
    <form [formGroup]="editorRegistroForm">

      <div class="main-container">
        <div class="pd-ltr-20 xs-pd-20-10">
          <div class="min-height-200px">
            <!-- horizontal Basic Forms Start -->
            <div class="pd-20 card-box mb-30">
              <div class="clearfix">
                <div class="pull-left">
                  <h4 class="text-blue h4">Sesión sin butacas</h4>
                </div>
              </div>

              <div class="form-group row">

                <div class="col-md-4 col-sm-12">
                  <h6 class="h6">{{translation.DisponibleTotal}}: {{LabelDisponibleTotaltxt}}</h6>
                </div>
                <div class="col-md-4 col-sm-12">
                  <h6 class="h6">{{translation.DisponibleOnline}}: {{LabelDisponibleOnlinetxt}}</h6>
                </div>
                <div class="col-md-4 col-sm-12">
                  <h6 class="h6">{{translation.AforoInicial}}: {{AforoInicial}}</h6>
                </div>
              </div>

              <div class="form-group row">

                <div class="col-md-12 col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.Nombre}}</mat-label>
                    <input matInput formControlName="nombre" class="editor-campo-registro">
                  </mat-form-field>
                </div>


              </div>

              <div class="form-group row">

                <div class="col-md-12 col-sm-12">
                  <mat-form-field appearance="outline">



                    <mat-label>{{translation.Recinto}}</mat-label>
                    <mat-select placeholder="{{translation.Recinto}}" formControlName="recinto"
                      [disabled]="this.model.pkId != null || this.funcion === 'U'">
                      <mat-option> --- --- </mat-option>
                      <mat-option *ngFor="let tiporecinto of tiposRecintos" [value]="tiporecinto.PkId">
                        {{tiporecinto.Nombre}}
                      </mat-option>
                    </mat-select>



                  </mat-form-field>
                </div>

              </div>

              <div class="clearfix">
                <div class="pull-left">
                  <h5 class="h5">Tipo de Aforo: {{tipoAforoValue}}</h5>
                </div>
              </div>

              <div class="form-group row">

                <div class="col-md-12 col-sm-12">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{translation.TipoSesion}}</mat-label>
                    <mat-select placeholder="{{translation.TipoSesion}}" formControlName="tiposesion"
                      name="tipoTipoSesion" [value]="model.TipoSesion" #listaTSesion
                      [disabled]="this.model.pkId != null || this.funcion === 'U'"
                      (selectionChange)="CambioTipoSesionSeleccionado($event)">
                      <mat-option> --- --- </mat-option>
                      <mat-option *ngFor="let tiposesion of tiposTiposSesion" [value]="tiposesion.pkId">
                        {{tiposesion.Descripcion}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

              </div>

              <div class="form-group row">

                <div class="col-md-6 col-sm-12">
                  <div class='grupo-editor-campo-registro'>
                    <mat-slide-toggle formControlName="activa" color="primary">
                      {{translation.SesionActiva}}
                    </mat-slide-toggle>
                  </div>
                </div>

                <div class="col-md-6 col-sm-12">
                  <div class='grupo-editor-campo-registro'>
                    <mat-slide-toggle formControlName="bloqueada" color="primary">
                      {{translation.SesionBloqueada}}
                    </mat-slide-toggle>
                  </div>
                </div>

              </div>

              <div class="clearfix mb-20">
                <div class="pull-left">
                  <h4 class="text-blue h4">Idiomas del espacio</h4>
                </div>
              </div>

              <div class="form-group row">

                <div class="col-md-12 col-sm-12">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{translation.IdiomaDeActividad}}</mat-label>
                    <mat-select placeholder="{{translation.IdiomaDeActividad}}" formControlName="idiomaActividad"
                      name="idiomaActividad" >
                      <mat-option> --- --- </mat-option>
                      <mat-option *ngFor="let idioma of tiposIdiomas" [value]="idioma.CodIdioma">
                        {{idioma.Nombre}} ({{idioma.CodIdioma}})
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

              </div>

              <!--<div *ngFor="let idioma of editorListaIdiomas">
                <div class="form-group row">

                  <div class="col-md-6 col-sm-12">
                    <mat-form-field appearance="outline">
                      <mat-label>{{translation.NombreInternet}}
                        ({{idioma.CodIdioma}})</mat-label>
                      <input matInput class="editor-campo-registro" type="text" placeholder="Nombre*"
                        [value]="idioma.Nombre" (change)="cambiaNombre($event.target.value, idioma.CodIdioma)">
                    </mat-form-field>
                  </div>

                  <div class="col-md-6 col-sm-12">
                    <mat-form-field appearance="outline">
                      <mat-label>{{translation.URL}} ({{idioma.CodIdioma}})</mat-label>
                      <input matInput class="editor-campo-registro" type="text" placeholder="URL" [value]="idioma.URL"
                        (change)="cambiaURL($event.target.value, idioma.CodIdioma)">
                    </mat-form-field>
                  </div>

                </div>

                <div class="form-group row">
                  <div class="col-md-12 col-sm-12">
                    <mat-form-field appearance="outline">
                      <mat-label>{{translation.Descripcion}}
                        ({{idioma.CodIdioma}})</mat-label>
                      <input matInput class="editor-campo-registro" type="text"
                        placeholder="{{translation.Descripcion}}" [value]="idioma.Descripcion"
                        (change)="cambiaDescripcion($event.target.value, idioma.CodIdioma)">
                    </mat-form-field>

                  </div>

                </div>
              </div>-->


              <div class="form-group row">

                <div class="col-md-4 col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.AforoMaximo}}</mat-label>
                    <input matInput formControlName="aforomaximo" class="editor-campo-registro" value="" type="number"
                      [(ngModel)]="model.MaximoAforo" [ngClass]="{ 'is-invalid': f.aforomaximo.errors }"
                      style="text-align:right;">
                  </mat-form-field>
                </div>

                <div class="col-md-4 col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.AforoMinimo}}</mat-label>
                    <input matInput formControlName="aforominimo" class="editor-campo-registro" value="" type="number"
                      [ngClass]="{ 'is-invalid': f.aforominimo.errors }"
                      style="text-align:right;">
                  </mat-form-field>
                </div>

                <div class="col-md-4 col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.AforoInternet}}</mat-label>
                    <input matInput formControlName="aforointernet" class="editor-campo-registro" value="" type="number"
                      [(ngModel)]="model.MaximoInternet" [ngClass]="{ 'is-invalid': f.aforointernet.errors }"
                      style="text-align:right;">
                  </mat-form-field>
                </div>

              </div>

              <div class="form-group row">

                <div class="col-md-12 col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.NumeroGrupos}}</mat-label>
                    <input matInput formControlName="numerogrupos" class="editor-campo-registro" type="text"
                      placeholder="" [(ngModel)]="model.ControlaHorario" style="text-align:right;">
                  </mat-form-field>
                </div>

              </div>

              <div class="form-group row">

                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.FechaDesde}}</mat-label>
                    <input matInput [matDatepicker]="dpdesde" placeholder="{{translation.FechaDesde}}"
                      [min]="fecha_minDesde" [max]="fecha_maxDesde" (dateChange)="cambiadesde($event)"
                      formControlName="fechaDesde">
                    <mat-datepicker-toggle matSuffix [for]="dpdesde"></mat-datepicker-toggle>
                    <mat-datepicker #dpdesde></mat-datepicker>
                  </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.FechaHasta}}</mat-label>
                    <input matInput [matDatepicker]="dphasta" placeholder="{{translation.FechaHasta}}"
                      [min]="fecha_minHasta" [max]="fecha_maxHasta" (dateChange)="cambiahasta($event)"
                      formControlName="fechaHasta">
                    <mat-datepicker-toggle matSuffix [for]="dphasta"></mat-datepicker-toggle>
                    <mat-datepicker #dphasta></mat-datepicker>
                  </mat-form-field>
                </div>

              </div>

              <div class="form-group row">

                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.HoraInicio}}</mat-label>
                    <input matInput formControlName="horainicio" class="editor-campo-registro time-picker"
                      placeholder="Hora inicio" type="time" (change)="cambiaHoradesde($event)"
                      [(ngModel)]="model.HoraInicio">
                  </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.HoraFin}}</mat-label>
                    <input matInput formControlName="horafin" class="editor-campo-registro time-picker"
                      placeholder="Hora fin" type="time" (change)="cambiaHorahasta($event)" [(ngModel)]="model.HoraFin">
                  </mat-form-field>
                </div>

              </div>


              <div class="form-group row">

                <div class="col-md-6 col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.DuracionHoras}}</mat-label>
                    <input matInput formControlName="duracionhora" placeholder="Hor" [value]="DuracionHoras"
                      class="editor-campo-registro" type="text">
                  </mat-form-field>
                </div>

                <div class="col-md-6 col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.DuracionMinutos}}</mat-label>
                    <input matInput formControlName="duracionminuto" placeholder="Min" [value]="DuracionMinutos"
                      class="editor-campo-registro" type="text">
                  </mat-form-field>
                </div>

              </div>

              <div class="form-group row" *ngIf="creando">
                <div class="col-sm-12 col-md-12">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{translation.IntervaloAforo}}</mat-label>
                    <mat-select formControlName="intervaloAforo" [disabled]="this.funcion === 'U'">
                      <mat-option selected="">Sin intervalo </mat-option>
                      <mat-option value="5"> 5 minutos</mat-option>
                      <mat-option value="10"> 10 minutos</mat-option>
                      <mat-option value="15"> 15 minutos</mat-option>
                      <mat-option value="20"> 20 minutos</mat-option>
                      <mat-option value="25"> 25 minutos</mat-option>
                      <mat-option value="30"> 30 minutos</mat-option>
                      <mat-option value="35"> 35 minutos</mat-option>
                      <mat-option value="40"> 40 minutos</mat-option>
                      <mat-option value="45"> 45 minutos</mat-option>
                      <mat-option value="50"> 50 minutos</mat-option>
                      <mat-option value="55"> 55 minutos</mat-option>
                      <mat-option value="60"> 1 hora</mat-option>
                      <mat-option value="90"> 1 hora 30 minutos</mat-option>
                      <mat-option value="120"> 2 hora</mat-option>
                      <mat-option value="150"> 2 hora 30 minutos</mat-option>
                      <mat-option value="180"> 3 hora</mat-option>
                      <mat-option value="210"> 3 hora 30 minutos</mat-option>
                      <mat-option value="240"> 4 hora</mat-option>
                      <mat-option value="270"> 4 hora 30 minutos</mat-option>
                      <mat-option value="300"> 5 hora</mat-option>
                      <mat-option value="330"> 5 hora 30 minutos</mat-option>
                      <mat-option value="360"> 6 hora</mat-option>
                      <mat-option value="390"> 6 hora 30 minutos</mat-option>
                      <mat-option value="420"> 7 hora</mat-option>
                      <mat-option value="450"> 7 hora 30 minutos</mat-option>
                      <mat-option value="480"> 8 hora</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div class="collapse collapse-box" id="horizontal-basic-form1">
                <div class="code-box">
                  <div class="clearfix">
                    <a href="javascript:;" class="btn btn-primary btn-sm code-copy pull-left"
                      data-clipboard-target="#horizontal-basic"><i class="fa fa-clipboard"></i> Copy Code</a>
                    <a href="#horizontal-basic-form1" class="btn btn-primary btn-sm pull-right" rel="content-y"
                      data-toggle="collapse" role="button"><i class="fa fa-eye-slash"></i> Hide Code</a>
                  </div>
                </div>
              </div>
            </div>
            <!-- horizontal Basic Forms End -->
          </div>
        </div>
      </div>
      <button class="btn btn-success btn-magento-naranja" (click)="newGuardar()" [disabled]="!editorRegistroForm.valid"
        [hidden]="!permisobtnGuardar || !editando">{{translation.Guardar}}
      </button>
    </form>
    <!-- Fin Nueva Maquetacion -->
  </div>
</div>