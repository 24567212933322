import {Deserializable} from "./deserializable.model";

export class TiposPromocion_Respuesta implements Deserializable {
    DatosResult?: DatosResult_TiposPromocion;
    Mensajes: Mensaje[];

    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}

export class TipoPromocion implements Deserializable {
  
  pkId: string;
  chCodigo: string;
  chNombrePromo: string;
  chDescripcionNombre: string;
  chTextoPantalla: string;
  chTextoImpresion: string;
  chTextoDisplay: string;
  chTipoValidez: string;
  iValidez: string;
  iGrupoId: string;
  iTipoProductoId: string;
  iGrupoAsociadoId: string;
  iTipoVisitaId: string;
  iTipoNivelId: string;
  iCategoriaId: string;
  bALaVenta: string;
  bImprimirPromo: string;
  bImprimirPrecio: string;
  chTeclaRapida: string;
  iTEDatosId: string;
  
  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class DatosResult_TiposPromocion implements Deserializable {
  lista: TipoPromocion[];

    deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Mensaje implements Deserializable {
  CodigoMensaje: string;
  DescripcionMensaje: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

//Botonera
export class Botonera {
  TeclaRapida: string;
  NombreTipo: string;
  idEntrada: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class DatosResult_Botonera {
  Lista: Botonera[];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class RespuestaBotonera {
  DatosResult: DatosResult_Botonera;
  Mensajes: Mensaje[];
  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

