import {Deserializable} from "./deserializable.model";

export class grupos_actividad_taller_Respuesta implements Deserializable {
    DatosResult?: DatosResult_grupos_actividad_taller;
    Mensajes: Mensaje[];

    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}

export class Grupos_actividad_taller implements Deserializable {
  PkId: string;
  Nombre: string;
  Descripcion: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class DatosResult_grupos_actividad_taller implements Deserializable {
  customanGrupoActividadTaller: Grupos_actividad_taller[];

    deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Mensaje implements Deserializable {
  CodigoMensaje: string;
  DescripcionMensaje: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}