import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Globales } from "../_clases/globales";

import {
  IdiomaGR,
  IdiomaconURL,
} from "../_modelos/grupos-recintos-Respuesta.model";
import {
  peticion_CalendariosTiposControl_Lista,
  peticion_Del_CalendariosTiposControl,
  peticion_Insercion_Upd_CalendariosTiposControl,
} from "../_modelos/peticion-calendarios-tipos-control.model";
import { peticion_Centros_Lista } from "../_modelos/peticion-centros.model";
import {
  peticion_Del_Divisas,
  peticion_Divisas_Lista,
  peticion_Insercion_Upd_Divisas,
} from "../_modelos/peticion-divisas.model";
import {
  peticion_Del_Festivos,
  peticion_Festivos_Lista,
  peticion_Insercion_Upd_Festivos,
} from "../_modelos/peticion-festivos.model";
import {
  peticion_Del_FormasPago,
  peticion_FormasPago_Lista,
  peticion_Insercion_Upd_FormasPago,
} from "../_modelos/peticion-formas-pago.model";
import {
  peticion_Del_GrupoTipoBono,
  peticion_Grupo_Tipo_Bono_Lista,
  peticion_Insercion_Upd_GrupoTipoBono,
} from "../_modelos/peticion-grupo-tipo-bono.model";
import {
  peticion_Del_grupos_actividad_taller,
  peticion_Insercion_Upd_grupos_actividad_taller,
  peticion_grupos_actividad_taller_Lista,
} from "../_modelos/peticion-grupos-actividad-taller.model";
import {
  peticion_Del_GruposClientes,
  peticion_GruposClientes_Lista,
  peticion_Insercion_Upd_GruposClientes,
} from "../_modelos/peticion-grupos-clientes.model";
import {
  peticion_Del_GruposRecintos,
  peticion_GruposRecintos_Lista,
  peticion_Insercion_Upd_GruposRecintos,
} from "../_modelos/peticion-grupos-recintos.model";
import {
  peticion_Del_GruposTiposEntrada,
  peticion_Grupos_Tipos_Entrada_Lista,
  peticion_Insercion_Upd_GruposTiposEntrada,
} from "../_modelos/peticion-grupos-tipos-entrada.model";
import {
  peticion_Del_Idiomas,
  peticion_Idiomas_Lista,
  peticion_Insercion_Upd_Idiomas,
} from "../_modelos/peticion-idiomas.model";
import {
  peticion_Del_ItemMotGrupo,
  peticion_Insercion_Upd_ItemMotGrupo,
  peticion_ItemMotGrupo_Lista,
} from "../_modelos/peticion-items-motivos-grupos.model";
import {
  peticion_Del_Lugares,
  peticion_Insercion_Upd_Lugares,
  peticion_Lugares_Lista,
} from "../_modelos/peticion-lugares.model";
import {
  peticion_Del_TiposCliente,
  peticion_Insercion_Upd_TiposCliente,
  peticion_TiposCliente_Lista,
} from "../_modelos/peticion-tipos-cliente.model";
import {
  peticion_Del_TiposControl,
  peticion_Insercion_Upd_TiposControl,
  peticion_TiposControl_Lista,
} from "../_modelos/peticion-tipos-control.model";
import {
  peticion_Del_TiposUsuario,
  peticion_Insercion_Upd_TiposUsuario,
  peticion_Tipos_Usuario_Lista,
} from "../_modelos/peticion-tipos-usuario.model";
import {
  peticion_Insercion_Upd_Usuarios,
  peticion_Usuario,
  peticion_Usuarios_Borrar,
  peticion_Usuarios_Lista,
} from "../_modelos/peticion-usuario.model";

import {
  peticion_Del_Sesion,
  peticion_Insercion_Upd_Sesion,
  peticion_Sesion_Lista,
} from "../_modelos/peticion-sesion.model";
import {
  peticion_Del_Tarifas,
  peticion_Insercion_Upd_Tarifas,
  peticion_Tarifas_Lista,
} from "../_modelos/peticion-tarifas.model";
import {
  peticion_Del_TiposEntrada,
  peticion_Get_Botonera,
  peticion_Insercion_Upd_TiposEntrada,
  peticion_TiposEntrada_Lista,
} from "../_modelos/peticion-tipos-entrada.model";
import {
  peticion_Insercion_Upd_TiposPromocion
} from "../_modelos/peticion-tipos-promocion.model";
import {
  peticion_Del_TipoSesion,
  peticion_Insercion_Upd_TipoSesion,
  peticion_TipoSesion_Lista,
} from "../_modelos/peticion-tipos-sesion.model";
import {
  peticion_Del_Recintos,
  peticion_Insercion_Upd_Recintos,
  peticion_Recintos_Lista,
} from "../_modelos/peticions-recintos.model";


import { EntryType } from "@sharedV11/classes/entradas-y-promociones/tipo-entrada/entry-type";
import { ApiTokenService } from "src/app/shared/services/api/token/api-token.service";
import { formas_pago_Respuesta } from "../_modelos/formas-pago-Respuesta.model";
import { grupos_cliente_Respuesta } from "../_modelos/grupos-cliente-Respuesta.model";
import { peticion_CalendarioTipoIVA_Lista } from "../_modelos/peticion-calendarios-tipos-iva.model";
import {
  peticion_EnlacesMenu_Lista,
  peticion_Insercion_Upd_EnlacesMenu,
} from "../_modelos/peticion-enlaces-menu.model";
import {
  peticion_Del_GruposInternet,
  peticion_GruposInternet_Lista,
  peticion_Insercion_Upd_GruposInternet,
} from "../_modelos/peticion-grupos-internet.model";
import { peticion_Promocion_Lista } from "../_modelos/peticion-promocion.model";
import { peticion_TipoAbonado_Lista } from "../_modelos/peticion-tipos-abonado.model";
import { peticion_TipoButaca_Lista } from "../_modelos/peticion-tipos-butaca.model";
import { peticion_TipoIVA_Lista } from "../_modelos/peticion-tipos-iva.model";
import {
  peticion_Del_TiposReserva,
  peticion_Insercion_Upd_TiposReserva,
  peticion_Tipos_Reserva_Lista,
} from "../_modelos/peticion-tipos-reserva.model";
import { tipos_cliente_Respuesta } from "../_modelos/tipos-cliente-Respuesta.model";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class ConectarAPIService {
  config: any;
  env: any;

  constructor(
    private http: HttpClient,
    private globales: Globales,
    private tokenService: ApiTokenService
  ) {}

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }
  //---------------------------------
  ///USUARIOS
  getUsuario(usuario, contrasena, CodigoApp?: string): Observable<any> {
    //Para los Permisos del Usuario
    //Montamos la peticion
    let peticion: peticion_Usuario = new peticion_Usuario();
    peticion.clienteAPI = this.globales.clienteapi; //TODO meter en global al arrancar
    peticion.NombreUsuario = usuario;
    peticion.ClaveUsuario = contrasena;
    peticion.IdAplicacion = this.globales.idAplicacion.toString();
    if (CodigoApp) peticion.AplicacionCod = CodigoApp;
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Usuarios",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getUsuario"))
      );
  }

  async getUsuarioAsync(usuario, contrasena): Promise<any> {
    //Para los Permisos del Usuario
    //Montamos la peticion
    let peticion: peticion_Usuario = new peticion_Usuario();
    peticion.clienteAPI = this.globales.clienteapi; //TODO meter en global al arrancar
    peticion.NombreUsuario = usuario;
    peticion.ClaveUsuario = contrasena;
    peticion.IdAplicacion = this.globales.idAplicacion.toString();
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Usuarios",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getUsuario"))
      )
      .toPromise();
    return respuesta;
  }

  getUsuarios(usuario?: string, tipousuario?: number): Observable<any> {
    //Montamos la peticion Listado de Usuario (necesitamos una generica no es peticionUsuario)
    let peticion: peticion_Usuarios_Lista = new peticion_Usuarios_Lista();
    peticion.clienteAPI = this.globales.clienteapi; //TODO
    if (usuario != null) {
      peticion.NombreUsuario = usuario;
    } else {
      peticion.NombreUsuario = "";
    }
    if (usuario != null) {
      peticion.iTipoUsuario = tipousuario;
    } else {
      peticion.iTipoUsuario = 0;
    }
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanUsuarios",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getUsuarios"))
      );
  }

  async getUsuariosAsync(usuario?: string, tipousuario?: number): Promise<any> {
    //Montamos la peticion Listado de Usuario (necesitamos una generica no es peticionUsuario)
    let peticion: peticion_Usuarios_Lista = new peticion_Usuarios_Lista();
    peticion.clienteAPI = this.globales.clienteapi; //TODO
    if (usuario != null) {
      peticion.NombreUsuario = usuario;
    } else {
      peticion.NombreUsuario = "";
    }
    if (usuario != null) {
      peticion.iTipoUsuario = tipousuario;
    } else {
      peticion.iTipoUsuario = 0;
    }
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanUsuarios",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getUsuarios"))
      )
      .toPromise();
    return respuesta;
  }

  insUpdUsuarios(
    PkId: string,
    Personal: string,
    Nombre: string,
    Clave: string,
    Tipo: string,
    Baja: boolean,
    Conectado: boolean,
    Empleado: string
  ): Observable<any> {
    // let Baja1:boolean = Baja.trim().toLowerCase() == "true" || Baja.trim().toLowerCase() == "1"?true:false;
    // let Conectado1:boolean = Conectado.trim().toLowerCase() == "true" || Conectado.trim().toLowerCase() == "1"?true:false;
    //Montamos la peticion Listado de Usuario (necesitamos una generica no es peticionUsuario)
    let peticion: peticion_Insercion_Upd_Usuarios =
      new peticion_Insercion_Upd_Usuarios();
    peticion.clienteAPI = this.globales.clienteapi; //TODO
    peticion.Nombre = Nombre;
    peticion.Clave = Clave;
    peticion.Tipo = Tipo;
    if (Empleado != null) {
      peticion.Empleado = Empleado;
    } else {
      peticion.Empleado = "";
    }
    if (Personal != null) {
      peticion.Personal = Personal;
    } else {
      peticion.Personal = "";
    }
    if (Baja) {
      peticion.Baja = "1";
    } else {
      peticion.Baja = "0";
    }
    if (Conectado) {
      peticion.Conectado = "1";
    } else {
      peticion.Conectado = "0";
    }
    if (PkId != null && PkId != "0") {
      peticion.Funcion = "U";
      peticion.PkId = PkId;
    } else {
      peticion.Funcion = "I";
      peticion.PkId = "0";
    }
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarActualizarCustomanUsuarios",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getUsuarios"))
      );
  }

  delUsuarios(PkId: string): Observable<any> {
    let peticion: peticion_Usuarios_Borrar = new peticion_Usuarios_Borrar();
    peticion.clienteAPI = this.globales.clienteapi; //TODO
    peticion.PkId = PkId;
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanUsuarios",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delUsuarios"))
      );
  }

  async delUsuariosAsync(PkId: string): Promise<any> {
    let peticion: peticion_Usuarios_Borrar = new peticion_Usuarios_Borrar();
    peticion.clienteAPI = this.globales.clienteapi; //TODO
    peticion.PkId = PkId;
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanUsuarios",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delUsuarios"))
      )
      .toPromise();
    return respuesta;
  }
  /// FUsuarios

  //---------------------------------
  /// TIPOS USUARIO
  //Get, permite filtrar pos like nombre o like descripcion
  getTiposUsuario(nombre?: string, descripcion?: string): Observable<any> {
    //Montamos la peticion Listado de Tipos Usuario
    let peticion: peticion_Tipos_Usuario_Lista =
      new peticion_Tipos_Usuario_Lista();
    peticion.clienteAPI = this.globales.clienteapi; //TODO
    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    if (descripcion != null) {
      peticion.Descripcion = descripcion;
    } else {
      peticion.Descripcion = "";
    }
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanTipoUsuarios",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getTiposUsuario"))
      );
  }

  async getTiposUsuarioAsync(
    nombre?: string,
    descripcion?: string
  ): Promise<any> {
    //Montamos la peticion Listado de Tipos Usuario
    let peticion: peticion_Tipos_Usuario_Lista =
      new peticion_Tipos_Usuario_Lista();
    peticion.clienteAPI = this.globales.clienteapi; //TODO
    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    if (descripcion != null) {
      peticion.Descripcion = descripcion;
    } else {
      peticion.Descripcion = "";
    }
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanTipoUsuarios",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getTiposUsuario"))
      )
      .toPromise();
    return respuesta;
  }

  insUpdTiposUsuario(
    PkId: string,
    Nombre: string,
    Descripcion: string,
    Privilegios: string
  ): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion: peticion_Insercion_Upd_TiposUsuario =
      new peticion_Insercion_Upd_TiposUsuario();
    peticion.clienteAPI = this.globales.clienteapi; //TODO
    peticion.Nombre = Nombre;
    peticion.Descripcion = Descripcion == null ? "" : Descripcion;
    peticion.Privilegios = Privilegios == null ? "" : Privilegios;
    if (PkId != null && PkId != "0") {
      peticion.Funcion = "U";
      peticion.PkId = PkId;
    } else {
      peticion.Funcion = "I";
      peticion.PkId = "0";
    }
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarCustomanActualizarTipoUsuarios",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("insUpdTiposUsuario"))
      );
  }

  delTiposUsuario(PkId: string): Observable<any> {
    let peticion: peticion_Del_TiposUsuario = new peticion_Del_TiposUsuario();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    peticion.PkId = PkId;
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanTipoUsuarios",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delTiposUsuario"))
      );
  }
  async delTiposUsuarioAsync(PkId: string): Promise<any> {
    let peticion: peticion_Del_TiposUsuario = new peticion_Del_TiposUsuario();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    peticion.PkId = PkId;
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanTipoUsuarios",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delTiposUsuario"))
      )
      .toPromise();
    return respuesta;
  }
  ///F TIPOS USUARIO

  //---------------------------------
  /// GRUPOS TIPOS ENTRADA

  getGruposTiposEntrada(
    nombre?: string,
    descripcion?: string
  ): Observable<any> {
    //Montamos la peticion Listado
    let peticion: peticion_Grupos_Tipos_Entrada_Lista =
      new peticion_Grupos_Tipos_Entrada_Lista();
    peticion.clienteAPI = this.globales.clienteapi; //TODO
    if (nombre != null) {
      peticion.NombreGrupo = nombre;
    } else {
      peticion.NombreGrupo = "";
    }
    if (descripcion != null) {
      peticion.chDescripcion = descripcion;
    } else {
      peticion.chDescripcion = "";
    }

    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanGrupoTipoEntrada",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getGruposTiposEntrada"))
      );
  }

  async getGruposTiposEntradaAsync(
    nombre?: string,
    descripcion?: string
  ): Promise<any> {
    //Montamos la peticion Listado
    let peticion: peticion_Grupos_Tipos_Entrada_Lista =
      new peticion_Grupos_Tipos_Entrada_Lista();
    peticion.clienteAPI = this.globales.clienteapi; //TODO
    if (nombre != null) {
      peticion.NombreGrupo = nombre;
    } else {
      peticion.NombreGrupo = "";
    }
    if (descripcion != null) {
      peticion.chDescripcion = descripcion;
    } else {
      peticion.chDescripcion = "";
    }

    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanGrupoTipoEntrada",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getGruposTiposEntrada"))
      )
      .toPromise();
    return respuesta;
  }

  insUpdGruposTiposEntrada(
    PkId: string,
    Nombre: string,
    Descripcion: string,
    ConMotivo: boolean
  ): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion: peticion_Insercion_Upd_GruposTiposEntrada =
      new peticion_Insercion_Upd_GruposTiposEntrada();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    peticion.NombreGrupo = Nombre;
    peticion.Descripcion = Descripcion;
    if (ConMotivo) {
      peticion.ConMotivo = "1";
    } else {
      peticion.ConMotivo = "0";
    }
    if (PkId != null && PkId != "0") {
      peticion.Funcion = "U";
      peticion.PkId = PkId;
    } else {
      peticion.Funcion = "I";
      peticion.PkId = "0";
    }
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarActualizarCustomanGrupoTipoEntrada",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("insUpdGruposTiposEntrada"))
      );
  }

  delGruposTiposEntrada(PkId: string): Observable<any> {
    let peticion: peticion_Del_GruposTiposEntrada =
      new peticion_Del_GruposTiposEntrada();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    peticion.PkId = PkId;
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanCustomanGrupoTipoEntrada",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delGruposTiposEntrada"))
      );
  }
  async delGruposTiposEntradaAsync(PkId: string): Promise<any> {
    let peticion: peticion_Del_GruposTiposEntrada =
      new peticion_Del_GruposTiposEntrada();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    peticion.PkId = PkId;
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanCustomanGrupoTipoEntrada",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delGruposTiposEntrada"))
      )
      .toPromise();
    return respuesta;
  }
  /// F GRUPOS TIPOS ENTRADA
  //---------------------------------
  /// GRUPO TIPO BONO

  getGrupoTipoBono(nombre?: string, descripcion?: string): Observable<any> {
    //Montamos la peticion Listado
    let peticion: peticion_Grupo_Tipo_Bono_Lista =
      new peticion_Grupo_Tipo_Bono_Lista();
    peticion.clienteAPI = this.globales.clienteapi; //TODO
    if (nombre != null) {
      peticion.NombreGrupo = nombre;
    } else {
      peticion.NombreGrupo = "";
    }
    if (descripcion != null) {
      peticion.chDescripcion = descripcion;
    } else {
      peticion.chDescripcion = "";
    }

    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanGrupoTipoBono",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getGruposTiposBono"))
      );
  }

  async getGrupoTipoBonoAsync(
    nombre?: string,
    descripcion?: string
  ): Promise<any> {
    //Montamos la peticion Listado
    let peticion: peticion_Grupo_Tipo_Bono_Lista =
      new peticion_Grupo_Tipo_Bono_Lista();
    peticion.clienteAPI = this.globales.clienteapi; //TODO
    if (nombre != null) {
      peticion.NombreGrupo = nombre;
    } else {
      peticion.NombreGrupo = "";
    }
    if (descripcion != null) {
      peticion.chDescripcion = descripcion;
    } else {
      peticion.chDescripcion = "";
    }

    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanGrupoTipoBono",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getGrupoTipoBono"))
      )
      .toPromise();
    return respuesta;
  }

  insUpdGrupoTipoBono(
    PkId: string,
    Nombre: string,
    Descripcion: string
  ): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion: peticion_Insercion_Upd_GrupoTipoBono =
      new peticion_Insercion_Upd_GrupoTipoBono();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    peticion.NombreGrupo = Nombre;
    peticion.Descripcion = Descripcion;

    if (PkId != null && PkId != "0") {
      peticion.Funcion = "U";
      peticion.PkId = PkId;
    } else {
      peticion.Funcion = "I";
      peticion.PkId = "0";
    }
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarActualizarCustomanGrupoTipoBono",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("insUpdGrupoTipoBono"))
      );
  }

  delGrupoTipoBono(PkId: string): Observable<any> {
    let peticion: peticion_Del_GrupoTipoBono = new peticion_Del_GrupoTipoBono();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    peticion.PkId = PkId;
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanCustomanGrupoTipoBono",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delGrupoTipoBono"))
      );
  }
  async delGrupoTipoBonoAsync(PkId: string): Promise<any> {
    let peticion: peticion_Del_GrupoTipoBono = new peticion_Del_GrupoTipoBono();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    peticion.PkId = PkId;
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanCustomanGrupoTipoBono",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delGrupoTipoBono"))
      )
      .toPromise();
    return respuesta;
  }
  /// F GRUPO TIPO BONO
  //---------------------------------
  /// TIPOS CLIENTE

  getTiposCliente(
    nombre?: string,
    descripcion?: string
  ): Observable<tipos_cliente_Respuesta> {
    //Montamos la peticion Listado
    let peticion: peticion_TiposCliente_Lista =
      new peticion_TiposCliente_Lista();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    if (nombre != null) {
      peticion.NombreTipoCliente = nombre;
    } else {
      peticion.NombreTipoCliente = "";
    }
    if (descripcion != null) {
      peticion.CodigoTipoCliente = descripcion;
    } else {
      peticion.CodigoTipoCliente = "";
    }
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanTiposClientes",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getTiposCliente"))
      );
  }

  async getTiposClienteAsync(
    nombre?: string,
    descripcion?: string
  ): Promise<any> {
    //Montamos la peticion Listado
    let peticion: peticion_TiposCliente_Lista =
      new peticion_TiposCliente_Lista();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    if (nombre != null) {
      peticion.NombreTipoCliente = nombre;
    } else {
      peticion.NombreTipoCliente = "";
    }
    if (descripcion != null) {
      peticion.CodigoTipoCliente = descripcion;
    } else {
      peticion.CodigoTipoCliente = "";
    }
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanTiposClientes",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getTiposCliente"))
      )
      .toPromise();
    return respuesta;
  }

  insUpdTiposCliente(
    PkId: string,
    Nombre: string,
    Clave: string
  ): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion: peticion_Insercion_Upd_TiposCliente =
      new peticion_Insercion_Upd_TiposCliente();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    peticion.Nombre = Nombre;
    peticion.Codigo = Clave;
    if (PkId != null && PkId != "0") {
      peticion.Funcion = "U";
      peticion.PkId = PkId;
    } else {
      peticion.Funcion = "I";
      peticion.PkId = "0";
    }
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarActualizarCustomanTipoClientes",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("insUpdTiposCliente"))
      );
  }

  delTiposCliente(PkId: string): Observable<any> {
    let peticion: peticion_Del_TiposCliente = new peticion_Del_TiposCliente();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    peticion.PkId = PkId;
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanTipoClientes",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delTiposCliente"))
      );
  }
  async delTiposClienteAsync(PkId: string): Promise<any> {
    let peticion: peticion_Del_TiposCliente = new peticion_Del_TiposCliente();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    peticion.PkId = PkId;
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanTipoClientes",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delTiposCliente"))
      )
      .toPromise();
    return respuesta;
  }
  /// F TIPOS CLIENTE
  //---------------------------------
  /// FORMAS DE PAGO

  getFormasPago(
    nombre?: string,
    descripcion?: string
  ): Observable<formas_pago_Respuesta> {
    //Montamos la peticion Listado
    let peticion: peticion_FormasPago_Lista = new peticion_FormasPago_Lista();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    if (nombre != null) {
      peticion.Denominacion = nombre;
    } else {
      peticion.Denominacion = "";
    }
    if (descripcion != null) {
      peticion.Descripcion = descripcion;
    } else {
      peticion.Descripcion = "";
    }
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanFormaPago",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getFormasPago"))
      );
  }

  async getFormasPagoAsync(
    nombre?: string,
    descripcion?: string
  ): Promise<any> {
    //Montamos la peticion Listado
    let peticion: peticion_FormasPago_Lista = new peticion_FormasPago_Lista();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    if (nombre != null) {
      peticion.Denominacion = nombre;
    } else {
      peticion.Denominacion = "";
    }
    if (descripcion != null) {
      peticion.Descripcion = descripcion;
    } else {
      peticion.Descripcion = "";
    }
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanFormaPago",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getFormasPago"))
      )
      .toPromise();
    return respuesta;
  }

  insUpdFormasPago(
    PkId: string,
    Denominacion: string,
    Descripcion: string,
    SiNumVencimiento: string,
    siDiasPrimerVto: string,
    rDescProntoPago: string,
    rAumentoRecargo: string,
    siVencimiento: string,
    iCodigoFormaPago: string,
    chFOP: string,
    chFOP1: string,
    iFacturar: string
  ): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion: peticion_Insercion_Upd_FormasPago =
      new peticion_Insercion_Upd_FormasPago();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    peticion.Denominacion = Denominacion;
    peticion.Descripcion = Descripcion == null ? "" : Descripcion;
    peticion.SiNumVencimiento = SiNumVencimiento;
    peticion.siDiasPrimerVto = siDiasPrimerVto;
    peticion.rDescProntoPago = rDescProntoPago;
    peticion.rAumentoRecargo = rAumentoRecargo;
    peticion.siVencimiento = siVencimiento == null ? "0" : siVencimiento;
    peticion.iCodigoFormaPago = iCodigoFormaPago;
    peticion.chFOP = chFOP;
    peticion.chFOP1 = chFOP1 == null ? "" : chFOP1;
    peticion.iFacturar = iFacturar == null ? "0" : iFacturar;
    if (PkId != null && PkId != "0") {
      peticion.Funcion = "U";
      peticion.PkId = PkId;
    } else {
      peticion.Funcion = "I";
      peticion.PkId = "0";
    }
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarActualizarCustomanFormaPago",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("insUpdFormasPago"))
      );
  }

  delFormasPago(PkId: string): Observable<any> {
    let peticion: peticion_Del_FormasPago = new peticion_Del_FormasPago();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    peticion.PkId = PkId;
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanCustomanFormaPago",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delFormasPago"))
      );
  }

  async delFormasPagoAsync(PkId: string): Promise<any> {
    let peticion: peticion_Del_FormasPago = new peticion_Del_FormasPago();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    peticion.PkId = PkId;
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanCustomanFormaPago",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delFormasPago"))
      )
      .toPromise();
    return respuesta;
  }
  /// F FORMAS DE PAGO
  //---------------------------------
  /// GRUPOS DE CLIENTES

  getGruposCliente(nombre?: string): Observable<grupos_cliente_Respuesta> {
    //Montamos la peticion Listado
    let peticion: peticion_GruposClientes_Lista =
      new peticion_GruposClientes_Lista();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanGrupoCliente",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getGruposCliente"))
      );
  }
  async getGruposClienteAsync(nombre?: string): Promise<any> {
    //Montamos la peticion Listado
    let peticion: peticion_GruposClientes_Lista =
      new peticion_GruposClientes_Lista();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanGrupoCliente",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getGruposCliente"))
      )
      .toPromise();
    return respuesta;
  }

  insUpdGruposCliente(
    PkId: string,
    CodigoGruposClientes: string,
    NombreGruposClientes: string
  ): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion: peticion_Insercion_Upd_GruposClientes =
      new peticion_Insercion_Upd_GruposClientes();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    peticion.CodigoGruposClientes =
      CodigoGruposClientes == null ? "0" : CodigoGruposClientes;
    peticion.NombreGruposClientes = NombreGruposClientes;
    if (PkId != null && PkId != "0") {
      peticion.Funcion = "U";
      peticion.PkId = PkId;
    } else {
      peticion.Funcion = "I";
      peticion.PkId = "0";
    }
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarActualizarCustomanGrupoCliente",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("insUpdGruposCliente"))
      );
  }

  delGruposCliente(PkId: string): Observable<any> {
    let peticion: peticion_Del_GruposClientes =
      new peticion_Del_GruposClientes();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    peticion.PkId = PkId;
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanCustomanGrupoCliente",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delFormasPago"))
      );
  }

  async delGruposClienteAsync(PkId: string): Promise<any> {
    let peticion: peticion_Del_GruposClientes =
      new peticion_Del_GruposClientes();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    peticion.PkId = PkId;
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanCustomanGrupoCliente",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delFormasPago"))
      )
      .toPromise();
    return respuesta;
  }
  /// F GRUPOS DE CLIENTES
  //---------------------------------
  /// MAESTROS / DIVISAS

  /*getDivisas(nombre?: string): Observable<any> {
    //Montamos la peticion Listado
    let peticion: peticion_Divisas_Lista = new peticion_Divisas_Lista();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    if (nombre != null) { peticion.Nombre = nombre; } else { peticion.Nombre = ""; }
    //Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Maestros/getDivisas', JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('getDivisas'))
    );
  }*/
  getDivisas(nombre?: string) {
    const body = {
      Nombre: nombre ? nombre : "",
    };
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Maestros/getDivisas ",
        body
      )
      .pipe(map((res) => res));
  }

  async getDivisasAsync(nombre?: string): Promise<any> {
    //Montamos la peticion Listado
    let peticion: peticion_Divisas_Lista = new peticion_Divisas_Lista();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Maestros/getDivisas",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getDivisas"))
      )
      .toPromise();
    return respuesta;
  }

  insUpdDivisas(
    pkId: string,
    Nombre: string,
    simbolo: string,
    fValorCambio: string,
    UltimaActualizacion: string,
    Color: string,
    PuedeSerReferencia: string,
    NumeroDecimales: string,
    OpsTarjeta: string,
    PosicionDivisa: string,
    SimboloDecimal: string,
    SimboloDerecha: string,
    CodISO: string
  ): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion: peticion_Insercion_Upd_Divisas =
      new peticion_Insercion_Upd_Divisas();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    peticion.Nombre = Nombre;
    peticion.simbolo = simbolo;
    peticion.fValorCambio = fValorCambio;
    peticion.UltimaActualizacion = UltimaActualizacion;
    peticion.Color = Color;
    peticion.PuedeSerReferencia = PuedeSerReferencia;
    peticion.NumeroDecimales = NumeroDecimales;
    peticion.OpsTarjeta = OpsTarjeta;
    peticion.PosicionDivisa = PosicionDivisa;
    peticion.SimboloDecimal = SimboloDecimal;
    peticion.SimboloDerecha = SimboloDerecha;
    peticion.CodISO = CodISO;

    if (pkId != null && pkId != "0") {
      peticion.funcion = "U";
      peticion.pkId = pkId;
    } else {
      peticion.funcion = "I";
      peticion.pkId = "0";
    }
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarActualizarCustomanDivisa",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("insUpdDivisas"))
      );
  }

  delDivisas(PkId: string): Observable<any> {
    let peticion: peticion_Del_Divisas = new peticion_Del_Divisas();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    peticion.PkId = PkId;
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanCustomanDivisa",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delDivisa"))
      );
  }
  async delDivisasAsync(PkId: string): Promise<any> {
    let peticion: peticion_Del_Divisas = new peticion_Del_Divisas();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    peticion.PkId = PkId;
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanCustomanDivisa",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delDivisa"))
      )
      .toPromise();
    return respuesta;
  }
  /// F DIVISAS
  //---------------------------------
  /// MAESTROS / LUGARES

  getLugares(nombre?: string): Observable<any> {
    //Montamos la peticion Listado
    let peticion: peticion_Lugares_Lista = new peticion_Lugares_Lista();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanLugares",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getLugares"))
      );
  }
  async getLugaresAsync(nombre?: string): Promise<any> {
    //Montamos la peticion Listado
    let peticion: peticion_Lugares_Lista = new peticion_Lugares_Lista();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanLugares",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getLugares"))
      )
      .toPromise();
    return respuesta;
  }

  insUpdLugares(
    pkId: string,
    Nombre: string,
    Descripcion: string
  ): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion: peticion_Insercion_Upd_Lugares =
      new peticion_Insercion_Upd_Lugares();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    peticion.Nombre = Nombre;
    peticion.Descripcion = Descripcion;

    if (pkId != null && pkId != "0") {
      peticion.Funcion = "U";
      peticion.pkId = pkId;
    } else {
      peticion.Funcion = "I";
      peticion.pkId = "0";
    }
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarActualizarCustomanLugares",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("insUpdDivisas"))
      );
  }

  delLugares(pkId: string): Observable<any> {
    let peticion: peticion_Del_Lugares = new peticion_Del_Lugares();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    peticion.pkId = pkId;
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanCustomanLugares",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delLugares"))
      );
  }
  async delLugaresAsync(pkId: string): Promise<any> {
    let peticion: peticion_Del_Lugares = new peticion_Del_Lugares();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    peticion.pkId = pkId;
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanCustomanLugares",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delLugares"))
      )
      .toPromise();
    return respuesta;
  }
  /// F LUGARES
  //---------------------------------
  /// MAESTROS / TiposControl

  getTiposControl(nombre?: string): Observable<any> {
    //Montamos la peticion Listado
    let peticion: peticion_TiposControl_Lista =
      new peticion_TiposControl_Lista();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanTiposControl",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getTiposControl"))
      );
  }

  async getTiposControlAsync(nombre?: string): Promise<any> {
    //Montamos la peticion Listado
    let peticion: peticion_TiposControl_Lista =
      new peticion_TiposControl_Lista();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanTiposControl",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getTiposControl"))
      )
      .toPromise();
    return respuesta;
  }

  async getTiposProductosAsync(nombre?: string): Promise<any> {
    //Montamos la peticion Listado
    let peticion: peticion_TiposControl_Lista =
      new peticion_TiposControl_Lista();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Configuracion/GetTiposProductos",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getTiposControl"))
      )
      .toPromise();
    return respuesta;
  }

  async getTipoVisitaAsync(nombre?: string): Promise<any> {
    //Montamos la peticion Listado
    let peticion: peticion_TiposControl_Lista =
      new peticion_TiposControl_Lista();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Configuracion/ObtenerCustomanTipoVisita",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getTiposControl"))
      )
      .toPromise();
    return respuesta;
  }

  async getGruposAsociadosAsync(nombre?: string): Promise<any> {
    //Montamos la peticion Listado
    let peticion: peticion_TiposControl_Lista =
      new peticion_TiposControl_Lista();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Configuracion/GetGruposAsociados",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getTiposControl"))
      )
      .toPromise();
    return respuesta;
  }

  insUpdTiposControl(
    PkId: string,
    CodigoTipoControl: string,
    Nombre: string,
    Descripcion: string,
    CompHoraria: string,
    PermisoSinFecha: string,
    CompDiaria: string,
    CompIntervalo: string,
    CompDiaSemana: string,
    CompPasoPrevio: string,
    CompCalendario: string,
    CompCalendHorario: string,
    DenegarAcceso: string,
    DiasIntervalo: string,
    AccesoLunes: string,
    AccesoMartes: string,
    AccesoMiercoles: string,
    AccesoJueves: string,
    AccesoViernes: string,
    AccesoSabado: string,
    AccesoDomingo: string,
    PorDefecto: string,
    NivelEntrada: string,
    NumPasosPrevios: string,
    NumPasosPreviosDiarios: string,
    DiasIntervaloDesde: string
  ): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion: peticion_Insercion_Upd_TiposControl =
      new peticion_Insercion_Upd_TiposControl();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    peticion.Nombre = Nombre;
    peticion.Descripcion = Descripcion;
    peticion.CodigoTipoControl = CodigoTipoControl;
    peticion.CompHoraria = CompHoraria;
    peticion.PermisoSinFecha = PermisoSinFecha;
    peticion.CompDiaria = CompDiaria;
    peticion.CompIntervalo = CompIntervalo;
    peticion.CompDiaSemana = CompDiaSemana;
    peticion.CompPasoPrevio = CompPasoPrevio;
    peticion.CompCalendario = CompCalendario;
    peticion.NumPasosPrevios = NumPasosPrevios;
    peticion.CompCalendHorario = CompCalendHorario;
    peticion.DenegarAcceso = DenegarAcceso;
    peticion.DiasIntervalo = DiasIntervalo;
    peticion.AccesoLunes = AccesoLunes;
    peticion.AccesoMartes = AccesoMartes;
    peticion.AccesoMiercoles = AccesoMiercoles;
    peticion.AccesoJueves = AccesoJueves;
    peticion.AccesoViernes = AccesoViernes;
    peticion.AccesoSabado = AccesoSabado;
    peticion.AccesoDomingo = AccesoDomingo;
    peticion.PorDefecto = PorDefecto;
    peticion.NivelEntrada = NivelEntrada;
    peticion.NumPasosPreviosDiarios = NumPasosPreviosDiarios;
    peticion.DiasIntervaloDesde = DiasIntervaloDesde;

    if (PkId != null && PkId != "0") {
      peticion.Funcion = "U";
      peticion.PkId = PkId;
    } else {
      peticion.Funcion = "I";
      peticion.PkId = "0";
    }
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarActualizarCustomanTiposControl",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("insUpdTiposControl"))
      );
  }

  delTiposControl(PkId: string): Observable<any> {
    let peticion: peticion_Del_TiposControl = new peticion_Del_TiposControl();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    peticion.PkId = PkId;
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanTiposControl",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delTiposControl"))
      );
  }
  async delTiposControlAsync(PkId: string): Promise<any> {
    let peticion: peticion_Del_TiposControl = new peticion_Del_TiposControl();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    peticion.PkId = PkId;
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanTiposControl",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delTiposControl"))
      )
      .toPromise();
    return respuesta;
  }
  /// F TiposControl
  //---------------------------------
  /// MAESTROS / CalendariosTiposControl

  getCalendariosTiposControl(nombre?: string): Observable<any> {
    //Montamos la peticion Listado
    let peticion: peticion_CalendariosTiposControl_Lista =
      new peticion_CalendariosTiposControl_Lista();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanCalendariosTiposControl",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getCalendariosTiposControl"))
      );
  }
  async getCalendariosTiposControlAsync(
    nombre?: string,
    codigo?: string
  ): Promise<any> {
    //Montamos la peticion Listado
    let peticion: peticion_CalendariosTiposControl_Lista =
      new peticion_CalendariosTiposControl_Lista();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    if (codigo != null) {
      peticion.CodigoTipoControl = codigo;
    } else {
      peticion.CodigoTipoControl = "";
    }

    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanCalendariosTiposControl",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getCalendariosTiposControl"))
      )
      .toPromise();
    return respuesta;
  }

  insUpdCalendariosTiposControl(
    pkId: string,
    TipoControlId: string,
    CodigoTipoControl: string,
    NombreTipoControl: string,
    FechaAcceso: string,
    FechaAccesoFin: string
  ): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion: peticion_Insercion_Upd_CalendariosTiposControl =
      new peticion_Insercion_Upd_CalendariosTiposControl();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    peticion.TipoControlId = TipoControlId;
    peticion.NombreTipoControl = NombreTipoControl;
    peticion.CodigoTipoControl = CodigoTipoControl;
    peticion.FechaAcceso = FechaAcceso;
    peticion.FechaAccesoFin = FechaAccesoFin;

    if (pkId != null && pkId != "0") {
      peticion.Funcion = "U";
      peticion.pkId = pkId;
    } else {
      peticion.Funcion = "I";
      peticion.pkId = "0";
    }
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarActualizarCustomanCalendariosTiposControl",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("insUpdCalendariosTiposControl"))
      );
  }
  async insUpdCalendariosTiposControlAsync(
    pkId: string,
    TipoControlId: string,
    CodigoTipoControl: string,
    NombreTipoControl: string,
    FechaAcceso: string,
    FechaAccesoFin: string
  ): Promise<any> {
    //Montamos la peticion insercion/Update
    let peticion: peticion_Insercion_Upd_CalendariosTiposControl =
      new peticion_Insercion_Upd_CalendariosTiposControl();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    peticion.TipoControlId = TipoControlId;
    peticion.NombreTipoControl = NombreTipoControl;
    peticion.CodigoTipoControl = CodigoTipoControl;
    peticion.FechaAcceso = FechaAcceso;
    peticion.FechaAccesoFin = FechaAccesoFin;

    if (pkId != null && pkId != "0") {
      peticion.Funcion = "U";
      peticion.pkId = pkId;
    } else {
      peticion.Funcion = "I";
      peticion.pkId = "0";
    }
    //Enviamos la peticion
    let respuesta = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarActualizarCustomanCalendariosTiposControl",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("insUpdCalendariosTiposControl"))
      )
      .toPromise();
    return respuesta;
  }

  delCalendariosTiposControl(pkId: string, codigo: string): Observable<any> {
    let peticion: peticion_Del_CalendariosTiposControl =
      new peticion_Del_CalendariosTiposControl();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    peticion.pkId = pkId;
    peticion.CodigoTipoControlId = codigo;
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanCalendariosTiposControl",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delCalendariosTiposControl"))
      );
  }
  async delCalendariosTiposControlAsync(
    pkId: string,
    codigo: string
  ): Promise<any> {
    let peticion: peticion_Del_CalendariosTiposControl =
      new peticion_Del_CalendariosTiposControl();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    peticion.pkId = pkId;
    peticion.CodigoTipoControlId = codigo;
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanCalendariosTiposControl",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delCalendariosTiposControl"))
      )
      .toPromise();
    return respuesta;
  }
  /// F TiposControl
  //---------------------------------
  /// MAESTROS / items MOTIVOS GRUPO

  getItemsMotivosGrupo(nombre?: string): Observable<any> {
    //Montamos la peticion Listado
    let peticion: peticion_ItemMotGrupo_Lista =
      new peticion_ItemMotGrupo_Lista();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanItemsMotivosGrupos",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getItemsMotivosGrupo"))
      );
  }
  async getItemsMotivosGrupoAsync(nombre?: string): Promise<any> {
    // Montamos la peticion Listado
    let peticion: peticion_ItemMotGrupo_Lista =
      new peticion_ItemMotGrupo_Lista();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanItemsMotivosGrupos",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getItemsMotivosGrupo"))
      )
      .toPromise();
    return respuesta;
  }

  insUpdItemsMotivosGrupo(
    PkId: string,
    Nombre: string,
    Descripcion: string,
    Seleccionable: string,
    GrupoId: string
  ): Observable<any> {
    // Montamos la peticion insercion/Update
    let peticion: peticion_Insercion_Upd_ItemMotGrupo =
      new peticion_Insercion_Upd_ItemMotGrupo();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    peticion.Nombre = Nombre;
    peticion.Descripcion = Descripcion;
    peticion.Seleccionable = Seleccionable;
    peticion.GrupoId = GrupoId;

    if (PkId != null && PkId != "0") {
      peticion.funcion = "U";
      peticion.PkId = PkId;
    } else {
      peticion.funcion = "I";
      peticion.PkId = "0";
    }
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarActualizarCustomanItemsMotivosGrupos",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("insUpdItemsMotivosGrupo"))
      );
  }

  delItemsMotivosGrupo(PkId: string): Observable<any> {
    let peticion: peticion_Del_ItemMotGrupo = new peticion_Del_ItemMotGrupo();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    peticion.PkId = PkId;
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanItemsMotivosGrupos",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delItemsMotivosGrupo"))
      );
  }
  async delItemsMotivosGrupoAsync(PkId: string): Promise<any> {
    let peticion: peticion_Del_ItemMotGrupo = new peticion_Del_ItemMotGrupo();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    peticion.PkId = PkId;
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanItemsMotivosGrupos",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delItemsMotivosGrupo"))
      )
      .toPromise();
    return respuesta;
  }
  /// F items MOTIVOS GRUPO
  // ---------------------------------
  /// MAESTROS / FESTIVOS

  getFestivos(nombre?: string): Observable<any> {
    // Montamos la peticion Listado
    let peticion: peticion_Festivos_Lista = new peticion_Festivos_Lista();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanFestivos",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getFestivos"))
      );
  }
  async getFestivosAsync(nombre?: string): Promise<any> {
    // Montamos la peticion Listado
    let peticion: peticion_Festivos_Lista = new peticion_Festivos_Lista();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanFestivos",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getFestivos"))
      )
      .toPromise();
    return respuesta;
  }

  insUpdFestivos(
    PkId: string,
    Fecha: string,
    Motivo: string,
    Venta: string,
    Reserva: string,
    HoraInicio1Venta: string,
    HoraFin1Venta: string,
    HoraInicio2Venta: string,
    HoraFin2Venta: string,
    HoraInicio1Reserva: string,
    HoraFin1Reserva: string,
    HoraInicio2Reserva: string,
    HoraFin2Reserva: string
  ): Observable<any> {
    // Montamos la peticion insercion/Update
    let peticion: peticion_Insercion_Upd_Festivos =
      new peticion_Insercion_Upd_Festivos();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    peticion.Fecha = Fecha;
    peticion.Motivo = Motivo;
    peticion.Venta = Venta;
    peticion.Reserva = Reserva;
    peticion.HoraInicio1Venta = HoraInicio1Venta;
    peticion.HoraFin1Venta = HoraFin1Venta;
    peticion.HoraInicio2Venta = HoraInicio2Venta;
    peticion.HoraFin2Venta = HoraFin2Venta;
    peticion.HoraInicio1Reserva = HoraInicio1Reserva;
    peticion.HoraFin1Reserva = HoraFin1Reserva;
    peticion.HoraInicio2Reserva = HoraInicio2Reserva;
    peticion.HoraFin2Reserva = HoraFin2Reserva;

    if (PkId != null && PkId != "0") {
      peticion.Funcion = "U";
      peticion.pkId = PkId;
    } else {
      peticion.Funcion = "I";
      peticion.pkId = "0";
    }
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarActualizarCustomanFestivos",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("insUpdFestivos"))
      );
  }

  delFestivos(pkId: string, motivo?: string): Observable<any> {
    let peticion: peticion_Del_Festivos = new peticion_Del_Festivos();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    peticion.pkId = pkId;
    peticion.Motivo = motivo;
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanFestivos",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delFestivos"))
      );
  }
  async delFestivosAsync(pkId: string, motivo?: string): Promise<any> {
    let peticion: peticion_Del_Festivos = new peticion_Del_Festivos();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    peticion.pkId = pkId;
    peticion.Motivo = motivo;
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanFestivos",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delFestivos"))
      )
      .toPromise();
    return respuesta;
  }
  /// F items FESTIVOS

  // ---------------------------------
  /// Recintos / Grupos Actividad-Taller

  getGruposActividadTaller(
    nombre?: string,
    descripcion?: string
  ): Observable<any> {
    // Montamos la peticion Listado
    let peticion: peticion_grupos_actividad_taller_Lista =
      new peticion_grupos_actividad_taller_Lista();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    if (descripcion != null) {
      peticion.Descripcion = descripcion;
    } else {
      peticion.Descripcion = "";
    }
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanGruposActividadTaller",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("GruposActividadTaller"))
      );
  }
  async getGruposActividadTallerAsync(
    nombre?: string,
    descripcion?: string
  ): Promise<any> {
    // Montamos la peticion Listado
    let peticion: peticion_grupos_actividad_taller_Lista =
      new peticion_grupos_actividad_taller_Lista();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    if (descripcion != null) {
      peticion.Descripcion = descripcion;
    } else {
      peticion.Descripcion = "";
    }
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanGruposActividadTaller",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("GruposActividadTaller"))
      )
      .toPromise();
    return respuesta;
  }

  insUpdGruposActividadTaller(
    PkId: string,
    Nombre: string,
    Descripcion: string
  ): Observable<any> {
    // Montamos la peticion insercion/Update
    let peticion: peticion_Insercion_Upd_grupos_actividad_taller =
      new peticion_Insercion_Upd_grupos_actividad_taller();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    peticion.Nombre = Nombre;
    peticion.Descripcion = Descripcion;

    if (PkId != null && PkId != "0") {
      peticion.funcion = "U";
      peticion.PkId = PkId;
    } else {
      peticion.funcion = "I";
      peticion.PkId = "0";
    }
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarActualizarCustomanGruposActividadTaller",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("insUpdGruposActividadTaller"))
      );
  }

  delGruposActividadTaller(PkId: string): Observable<any> {
    let peticion: peticion_Del_grupos_actividad_taller =
      new peticion_Del_grupos_actividad_taller();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    peticion.PkId = PkId;
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanGruposActividadTaller",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delGruposActividadTaller"))
      );
  }
  async delGruposActividadTallerAsync(PkId: string): Promise<any> {
    let peticion: peticion_Del_grupos_actividad_taller =
      new peticion_Del_grupos_actividad_taller();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    peticion.PkId = PkId;
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanGruposActividadTaller",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delGruposActividadTaller"))
      )
      .toPromise();
    return respuesta;
  }
  /// F items Grupos Actividad-Taller
  // ---------------------------------
  /// MAESTROS / IDIOMAS

  /*getIdiomas(nombre?: string, codigo?: string, activo?: string): Observable<any> {
    // Montamos la peticion Listado
    let peticion: peticion_Idiomas_Lista = new peticion_Idiomas_Lista();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    if (nombre != null) { peticion.Nombre = nombre; } else { peticion.Nombre = ""; }
    if (codigo != null) { peticion.Codigo = codigo; } else { peticion.Codigo = ""; }
    if (activo != null) { peticion.Activo = activo; } else { peticion.Activo = ""; }
    // Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerIdiomas', JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('getIdiomas'))
    );
  }*/

  getIdiomas(nombre?: string, codigo?: string, activo?: string) {
    const body = {
      Nombre: nombre ? nombre : "",
      Codigo: codigo ? codigo : "",
      Activo: activo ? activo : "",
    };
    return this.http.post<any>(
      this.tokenService.getConfig("API_URL") + "Customan/ObtenerIdiomas ",
      body
    );
  }

  async getIdiomasAsync(
    nombre?: string,
    codigo?: string,
    activo?: string
  ): Promise<any> {
    // Montamos la peticion Listado
    let peticion: peticion_Idiomas_Lista = new peticion_Idiomas_Lista();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    if (codigo != null) {
      peticion.Codigo = codigo;
    } else {
      peticion.Codigo = "";
    }
    if (activo != null) {
      peticion.Activo = activo;
    } else {
      peticion.Activo = "";
    }
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/ObtenerIdiomas",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getIdiomas"))
      )
      .toPromise();
    return respuesta;
  }

  insUpdIdiomas(
    PkId: string,
    Nombre: string,
    CodIdioma: string,
    Activo: string
  ): Observable<any> {
    // Montamos la peticion insercion/Update
    let peticion: peticion_Insercion_Upd_Idiomas =
      new peticion_Insercion_Upd_Idiomas();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    peticion.Nombre = Nombre;
    peticion.CodIdioma = CodIdioma;
    peticion.Activo = Activo;

    if (PkId != null && PkId != "0") {
      peticion.funcion = "U";
      peticion.PkId = PkId;
    } else {
      peticion.funcion = "I";
      peticion.PkId = "0";
    }
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarActualizarCustomanIdiomas",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("insUpdIdiomas"))
      );
  }

  delIdiomas(PkId: string): Observable<any> {
    let peticion: peticion_Del_Idiomas = new peticion_Del_Idiomas();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    peticion.PkId = PkId;
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanCustomanIdiomas",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delIdiomas"))
      );
  }
  async delIdiomasAsync(PkId: string): Promise<any> {
    let peticion: peticion_Del_Idiomas = new peticion_Del_Idiomas();
    peticion.clienteAPI = this.globales.clienteapi; // TODO
    peticion.PkId = PkId;
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanCustomanIdiomas",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delIdiomas"))
      )
      .toPromise();
    return respuesta;
  }
  /// F IDIOMAS
  // ---------------------------------
  /// RECINTOS / GRUPOS RECINTOS

  getGruposRecintos(
    nombre?: string,
    subgrupo?: string,
    activo?: string
  ): Observable<any> {
    // Montamos la peticion Listado
    let peticion: peticion_GruposRecintos_Lista =
      new peticion_GruposRecintos_Lista();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    if (subgrupo != null) {
      peticion.Subgrupo = subgrupo;
    } else {
      peticion.Subgrupo = "";
    }
    if (activo != null) {
      peticion.Activo = activo;
    } else {
      peticion.Activo = "";
    }
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerGruposRecintos",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getGruposRecintos"))
      );
  }
  async getGruposRecintosAsync(
    nombre?: string,
    subgrupo?: string,
    activo?: string
  ): Promise<any> {
    // Montamos la peticion Listado
    let peticion: peticion_GruposRecintos_Lista =
      new peticion_GruposRecintos_Lista();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    if (subgrupo != null) {
      peticion.Subgrupo = subgrupo;
    } else {
      peticion.Subgrupo = "";
    }
    if (activo != null) {
      peticion.Activo = activo;
    } else {
      peticion.Activo = "";
    }
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerGruposRecintos",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getGruposRecintos"))
      )
      .toPromise();
    return respuesta;
  }

  insUpdGruposRecintos(
    PkId: string,
    Nombre: string,
    subGrupo: string,
    Activo: string,
    CentroId: string,
    Idiomas: Array<IdiomaGR>
  ): Observable<any> {
    // Montamos la peticion insercion/Update
    let peticion: peticion_Insercion_Upd_GruposRecintos =
      new peticion_Insercion_Upd_GruposRecintos();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    peticion.Nombre = Nombre;
    peticion.subGrupo = subGrupo;
    peticion.Activo = Activo;
    peticion.CentroId = CentroId;
    peticion.Idiomas = Idiomas;

    if (PkId != null && PkId != "0") {
      peticion.funcion = "U";
      peticion.PkId = PkId;
    } else {
      peticion.funcion = "I";
      peticion.PkId = "0";
    }
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarActualizarCustomanGruposRecintos",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("insUpdGruposRecintos"))
      );
  }

  delGruposRecintos(PkId: string): Observable<any> {
    let peticion: peticion_Del_GruposRecintos =
      new peticion_Del_GruposRecintos();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    peticion.PkId = PkId;
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanGrupoRecintos",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delGruposRecintos"))
      );
  }
  async delGruposRecintosAsync(PkId: string): Promise<any> {
    let peticion: peticion_Del_GruposRecintos =
      new peticion_Del_GruposRecintos();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    peticion.PkId = PkId;
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanGrupoRecintos",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delGruposRecintos"))
      )
      .toPromise();
    return respuesta;
  }
  /// F GRUPOS RECINTOS

  // ---------------------------------
  /// RECINTOS / CENTROS

  getCentros(
    nombre?: string,
    clave?: string,
    activo?: string
  ): Observable<any> {
    // Montamos la peticion Listado
    let peticion: peticion_Centros_Lista = new peticion_Centros_Lista();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    if (clave != null) {
      peticion.Clave = clave;
    } else {
      peticion.Clave = "";
    }
    if (activo != null) {
      peticion.Activo = activo;
    } else {
      peticion.Activo = "";
    }
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/ObtenerCentros",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getCentros"))
      );
  }
  async getCentrosAsync(
    nombre?: string,
    clave?: string,
    activo?: string
  ): Promise<any> {
    // Montamos la peticion Listado
    let peticion: peticion_Centros_Lista = new peticion_Centros_Lista();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    if (clave != null) {
      peticion.Clave = clave;
    } else {
      peticion.Clave = "";
    }
    if (activo != null) {
      peticion.Activo = activo;
    } else {
      peticion.Activo = "";
    }
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/ObtenerCentros",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getCentros"))
      )
      .toPromise();
    return respuesta;
  }

  // insUpdIdiomas(PkId: string,
  //   Nombre: string,
  //   CodIdioma: string,
  //   Activo: string
  // ): Observable<any> {
  //   //Montamos la peticion insercion/Update
  // }

  // delIdiomas(PkId: string): Observable<any>
  // {
  //
  // }
  // async delIdiomasAsync(PkId: string): Promise<any>
  // {
  // }
  /// F CENTROS
  // ---------------------------------

  // ---------------------------------
  /// RECINTOS / RECINTOS

  getRecintos(
    nombre?: string,
    grupo?: string,
    activo?: string,
    idioma?: string
  ): Observable<any> {
    // Montamos la peticion Listado
    let peticion: peticion_Recintos_Lista = new peticion_Recintos_Lista();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    if (grupo != null) {
      peticion.Grupo = grupo;
    } else {
      peticion.Grupo = "";
    }
    if (activo != null) {
      peticion.Activo = activo;
    } else {
      peticion.Activo = "";
    }
    if (idioma != null) {
      peticion.Idioma = idioma;
    } else {
      peticion.Idioma = "";
    }
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/ObtenerRecintos",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getRecintos"))
      );
  }
  async getRecintosAsync(
    nombre?: string,
    grupo?: string,
    activo?: string,
    idioma?: string
  ): Promise<any> {
    // Montamos la peticion Listado
    let peticion: peticion_Recintos_Lista = new peticion_Recintos_Lista();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    if (grupo != null) {
      peticion.Grupo = grupo;
    } else {
      peticion.Grupo = "";
    }
    if (activo != null) {
      peticion.Activo = activo;
    } else {
      peticion.Activo = "";
    }
    if (idioma != null) {
      peticion.Idioma = idioma;
    } else {
      peticion.Idioma = "";
    }
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/ObtenerRecintos",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getRecintos"))
      )
      .toPromise();
    return respuesta;
  }

  insUpdRecintos(
    PkId: string,
    Nombre: string,
    Activo: string,
    GrupoRecintosId: string,
    ControlarAforo: string,
    ControlarAforoReserva: string,
    TipoAforo: string,
    AforoMaximo: string,
    LimiteAforo: string,
    HoraInicio: string,
    HoraFin: string,
    PorDefecto: string,
    MinimoAforo: string,
    Taller: string,
    Butacas: string,
    MostrarInternet: string,
    MaximoInternet: string,
    GrupoActividadId: string,
    CodigoTipoControl: string,
    Secciones: string,
    Shape: string,
    Coords: string,
    OrdenFilas: string,
    OrdenButacas: string,
    Numeracion: string,
    NumSesion: string,
    TiempoExtra: string,
    subGrupoRecinto: string,
    Idiomas: Array<IdiomaconURL>,
    Entradas_Recinto_Taller: Array<string>
  ): Observable<any> {
    // Montamos la peticion insercion/Update
    let peticion: peticion_Insercion_Upd_Recintos =
      new peticion_Insercion_Upd_Recintos();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    peticion.Nombre = Nombre;
    peticion.Activo = Activo;
    peticion.GrupoRecintosId = GrupoRecintosId;
    peticion.ControlarAforo = ControlarAforo;
    peticion.ControlarAforoReserva = ControlarAforoReserva;
    peticion.TipoAforo = TipoAforo;
    peticion.AforoMaximo = AforoMaximo;
    peticion.LimiteAforo = LimiteAforo;
    peticion.HoraInicio = HoraInicio;
    peticion.HoraFin = HoraFin;
    peticion.PorDefecto = PorDefecto;
    peticion.MinimoAforo = MinimoAforo;
    peticion.Taller = Taller;
    peticion.Butacas = Butacas;
    peticion.MostrarInternet = MostrarInternet;
    peticion.MaximoInternet = MaximoInternet;
    peticion.GrupoActividadId = GrupoActividadId;
    peticion.CodigoTipoControl = CodigoTipoControl;
    peticion.Secciones = Secciones;
    peticion.Shape = Shape;
    peticion.Coords = Coords;
    peticion.OrdenFilas = OrdenFilas;
    peticion.OrdenButacas = OrdenButacas;
    peticion.Numeracion = Numeracion;
    peticion.NumSesion = NumSesion;
    peticion.TiempoExtra = TiempoExtra;
    peticion.subGrupoRecinto = subGrupoRecinto;

    peticion.Idiomas = Idiomas;
    peticion.Entradas_Recinto_Taller = Entradas_Recinto_Taller;

    if (PkId != null && PkId != "0") {
      peticion.funcion = "U";
      peticion.PkId = PkId;
    } else {
      peticion.funcion = "I";
      peticion.PkId = "0";
    }
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarActualizarCustomanRecintos",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("insUpdRecintos"))
      );
  }

  insUpdRecintosByBody(body: any): Observable<any> {
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarActualizarCustomanRecintos",
        JSON.stringify(body),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("insUpdRecintos"))
      );
  }

  delRecintos(PkId: string): Observable<any> {
    let peticion: peticion_Del_Recintos = new peticion_Del_Recintos();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    peticion.PkId = PkId;
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanRecintos",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delRecintos"))
      );
  }
  async delRecintosAsync(PkId: string): Promise<any> {
    let peticion: peticion_Del_Recintos = new peticion_Del_Recintos();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    peticion.PkId = PkId;
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanRecintos",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delRecintos"))
      )
      .toPromise();
    return respuesta;
  }
  /// F RECINTOS
  // ---------------------------------

  /// Entradas / Tipos entradas

  /*getTiposEntradas(id?: string, nombre?: string, recinto?: string, tipo?: string): Observable<any> {
    // Montamos la peticion Listado
    let peticion: peticion_TiposEntrada_Lista = new peticion_TiposEntrada_Lista();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    if (nombre != null) { peticion.Nombre = nombre; } else { peticion.Nombre = ""; }
    if (id != null) { peticion.id = id; } else { peticion.id = ""; }
    if (recinto != null) { peticion.Recinto = recinto; } else { peticion.Recinto = null; }
    if (tipo != null) { peticion.TallerActividad = tipo; } else { peticion.TallerActividad = null; }
    // Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/GetTipoEntrada', JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('getTiposEntradas'))
    );
  }*/

  getTiposEntradas(
    id?: string,
    nombre?: string,
    recinto?: string,
    talleractividad?: string,
    grupotipoentrada?: string,
    tipomotivo?: string,
    controlacceso?: string,
    alaventa?: string,
    filtro?: string
  ) {
    const body = {
      Nombre: nombre ? nombre : "",
      id: id ? id : "",
      Recinto: recinto ? recinto : "",
      TallerActividad: talleractividad ? talleractividad : "",
      GrupoTipoEntrada: grupotipoentrada ? grupotipoentrada : "",
      ControlAcceso: controlacceso ? controlacceso : "",
      ALaVenta:
        alaventa && alaventa.trim() != ""
          ? alaventa.toLowerCase().trim() == "si"
            ? (alaventa = "1")
            : (alaventa = "0")
          : "",
      Filtro: filtro ? filtro : "",
    };
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/GetTipoEntrada ",
        body
      )
      .pipe(map((res) => res));
  }

  async getTiposEntradasAsync(
    id?: string,
    nombre?: string,
    recinto?: string,
    talleractividad?: string,
    grupotipoentrada?: string,
    tipomotivo?: string,
    controlacceso?: string,
    alaventa?: string,
    filtro?: string
  ): Promise<any> {
    // Montamos la peticion Listado
    let peticion: peticion_TiposEntrada_Lista =
      new peticion_TiposEntrada_Lista();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    if (id != null) {
      peticion.id = id;
    } else {
      peticion.id = "";
    }
    if (recinto != null) {
      peticion.Recinto = recinto;
    } else {
      peticion.Recinto = null;
    }
    if (talleractividad != null) {
      peticion.TallerActividad = talleractividad;
    } else {
      peticion.TallerActividad = null;
    }
    if (grupotipoentrada != null) {
      peticion.GrupoTipoEntrada = grupotipoentrada;
    } else {
      peticion.GrupoTipoEntrada = null;
    }
    if (tipomotivo != null) {
      peticion.TipoMotivo = tipomotivo;
    } else {
      peticion.TipoMotivo = null;
    }
    if (controlacceso != null) {
      peticion.ControlAcceso = controlacceso;
    } else {
      peticion.ControlAcceso = null;
    }
    if (alaventa != null && alaventa.trim() != "") {
      alaventa.toLowerCase().trim() == "si"
        ? (peticion.ALaVenta = "1")
        : (peticion.ALaVenta = "0");
    } else {
      peticion.ALaVenta = null;
    }
    if (filtro != null) {
      peticion.Filtro = filtro;
    } else {
      peticion.Filtro = null;
    }

    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/GetTipoEntrada",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getTiposEntradas"))
      )
      .toPromise();
    return respuesta;
  }

  insUpdTiposEntradas(entrada: EntryType): Observable<any> {
    // Montamos la peticion insercion/Update
    let peticion: peticion_Insercion_Upd_TiposEntrada =
      new peticion_Insercion_Upd_TiposEntrada();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    peticion.Nombre = entrada.Nombre;
    peticion.Descripcion = entrada.Descripcion;
    peticion.PrecioUnitario = entrada.PrecioUnitario;
    peticion.DivisaId = entrada.DivisaId;
    peticion.ALaVenta = entrada.ALaVenta;
    peticion.FormatoId = entrada.FormatoId;
    peticion.TextoPantalla = entrada.TextoPantalla;
    peticion.CondicionesVenta = entrada.CondicionesVenta;
    peticion.TextoImpresion = entrada.TextoImpresion;
    peticion.TextoDisplay = entrada.TextoDisplay;
    peticion.CodigoBarras = entrada.CodigoBarras;
    peticion.IVA = entrada.IVA;
    peticion.BaseImponible = entrada.BaseImponible;
    peticion.TipoAbreviado = entrada.TipoAbreviado;
    peticion.MaximoPorDia = entrada.MaximoPorDia;
    peticion.GrupoId = entrada.GrupoId;
    peticion.ImprimirTicket = entrada.ImprimirTicket;
    peticion.ControlarAcceso = entrada.ControlarAcceso;
    peticion.CodigoTipoControl = entrada.CodigoTipoControl;
    peticion.TeclaRapida = entrada.TeclaRapida;
    peticion.GrupoMotivoId = entrada.GrupoMotivoId;
    peticion.ImpresoraSecundaria = entrada.ImpresoraSecundaria;
    peticion.TicketVendido = entrada.TicketVendido;
    peticion.ImprimirPrecio = entrada.ImprimirPrecio;
    peticion.TipoButaca = entrada.TipoButaca;
    peticion.CuentaVisitante = entrada.CuentaVisitante;
    peticion.CaracteristicaId = entrada.CaracteristicaId;
    peticion.ArgumentosCaracteristica = entrada.ArgumentosCaracteristica;
    peticion.TipoButacaId = entrada.TipoButacaId;
    peticion.BarCodeInternet = entrada.BarCodeInternet;
    peticion.RecintosAsociados = entrada.RecintosAsociados;
    peticion.ActividadesAsociadas = entrada.ActividadesAsociadas;

    // Nuevos campos
    peticion.TipoProducto = entrada.TipoProducto;
    peticion.GrupoAsociado = entrada.GrupoAsociado;
    peticion.TipoVisita = entrada.TipoVisita;
    peticion.TipoNivel = entrada.TipoNivel;
    peticion.Categoria = entrada.Categoria;
    peticion.TEDDatos = entrada.TEDDatos;
    peticion.ConfiguracionAdicional = entrada.ConfiguracionAdicional;
    peticion.CentrosIds = entrada.CentrosIds;
    peticion.CentroCosteId = entrada.CentroCosteId;
    peticion.CanalId = entrada.CanalId;

    if (entrada.pkId != null && entrada.pkId != "0") {
      peticion.Funcion = "U";
      peticion.EntradaId = entrada.pkId;
    } else {
      peticion.Funcion = "I";
      peticion.EntradaId = "0";
    }
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/InsUpdTipoEntrada",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("insUpdTiposEntradas"))
      );
  }

  insUpdTiposPromociones(
    pkId: string,
    chCodigo: string,
    chNombrePromo: string,
    chDescripcionNombre: string,
    chTextoPantalla: string,
    chTextoImpresion: string,
    chTextoDisplay: string,
    chTipoValidez: string,
    iValidez: string,
    iGrupoId: string,
    iTipoProductoId: string,
    iGrupoAsociadoId: string,
    iTipoVisitaId: string,
    iTipoNivelId: string,
    iCategoriaId: string,
    bALaVenta: string,
    bImprimirPromo: string,
    bImprimirPrecio: string,
    chTeclaRapida: string,
    iTEDatosId: string
  ): Observable<any> {
    // Montamos la peticion insercion/Update
    let peticion: peticion_Insercion_Upd_TiposPromocion =
      new peticion_Insercion_Upd_TiposPromocion();

    peticion.chCodigo = chCodigo;
    peticion.chNombrePromo = chNombrePromo;
    peticion.chDescripcionNombre = chDescripcionNombre;
    peticion.chTextoPantalla = chTextoPantalla;
    peticion.chTextoImpresion = chTextoImpresion;
    peticion.chTextoDisplay = chTextoDisplay;
    peticion.chTipoValidez = chTipoValidez;
    peticion.iValidez = iValidez;
    peticion.iGrupoId = iGrupoId;
    peticion.iTipoProductoId = iTipoProductoId;
    peticion.iGrupoAsociadoId = iGrupoAsociadoId;
    peticion.iTipoVisitaId = iTipoVisitaId;
    peticion.iTipoNivelId = iTipoNivelId;
    peticion.iCategoriaId = iCategoriaId;
    peticion.bALaVenta = bALaVenta;
    peticion.bImprimirPromo = bImprimirPromo;
    peticion.bImprimirPrecio = bImprimirPrecio;
    peticion.chTeclaRapida = chTeclaRapida;
    peticion.iTEDatosId = iTEDatosId;

    if (pkId != null && pkId != "0") {
      peticion.Funcion = "U";
      peticion.pkId = pkId;
    } else {
      peticion.Funcion = "I";
      peticion.pkId = "0";
    }
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/InsUpdTipoPromocion",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("insUpdTiposPromociones"))
      );
  }

  delTiposEntradas(PkId: string): Observable<any> {
    let peticion: peticion_Del_TiposEntrada = new peticion_Del_TiposEntrada();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    peticion.entradaId = PkId;
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/DelTipoEntrada",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delTiposEntradas"))
      );
  }
  async delTiposEntradasAsync(PkId: string): Promise<any> {
    let peticion: peticion_Del_TiposEntrada = new peticion_Del_TiposEntrada();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    peticion.entradaId = PkId;
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/DelTipoEntrada",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delTiposEntradas"))
      )
      .toPromise();
    return respuesta;
  }
  /// F Tipos ENTRADAS
  /// Botonera
  //Get
  async getBotoneraAsync(id?: string, maximo?: string): Promise<any> {
    //Montamos la peticion Listado
    let peticion: peticion_Get_Botonera = new peticion_Get_Botonera();
    peticion.clienteAPI = this.globales.clienteapi; //TODO

    //if(id != null) {peticion.id = id;} else {peticion.id ="";}
    if (maximo != null) {
      peticion.Max = maximo;
    } else {
      peticion.Max = null;
    }
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/GetTipoEntradaBotonera",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getTiposEntradasBotonera"))
      )
      .toPromise();
    return respuesta;
  }
  ///F Botonera
  //---------------------------------

  /// Recintos / Tipos Sesion

  getTiposSesion(id?: string, nombre?: string): Observable<any> {
    // Montamos la peticion Listado
    let peticion: peticion_TipoSesion_Lista = new peticion_TipoSesion_Lista();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    if (id != null) {
      peticion.id = id;
    } else {
      peticion.id = "";
    }
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/ObtenerTipoSesion",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getTiposSesion"))
      );
  }
  async getTiposSesionAsync(id?: string, nombre?: string): Promise<any> {
    // Montamos la peticion Listado
    let peticion: peticion_TipoSesion_Lista = new peticion_TipoSesion_Lista();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    if (id != null) {
      peticion.id = id;
    } else {
      peticion.id = "";
    }
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/ObtenerTipoSesion",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getTiposSesion"))
      )
      .toPromise();
    return respuesta;
  }

  insUpdTiposSesion(
    pkId: string,
    Descripcion: string,
    chTipoSesion: string,
    Recinto: string,
    FechaInicio: string,
    FechaFin: string,
    HoraInicio: string,
    HoraFin: string,
    Intervalo: string,
    Lunes: string,
    Martes: string,
    Miercoles: string,
    Jueves: string,
    Viernes: string,
    Sabado: string,
    Domingo: string,
    Evento: string,
    TipoButaca: string,
    AforoMaximo: string,
    MinimoAforo: string,
    MaximoInternet: string,
    Butacas: string,
    Duracion: string,
    EspectaculoId: string,
    EnvioExternos: string,
    NumeroGrupos: string,
    CodIdioma: string,
    Idiomas: any[]
  ): Observable<any> {
    // Montamos la peticion insercion/Update
    let peticion: peticion_Insercion_Upd_TipoSesion =
      new peticion_Insercion_Upd_TipoSesion();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    peticion.Descripcion = Descripcion;
    peticion.chTipoSesion = chTipoSesion;
    peticion.Recinto = Recinto;
    peticion.FechaInicio = FechaInicio;
    peticion.FechaFin = FechaFin;
    peticion.HoraInicio = HoraInicio;
    peticion.HoraFin = HoraFin;
    peticion.Intervalo = Intervalo;
    peticion.Lunes = Lunes;
    peticion.Martes = Martes;
    peticion.Miercoles = Miercoles;
    peticion.Jueves = Jueves;
    peticion.Viernes = Viernes;
    peticion.Sabado = Sabado;
    peticion.Domingo = Domingo;
    peticion.Evento = Evento;
    peticion.TipoButaca = TipoButaca;
    peticion.AforoMaximo = AforoMaximo;
    peticion.MinimoAforo = MinimoAforo;
    peticion.MaximoInternet = MaximoInternet;
    peticion.Butacas = Butacas;
    peticion.Duracion = Duracion;
    peticion.EspectaculoId = EspectaculoId;
    peticion.EnvioExternos = EnvioExternos;
    peticion.NumeroGrupos = NumeroGrupos;
    peticion.CodIdioma = CodIdioma;
    if (Idiomas.length) {
      peticion.Idiomas = Idiomas;
    }

    if (pkId != null && pkId != "0") {
      peticion.Funcion = "U";
      peticion.pkId = pkId;
    } else {
      peticion.Funcion = "I";
      peticion.pkId = "0";
    }
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarActualizarCustomanTipoSesion",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("insUpdTiposSesion"))
      );
  }

  delTiposSesion(PkId: string): Observable<any> {
    let peticion: peticion_Del_TipoSesion = new peticion_Del_TipoSesion();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    peticion.PkId = PkId;
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanTipoSesion",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delTiposSesion"))
      );
  }
  async delTiposSesionAsync(PkId: string): Promise<any> {
    let peticion: peticion_Del_TipoSesion = new peticion_Del_TipoSesion();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    peticion.PkId = PkId;
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanTipoSesion",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delTiposSesion"))
      )
      .toPromise();
    return respuesta;
  }
  /// F Tipos Session

  // ---------------------------------

  /// Recintos /  Sesion sin Butacas

  getSesion(id?: string, nombre?: string): Observable<any> {
    // Montamos la peticion Listado
    let peticion: peticion_Sesion_Lista = new peticion_Sesion_Lista();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    if (id != null) {
      peticion.id = id;
    } else {
      peticion.id = "";
    }
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerRecintoSesion",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getSesion"))
      );
  }
  async getSesionAsync(
    id?: string,
    nombre?: string,
    orden?,
    direccion?,
    pagina?,
    tamanopagina?,
    activa?,
    fechafin?,
    fechainicio?,
    horafin?,
    horainicio?,
    recinto?,
    tiposesion?,
    bloquada?,
    filtro?
  ): Promise<any> {
    //Montamos la peticion Listado
    let peticion: peticion_Sesion_Lista = new peticion_Sesion_Lista();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    if (id != null) {
      peticion.id = id;
    } else {
      peticion.id = "";
    }
    if (pagina != null) {
      peticion.Pagina = pagina;
    }
    if (tamanopagina != null) {
      peticion.NumeroRegistros = tamanopagina;
    }
    if (activa != null) {
      peticion.Activa = this.globales.aBit(activa).toString();
    }
    if (fechafin != null) {
      peticion.FechaFin = fechafin;
    }
    if (fechainicio != null) {
      peticion.FechaInicio = fechainicio;
    }
    if (horafin != null) {
      peticion.HoraFin = horafin;
    }
    if (horainicio != null) {
      peticion.HoraInicio = horainicio;
    }
    if (recinto != null) {
      peticion.Recinto = recinto;
    }
    if (tiposesion != null) {
      peticion.TipoSesion = tiposesion;
    }
    if (bloquada != null) {
      peticion.SesionBloqueada = this.globales.aBit(bloquada).toString();
    }
    if (filtro != null) {
      peticion.Filtro = filtro;
    }
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerRecintoSesion",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getSesion"))
      )
      .toPromise();
    return respuesta;
  }

  insUpdSesion(
    pkId: string,
    Recinto: string,
    TipoSesion: string,
    Fecha: string,
    Descripcion: string,
    HoraInicio: string,
    HoraFin: string,
    Reservadas: string,
    Vendidas: string,
    Creado: string,
    Activa: string,
    ControlaHorario: string,
    LimiteAforo: string,
    Didactica: string,
    MaximoAforo: string,
    MaximoInternet: string,
    VendidasInternet: string,
    SesionBloqueada: string,
    NumSesion: string,
    AforoInicial: string,
    FechaHasta: string
  ): Observable<any> {
    // Montamos la peticion insercion/Update
    let peticion: peticion_Insercion_Upd_Sesion =
      new peticion_Insercion_Upd_Sesion();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    peticion.Recinto = Recinto;
    peticion.TipoSesion = TipoSesion;
    peticion.Fecha = Fecha;
    peticion.Descripcion = Descripcion;
    peticion.HoraInicio = HoraInicio;
    peticion.HoraFin = HoraFin;
    peticion.Reservadas = Reservadas;
    peticion.Vendidas = Vendidas;
    peticion.Creado = Creado;
    peticion.Activa = Activa;
    peticion.ControlaHorario = ControlaHorario;
    peticion.LimiteAforo = LimiteAforo;
    peticion.Didactica = Didactica;
    peticion.MaximoAforo = MaximoAforo;
    peticion.MaximoInternet = MaximoInternet;
    peticion.VendidasInternet = VendidasInternet;
    peticion.SesionBloqueada = SesionBloqueada;
    peticion.NumSesion = NumSesion;
    peticion.AforoInicial = AforoInicial;
    peticion.FechaHasta = FechaHasta;

    if (pkId != null && pkId != "0") {
      peticion.Funcion = "U";
      peticion.pkId = pkId;
    } else {
      peticion.Funcion = "I";
      peticion.pkId = "0";
    }
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarActualizarCustomanRecintoSesion",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("insUpdSesion"))
      );
  }

  insUpdSesionSinButaca(body): Observable<any> {
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarActualizarCustomanRecintoSesion",
        JSON.stringify(body),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("insUpdSesion"))
      );
  }

  delSesion(PkId: string): Observable<any> {
    let peticion: peticion_Del_Sesion = new peticion_Del_Sesion();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    peticion.PkId = PkId;
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanRecintoSesion",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delSesion"))
      );
  }
  async delSesionAsync(PkId: string): Promise<any> {
    let peticion: peticion_Del_Sesion = new peticion_Del_Sesion();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    peticion.PkId = PkId;
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanRecintoSesion",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delSesion"))
      )
      .toPromise();
    return respuesta;
  }
  /// F Session
  // ---------------------------------

  /// Maestros / Tipos IVA

  /*getTiposIVA(id?: string, nombre?: string): Observable<any> {
    // Montamos la peticion Listado
    let peticion: peticion_TipoIVA_Lista = new peticion_TipoIVA_Lista();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    if (nombre != null) { peticion.Nombre = nombre; } else { peticion.Nombre = ""; }
    if (id != null) { peticion.pkId = id; } else { peticion.pkId = ""; }
    // Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerTiposIva', JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('getTiposIVA'))
    );
  }*/
  getTiposIVA(id?: string, nombre?: string) {
    const body = {
      Nombre: nombre ? nombre : "",
      pkId: id ? id : "",
    };
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/ObtenerTiposIva ",
        body
      )
      .pipe(map((res) => res));
  }

  async getTiposIVAAsync(id?: string, nombre?: string): Promise<any> {
    // Montamos la peticion Listado
    let peticion: peticion_TipoIVA_Lista = new peticion_TipoIVA_Lista();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    if (id != null) {
      peticion.pkId = id;
    } else {
      peticion.pkId = "";
    }
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/ObtenerTiposIva",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getTiposIVA"))
      )
      .toPromise();
    return respuesta;
  }
  // INS (TODO)
  // DEL (TODO)

  // -- F TIPOS IVA
  // ---------------------------------

  /// Maestros / Grupos Internet

  /*getGruposInternet(idreserva?: string, nombre?: string): Observable<any> {
    // Montamos la peticion Listado
    let peticion: peticion_GruposInternet_Lista = new peticion_GruposInternet_Lista();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    if (nombre != null) { peticion.Nombre = nombre; } else { peticion.Nombre = ""; }
    if (idreserva != null) { peticion.TipoReserva = idreserva; } else { peticion.TipoReserva = ""; }
    // Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerGruposInternet', JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('getGruposInternet'))
    );
  }*/
  getGruposInternet(idreserva?: string, nombre?: string) {
    const body = {
      Nombre: nombre ? nombre : "",
      TipoReserva: idreserva ? idreserva : "",
    };
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerGruposInternet ",
        body
      )
      .pipe(map((res) => res));
  }

  async getGruposInternetAsync(
    idreserva?: string,
    nombre?: string
  ): Promise<any> {
    // Montamos la peticion Listado
    let peticion: peticion_GruposInternet_Lista =
      new peticion_GruposInternet_Lista();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    if (idreserva != null) {
      peticion.TipoReserva = idreserva;
    } else {
      peticion.TipoReserva = "";
    }
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerGruposInternet",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getGruposInternet"))
      )
      .toPromise();
    return respuesta;
  }

  // Ins
  insUpdGruposInternet(
    pkId: string,
    Nombre: string,
    Descripcion: string,
    chCodigo: string,
    Minimo: string,
    Maximo: string,
    Orden: string,
    TipoReservaId: string,
    ValidarGratuitas: string,
    EnlaceMenuId: string,
    Idioma: string,
    Idiomas: Array<IdiomaconURL>
  ): Observable<any> {
    // Montamos la peticion insercion/Update
    let peticion: peticion_Insercion_Upd_GruposInternet =
      new peticion_Insercion_Upd_GruposInternet();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    peticion.Nombre = Nombre;
    peticion.Descripcion = Descripcion;
    peticion.chCodigo = chCodigo;
    peticion.Minimo = Minimo;
    peticion.Maximo = Maximo;
    peticion.Orden = Orden;
    peticion.TipoReservaId = TipoReservaId;
    peticion.ValidarGratuitas = ValidarGratuitas;
    peticion.Idioma = Idioma;
    peticion.Idiomas = Idiomas;

    if (pkId != null && pkId != "0") {
      peticion.Funcion = "U";
      peticion.pkId = pkId;
    } else {
      peticion.Funcion = "I";
      peticion.pkId = "0";
    }
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarActualizarCustomanGruposInternet",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("insUpdGruposInternet"))
      );
  }

  // Del
  delGruposInternet(PkId: string): Observable<any> {
    let peticion: peticion_Del_GruposInternet =
      new peticion_Del_GruposInternet();
    peticion.PkId = PkId;
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanGruposInternet",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delGruposInternet"))
      );
  }
  async delGruposInternetAsync(PkId: string): Promise<any> {
    let peticion: peticion_Del_GruposInternet =
      new peticion_Del_GruposInternet();
    peticion.PkId = PkId;
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanGruposInternet",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delGruposInternet"))
      )
      .toPromise();
    return respuesta;
  }

  // -- F TIPOS IVA
  // ---------------------------------

  /// ----- / PROMOCIONES
  /* getPromociones(id?: string, nombre?: string, alaventa?: string): Observable<any> {
     // Montamos la peticion Listado
     let peticion: peticion_Promocion_Lista = new peticion_Promocion_Lista();
     if (nombre != null) { peticion.Nombre = nombre; } else { peticion.Nombre = ""; }
     if (id != null) { peticion.pkId = id; } else { peticion.pkId = ""; }
     if (alaventa != null) { peticion.Alaventa = alaventa; }
     // Enviamos la peticion
     return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerTiposPromocion', JSON.stringify(peticion), httpOptions).pipe(
       map(this.extractData),
       catchError(this.handleError<any>('getPromociones'))
     );
   }*/
  async getPromocionesAsync(
    id?: string,
    nombre?: string,
    alaventa?: string
  ): Promise<any> {
    // Montamos la peticion Listado
    let peticion: peticion_Promocion_Lista = new peticion_Promocion_Lista();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    if (id != null) {
      peticion.pkId = id;
    } else {
      peticion.pkId = "";
    }
    if (alaventa != null) {
      peticion.Alaventa = alaventa;
    }
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerTiposPromocion",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getPromociones"))
      )
      .toPromise();
    return respuesta;
  }

  async getTiposBonoAsync(
    id?: string,
    nombre?: string,
    alaventa?: string
  ): Promise<any> {
    // Montamos la peticion Listado
    let peticion: peticion_Promocion_Lista = new peticion_Promocion_Lista();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    if (id != null) {
      peticion.Id = id;
    } else {
      peticion.Id = "";
    }
    if (alaventa != null) {
      peticion.Alaventa = alaventa;
    }
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanTipoBono",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getPromociones"))
      )
      .toPromise();
    return respuesta;
  }

  async getTiposBonoByIdAsync(id?: string): Promise<any> {
    // Montamos la peticion Listado
    let peticion: peticion_Promocion_Lista = new peticion_Promocion_Lista();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    if (id != null) {
      peticion.Id = id;
    } else {
      peticion.Id = "";
    }
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanTipoBono",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getPromociones"))
      )
      .toPromise();
    return respuesta;
  }

  getPromociones(id?: string, nombre?: string, alaventa?: string) {
    const body = {
      Nombre: nombre ? nombre : "",
      pkId: id ? id : "",
      Alaventa: alaventa ? alaventa : "",
    };
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerTiposPromocion ",
        body
      )
      .pipe(map((res) => res));
  }
  // INS (TODO)
  // DEL (TODO)

  // -- F PROMOCIONES

  // ---------------------------------
  /// ----- / TiposAbonado
  /*getTiposAbonado(id?: string, nombre?: string, alaventa?: string): Observable<any> {
    // Montamos la peticion Listado
    let peticion: peticion_TipoAbonado_Lista = new peticion_TipoAbonado_Lista();
    if (nombre != null) { peticion.Nombre = nombre; } else { peticion.Nombre = ""; }
    if (id != null) { peticion.pkId = id; } else { peticion.pkId = ""; }
    if (alaventa != null) { peticion.Alaventa = alaventa; }
    // Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerTiposAbonado', JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('getTiposAbonado'))
    );
  }*/
  getTiposAbonado(id?: string, nombre?: string, alaventa?: string) {
    const body = {
      Nombre: nombre ? nombre : "",
      pkId: id ? id : "",
      Alaventa: alaventa ? alaventa : "",
    };
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerTiposAbonado ",
        body
      )
      .pipe(map((res) => res));
  }

  async getTiposAbonadoAsync(
    id?: string,
    nombre?: string,
    alaventa?: string
  ): Promise<any> {
    // Montamos la peticion Listado
    let peticion: peticion_TipoAbonado_Lista = new peticion_TipoAbonado_Lista();
    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    if (id != null) {
      peticion.pkId = id;
    } else {
      peticion.pkId = "";
    }
    if (alaventa != null) {
      peticion.Alaventa = alaventa;
    }
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/ObtenerTiposAbonado",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getTiposAbonado"))
      )
      .toPromise();
    return respuesta;
  }
  // INS (TODO)
  // DEL (TODO)

  // -- F TiposAbonado

  // ---------------------------------
  /// ----- / TiposButaca
  getTiposButaca(id?: string): Observable<any> {
    // Montamos la peticion Listado
    let peticion: peticion_TipoButaca_Lista = new peticion_TipoButaca_Lista();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    if (id != null) {
      peticion.id = id;
    } else {
      peticion.id = "";
    }
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/GetTipoButaca",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getTiposButaca"))
      );
  }
  async getTiposButacaAsync(id?: string): Promise<any> {
    // Montamos la peticion Listado
    let peticion: peticion_TipoButaca_Lista = new peticion_TipoButaca_Lista();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    if (id != null) {
      peticion.id = id;
    } else {
      peticion.id = "";
    }
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/GetTipoButaca",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getTiposButaca"))
      )
      .toPromise();
    return respuesta;
  }
  // INS (TODO)
  // DEL (TODO)

  // -- F TiposButaca

  // ---------------------------------
  /// ----- / TiposReserva
  getTiposReserva(nombre?: string): Observable<any> {
    // Montamos la peticion Listado
    let peticion: peticion_Tipos_Reserva_Lista =
      new peticion_Tipos_Reserva_Lista();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanTipoReserva",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getTiposReserva"))
      );
  }
  async getTiposReservaAsync(nombre?: string): Promise<any> {
    // Montamos la peticion Listado
    let peticion: peticion_Tipos_Reserva_Lista =
      new peticion_Tipos_Reserva_Lista();
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanTipoReserva",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getTiposReserva"))
      )
      .toPromise();
    return respuesta;
  }
  // INS
  insUpdTiposReserva(
    traspaso: peticion_Insercion_Upd_TiposReserva
  ): Observable<any> {
    // Montamos la peticion insercion/Update
    let peticion: peticion_Insercion_Upd_TiposReserva = traspaso;
    peticion.clienteAPI = this.globales.clienteapi; // TODO

    if (traspaso.pkId != null && traspaso.pkId != "0") {
      peticion.Funcion = "U";
      peticion.pkId = traspaso.pkId;
    } else {
      peticion.Funcion = "I";
      peticion.pkId = "0";
    }
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarActualizarCustomanTipoReserva",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("insUpdTiposReserva"))
      );
  }

  // Del
  delTiposReserva(PkId: string): Observable<any> {
    let peticion: peticion_Del_TiposReserva = new peticion_Del_TiposReserva();
    peticion.PkId = PkId;
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanTipoReserva",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delTiposReserva"))
      );
  }
  async delTiposReservaAsync(PkId: string): Promise<any> {
    let peticion: peticion_Del_TiposReserva = new peticion_Del_TiposReserva();
    peticion.PkId = PkId;
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanTipoReserva",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delTiposReserva"))
      )
      .toPromise();
    return respuesta;
  }

  // -- F TiposReserva

  // ---------------------------------
  /// ----- / Enlaces Menu
  getEnlacesMenu(grupoinet?: string): Observable<any> {
    // Montamos la peticion Listado
    let peticion: peticion_EnlacesMenu_Lista = new peticion_EnlacesMenu_Lista();
    if (grupoinet != null) {
      peticion.GrupoInternet = parseInt(grupoinet);
    } else {
      peticion.GrupoInternet = null;
    }
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "TraerEnlacesMenuPorConexionId",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getEnlacesMenu"))
      );
  }
  async getEnlacesMenuAsync(grupoinet?: string): Promise<any> {
    // Montamos la peticion Listado
    let peticion: peticion_EnlacesMenu_Lista = new peticion_EnlacesMenu_Lista();
    peticion.clienteAPI = this.globales.clienteapi; // TODO
    if (grupoinet != null) {
      peticion.GrupoInternet = parseInt(grupoinet);
    } else {
      peticion.GrupoInternet = null;
    }
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "TraerEnlacesMenuPorConexionId",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getEnlacesMenu"))
      )
      .toPromise();
    return respuesta;
  }

  // insUpd
  insUpdEnlacesMenu(
    traspaso: peticion_Insercion_Upd_EnlacesMenu
  ): Observable<any> {
    // Montamos la peticion insercion/Update
    let peticion: peticion_Insercion_Upd_EnlacesMenu = traspaso;

    // Pablo por fin se la ha cepillado
    // if(traspaso.pkid != null && traspaso.pkid != "0")
    //   {peticion.Funcion = "U";
    //   peticion.pkid = traspaso.pkid;}
    // else
    //   {peticion.Funcion ="I";
    //   peticion.pkid = "0"}
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "InsUpdEnlaceMenu",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("insUpdEnlacesMenu"))
      );
  }
  // DEL (TODO)

  // -- F Enlaces Menu

  // ---------------------------------
  /// ----- / Calendarios Tipo Iva
  /*getCalendariosTiposIVA(id?: string): Observable<any> {
    // Montamos la peticion Listado
    let peticion: peticion_CalendarioTipoIVA_Lista = new peticion_CalendarioTipoIVA_Lista();
    if (id != null) { peticion.id = id; } else { peticion.id = ""; }

    // Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/GetTipoIvaCalendario', JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('getCalendariosTiposIVA'))
    );
  }*/
  getCalendariosTiposIVA(id?: string) {
    const body = {
      id: id ? id : "",
    };
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/GetTipoIvaCalendario ",
        body
      )
      .pipe(map((res) => res));
  }

  async getCalendariosTiposIVAAsync(id?: string): Promise<any> {
    // Montamos la peticion Listado
    let peticion: peticion_CalendarioTipoIVA_Lista =
      new peticion_CalendarioTipoIVA_Lista();
    if (id != null) {
      peticion.id = id;
    } else {
      peticion.id = "";
    }

    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/GetTipoIvaCalendario",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getCalendariosTiposIVA"))
      )
      .toPromise();
    return respuesta;
  }
  // INS (TODO)
  // DEL (TODO)

  // -- F TiposAbonado

  // ---------------------------------

  /// Tarifas / Tarifas

  getTarifas(
    AlaVenta?: string,
    Taquilla?: string,
    Internet?: string,
    Automatica?: string,
    desdeC?: string,
    hastaC?: string,
    desdeV?: string,
    hastaV?: string,
    TEntradaId?: string,
    textoGeneral?: string,
    pagina?: string,
    tamanopagina?: string
  ) {
    const body = {
      alaVenta:
        AlaVenta && AlaVenta.toLowerCase().trim() == "si"
          ? "1"
          : AlaVenta && AlaVenta.trim() != ""
          ? "0"
          : "",
      taquilla:
        Taquilla && Taquilla.toLowerCase().trim() == "si"
          ? "1"
          : Taquilla && Taquilla.trim() != ""
          ? "0"
          : "",
      internet:
        Internet && Internet.toLowerCase().trim() == "si"
          ? "1"
          : Internet && Internet.trim() != ""
          ? "0"
          : "",
      taquillaAutomatica:
        Automatica && Automatica.toLowerCase().trim() == "si"
          ? "1"
          : Automatica && Automatica.trim() != ""
          ? "0"
          : "",
      feciniCompra: desdeC ? desdeC : "",
      fecFinCompra: hastaC ? hastaC : "",
      fecIniVisita: desdeV ? desdeV : "",
      fecFinVisita: hastaV ? hastaV : "",
      tipoEntradaId: TEntradaId ? TEntradaId : "",
      filtro: textoGeneral ? textoGeneral : "",
      pagina: pagina ? pagina : "",
      numeroRegistros: tamanopagina ? tamanopagina : "",
    };
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/ObtenerTarifas ",
        body
      )
      .pipe(map((res) => res));
  }

  async getTarifasAsync(filtro: peticion_Tarifas_Lista): Promise<any> {
    //tipob?:string, ALaVenta?:string
    //Montamos la peticion Listado
    //Enviamos la peticion (Cambiamos por filtro)
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/ObtenerTarifas",
        JSON.stringify(filtro),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getTarifas"))
      )
      .toPromise();
    return respuesta;
  }

  // insUpd
  insUpdTarifas(traspaso: peticion_Insercion_Upd_Tarifas): Observable<any> {
    // Montamos la peticion insercion/Update
    let peticion: peticion_Insercion_Upd_Tarifas = traspaso;

    if (traspaso.PkId != null && traspaso.PkId != "0") {
      peticion.Funcion = "U";
      peticion.PkId = traspaso.PkId;
    } else {
      peticion.Funcion = "I";
      peticion.PkId = "0";
    }
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarActualizarCustomanTarifas",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("insUpdTarifas"))
      );
  }

  InsertarActualizarCustomanTarifas(body) {
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarActualizarCustomanTarifas",
        JSON.stringify(body),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("insUpdTarifas"))
      );
  }

  delTarifas(PkId: string): Observable<any> {
    let peticion: peticion_Del_Tarifas = new peticion_Del_Tarifas();
    peticion.PkId = PkId;
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanTarifas",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delTarifas"))
      );
  }
  async delTarifasAsync(PkId: string): Promise<any> {
    let peticion: peticion_Del_Tarifas = new peticion_Del_Tarifas();
    peticion.PkId = PkId;
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanTarifas",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delTarifas"))
      )
      .toPromise();
    return respuesta;
  }

  async ObtenerCustomanCanales(): Promise<any> {
    let body = {
      // "IdCanal": 0,
      Canal: "",
      Seleccionable: "",
      IdSubCanal: "",
      SubCanal: "",
      CodSubCanal: "",
    };
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanCanales",
        JSON.stringify(body),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("ObtenerCustomanCanales"))
      )
      .toPromise();
    return respuesta;
  }

  async obtenerCanales(): Promise<any> {
    let body = {
      IdCanal: 0,
      Canal: "",
      Seleccionable: 0,
      IdSubCanal: 0,
      SubCanal: "",
      CodSubCanal: "",
    };
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/ObtenerCanales",
        JSON.stringify(body),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("ObtenerCanales"))
      )
      .toPromise();
    return respuesta;
  }

  async ObtenerCustomanGrupoTipoAbonado(): Promise<any> {
    let body = {
      // "pkId": 0,
      Nombre: "",
      Descripcion: "",
    };
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanGrupoTipoAbonado",
        JSON.stringify(body),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("ObtenerCustomanGrupoTipoAbonado"))
      )
      .toPromise();
    return respuesta;
  }

  /// F Tarifas
  // ---------------------------------
  // ---------------------------------

  /// CLientes / CLIENTES
  getZonaComercial(): Observable<any> {
    const peticion = {
      NombreTabla: "zonacomercial",
    };

    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Configuracion/TraerDatosListas",
        peticion,
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getZonaComercial"))
      );
  }

  /// F Tarifas
  // ---------------------------------

  // ---------------------------------
  async getDispositivosAsync(codigoAplicacion: string): Promise<any> {
    const body = {
      chCodigoAplicacion: codigoAplicacion,
      dDeBaja: null,
    };
    const respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "GetDispositivos",
        body
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getDispositivos"))
      )
      .toPromise();
    return respuesta;
  }

  async getEspectaculosAsync(idioma = "es-ES"): Promise<any> {
    const body = {
      bActivo: null,
      chIdioma: idioma,
    };
    const respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/GetEspectaculos",
        body
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("Customan/GetEspectaculos"))
      )
      .toPromise();
    return respuesta;
  }

  async GetEspectaculoByIdAsync(pkId = 0, idioma = "es-ES"): Promise<any> {
    const body = {
      pkId: pkId,
      chIdioma: idioma,
    };
    const respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/GetEspectaculoById",
        body
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("Customan/GetEspectaculoById"))
      )
      .toPromise();
    return respuesta;
  }

  // tslint:disable-next-line:max-line-length
  async insUpdEspectaculosAsync(
    pkID = "",
    espectaculo,
    Idiomas: Array<IdiomaGR>,
    ListidsTiposEntrada = []
  ): Promise<any> {
    const chTraducciones = {
      Nombre: [],
      Descripcion: [],
    };

    Idiomas.forEach((idioma) => {
      chTraducciones.Nombre.push({
        i18n: idioma.CodIdioma,
        msg: idioma.Nombre,
        activo: 1,
      });
      chTraducciones.Descripcion.push({
        i18n: idioma.CodIdioma,
        msg: idioma.Descripcion,
        activo: 1,
      });
    });

    const body = {
      pkId: pkID,
      bActivo: espectaculo.bActivo ? espectaculo.bActivo : "",
      chPathMapaSVG: espectaculo.pathSVG ? espectaculo.pathSVG : "",
      chGrupoRecintos: "[" + espectaculo.chGrupoRecintos + "]",
      chNombreEspectaculo: espectaculo.chNombre ? espectaculo.chNombre : "",
      chTiposEntrada: "[" + ListidsTiposEntrada.toString() + "]",
      chIdioma: "es-ES",
      chTraducciones: JSON.stringify(chTraducciones),
      chPromotor: espectaculo.chPromotor ? espectaculo.chPromotor : "",
      chCIF: espectaculo.chCIF ? espectaculo.chCIF : "",
    };
    const respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/InsUpdEspectaculos",
        body
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("Customan/InsUpdEspectaculos"))
      )
      .toPromise();
    return respuesta;
  }

  async delEspectaculosAsync(pkId): Promise<any> {
    const body = {
      pkId: pkId,
    };
    const respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/DelEspectaculos",
        body
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("Customan/DelEspectaculos"))
      )
      .toPromise();
    return respuesta;
  }

  async getConfiguracionesAsync(
    codigoAplicacion: string,
    iCodigoModulo: string,
    iDispositivo: string
  ): Promise<any> {
    const body = {
      chCodigoAplicacion: codigoAplicacion,
      iCodigoModulo: iCodigoModulo,
      iDispositivo: iDispositivo,
      bDeBaja: null,
    };
    const respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "GetConfiguraciones",
        body
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getConfiguraciones"))
      )
      .toPromise();

    return respuesta;
  }

  // tslint:disable-next-line:max-line-length
  async getBitacoraAsync(
    FechaDesde = "01/01/1900",
    FechaHasta = "01/01/2900",
    HoraDesde = "00:00:00",
    HoraHasta = "23:59:00"
  ): Promise<any> {
    const body = {
      Usuario: "",
      Password: "",
      TipoUsuario: "0",
      Procedimiento: "",
      RecintoId: "",
      Localizador: "",
      RecintoSesionId: "",
      FechaDesde: FechaDesde,
      FechaHasta: FechaHasta,
      HoraDesde: HoraDesde,
      HoraHasta: HoraHasta,
      FechaProcesoDesde: "",
      FechaProcesoHasta: "",
      HoraProcesoDesde: "",
      HoraProcesoHasta: "",
      TablaUsuarios: "",
      ModuloAccionId: "",
      NumeroRegistros: "",
      Pagina: "",
      DireccionOrden: "",
    };

    const respuesta = await this.http
      .post<any>(this.tokenService.getConfig("API_URL") + "GetBitacora", body)
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getBitacora"))
      )
      .toPromise();

    return respuesta;
  }

  async InsUpdConfigKuroiTPVAsync(
    pkId: string,
    chCodigoAplicacion: string,
    iCodigoModulo: string,
    iDispositivoId: string,
    chConfiguracion: string,
    chJSONDetalle: string
  ): Promise<any> {
    const body = {
      pkId: pkId,
      chCodigoAplicacion: chCodigoAplicacion,
      iCodigoModulo: iCodigoModulo,
      iDispositivoId: iDispositivoId,
      chConfiguracion: chConfiguracion,
      chJSONDetalle: chJSONDetalle,
    };

    const respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "InsUpdConfigKuroiTPV",
        body
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("InsUpdConfigKuroiTPV"))
      )
      .toPromise();

    return respuesta;
  }

  async DelConfigKuroiTPVAsync(
    pkId: string,
    chCodigoAplicacion: string,
    iCodigoModulo: string,
    iDispositivoId: string,
    chConfiguracion: string
  ): Promise<any> {
    const body = {
      pkId: pkId,
      chCodigoAplicacion: chCodigoAplicacion,
      iCodigoModulo: iCodigoModulo,
      iDispositivoId: iDispositivoId,
      chConfiguracion: chConfiguracion,
    };
    const respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "DelConfigKuroiTPV",
        body
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("DelConfigKuroiTPV"))
      )
      .toPromise();

    return respuesta;
  }

  async getSesionesAsync(
    espectaculo = "%prado%",
    descripcion = "%ON%",
    minFecha = "20200101",
    maxFecha = "20201230"
  ): Promise<any> {
    const chParams = {
      byIdTiposSesiones: 0,
      pageSize: 1000,
      page: 1,
      likeDescripcionEspectaculo: espectaculo,
      xlikeDescripcion: descripcion,
      minFecha: minFecha,
      maxFecha: maxFecha,
    };
    const body = {
      chParams: JSON.stringify(chParams),
    };
    const respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "VentaTaquilla/GetSesiones",
        body
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getSesiones"))
      )
      .toPromise();

    return respuesta;
  }

  async crearSesionesAsync(idEspectaculo, fechaInicio, horaInicio) {
    const body = {
      iEspectaculoId: idEspectaculo,
      dtFechaInicio: fechaInicio,
      dtHoraInicio: horaInicio,
    };
    const respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/CrearSesiones",
        body
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("CrearSesiones"))
      )
      .toPromise();

    return respuesta;
  }

  async updRecintosSesionesAsync(data) {
    const body = {
      Id: data.Id,
      RecintoId: data.RecintoId,
      Fecha: data.Fecha,
      HoraInicio: data.HoraInicio,
      HoraFin: data.HoraFin,
      Activa: data.Activo,
      ControlaHorario: data.ControlaHorario,
      MaximoAforo: data.MaximoAforo,
      MaximoInternet: data.MaximoInternet,
      SesionBloqueada: data.SesionBloqueada,
      NumSesion: data.NumSesion,
    };

    const respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Configuracion/Updrecintossesiones",
        body
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("Updrecintossesiones"))
      )
      .toPromise();

    return respuesta;
  }

  async getButacasAsync(idGrupoRecinto, idSesion, fecha, hora) {
    const chParams = {
      pageSize: 9200,
      page: 1,
      byIdSesionButaca: idSesion,
      byIdGrupoRecinto: idGrupoRecinto,
      byFecha: fecha,
      byHora: hora,
    };
    const body = {
      chParams: JSON.stringify(chParams),
    };

    const respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/GetButacas",
        body
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("GetButacas"))
      )
      .toPromise();

    return respuesta;
  }

  async traerSesionAsync(idRecinto, idSesion) {
    const body = {
      IdSesion: idSesion,
      Recinto: idRecinto,
    };
    const respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Configuracion/TraerSesion",
        body
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("TraerSesion"))
      )
      .toPromise();

    return respuesta;
  }

  async traerSesionesEntreFechasAsync(
    fechaInicio = "2019/01/01",
    fechaFin = "2019/12/31",
    recinto = '[{"codRecinto": 0}]'
  ) {
    const body = {
      FechaDesde: fechaInicio, // yyyy/mm/dd
      FechaHasta: fechaFin,
      Recinto: recinto,
      /*[
        {
          "codRecinto": 55
        },
        {
          "codRecinto": 97
        }
      ]*/
    };
    const respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Configuracion/TraerSesionesEntreFechas",
        body
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("TraerSesionesEntreFechas"))
      )
      .toPromise();

    return respuesta;
  }

  async eliminarSesionesAsync(idEspectaculo, fechaInicio, horaInicio) {
    const body = {
      clienteAPI: {
        Id: "XXXXXXXXXXXX",
        Token:
          "de8ISg2QGe4111NPHBnfijZnxb8cVKRCJms8Mkxl9kvCYW4SlCpfp2toGDEQtOo3mkjYdfCMq00WlYNCGOyYXkjJXqhmSi1lTr1HVqtxcv9cseyHEJjGl9JMBfTBhFkH",
      },
      iEspectaculoId: idEspectaculo,
      dtFechaInicio: fechaInicio,
      dtHoraInicio: horaInicio,
    };
    const respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/EliminarSesiones",
        body
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("EliminarSesiones"))
      )
      .toPromise();

    return respuesta;
  }

  async sesionActivate(idEspectaculo, fechaInicio, horaInicio) {
    const body = {
      clienteAPI: {
        Id: "XXXXXXXXXXXX",
        Token:
          "de8ISg2QGe4111NPHBnfijZnxb8cVKRCJms8Mkxl9kvCYW4SlCpfp2toGDEQtOo3mkjYdfCMq00WlYNCGOyYXkjJXqhmSi1lTr1HVqtxcv9cseyHEJjGl9JMBfTBhFkH",
      },
      iEspectaculoId: idEspectaculo,
      dtFechaInicio: fechaInicio,
      dtHoraInicio: horaInicio,
    };
    const respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/ActivarSesiones",
        body
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("ActivarSesiones"))
      )
      .toPromise();

    return respuesta;
  }

  async sesionDeactivate(idEspectaculo, fechaInicio, horaInicio) {
    const body = {
      clienteAPI: {
        Id: "XXXXXXXXXXXX",
        Token:
          "de8ISg2QGe4111NPHBnfijZnxb8cVKRCJms8Mkxl9kvCYW4SlCpfp2toGDEQtOo3mkjYdfCMq00WlYNCGOyYXkjJXqhmSi1lTr1HVqtxcv9cseyHEJjGl9JMBfTBhFkH",
      },
      iEspectaculoId: idEspectaculo,
      dtFechaInicio: fechaInicio,
      dtHoraInicio: horaInicio,
    };
    const respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/DesactivarSesiones",
        body
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("DesactivarSesiones"))
      )
      .toPromise();

    return respuesta;
  }

  async butacaSesionActivate(idSesionButaca, chIdRecintoButaca) {
    const body = {
      clienteAPI: {
        Id: "XXXXXXXXXXXX",
        Token:
          "de8ISg2QGe4111NPHBnfijZnxb8cVKRCJms8Mkxl9kvCYW4SlCpfp2toGDEQtOo3mkjYdfCMq00WlYNCGOyYXkjJXqhmSi1lTr1HVqtxcv9cseyHEJjGl9JMBfTBhFkH",
      },
      idSesionButaca: idSesionButaca,
      chIdRecintoButaca: "[" + chIdRecintoButaca.toString() + "]",
    };
    const respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/ActivarSesionButaca",
        body
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("ActivarSesionButaca"))
      )
      .toPromise();

    return respuesta;
  }

  async butacaSesionDeactivate(idSesionButaca, chIdRecintoButaca) {
    const body = {
      clienteAPI: {
        Id: "XXXXXXXXXXXX",
        Token:
          "de8ISg2QGe4111NPHBnfijZnxb8cVKRCJms8Mkxl9kvCYW4SlCpfp2toGDEQtOo3mkjYdfCMq00WlYNCGOyYXkjJXqhmSi1lTr1HVqtxcv9cseyHEJjGl9JMBfTBhFkH",
      },
      idSesionButaca: idSesionButaca,
      chIdRecintoButaca: "[" + chIdRecintoButaca.toString() + "]",
    };
    const respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/DesactivarSesionButaca",
        body
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("DesactivarSesionButaca"))
      )
      .toPromise();

    return respuesta;
  }

  async EstadosButacasAsync() {
    const body = {};
    const respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "/EstadosButacas",
        body
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("EstadosButacas"))
      )
      .toPromise();

    return respuesta;
  }

  async EstadosButacasPorRecintoAsync(
    espectaculoId,
    fecha,
    hora,
    grupoRecintoId
  ) {
    const body = {
      EspectaculoId: espectaculoId,
      FechaSesion: fecha,
      HoraInicioSesion: hora,
      GrupoRecinto: grupoRecintoId,
    };
    const respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "/EstadosButacas",
        body
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("EstadosButacas"))
      )
      .toPromise();

    return respuesta;
  }

  async GetEspectaculosMarcacion(idRecinto, idSesion) {
    const body = {
      IdSesion: idSesion,
      Recinto: idRecinto,
    };
    const respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "/GetEspectaculosMarcacion",
        body
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("GetEspectaculosMarcacion"))
      )
      .toPromise();

    return respuesta;
  }

  /*   async TraerDatosListasAsync(tabla) {
      const body = {
  
        "NombreTabla": tabla
      };
      const respuesta = await this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Configuracion/TraerDatosListas ', body).pipe(
        map(this.extractData),
        catchError(this.handleError<any>('TraerDatosListas '))
      ).toPromise();
      
      return respuesta;
    } */
  TraerDatosListasAsync(tabla) {
    const body = {
      NombreTabla: tabla,
    };
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Configuracion/TraerDatosListas ",
        body
      )
      .pipe(map((res) => res));
  }

  GetTipoButacasPorRecintoAsync(
    espectaculoId,
    fechaSesion,
    horaSesion,
    grupoRecintoId
  ) {
    const body = {
      EspectaculoId: espectaculoId,
      RecintosId: null,
      FechaSesion: fechaSesion,
      HoraInicioSesion: horaSesion,
      SesionInternetId: null,
      GrupoRecintoId: grupoRecintoId,
    };
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "/GetTipoButacasPorRecinto ",
        body
      )
      .pipe(map((res) => res));
  }

  ButacaDetalleAsync(recintosButacasId) {
    const body = {
      RecintosButacasId: recintosButacasId,
      GruposInternetId: "",
      TipoEntrada: 0,
      Espectaculo: "",
      Nombre: "",
      Contrasena: "",
      chPlataforma: "",
    };
    return this.http
      .post<any>(this.tokenService.getConfig("API_URL") + "/ButacaInfo ", body)
      .pipe(map((res) => res));
  }

  MarcarDesmarcarButacaAsync(marcado, marcar) {
    const token = JSON.parse(localStorage.getItem("currentToken"));
    const body = {
      Marcado: marcado,
      Marcar: marcar,
      Taquilla: "-1",
      SesionInternetId: token.Id,
    };
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "/MarcarDesmarcarButaca ",
        body
      )
      .pipe(map((res) => res));
  }

  MarcarDesmarcarButacaNoNumeradaAsync(
    idRecinto,
    idEspectaculo,
    fecha,
    hora,
    tipoButaca,
    marcado
  ) {
    const token = JSON.parse(localStorage.getItem("currentToken"));
    const body = {
      RecintoId: idRecinto,
      EspectaculoId: idEspectaculo,
      FechaEspectaculo: fecha,
      HoraEspectaculo: hora,
      Marcar: 2,
      Taquilla: -1,
      TipoButaca: 0,
      TipoButacaN: tipoButaca,
      SesionInternetId: token.Id,
      Marcado: marcado,
    };
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "/MarcarDesmarcarNoNumerada ",
        body
      )
      .pipe(map((res) => res));
  }

  DesamarcarButacasBySesionId() {
    const token = JSON.parse(localStorage.getItem("currentToken"));
    const body = {
      Accion: 0,
      RecintosSesionesId: 0,
      IdEntrada: 0,
      SesionInternetId: token.Id,
    };
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "/DesamarcarButacasBySesionId ",
        body
      )
      .pipe(map((res) => res));
  }

  RecintosButacasCambioTipoEstado(tipoButaca, estadoButaca) {
    const token = JSON.parse(localStorage.getItem("currentToken"));
    const body = {
      TipoButaca: tipoButaca,
      EstadoButaca: estadoButaca,
      SesionInternetId: token.Id,
    };
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "/RecintosButacasCambioTipoEstado ",
        body
      )
      .pipe(map((res) => res));
  }

  GetAppConfig(userId) {
    const token = JSON.parse(localStorage.getItem("currentToken"));
    const body = {
      CodigoApp: "CUSTOMAN",
      Id: userId,
    };
    return this.http.post<any>(
      this.tokenService.getConfig("API_URL") + "Configuracion/GetAppConfig ",
      body
    );
  }

  GetDatosInicio() {
    const token = JSON.parse(localStorage.getItem("currentToken"));
    const body = {
      CodigoApp: "CUSTOMAN",
    };
    return this.http.post<any>(
      this.tokenService.getConfig("API_URL") + "Configuracion/GetDatosInicio ",
      body
    );
  }

  ObtenerCustomanTipoBonoEntradas(bonoId) {
    const token = JSON.parse(localStorage.getItem("currentToken"));
    const body = {
      TipoBonoId: bonoId,
    };
    return this.http.post<any>(
      this.tokenService.getConfig("API_URL") +
        "Customan/ObtenerCustomanTipoBonoEntradas",
      body
    );
  }

  getPaises() {
    const body = {};
    return this.http.post<any>(
      this.tokenService.getConfig("API_URL") + "Configuracion/GetPaises",
      body
    );
  }

  InsUpdTipoBonoEntradas(tipoBonoId, entrada) {
    const token = JSON.parse(localStorage.getItem("currentToken"));
    const body = {
      ipkId: entrada.iTipoBonoEntradasId,
      iTipoBonoId: tipoBonoId,
      iTipoEntradaId: entrada.iTipoEntradaId,
      iNumEntradas: entrada.iNumEntradas,
      rPorcentaje: entrada.rPorcentaje,
      dtFechaInicio: entrada.dtFechaInicio,
      dtFechaFin: entrada.dtFechaFin,
    };
    return this.http.post<any>(
      this.tokenService.getConfig("API_URL") +
        "Customan/InsUpdTipoBonoEntradas",
      body
    );
  }

  InsUpdTipoBono(pkId, datos) {
    const token = JSON.parse(localStorage.getItem("currentToken"));

    return this.http.post<any>(
      this.tokenService.getConfig("API_URL") + "Customan/InsUpdTipoBono",
      datos
    );
  }

  InsUpdTipoBonoCompleto(datos) {
    const token = JSON.parse(localStorage.getItem("currentToken"));
    return this.http.post<any>(
      this.tokenService.getConfig("API_URL") +
        "Customan/InsertarCustomanTipoBonoModel",
      datos
    );
  }

  DelTipoBonoEntradas(iTipoBonoEntradasId) {
    const token = JSON.parse(localStorage.getItem("currentToken"));
    const body = {
      iTipoBonoEntradasId: iTipoBonoEntradasId,
    };
    return this.http.post<any>(
      this.tokenService.getConfig("API_URL") + "Customan/DelTipoBonoEntradas",
      body
    );
  }

  DelTipoBono(id) {
    const token = JSON.parse(localStorage.getItem("currentToken"));
    const body = {
      idTipoBono: id,
    };
    return this.http.post<any>(
      this.tokenService.getConfig("API_URL") + "Customan/DelTipoBono",
      body
    );
  }

  GetTiposEntradaDatos() {
    const body = {
      CodigoApp: "CUSTOMAN",
      Id: "",
    };
    return this.http.post<any>(
      this.tokenService.getConfig("API_URL") +
        "Configuracion/GetTiposEntradaDatos ",
      body
    );
  }

  GetGruposAsociados() {
    const body = {
      CodigoApp: "CUSTOMAN",
      Id: "",
    };
    return this.http.post<any>(
      this.tokenService.getConfig("API_URL") +
        "Configuracion/GetGruposAsociados ",
      body
    );
  }

  GetTiposNiveles() {
    const body = {
      CodigoApp: "CUSTOMAN",
      Id: "",
    };
    return this.http.post<any>(
      this.tokenService.getConfig("API_URL") + "Configuracion/GetTiposNiveles ",
      body
    );
  }

  GetTiposProductos() {
    const body = {
      CodigoApp: "CUSTOMAN",
      Id: "",
    };
    return this.http.post<any>(
      this.tokenService.getConfig("API_URL") +
        "Configuracion/GetTiposProductos ",
      body
    );
  }

  ObtenerCustomanTipoVisita() {
    const body = {
      CodigoApp: "CUSTOMAN",
      Id: "",
    };
    return this.http.post<any>(
      this.tokenService.getConfig("API_URL") +
        "Customan/ObtenerCustomanTipoVisita ",
      body
    );
  }

  ObtenerCustomanCategoria() {
    const body = {
      CodigoApp: "CUSTOMAN",
      Id: "",
    };
    return this.http.post<any>(
      this.tokenService.getConfig("API_URL") +
        "Customan/ObtenerCustomanCategoria ",
      body
    );
  }

  ObtenerCustomanCentroCoste() {
    const body = {
      CodigoApp: "CUSTOMAN",
      Id: "",
      CentroCosteId: 0,
      CentroCoste: "",
      CodCentroCoste: "",
      CuentaCentroCoste: "",
      Seleccionable: 1,
    };
    return this.http.post<any>(
      this.tokenService.getConfig("API_URL") +
        "Customan/ObtenerCustomanCentroCoste ",
      body
    );
  }

  ObtenerCustomanCanalesSub() {
    const body = {
      CodigoApp: "CUSTOMAN",
      Id: "",
      IdCanal: 0,
      Canal: "",
      Seleccionable: 2,
      IdSubCanal: 0,
      SubCanal: "",
      CodSubCanal: "",
    };
    return this.http.post<any>(
      this.tokenService.getConfig("API_URL") +
        "Customan/ObtenerCustomanCanales ",
      body
    );
  }

  GetTipoEntrada() {
    const body = {
      CodigoApp: "CUSTOMAN",
      Id: "",
    };
    return this.http.post<any>(
      this.tokenService.getConfig("API_URL") + "Customan/GetTipoEntrada ",
      body
    );
  }

  // ---------------------------------
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
