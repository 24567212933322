<app-buttons
  (open)="openPanel()"
  [buttonsWrap]="'add'"
  [addbut]="addbut"
></app-buttons>

<!-- <button (click)="customanpanel='AlertComponent'" class="btn btn-success btn-magento-naranja">Añadir Perfil De Usuario</button> -->

<!-- <button (click)="customanpanel='AlertComponent'">Panel</button> -->
<app-filter [tableName]="'perfilUsuarios'"></app-filter>

<app-table
  [tableName]="'perfilUsuarios'"
  (addbut)="addbut = $event"
></app-table>
<app-panelcustoman
  *ngIf="customanpanel !== undefined"
  [ConfigPanel]="customanpanel"
  [mode]="'I'"
>
</app-panelcustoman>
