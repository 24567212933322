import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectorButacasComponent } from './selector-butacas.component';
import { MapaRecintoComponent } from './componentes/mapa-recinto/mapa-recinto.component';
import { MinimapaRecintoComponent } from './componentes/minimapa-recinto/minimapa-recinto.component';
import { HttpClientModule } from '@angular/common/http';
import { ModalComponent } from './modal/modal.component';
import { SelectorNoNumeradasComponent } from './componentes/selector-no-numeradas/selector-no-numeradas.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SliderPreciosComponent } from './componentes/slider-precios/slider-precios.component';
import { ModalNONumeradoComponent } from './modal-no-numerado/modal-no-numerado.component';
import { ModalInfoReservaComponent } from './modal-info-reserva/modal-info-reserva.component';
import { MaterialModule } from '../../../shared/material/material.module';
import { SharedModule } from '../../shared.module';
import { SesionesMapaComponent } from '../sesiones-mapa/sesiones-mapa.component';

@NgModule({
  declarations: [
    SelectorButacasComponent, 
    MapaRecintoComponent,
    MinimapaRecintoComponent,
    ModalComponent,
    ModalNONumeradoComponent,
    SelectorNoNumeradasComponent,
    SliderPreciosComponent,
    ModalInfoReservaComponent,
    SesionesMapaComponent,
  ],
  imports: [
    //FontAwesomeModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule
  ],
  entryComponents: [SliderPreciosComponent],
  bootstrap: [SliderPreciosComponent],

  exports: [SelectorButacasComponent,MapaRecintoComponent,
    MinimapaRecintoComponent, SesionesMapaComponent
    // , ModalComponent, ModalNONumeradoComponent, ModalInfoReservaComponent
  ]
})
export class SelectorButacasModule {
/*   constructor(library: FaIconLibrary, config: FaConfig) {
    config.fallbackIcon = faBan;
    library.addIconPacks(fas);
    library.addIconPacks(far);
    library.addIconPacks(fab);
  } */

}
