import {Deserializable} from "./deserializable.model";

export class tipos_reserva_Respuesta implements Deserializable {
    DatosResult?: DatosResult_tiposReserva;
    Mensajes: Mensaje[];

    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}

export class TipoReserva implements Deserializable {
  
  pkId: string;
  Nombre: string;
  Descripcion: string;
  IntervaloControl: string;
  LimiteIntervalo: string;
  deBaja: string;
  ControlHorario: string;
  TiempoLimite: string;
  MostrarHorario: string;
  ControlarAforo: string;
  IncluirLLegada: string;
  Internet: string;
  MaximoInternet: string;
  MinimoInternet: string;
  dePago: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class DatosResult_tiposReserva implements Deserializable {
  listaTipoReserva: TipoReserva[];

    deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Mensaje implements Deserializable {
  CodigoMensaje: string;
  DescripcionMensaje: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}