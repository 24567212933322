import { Deserializable } from "../deserializable";

export class TiposSesion_show implements Deserializable {
    pkId:                    string;
    Recinto:                  string;
    Descripcion:             string;
    FechaInicio:                  string;
    FechaFin:                  string;
    chTipoSesion:                   string;
    Aforos:           string;
    Intervalo:        string;
    Duracion:             string;
    Dias:              string;

  
    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }


    assign(param,data){
      this[param] = data
    }

  }
