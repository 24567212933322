import {CLienteAPi} from "./cliente-api.model";
import {IdiomaGR, IdiomaconURL} from "./grupos-recintos-Respuesta.model"

//getRecintos
export class peticion_Recintos_Lista {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    Nombre: string;
    Activo: string;
    Grupo: string;
    Idioma: string;
}

//insUpdRecintos //TODO
export class peticion_Insercion_Upd_Recintos {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;

    PkId: string;
    Nombre: string;
    Activo: string;
    ControlarAforo: string;
    ControlarAforoReserva: string;
    TipoAforo: string;
    AforoMaximo: string;
    LimiteAforo: string;
    HoraInicio: string;
    HoraFin: string;
    PorDefecto: string;
    MinimoAforo: string;
    Taller: string;
    Butacas: string;
    MostrarInternet: string;
    MaximoInternet: string;
    GrupoActividadId: string;
    CodigoTipoControl: string;
    Secciones: string;
    GrupoRecintosId: string;
    Shape: string;
    Coords: string;
    OrdenFilas: string;
    OrdenButacas: string;
    Numeracion: string;
    NumSesion: string;
    TiempoExtra: string;
    subGrupoRecinto: string;

    Idiomas: IdiomaconURL[];
    Entradas_Recinto_Taller: Array<string>;

    funcion: string;
}

//delRecintos
export class peticion_Del_Recintos {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    PkId: string;
}
