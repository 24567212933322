import { CLienteAPi } from "@sharedV11/classes/userRequest/client_api";


//getGruposClientes
export class peticion_GruposClientes_Lista {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    Nombre: string;
}

//insUpdGruposClientes
export class peticion_Insercion_Upd_GruposClientes {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    pkId: string;
    CodigoGruposClientes: string;
    NombreGruposClientes: string;
    Funcion: string;
}

//delGruposClientes
export class peticion_Del_GruposClientes {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    pkId: string;
}