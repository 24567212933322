import { Filter } from '@sharedV11/classes/filters/filter';
import { Paginator } from '@sharedV11/classes/paginator/paginator';
import { CLienteAPi } from '../client_api';

export class PeticionClienteLista {
  
  ConexionIacpos: number;
  clienteAPI: CLienteAPi;
  paginator:Paginator
  filters:Filter[];
  Usuario: string;
  Password:string;
  

  constructor(paginator?,filters?) {
    this.paginator =paginator
    this.filters = filters;
  }
 
}

export interface PeticionAltaBajaCliente {

  Usuario: string;
  CodigoCliente: string;
  CodigoAltaBaja: string;

  FechaDebaja?: string;
  Magento: true;
}
