<!-- <div class="menu-acciones-metodo">
    <button class="btn btn-primary btn-magento-atras" (click)="editando=false;" [hidden]="!editando"><i
        class="fa fa-arrow-left fa-lg"></i>&nbsp;&nbsp;{{translation.Atras}}</button>    
    <button *ngIf="permisobtnEliminar && model.Pkid!=null && editando"  class="btn btn-danger btn-magento-gris"
    (click)="pregborrarRegistro()">{{translation.Borrar}}</button>
    <button *ngIf="permisobtnDuplicar && model.Pkid!=null && editando" class="btn btn-warning btn-magento-gris"
    (click)="duplicarRegistro()">{{translation.DuplicarRegistro}}</button>    
    <button class="btn btn-success btn-magento-naranja" (click)="guardar()" [disabled]="!editorRegistroForm.valid"
    [hidden]="!permisobtnGuardar || !editando">{{translation.Guardar}}</button>
    <button class="btn btn-warning btn-magento-naranja" color="accent" (click)="AnadirRegistro()"
    [hidden]="!permisobtnNuevo || editando">{{translation[title]}}</button>
</div>
 -->

<div class="menu-acciones-metodo">
  <ng-container *ngIf="layout != undefined || (layout != null && content)">
    <ng-container [ngSwitch]="layout">
      <ng-container *ngSwitchCase="'solo'">
        <div class="solo">
          <ng-container *ngFor="let columnDB of content">
            <button
              mat-raised-button
              color="primary"
              (click)="funcion1(columnDB.nameFunction)"
              id="{{ columnDB.buttonTranslate }}"
              [disabled]="columnDB.nameFunction == 'open' && addbut == false"
            >
              {{ translation[columnDB.buttonTranslate] }}
            </button>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'columns2'">
        <div class="columns2">
          <div>
            <ng-container *ngFor="let columnDB of content[0]">
              <!-- <pre>{{columnDB.nameFunction}}</pre> -->
              <button
                *ngIf="columnDB.nameFunction != 'desactivar'"
                mat-raised-button
                color="primary"
                [disabled]="
                  columnDB.disabled ||
                  (columnDB.nameFunction == 'save' && !modificar)
                "
                id="{{ columnDB.buttonTranslate }}"
                (click)="funcion1(columnDB.nameFunction)"
              >
                {{ translation[columnDB.buttonTranslate] }}
              </button>
              <button
                *ngIf="!debaja && columnDB.nameFunction == 'desactivar'"
                mat-raised-button
                color="primary"
                [disabled]="columnDB.disabled"
                id="{{ columnDB.buttonTranslate }}"
                (click)="funcion1(columnDB.nameFunction)"
              >
                {{ translation[columnDB.buttonTranslate] }}
              </button>
              <button
                *ngIf="debaja && columnDB.nameFunction == 'desactivar'"
                mat-raised-button
                color="accent"
                [disabled]="columnDB.disabled"
                id="{{ columnDB.buttonTranslate }}"
                (click)="funcion1(columnDB.nameFunction)"
              >
                {{ translation.Activar }}
              </button>

              <!-- <button class="btn btn-warning btn-magento-naranja" (click)="this[columnDB.nameFunction]()" >{{translation[columnDB.buttonTranslate]}}</button> -->
              <!-- ng-click probarlo -->
            </ng-container>
          </div>
          <div>
            <ng-container *ngFor="let columnDB of content[1]">
              <ng-container *ngIf="columnDB.buttonTranslate == 'Cerrar'">
                <button
                  mat-button
                  matRipple
                  color="primary"
                  id="{{ columnDB.buttonTranslate }}"
                >
                  <mat-icon
                    class="cerrar"
                    (click)="funcion1(columnDB.nameFunction)"
                    >close</mat-icon
                  >
                </button>
              </ng-container>
              <ng-container *ngIf="columnDB.buttonTranslate != 'Cerrar'">
                <button
                  mat-button
                  matRipple
                  color="primary"
                  id="{{ columnDB.buttonTranslate }}"
                  (click)="funcion1(columnDB.nameFunction)"
                >
                  {{ translation[columnDB.buttonTranslate] }}
                </button>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <!--  <ng-container *ngFor="let columnDB of config"> 
        <button class="btn btn-warning btn-magento-naranja">{{translation[columnDB.buttonTranslate]}}</button>
    </ng-container> -->
</div>
<!-- <app-panelcustoman 
*ngIf="customanpanel!==undefined" 
[ConfigPanel]="customanpanel"
[mode]="'I'" >
</app-panelcustoman>  -->
