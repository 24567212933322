import { Component, OnInit, HostBinding, ViewChild, LOCALE_ID } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator,MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';

import { first } from 'rxjs/operators';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { FormControl, FormBuilder, FormGroup, Validators, AbstractControl, ValidatorFn } from '@angular/forms';

import { TipoSesion_Respuesta, TipoSesion } from '../../_modelos/tipos-sesion-Respuesta.model';
import { Sesion_Respuesta, Sesion } from '../../_modelos/sesion-Respuesta.model';
import { ConectarAPIService } from '../../_API/conectar-api.service';
import { AutentificacionService } from '../../_otrosproveedores/autentificacion.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogoPopUp } from '../../shared/_alerta/popup.service';
import { Globales } from '@clasesV6/globales';
import { CalendariosTiposControl_Respuesta, CalendariosTipoControl } from '@modelosV6/clendarios-tipos-control-Respuesta.model';
import { Recintos, Recintos_Respuesta } from '@modelosV6/recintos-Respuesta.model';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_FORMATOS_FECHA } from '../../_otrosproveedores/date-format.adapter';
import { AlertaService } from '@sharedV6/_alerta/alerta.service';
import { Translator } from 'src/app/shared/services/translator/translator.service';
import { IdiomasconURL } from '../recintos/recintos.component';
import { Idiomas, Idiomas_Respuesta } from '@modelosV6/Idiomas-Respuesta.model';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MatPaginatorIntlEses } from '@otrosProveedoresV6/ES-es.paginator';
import moment from 'moment';


export interface DialogData {
  titulo: string;
  texto: string;
  eleccion: string;
  ok: string;
  ko: string;
}

export interface AccionesDialogo {
  accion: string;
  texto: string;
  funcion: string;
}

export class traspasomodelo {
  pkId: string;
  Recinto: string;
  TipoSesion: string;
  Fecha: string;
  Descripcion: string;
  HoraInicio: string;
  HoraFin: string;
  Reservadas: string;
  Vendidas: string;
  Creado: boolean;
  Activa: boolean;
  ControlaHorario: string;
  LimiteAforo: string;
  Didactica: boolean;
  MaximoAforo: string;
  MaximoInternet: string;
  VendidasInternet: string;
  SesionBloqueada: string;
  NumSesion: string;
  AforoInicial: string;
  FechaHasta: string;

  deserialize: null;
}

export class filtrosActivos {
  Nombre: string;
  CodigoTipoRecinto: string;
  CodigoTipoSesion: string;
  desde: string;
  hasta: string;
  Activa: string;
  textoGeneral: string;
}

export class intervalosAforo {
  id: number;
  texto: string;
  minutos: number;
}

@Component({
  selector: 'app-sesiones',
  templateUrl: './sesiones.component.html',
  styleUrls: ['./sesiones.component.css'],
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_FORMATOS_FECHA
    },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlEses },
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: MAT_DATE_LOCALE, useValue: 'es' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class SesionesComponent implements OnInit {
  displayedColumns: string[] = ['Acciones', 'Recinto', 'Nombre', 'Fecha', 'Horario', 'Irregular', 'Aforo',
    'Usadas', 'Disponible', 'NumSesion', 'Activo', 'ControlaHorario', 'Bloqueada', 'Didactica', 'Creado'];
  //columnsToDisplay: string[] = this.displayedColumns.slice();
  columnsToDisplay: string[] = ['Acciones', 'Recinto', 'Nombre', 'Fecha', 'Horario', 'Irregular', 'Aforo',
    'Usadas', 'Disponible', 'NumSesion', 'Activo'];

  displayedFilters: string[] = ['Nombre', 'Tipo Usuario', 'Personal', 'Conectado', 'Baja'];
  filtersToDisplay: string[] = this.displayedFilters.slice();

  dataSource: MatTableDataSource<Sesion>;
  seleccion = new SelectionModel<Sesion>(true, []);
  //
  @ViewChild('paginatorPrincipal') paginator: MatPaginator;
  @ViewChild('sortPrincipal') sort: MatSort;
  //
  // @ViewChild('paginatorCalendario') paginatorCalendario: MatPaginator;
  // @ViewChild('sortCalendario') sortCalendario: MatSort;
  // @ViewChild('paginatorCalendarioHorario') paginatorCalendarioHorario: MatPaginator;
  // @ViewChild('sortCalendarioHorario') sortCalendarioHorario: MatSort;

  respuestaAPI: Sesion_Respuesta;
  respuestaAPICalendario: CalendariosTiposControl_Respuesta;
  //--
  respuestaAPIRecintos: Recintos_Respuesta;
  tiposRecintos: Array<Recintos>;
  //--
  respuestaAPITiposSesion: TipoSesion_Respuesta;
  tiposTiposSesion: Array<TipoSesion>;
  //--
  nombreCabecera: string = '';
  editando: boolean = false;
  creando: boolean = false;
  editandoCalendarios: boolean = false;
  objedicionCalendario: CalendariosTipoControl = new CalendariosTipoControl;
  editandoCalendariosHorario: boolean = false;
  objedicionCalendarioHorario: CalendariosTipoControl = new CalendariosTipoControl;
  leyendo: boolean = true;
  mensaje_dialogo: string;
  eleccion_dialogo: string;
  titulo_dialogo: string;
  ok_dialogo: string;
  ko_dialogo: string;
  editorRegistroForm: FormGroup;
  editorFiltrosForm: FormGroup;
  formControlListaColumnas: FormControl;
  permisoFormulario: boolean = true;
  permisobtnNuevo: boolean = false;
  permisobtnGuardar: boolean = false;
  permisobtnEliminar: boolean = false;
  permisobtnDuplicar: boolean = false;
  Acciones: Array<AccionesDialogo>;
  // recinto = new FormControl('');
  // tipoRecinto = new FormControl('');
  accionselecionada: string = null;
  panelFiltrosvisible: boolean = false;
  panelColumnasvisible: boolean = false;
  filtrosactivosModelo: filtrosActivos = new filtrosActivos;
  filtrosactivos: filtrosActivos = Object.assign({}, this.filtrosactivosModelo);
  fecha_minDesde = new Date();
  fecha_minHasta = new Date();
  fecha_maxDesde: Date;
  fecha_maxHasta: Date;
  arr_dias_semana_seleccionadas: Array<any> = [];
  arr_intervalos_aforo: Array<intervalosAforo> = [{ id: 0, texto: 'SinIntervalo', minutos: 0 }, {
    id: 1,
    texto: '5 minutos',
    minutos: 5
  }, { id: 2, texto: '10 minutos', minutos: 10 }
    , { id: 3, texto: '15 minutos', minutos: 15 }, { id: 4, texto: '20 minutos', minutos: 20 }, {
    id: 5,
    texto: '25 minutos',
    minutos: 25
  }, { id: 6, texto: '30 minutos', minutos: 30 }
    , { id: 7, texto: '35 minutos', minutos: 35 }, { id: 8, texto: '40 minutos', minutos: 40 }, {
    id: 5,
    texto: '45 minutos',
    minutos: 45
  }, { id: 9, texto: '50 minutos', minutos: 50 }
    , { id: 10, texto: '55 minutos', minutos: 55 }, { id: 11, texto: '1 hora', minutos: 60 }, {
    id: 12,
    texto: '1 hora 30 minutos',
    minutos: 90
  }, { id: 13, texto: '2 horas', minutos: 120 }
    , { id: 14, texto: '2 horas 30 minutos', minutos: 150 }, { id: 15, texto: '3 horas', minutos: 180 }, {
    id: 16,
    texto: '3 horas 30 minutos',
    minutos: 210
  }, { id: 17, texto: '4 horas', minutos: 240 }
    , { id: 18, texto: '4 horas 30 minutos', minutos: 270 }, { id: 19, texto: '5 horas', minutos: 300 }, {
    id: 20,
    texto: '5 horas 30 minutos',
    minutos: 330
  }, { id: 21, texto: '6 horas', minutos: 360 }
    , { id: 22, texto: '6 horas 30 minutos', minutos: 390 }, { id: 23, texto: '7 horas', minutos: 420 }, {
    id: 24,
    texto: '7 horas 30 minutos',
    minutos: 450
  }, { id: 25, texto: '8 horas', minutos: 480 }];
  DuracionHoras: number = 0;
  DuracionMinutos: number = 0;
  numerogrupos: number = 0;
  recintoSeleccionado: Recintos = new Recintos();
  tiposesionSeleccionado: TipoSesion = new TipoSesion();
  LabelDisponibleOnlinetxt: string = '';
  LabelDisponibleTotaltxt: string = '';
  AforoInicial: string = '';
  //--Para controlar la lectura de la API
  resultsLength = 0;
  isLoadingResults = true;
  translation: any;
  panelSelectorFiltrosvisible: boolean = false;
  formControlListaFiltros: FormControl;
  listaObtenerIdiomas: Array<IdiomasconURL>;
  itemPkId: string;
  tiposIdiomasAPI: Idiomas_Respuesta;
  tiposIdiomas: Array<Idiomas>;
  editorListaIdiomas: IdiomasconURL[];
  PkId = '';
  funcion: string;
  modificando: boolean;
  copiaRow: any;
  tipoAforoValue: any;
  itemRecinto: any;

  MY_FORMATS = {
    parse: {
      dateInput: 'LL',
    },
    display: {
      dateInput: 'DD-MM-YYYY',
      monthYearLabel: 'YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'YYYY',
    },
  };

  constructor(private servicioAPI: ConectarAPIService,
    private alertaServicio: AlertaService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private autentificador: AutentificacionService,
    private router: Router,
    private _ruta: ActivatedRoute,
    public globales: Globales,
    private translator: Translator) {
  }

  @HostBinding('class.col') true;
  model = new traspasomodelo();

  async ngOnInit() {
    this.translation = this.translator.GetTranslations();

    this.displayedFilters = [this.translation.NombreDeLaSesion, this.translation.Recinto, this.translation.TipoSesion, this.translation.FechaInicio, this.translation.Activa];
    this.filtersToDisplay = this.displayedFilters.slice();

    //Comprobamos logueo en QueryString
    this.autentificador.verificarAccesoQueryString(this._ruta).then(
      //montamos los segmentos de la Ruta uno a uno porque la URL contiene los parametros y no nos sirve
      () => {
        let ruta = '/';
        this._ruta.snapshot.pathFromRoot[this._ruta.snapshot.pathFromRoot.length - 1].url.forEach(elemento => {
          ruta = ruta + elemento.path + '/';
        });
        ruta = ruta.substring(0, ruta.length - 1);
        this.permisoFormulario = this.autentificador.visualizacionAccion(ruta, 'VER');
        //
        if (!this.permisoFormulario) {
          this.router.navigate(['/portada']);
        }
        ;
        this.permisobtnNuevo = this.autentificador.visualizacionAccion(ruta, 'NUEVO');
        this.permisobtnGuardar = this.autentificador.visualizacionAccion(ruta, 'MODIFICAR');
        this.permisobtnEliminar = this.autentificador.visualizacionAccion(ruta, 'ELIMINAR');
        this.permisobtnDuplicar = this.autentificador.visualizacionAccion(ruta, 'DUPLICAR');
        this.nombreCabecera = this.autentificador.devolverNombre(ruta);
        //Creamos el desplegable de acciones
        this.Acciones = new Array<AccionesDialogo>();
        if (this.permisobtnEliminar) {
          this.Acciones.push({ accion: 'borrar', texto: this.translation.Borrar, funcion: 'BorrarSeleccionados' });
        }
      }
    );

    this.editorRegistroForm = this.formBuilder.group({
      nombre: ['', Validators.required],
      recinto: [''],
      tiposesion: [''],
      activa: [''],
      bloqueada: [''],
      idiomaActividad: [''], // TODO::: falta la peticion ObtenerIdiomas para cargar las selecciones del desplegable
      // TODO::: faltan los idiomas (ponlo igual que en recintos)
      aforomaximo: ['', [Validators.maxLength(6), this.RangoValidador_Aforo(0, parseInt(this.recintoSeleccionado.AforoMaximo))]],
      aforominimo: ['', [Validators.maxLength(6), this.RangoValidador_Aforo(0, parseInt(this.recintoSeleccionado.AforoMaximo))]],
      aforointernet: ['', [Validators.maxLength(6), this.RangoValidador_AforoInternet(0, parseInt(this.recintoSeleccionado.MaximoInternet))]],
      numerogrupos: ['', [Validators.pattern('^-?[0-9]*$'), Validators.min(0)]],
      fechaDesde: ['', Validators.required],
      fechaHasta: ['', Validators.required],
      horainicio: ['', Validators.required],
      horafin: ['', Validators.required],
      intervaloAforo: [''],
      duracionhora: ['', Validators.maxLength(2)],
      duracionminuto: ['', Validators.maxLength(2)],
    });

    //TODO  Validators
    this.editorFiltrosForm = this.formBuilder.group({
      nombre: [''],
      tipoControl: [''],
      tipoControltSesion: [''],
      UOdesde: [''],
      UOhasta: [''],
      activa: ['']
    });
    this.sort = new MatSort;
    this.paginator =new MatPaginator(new MatPaginatorIntl,null);
    //Para enlazar los cambios de pagina de tabla a un cambio de datos
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.cargarDatosAsync(
            this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize);
        }),
        map(() => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.resultsLength = parseInt(this.dataSource.data[0].TotalRegistros);//data.total_count;
          //
          return true;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the API has reached its rate limit. Return empty data.
          return observableOf([]);
        })
      ).subscribe();

    this.resetearFiltrosActivos();
    this.formControlListaColumnas = new FormControl(this.displayedColumns);
    this.formControlListaFiltros = new FormControl(this.displayedFilters);

  }

  // un getter para facilitar el acceso a los campos del form
  get f() {
    return this.editorRegistroForm.controls;
  } //Editor
  get fFiltro() {
    return this.editorFiltrosForm.controls;
  } //Filtros


  x() {
    
  }
  AnadirRegistro() {
    Object.keys(this.editorRegistroForm.controls).forEach(key => {
      this.editorRegistroForm.get(key).reset();
    });
    this.editorRegistroForm.controls['recinto'].enable();
    this.funcion = 'I'
    this.creando = true;
    this.PkId = '';
    this.listaObtenerIdiomas = new Array<IdiomasconURL>();
    this.tiposIdiomas.forEach(idioma => {
      this.listaObtenerIdiomas.push(<IdiomasconURL>{ CodIdioma: idioma.CodIdioma, Nombre: null, Descripcion: null, URL: null });
    });
    this.model = new traspasomodelo();
    this.editando = true;
    this.alertaServicio.limpiar();
    //Ponemos los booleanos a false, pqu si no se modifican el check no les da valor
    this.model.Creado = false;
    this.model.Activa = false;
    this.model.Didactica = false;

    this.editorListaIdiomas = new Array<IdiomasconURL>();
    this.tiposIdiomas.forEach(idioma => {
      this.editorListaIdiomas.push(<IdiomasconURL>{ CodIdioma: idioma.CodIdioma, Nombre: null, Descripcion: null, URL: null });
    });


    //
    this.editorRegistroForm.controls['activa'].setValue(false);
    this.editorRegistroForm.controls['aforointernet'].setValue('');
    this.editorRegistroForm.controls['aforomaximo'].setValue('');
    this.editorRegistroForm.controls['aforominimo'].setValue('');
    this.editorRegistroForm.controls['bloqueada'].setValue(false);
    this.editorRegistroForm.controls['duracionhora'].setValue('');
    this.editorRegistroForm.controls['duracionminuto'].setValue('');
    this.editorRegistroForm.controls['idiomaActividad'].setValue('');
    this.editorRegistroForm.controls['nombre'].setValue('');
    this.editorRegistroForm.controls['recinto'].setValue('');
    this.editorRegistroForm.controls['tiposesion'].setValue('');
    //
  }

  editar(item) {
    this.PkId = item.pkId;
    let objtraspaso = new traspasomodelo();
    objtraspaso.pkId = item.pkId;
    objtraspaso.Recinto = item.Recinto;
    objtraspaso.TipoSesion = item.TipoSesion;
    objtraspaso.Fecha = item.Fecha;
    objtraspaso.Descripcion = item.Descripcion;
    objtraspaso.HoraInicio = item.HoraInicio == null || item.HoraInicio.length == 0 ? null : item.HoraInicio.substr(10, 20).trim();
    objtraspaso.HoraFin = item.HoraFin == null || item.HoraFin.length == 0 ? null : item.HoraFin.substr(10, 20).trim();
    objtraspaso.Reservadas = item.Reservadas;
    objtraspaso.Vendidas = item.Vendidas;
    objtraspaso.Creado = this.globales.aBooleano(item.Creado);
    objtraspaso.Activa = this.globales.aBooleano(item.Activa);
    objtraspaso.ControlaHorario = item.ControlaHorario;
    objtraspaso.LimiteAforo = item.LimiteAforo;
    objtraspaso.Didactica = this.globales.aBooleano(item.Didactica);
    objtraspaso.MaximoAforo = item.MaximoAforo;
    objtraspaso.MaximoInternet = item.MaximoInternet;
    objtraspaso.VendidasInternet = item.VendidasInternet;
    objtraspaso.SesionBloqueada = item.SesionBloqueada;
    objtraspaso.NumSesion = item.NumSesion;
    objtraspaso.AforoInicial = item.AforoInicial;
    objtraspaso.FechaHasta = item.FechaHasta;

    this.LabelDisponibleOnlinetxt = this.showDisponible(item).split('/')[1];
    this.LabelDisponibleTotaltxt = this.showDisponible(item).split('/')[0];
    this.model = objtraspaso;
    this.editando = true;
    //
    this.alertaServicio.limpiar();
    Object.keys(this.editorRegistroForm.controls).forEach(field => { // {1}
      const control = this.editorRegistroForm.get(field);            // {2}
      control.markAsTouched({ onlySelf: true });       // {3}
    });
  }

  guardar() {
    this.isLoadingResults = true;
    let insertando = this.model.pkId == null;
    let globales: Globales = new Globales();
    if (!this.editorRegistroForm.invalid) {
      //Comprobamos que el Pkid no sea null (estariamos insertando)
      let objtraspaso = new traspasomodelo;
      if (this.model.pkId != null) {
        objtraspaso = this.dataSource.data.map<traspasomodelo>(function (item) {
          return {
            pkId: item.pkId,
            Recinto: item.Recinto,
            TipoSesion: item.TipoSesion,
            Fecha: item.Fecha,
            Descripcion: item.Descripcion,
            HoraInicio: item.HoraInicio,
            HoraFin: item.HoraFin,
            Reservadas: item.Reservadas,
            Vendidas: item.Vendidas,
            Creado: globales.aBooleano(item.Creado),
            Activa: globales.aBooleano(item.Activa),
            ControlaHorario: item.ControlaHorario,
            LimiteAforo: item.LimiteAforo,
            Didactica: globales.aBooleano(item.Didactica),
            MaximoAforo: item.MaximoAforo,
            MaximoInternet: item.MaximoInternet,
            VendidasInternet: item.VendidasInternet,
            SesionBloqueada: item.SesionBloqueada,
            NumSesion: item.NumSesion,
            AforoInicial: item.AforoInicial,
            FechaHasta: null,
            deserialize: null
          };
        }).find(item => item.pkId == this.model.pkId);
      }
      objtraspaso.Recinto = this.model.Recinto;
      objtraspaso.TipoSesion = this.model.TipoSesion;
      objtraspaso.Fecha = this.model.Fecha;
      objtraspaso.Descripcion = this.model.Descripcion;
      objtraspaso.HoraInicio = this.model.HoraInicio;
      objtraspaso.HoraFin = this.model.HoraFin;
      objtraspaso.Reservadas = this.model.Reservadas;
      objtraspaso.Vendidas = this.model.Vendidas;
      objtraspaso.Creado = this.model.Creado;
      objtraspaso.Activa = this.model.Activa;
      objtraspaso.ControlaHorario = this.f.numerogrupos.value == undefined ? '' : this.f.numerogrupos.value.toString();
      objtraspaso.LimiteAforo = this.model.LimiteAforo;
      objtraspaso.Didactica = this.model.Didactica;
      objtraspaso.MaximoAforo = this.model.MaximoAforo;
      objtraspaso.MaximoInternet = this.model.MaximoInternet;
      objtraspaso.VendidasInternet = this.model.VendidasInternet;
      objtraspaso.SesionBloqueada = this.model.SesionBloqueada;
      objtraspaso.NumSesion = this.model.NumSesion;
      objtraspaso.AforoInicial = this.model.AforoInicial;
      // objtraspaso.Duracion = this.uneHM(
      //   this.f.duracionhora.touched?this.f.duracionhora.value.toString():this.DuracionHoras.toString(),
      //   this.f.duracionminuto.touched?this.f.duracionminuto.value.toString():this.DuracionMinutos.toString()).toString();
      objtraspaso.FechaHasta = this.model.FechaHasta;
      //Salvar a API
      this.servicioAPI.insUpdSesion(
        this.model.pkId,
        this.model.Recinto,
        this.model.TipoSesion,
        this.model.Fecha,
        this.model.Descripcion,
        '01/01/1900 ' + this.model.HoraInicio + ':00',
        '01/01/1900 ' + this.model.HoraFin + ':00',
        this.model.Reservadas,
        this.model.Vendidas,
        this.globales.aBit(this.model.Creado).toString(),
        this.globales.aBit(this.model.Activa).toString(),
        this.f.numerogrupos.value == undefined ? '' : this.f.numerogrupos.value.toString(),
        this.model.LimiteAforo,
        this.globales.aBit(this.model.Didactica).toString(),
        this.model.MaximoAforo,
        this.model.MaximoInternet,
        this.model.VendidasInternet,
        this.globales.aBit(this.model.SesionBloqueada).toString(),
        this.model.NumSesion,
        this.model.AforoInicial,
        this.model.FechaHasta)
        .pipe(first())
        .subscribe(
          data => {
            this.respuestaAPI = new Sesion_Respuesta().deserialize(data);
            if (this.respuestaAPI.DatosResult != null) {
              this.alertaServicio.success('Datos Guardados Correctamente');
              //
              //Hacemos un push al data para que se muestre
              if (insertando) {
                // this.model.pkId = ''+(max + 1);
                // let pusheddata =   this.dataSource.data;
                // pusheddata .push(this.modeloaclaseAPI(this.model));
                // this.dataSource.data = pusheddata;
                this.cargarDatosAsync(this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize);
              } else {
                this.cargarDatosAsync(this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize);
              }
              this.isLoadingResults = false;
              this.editando = false;
            } else {
              this.alertaServicio.error(this.respuestaAPI.Mensajes[0].DescripcionMensaje);
              this.isLoadingResults = false;
              this.editando = false;
            }

          },
          error => {
            this.alertaServicio.error(error);
            this.isLoadingResults = false;
            this.editando = false;
          }
        );

    }
  }

  duplicarRegistro() {
    //this.model = new CustomanUsuario();
    if (this.model.pkId != null) {
      this.model.pkId = null; //si no estamos ya con uno nuevo, ponemos el Pkid a null y lo trataremos como insercion.
    }
    this.creando = false;
  }

  pregborrarRegistro() {
    //Borrar
    if (this.model.pkId != null) {//Si no estamos añadiendo
      this.titulo_dialogo = this.translation.Borrar;
      this.mensaje_dialogo = this.translation.EstaSeguroQueDeseaBorrarEsteRegistro;
      this.ok_dialogo = this.translation.Si;
      this.ko_dialogo = this.translation.Cancelar;
      this.openDialog();
    }
  }

  async applyFilter(filterValue: string) {
    this.filtrosactivosModelo.textoGeneral = filterValue.trim();
    // this.alertaServicio.limpiar();
    await this.AplicarFiltros();
    //this.dataSource.filter = filterValue.trim().toLowerCase();

    //this.filtrosactivosModelo.textoGeneral = filterValue.trim();
    this.filtrosactivos = Object.assign({}, this.filtrosactivosModelo);


    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    // this.alertaServicio.limpiar();
  }

  //Acciones
  HacerAccion(any) {
    this.alertaServicio.limpiar();
    if (any != null && any != undefined) {
      if (this.seleccion.selected == null || this.seleccion.selected.length == 0) {
        this.titulo_dialogo = this.translation.Error;
        this.mensaje_dialogo = this.translation.NoHayRegistrosSeleccionadosParaEstaAccion;
        this.ok_dialogo = this.translation.Ok;
        this.openDialogSeleccionVacia();
        //
      } else {
        this.titulo_dialogo = this.translation.ConfirmarBorrado;
        this.mensaje_dialogo = this.translation.VaAProcederAlBorradoDe + this.seleccion.selected.length.toString() + this.translation.RegistrosDeseaConfirmarElBorrado;
        this.ok_dialogo = this.translation.Si;
        this.ko_dialogo = this.translation.No;
        this.openDialogBorradoSeleccionadas();
      }
    }

  }

  MostrarFiltros() {
    this.alertaServicio.limpiar();
    this.panelSelectorFiltrosvisible = !this.panelSelectorFiltrosvisible;
    this.panelColumnasvisible = false;
    this.formControlListaFiltros = new FormControl(this.filtersToDisplay);
  }

  MostrarColumnas() {
    this.alertaServicio.limpiar();
    this.panelColumnasvisible = !this.panelColumnasvisible;
    //Cambiar el boton
    this.formControlListaColumnas = new FormControl(this.columnsToDisplay);
  }

  visibilidadColumna(evento, seleccioncolumnas) {
    if (evento != null && seleccioncolumnas != null) {
      //

      if (!evento.option.selected) {
        //Borramos si no nos quedamos sin columnas
        if (this.columnsToDisplay.length) {
          this.columnsToDisplay.splice(this.columnsToDisplay.findIndex(x => x === evento.option.value), 1);
        }
      } else {
        //Añadimos
        this.columnsToDisplay = [];
        for (let columna of this.displayedColumns) {
          if (seleccioncolumnas.findIndex(x => x.value === columna) >= 0) {
            this.columnsToDisplay.push(columna);
          }
        }
        // for(let a of seleccioncolumnas) {
        //   
      }
    }
  }


  //Click en el boton de aplicar Filtros a los datos
  async AplicarFiltros(filtro?: filtrosActivos) {
    this.alertaServicio.limpiar();
    //CArgamos los datos de muevo solo porsiacaso
    this.isLoadingResults = true;
    // await this.cargarDatosAsync().then(
    //   respuesta => {
    //       let siono = new Boolean(respuesta);
    //       //
    //       if(siono)
    //         {
    //let datostemporales = [];//this.dataSource.data;
    //aplicamos los filtros sobre los datos temporales
    if (filtro != null) {
      if (filtro.Nombre != null && filtro.Nombre.trim() != '') {
        //datostemporales = datostemporales.filter(x => x.Descripcion.indexOf(filtro.Nombre.trim()) >= 0);
        //Meter en FiltrosActivos
        this.filtrosactivosModelo.Nombre = filtro.Nombre.trim();
      }
      this.fFiltro['nombre'].setValue(filtro.Nombre.trim());

      if (filtro.CodigoTipoRecinto != null && filtro.CodigoTipoRecinto != '') {
        //datostemporales = datostemporales.filter(x => x.Recinto == filtro.CodigoTipoRecinto);
        //Meter en FiltrosActivos
        this.filtrosactivosModelo.CodigoTipoRecinto = filtro.CodigoTipoRecinto;
      }
      this.fFiltro['tipoControl'].setValue(filtro.CodigoTipoRecinto);

      if (filtro.CodigoTipoSesion != null && filtro.CodigoTipoSesion != '') {
        //datostemporales = datostemporales.filter(x => x.TipoSesion == filtro.CodigoTipoSesion);
        //Meter en FiltrosActivos
        this.filtrosactivosModelo.CodigoTipoSesion = filtro.CodigoTipoSesion;
      }
      this.fFiltro['tipoControltSesion'].setValue(filtro.CodigoTipoSesion);

      if (filtro.Activa != null && filtro.Activa.trim() != '') {
        //datostemporales = datostemporales.filter(x => x.Activo.trim().toLowerCase() == (filtro.Activa.trim().toLowerCase() == "si" ? "1" : "0"));
        //Meter en FiltrosActivos
        this.filtrosactivosModelo.Activa = filtro.Activa.trim();
      }
      this.fFiltro['activa'].setValue(filtro.Activa.trim());

      if ((filtro.desde != null || filtro.hasta != null) && (filtro.desde.trim() != '' || filtro.hasta.trim() != '')) {
        //datostemporales = datostemporales.filter(x => this.aFecha(x.Fecha.substr(0, 10)) >= (this.aFecha((filtro.desde.trim() == "" ? "01/01/1970 00:00:00" : filtro.desde.trim() + " 00:00:00"))) &&
        //  this.aFecha(x.Fecha.substr(0, 10)) <= (this.aFecha(filtro.hasta.trim() == "" ? "31/12/2999 23:59:50" : filtro.hasta.trim() + " 23:59:50")));
        //Desde Hasta por Fechas
        this.filtrosactivosModelo.desde = filtro.desde.trim();
        this.filtrosactivosModelo.hasta = filtro.hasta.trim();
      }
      this.fFiltro['UOdesde'].setValue(this.aFecha(filtro.desde.trim()));
      this.fFiltro['UOhasta'].setValue(this.aFecha(filtro.hasta.trim()));


    } else {

      if (this.fFiltro['nombre'].value != null && this.fFiltro['nombre'].value.trim() != '') {
        //datostemporales = datostemporales.filter(x => x.Descripcion.indexOf(this.fFiltro["nombre"].value.trim()) >= 0);
        //Meter en FiltrosActivos
        this.filtrosactivosModelo.Nombre = this.fFiltro['nombre'].value;
      }

      if (this.fFiltro['tipoControl'].value != null && this.fFiltro['tipoControl'].value != '') {
        //datostemporales = datostemporales.filter(x => x.Recinto == this.fFiltro["tipoControl"].value);
        //Meter en FiltrosActivos
        this.filtrosactivosModelo.CodigoTipoRecinto = this.fFiltro['tipoControl'].value;
      }

      if (this.fFiltro['tipoControltSesion'].value != null && this.fFiltro['tipoControltSesion'].value != '') {
        //datostemporales = datostemporales.filter(x => x.TipoSesion == this.fFiltro["tipoControltSesion"].value);
        //Meter en FiltrosActivos
        this.filtrosactivosModelo.CodigoTipoSesion = this.fFiltro['tipoControltSesion'].value;
      }

      if (this.fFiltro['activa'].value != null && this.fFiltro['activa'].value.trim() != '') {
        //datostemporales = datostemporales.filter(x => x.Activo.trim().toLowerCase() == (this.fFiltro["activa"].value.trim().toLowerCase() == "si" ? "1" : "0"));
        //Meter en FiltrosActivos
        this.filtrosactivosModelo.Activa = this.fFiltro['activa'].value;
      }

      if ((this.fFiltro['UOdesde'].value != null || this.fFiltro['UOhasta'].value != null) && (this.fFiltro['UOdesde'].value != '' || this.fFiltro['UOhasta'].value != '')) {
        let FechasDesde = new DatePipe('es').transform((new Date(this.fFiltro['UOdesde'].value)), 'dd/MM/yyyy').trim();
        let FechasHasta = new DatePipe('es').transform((new Date(this.fFiltro['UOhasta'].value)), 'dd/MM/yyyy').trim();
        //datostemporales = datostemporales.filter(x => this.aFecha(x.Fecha.substr(0, 10)) >= (this.aFecha(FechasDesde == "" ? "01/01/1970 00:00:00" : FechasDesde + " 00:00:00")) &&
        //  this.aFecha(x.Fecha.substr(0, 10)) <= (this.aFecha(FechasHasta == "" ? "31/12/2999 23:59:50" : FechasHasta + " 23:59:50")));
        //Meter en FiltrosActivos
        this.filtrosactivosModelo.desde = FechasDesde;
        this.filtrosactivosModelo.hasta = FechasHasta;
      }

    }


    await this.cargarDatosAsync(this.paginator.pageIndex, this.paginator.pageSize).then(
      respuesta => {
        let siono = new Boolean(respuesta);
        //
        if (siono) {
          //let datostemporales = this.dataSource.data;
          //aplicamos los filtros sobre los datos temporales


          //this.dataSource.data = datostemporales;

          //if (this.filtrosactivosModelo.textoGeneral != null && this.filtrosactivosModelo.textoGeneral.trim() != "") {
          //  this.dataSource.filter = this.filtrosactivosModelo.textoGeneral.trim().toLowerCase();
          //}
          //else {

          //}

          

          this.filtrosactivos = Object.assign({}, this.filtrosactivosModelo);
          this.resultsLength = parseInt(this.dataSource.data[0].TotalRegistros);//data.total_count;
          if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
          }

        } else {
          //No hemos podido cargar los datos
        }
        //Montamos los FormControlArray

      },
      error => {
        //No hemos podido cargar los datos
      });


  }

  //Para resetear filtros, se pasa el filtro o se ejecuta sin parametro y se resetean todos
  resetearFiltrosActivos(filtro?: string) {
    this.filtrosactivosModelo.Nombre = filtro == null || filtro == 'Nombre' ? '' : this.filtrosactivosModelo.Nombre;
    this.filtrosactivosModelo.desde = filtro == null || filtro == 'fecha' ? '' : this.filtrosactivosModelo.desde;
    this.filtrosactivosModelo.hasta = filtro == null || filtro == 'fecha' ? '' : this.filtrosactivosModelo.hasta;
    this.filtrosactivosModelo.CodigoTipoRecinto = filtro == null || filtro == 'CodigoTipoRecinto' ? null : this.filtrosactivosModelo.CodigoTipoRecinto;
    this.filtrosactivosModelo.CodigoTipoSesion = filtro == null || filtro == 'CodigoTipoSesion' ? null : this.filtrosactivosModelo.CodigoTipoSesion;
    this.filtrosactivosModelo.Activa = filtro == null || filtro == 'Activa' ? '' : this.filtrosactivosModelo.Activa;
    this.filtrosactivosModelo.textoGeneral = filtro == null || filtro == 'General' ? '' : this.filtrosactivosModelo.textoGeneral;
    this.filtrosactivos = Object.assign({}, this.filtrosactivosModelo);
    this.AplicarFiltros(this.filtrosactivos);
    if (this.editorFiltrosForm != null) {
      this.editorFiltrosForm.updateValueAndValidity({ onlySelf: true });
      this.editorFiltrosForm.markAsPristine();
      //
    }

  }

  hayFiltros(filtros: filtrosActivos): boolean {
    if ((filtros.Nombre != null && filtros.Nombre.trim() != '') || (filtros.desde != null && filtros.desde.trim() != '') ||
      (filtros.hasta != null && filtros.hasta.trim() != '') || (filtros.CodigoTipoRecinto != null && filtros.CodigoTipoRecinto.trim() != '') ||
      (filtros.CodigoTipoSesion != null && filtros.CodigoTipoSesion.trim() != '') ||
      (filtros.Activa != null && filtros.Activa.trim() != '') ||
      (filtros.textoGeneral != null && filtros.textoGeneral.trim() != '')) {
      return true;
    } else {
      return false;
    }
    //WOIT
  }

  //Para que funcione le mat select cuando al option le metes objetos en lugar de strings simples
  comparaObjetosSelect(o1: any, o2: any) {
    return o1 && o2 ? o1.id === o2.id || o1 === o2.id || o1.id === o2 || o1.pkId === o2.pkId || o1.PkId === o2.PkId : o1 === o2;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.seleccion.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.seleccion.clear() :
      this.dataSource.data.forEach(row => this.seleccion.select(row));
  }

  ///Validador

  RangoValidador_Aforo(min: number, max: number): ValidatorFn {

    return (control: AbstractControl): { [key: string]: boolean } | null => {

      if (control.value !== undefined && (isNaN(control.value) || control.value < min || control.value > max)) {

        return { 'noenRango': true };

      }

      return null;

    };

  }

  RangoValidador_AforoInternet(min: number, max: number): ValidatorFn {

    return (control: AbstractControl): { [key: string]: boolean } | null => {

      if (control.value !== undefined && (isNaN(control.value) || control.value < min || control.value > max)) {

        return { 'noenRango': true };

      }

      return null;

    };

  }


  CambioDiasSemanaMultiple() {
    //
    //En principio no lo vamos a usar
  }

  ///Funcionalidades
  traeNombreRecintoxID(id) {
    if (this.tiposRecintos == null || id == null) {
      return null;
    } else {
      let respuesta = this.tiposRecintos.filter(x => x.PkId == id);
      return respuesta == null || respuesta.length == 0 ? null : respuesta[0].Nombre;
    }
  }

  traeNombreTipoSesionxID(id) {
    if (this.tiposTiposSesion == null || id == null) {
      return null;
    } else {
      let respuesta = this.tiposTiposSesion.filter(x => x.pkId == id);
      return respuesta == null || respuesta.length == 0 ? null : respuesta[0].Descripcion;
    }
  }

  traeTipoAforoRecintoxID(id) {
    if (this.tiposRecintos == null || id == null) {
      return null;
    } else {
      let respuesta = this.tiposRecintos.filter(x => x.PkId == id);
      return respuesta == null || respuesta.length == 0 ? null : respuesta[0].TipoAforo;
    }
  }

  CambioRecintoSeleccionado(evento) {
    //
    this.seleccionaRecinto(evento.value);
    // TODO::: actualiza tiposTiposSesion con las sesiones del recinto seleccionado (escanea los tipos de sesion buscando los que coinciden con el recinto)
    // this.respuestaAPITiposSesion
    // this.tiposTiposSesion = this.respuestaAPITiposSesion.DatosResult.Lista;
    // this.tiposRecintos
    
    
    
    this.tiposTiposSesion = [];
    this.respuestaAPITiposSesion.DatosResult.Lista.forEach(sesion => {
      if (sesion.Recinto === evento.value) {
        this.tiposTiposSesion.push(sesion);
      }
    });

  }

  seleccionaRecinto(id) {
    this.recintoSeleccionado = Object.assign({}, this.tiposRecintos.find(x => x.PkId == id));
    // this.f.aforomaximo.setValidators([Validators.maxLength(6), this.RangoValidador_Aforo(0, parseInt(this.recintoSeleccionado.AforoMaximo))]);
    // this.f.aforointernet.setValidators([Validators.maxLength(6), this.RangoValidador_Aforo(0, parseInt(this.recintoSeleccionado.MaximoInternet))]);
    //
  }

  CambioTipoSesionSeleccionado(evento) {
    this.seleccionaTipoSesion(evento.value);
  }

  seleccionaTipoSesion(id) {
    this.tiposesionSeleccionado = Object.assign({}, this.tiposTiposSesion.find(x => x.pkId == id));
    
    this.LabelDisponibleTotaltxt = this.tiposesionSeleccionado.AforoMaximo;
    this.LabelDisponibleOnlinetxt = this.tiposesionSeleccionado.MaximoInternet;
    this.model.MaximoAforo  = this.tiposesionSeleccionado.AforoMaximo;
    this.editorRegistroForm.controls['aforominimo'].setValue(this.tiposesionSeleccionado.MinimoAforo)
    this.model.MaximoInternet  = this.tiposesionSeleccionado.MaximoInternet;
    this.tipoAforoValue = this.tipoSesionKeyValue(this.tiposesionSeleccionado.chTipoSesion);
    this.f.aforomaximo.setValidators([Validators.maxLength(6), this.RangoValidador_Aforo(0, parseInt(this.tiposesionSeleccionado.AforoMaximo))]);
    this.f.aforointernet.setValidators([Validators.maxLength(6), this.RangoValidador_Aforo(0, parseInt(this.tiposesionSeleccionado.MaximoInternet))]);
  }

  tipoSesionKeyValue(chTipoSesion) {
    
    
    this.globales.tiposAforo.find(item => item.id === chTipoSesion);
    
    return this.globales.tiposAforo.find(item => item.id === chTipoSesion).value;
  }

  // filtrarTSesionUsados(tsesion:TipoSesion[])
  // {
  //   if (tsesion == null)
  //   {return [];}else{
  //     let respuesta =tsesion.filter(x=> this.dataSource.data.findIndex(y=> y.TipoSesion == x.pkId) >= 0 )
  //     return respuesta; //== null || respuesta.length == 0? null: respuesta;
  //   }
  // }

  // filtrarRecintosUsados(recintos:Recintos[])
  // {
  //   if (recintos == null)
  //   {return [];}else{
  //     let respuesta =recintos.filter(x=> this.dataSource.data.findIndex(y=> y.Recinto == x.PkId) >= 0 )
  //     return respuesta; //== null || respuesta.length == 0? null: respuesta;
  //   }
  // }

  filtrarRecintosSinButacas(recintos: Recintos[]) {
    if (recintos == null) {
      return [];
    } else {
      let respuesta = recintos.filter(x => x.Butacas != '1');
      return respuesta; //== null || respuesta.length == 0? null: respuesta;
    }
  }

  cargarDatos() {
    //Cargamos Datos
    this.servicioAPI.getRecintos().pipe(first())
      .subscribe(
        data => {
          this.respuestaAPIRecintos = new Recintos_Respuesta().deserialize(data);
          if (this.respuestaAPIRecintos.DatosResult != null) {
            this.tiposRecintos = this.respuestaAPIRecintos.DatosResult.ListaRecintos.filter(x => x.ControlarAforo == '1' || x.ControlarAforoReserva == '1');
            this.servicioAPI.getSesion().pipe(first())
              .subscribe(
                data => {
                  this.respuestaAPI = new Sesion_Respuesta().deserialize(data);
                  if (this.respuestaAPI.DatosResult != null) {
                    this.dataSource = new MatTableDataSource(this.respuestaAPI.DatosResult.Lista);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                    //
                    this.isLoadingResults = false;
                  } else {
                    this.alertaServicio.error(this.respuestaAPI.Mensajes[0].DescripcionMensaje);
                    this.isLoadingResults = false;
                  }

                },
                error => {
                  this.alertaServicio.error(error);
                  this.isLoadingResults = false;
                }
              );
          } else {
            this.alertaServicio.error(this.respuestaAPIRecintos.Mensajes[0].DescripcionMensaje);
            this.isLoadingResults = false;
          }

        },
        error => {
          this.alertaServicio.error(error);
          this.isLoadingResults = false;
        }
      );
  }

  async cargarDatosAsync(orden?, direccion?, pagina?, tamanopagina?): Promise<boolean> {
    this.isLoadingResults = true;
    //Cargamos Datos
    let resultado: boolean = false;
    await this.servicioAPI.getSesionAsync(null, this.filtrosactivosModelo.Nombre == null || this.filtrosactivosModelo.Nombre.trim() == '' ? null : this.filtrosactivosModelo.Nombre
      , orden, direccion, pagina == null ? 1 : pagina + 1, tamanopagina == null ? 10 : tamanopagina,
      this.filtrosactivosModelo.Activa == null || this.filtrosactivosModelo.Activa.trim() == '' || this.filtrosactivosModelo.Activa == '0' ? null : this.filtrosactivosModelo.Activa,
      this.filtrosactivosModelo.hasta == null || this.filtrosactivosModelo.hasta.trim() == '' ? null : this.filtrosactivosModelo.hasta,
      this.filtrosactivosModelo.desde == null || this.filtrosactivosModelo.desde.trim() == '' ? null : this.filtrosactivosModelo.desde,
      null, null,
      this.filtrosactivosModelo.CodigoTipoRecinto == null || this.filtrosactivosModelo.CodigoTipoRecinto.trim() == '' || this.filtrosactivosModelo.CodigoTipoRecinto == '0' ? null : this.filtrosactivosModelo.CodigoTipoRecinto,
      this.filtrosactivosModelo.CodigoTipoSesion == null || this.filtrosactivosModelo.CodigoTipoSesion.trim() == '' || this.filtrosactivosModelo.CodigoTipoSesion == '0' ? null : this.filtrosactivosModelo.CodigoTipoSesion,
      null, this.filtrosactivosModelo.textoGeneral
    )
      .then(
        data => {
          this.respuestaAPI = new Sesion_Respuesta().deserialize(data);
          
          if (this.respuestaAPI.DatosResult != null) {
            this.dataSource = new MatTableDataSource(this.respuestaAPI.DatosResult.Lista);
            //this.dataSource.paginator = this.paginator;
            //this.dataSource.sort = this.sort;
            //
          } else {
            this.alertaServicio.error(this.respuestaAPI.Mensajes[0].DescripcionMensaje);
          }

        },
        error => {
          this.alertaServicio.error(error);
          resultado = false;
        }
      );
    //Lanzamos encadenado
    await this.servicioAPI.getRecintosAsync()
      .then(
        data => {
          this.respuestaAPIRecintos = new Recintos_Respuesta().deserialize(data);
          if (this.respuestaAPIRecintos.DatosResult != null) {
            this.tiposRecintos = this.respuestaAPIRecintos.DatosResult.ListaRecintos.filter(x => x.ControlarAforo == '1' || x.ControlarAforoReserva == '1');
            //
          } else {
            this.alertaServicio.error(this.respuestaAPIRecintos.Mensajes[0].DescripcionMensaje);
            resultado = false;
          }

        },
        error => {
          this.alertaServicio.error(error);
          resultado = false;
        }
      );
    //Lanzamos encadenado
    await this.servicioAPI.getTiposSesionAsync()
      .then(
        data => {
          this.respuestaAPITiposSesion = new TipoSesion_Respuesta().deserialize(data);
          if (this.respuestaAPITiposSesion.DatosResult != null) {
            this.tiposTiposSesion = this.respuestaAPITiposSesion.DatosResult.Lista;
            
            //
          } else {
            this.alertaServicio.error(this.respuestaAPITiposSesion.Mensajes[0].DescripcionMensaje);
            resultado = false;
          }

        },
        error => {
          this.alertaServicio.error(error);
          resultado = false;
        }
      );
    this.servicioAPI.getIdiomas(null, null, "1").subscribe(idiomas => {
      if (idiomas.DatosResult && idiomas.DatosResult.ListaIdiomas.length > 0) {
        this.tiposIdiomas = idiomas.DatosResult.ListaIdiomas;
      } else {
        this.tiposIdiomas = [];
        resultado = false;
      }
    });

    this.isLoadingResults = false;
    return resultado;
  }


  cambiadesde(evento) {
    //
    this.fecha_minHasta = evento.value;
    //this.model.Fecha = new DatePipe('es').transform(evento.value, 'dd/MM/yyyy').trim();
  }

  cambiahasta(evento) {
    //
    this.fecha_maxDesde = evento.value;
    //this.model.FechaHasta = new DatePipe('es').transform(evento.value, 'dd/MM/yyyy').trim();
  }

  getFechaInicio() {
    // return this.aFecha(this.model.Fecha);
  }

  getFechaInicioEditar(fecha): Date {
    let ans = fecha.split(' ');
    
    return this.aFecha(ans[0]);
  }

  getHoraInicioEditar(hora) {
    let ans = hora.split(' ');
    let res = ans[1].split(':');
    let newdate = new Date();
    newdate.setHours(parseInt(res[0]));
    newdate.setMinutes(parseInt(res[1]));

    var horas = newdate.getHours().toString();
    var minutos = newdate.getMinutes().toString();

    if (horas.length !== 2) {
      horas = '0'.concat(horas);
    }

    if (minutos.length !== 2) {
      minutos = '0'.concat(minutos);
    }

    return horas + ':' + minutos;
  }

  getFechaFin() {
    // return this.aFecha(this.model.FechaHasta);
  }

  showDisponible(item): string //Sesion
  {
    //
    //let respuesta: string = '';
    let maxInternet: number = Number(item.MaximoInternet) || 0;
    let maxTotal: number = Number(item.MaximoAforo) || 0;
    let numVendidasInet: number = Number(item.VendidasInternet) || 0;
    let numVendidasTaquilla: number = Number(item.Vendidas) || 0;
    let numReservadasInet: number = Number(item.Reservadas) || 0;
    let online = maxInternet - numVendidasInet - numReservadasInet;
    let total = maxTotal - numVendidasInet - numReservadasInet - numVendidasTaquilla;
    return (total != null ? total.toString() : '') + ' / ' + (online != null ? online.toString() : '');
  }

  cambiaHoradesde(evento) {
    //
    this.objedicionCalendarioHorario.FechaAcceso = evento.target.value + ':00';
  }

  cambiaHorahasta(evento) {
    //
    this.objedicionCalendarioHorario.FechaAccesoFin = evento.target.value + ':00';
  }


  checkCodigoNoUsado() {
    // if (this.dataSource == null || this.dataSource.data == null || this.dataSource.data.length == 0)
    // {
    //   return [{codigoNOUsado:true}];
    // }
    // else
    // {
    //   return [{codigoNOUsado:this.dataSource.data.filter(x => x.CodigoTipoControl.trim() == codigoID.trim()) == null}];
    // }

  }

  estaUsado() {
    // return (control: AbstractControl) => {
    //   return this.checkCodigoNoUsado(control.value).map(res => {
    //     return res.codigoNOUsado ? null : {codigoUsado: true};
    //   });
    // }
  }

  aFecha(fecha: string): Date {
    if (fecha == null || fecha.length == 0) {
      return null;
    } else {
      var parts = fecha.split('/');
      return new Date(parseInt(parts[2].substring(0, 4)), parseInt(parts[1]) - 1, parseInt(parts[0]));
    }
  }

  separaHM(valor: number, tipo: string): number {
    let hora = this.globales.truncarDecimales(valor / 60);
    if (tipo == 'H') {
      return hora;
    } else {
      return valor - (hora * 60);
    }
  }

  uneHM(hora: string, minuto: string) {
    return (parseInt(hora) * 60) + parseInt(minuto);
  }

  //Cambiar el modelo API (string) a modelo con boolean para los checks
  modeloaclaseAPI(item: traspasomodelo): Sesion {
    let respuesta = new Sesion;
    respuesta.pkId = item.pkId;
    respuesta.Recinto = item.Recinto;
    respuesta.TipoSesion = item.TipoSesion;
    respuesta.Fecha = item.Fecha;
    respuesta.Descripcion = item.Descripcion;

    respuesta.HoraInicio = item.HoraInicio;
    respuesta.HoraFin = item.HoraFin;
    respuesta.Reservadas = item.Reservadas;
    respuesta.Vendidas = item.Vendidas;
    respuesta.Creado = item.Creado ? '1' : '0';
    respuesta.Activa = item.Activa ? '1' : '0';
    respuesta.ControlaHorario = item.ControlaHorario ? '1' : '0';
    respuesta.LimiteAforo = item.LimiteAforo;
    respuesta.Didactica = item.Didactica ? '1' : '0';
    respuesta.MaximoAforo = item.MaximoAforo;
    respuesta.MaximoInternet = item.MaximoInternet;
    respuesta.VendidasInternet = item.VendidasInternet;
    respuesta.SesionBloqueada = item.SesionBloqueada ? '1' : '0';
    respuesta.NumSesion = item.NumSesion;
    respuesta.AforoInicial = item.AforoInicial;
    //respuesta.FechaHasta = item.FechaHasta;

    return respuesta;
  }

  //Se podria instanciar este openDialog si requiriesemos mas popups (este seria openDialogBorrar)
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: '250px',
      data: {
        titulo: this.titulo_dialogo,
        texto: this.mensaje_dialogo,
        eleccion: this.eleccion_dialogo,
        ok: this.ok_dialogo,
        ko: this.ko_dialogo
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      //
      this.eleccion_dialogo = result;
      //
      if (result == 'SI') {
        //Salvar a API
        this.servicioAPI.delSesion(
          this.model.pkId)
          .pipe(first())
          .subscribe(
            data => {
              this.respuestaAPI = new Sesion_Respuesta().deserialize(data);
              if (this.respuestaAPI.DatosResult != null) {
                this.alertaServicio.success('Datos Borrados Correctamente');
                //
                //this.dataSource.data.splice(,1)
                this.dataSource.data = this.dataSource.data.filter(item => item.pkId !== this.model.pkId);
              } else {
                this.alertaServicio.error(this.respuestaAPI.Mensajes[0].DescripcionMensaje);
              }

            },
            error => {
              this.alertaServicio.error(error);
            }
          );
        this.editando = false;
      }
    });
  }

  //Instancia para Accionse sobre seleccion vacia
  openDialogSeleccionVacia(): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: '250px',
      data: { titulo: this.titulo_dialogo, texto: this.mensaje_dialogo, eleccion: this.eleccion_dialogo, ok: this.ok_dialogo, ko: null }
    });

    dialogRef.afterClosed().subscribe(() => {
      //
      this.accionselecionada = null;
    });
  }

  //Instancia Borrado Seleccionadas
  openDialogBorradoSeleccionadas(): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: '250px',
      data: {
        titulo: this.titulo_dialogo,
        texto: this.mensaje_dialogo,
        eleccion: this.eleccion_dialogo,
        ok: this.ok_dialogo,
        ko: this.ko_dialogo
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      //
      this.eleccion_dialogo = result;
      //
      if (result == 'SI') {
        var resultado: string = '';
        this.seleccion.selected.forEach(
          element => {
            //Recorremos los seleccionados y vamos borrando
            this.servicioAPI.delSesionAsync(
              element.pkId)
              .then(
                data => {
                  this.respuestaAPI = new Sesion_Respuesta().deserialize(data);
                  if (this.respuestaAPI.DatosResult != null) {
                    this.dataSource.data = this.dataSource.data.filter(item => item.pkId !== element.pkId);
                  } else {
                    resultado += this.respuestaAPI.Mensajes[0].DescripcionMensaje + ' <BR />';
                  }
                },
                error => {
                  resultado += error + ' <BR />';
                }
              );
          });
        if (resultado != null && resultado.length > 0) {
          this.alertaServicio.error(resultado);
        } else {
          this.alertaServicio.success('Datos Borrados Correctamente');
        }

        //End For
      }
      //F Si
      this.seleccion.clear();
      this.accionselecionada = null;
    });
    //F Subscribe
  }

  duplicar(row: traspasomodelo) {
    this.model = row; // cambiar por los datos de la fila
    if (this.model.pkId != null) {
      this.model.pkId = null; //si no estamos ya con uno nuevo, ponemos el Pkid a null y lo trataremos como insercion.
    }
    this.editando = true;
    this.alertaServicio.limpiar();
  }

  borrar(pkId: string): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: '250px',
      data: {
        titulo: this.translation.BorrarCliente, texto: this.translation.EstaSeguroQueDeseaBorrarElRegistro,
        eleccion: this.translation.Patatas, ok: this.translation.Confirmar, ko: this.translation.Cancelar
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'SI') {
        this.servicioAPI.delSesion(
          pkId)
          .pipe(first())
          .subscribe(
            data => {
              this.respuestaAPI = new Sesion_Respuesta().deserialize(data);
              if (this.respuestaAPI.DatosResult != null) {
                this.alertaServicio.success('Datos Borrados Correctamente');
                //
                //this.dataSource.data.splice(,1)
                this.dataSource.data = this.dataSource.data.filter(item => item.pkId !== pkId);
              } else {
                this.alertaServicio.error(this.respuestaAPI.Mensajes[0].DescripcionMensaje);
              }

            },
            error => {
              this.alertaServicio.error(error);
            }
          );
        this.editando = false;
      }
    });
  }

  visibilidadFiltro(evento, seleccionfiltros) {
    if (evento != null && seleccionfiltros != null) {
      //

      if (!evento.option.selected) {
        //Borramos si no nos quedamos sin filtros
        if (this.filtersToDisplay.length) {
          this.filtersToDisplay.splice(this.filtersToDisplay.findIndex(x => x === evento.option.value), 1);
        }
      } else {
        //Añadimos
        this.filtersToDisplay = [];
        for (let filtro of this.displayedFilters) {
          if (seleccionfiltros.findIndex(x => x.value === filtro) >= 0) {
            this.filtersToDisplay.push(filtro);
          }
        }
        // for(let a of seleccionfiltros) {
        //   
      }
    }
  }

  //----------------vvvvsiguevvv

  newGuardar() {
    
    
    

    


    let body = {

      // Campos JSON
      "pkId": this.PkId,
      "Funcion": this.funcion,
      "Butacas": "0", // sin butacas

      // Nombre
      "Descripcion": this.editorRegistroForm.value.nombre,

      // Recinto
      "Recinto": this.editorRegistroForm.value.recinto === undefined ? this.itemRecinto : this.editorRegistroForm.value.recinto,

      // Tipo Sesion
      "TipoSesion": this.editorRegistroForm.value.tiposesion,

      // Sesion Activa
      "Activa": this.editorRegistroForm.value.activa === true ? '1' : '0',

      // Sesion Bloqueada
      "SesionBloqueada": this.editorRegistroForm.value.bloqueada === true ? '1' : '0',

      // Idioma de Actividad
      "CodIdioma": this.editorRegistroForm.value.idiomaActividad,

      // Datos Idioma
      "Idiomas": this.editorListaIdiomas,

      // Aforo Maximo
      "MaximoAforo": this.editorRegistroForm.value.aforomaximo,

      // Aforo Minimo
      "MinimoAforo": this.editorRegistroForm.value.aforominimo,

      // Aforo Internet
      "MaximoInternet": this.editorRegistroForm.value.aforointernet,

      // Numero Grupos
      "NumeroGrupos": this.editorRegistroForm.get('numerogrupos').value === '0' ? this.editorRegistroForm.get('numerogrupos').value : (parseInt(this.editorRegistroForm.get('numerogrupos').value) + 1).toString(),

      // Fecha Desde
      "Fecha": moment(this.editorRegistroForm.get('fechaDesde').value).format('DD/MM/YYYY 0:00:00'),

      // Fecha Hasta
      "FechaHasta": moment(this.editorRegistroForm.get('fechaHasta').value).format('DD/MM/YYYY 0:00:00'),

      // Horario Desde
      "HoraInicio": this.editorRegistroForm.value.horainicio + ":00",

      // Horario Hasta
      "HoraFin": this.editorRegistroForm.value.horafin + ":00",

      // Intervalo Aforo (esto no va en el body)
      // "Intervalo": this.editorRegistroForm.value.intervaloAforo,

      // Duracion M
      // Duracion H
      "Duracion": this.calcularDuracion(this.editorRegistroForm.value.duracionminuto, this.editorRegistroForm.value.duracionhora),

      // Campos obligatorios
      "Vendidas": "0",
      "VendidasAdmon": "0",
      "Creado": "1",
      "ControlaHorario": "1",
      "LimiteAforo": "0",
      "Didactica": "0",
      "VendidasInternet": "0",
      "NumSesion": "0",
      "AforoInicial": this.editorRegistroForm.value.aforomaximo

    };

    if (this.funcion === 'U') {
      body.Vendidas = this.copiaRow.Vendidas,
        body.VendidasAdmon = this.copiaRow.VendidasAdmon,
        body.Creado = this.copiaRow.Creado,
        body.ControlaHorario = "1",
        body.LimiteAforo = this.copiaRow.LimiteAforo,
        body.Didactica = this.model.Didactica === true ? '1' : '0',
        body.VendidasInternet = this.copiaRow.VendidasInternet,
        body.NumSesion = this.copiaRow.NumSesion,
        body.AforoInicial = this.editorRegistroForm.value.aforomaximo
    }

    

    this.servicioAPI.insUpdSesionSinButaca(body).subscribe(res => {
      if (res.DatosResult != null) {
        this.editando = false;
        this.alertaServicio.success("Datos Guardados Correctamente")
      } else {
        this.alertaServicio.error(res.Mensajes[0].DescripcionMensaje);
        //this.editando = false;
      }
    });


  }

  async newEditar(item) {
    Object.keys(this.editorRegistroForm.controls).forEach(key => {
      this.editorRegistroForm.get(key).reset();
    });
    this.creando = false;
    this.copiaRow = item;
    this.funcion = 'U';
    this.PkId = item.pkId;
    
    

    let tipoSesionPkId = '0';
    let editDuracion = '0';

    this.tiposTiposSesion.forEach(element => {
      if (element.Descripcion === item.Descripcion) {
        tipoSesionPkId = element.pkId;
        editDuracion = element.Duracion;
      }
    });

    if (tipoSesionPkId === '0') { // la descripcion no coincide con ninguna sesion
      this.tiposTiposSesion.forEach(element => {
        if (element.pkId === item.TipoSesion) {
          tipoSesionPkId = element.pkId;
          editDuracion = element.Duracion;
        }
      });
    }

    this.editorListaIdiomas = new Array<IdiomasconURL>();

    if (item.Idiomas) {
      this.tiposIdiomas.forEach(idioma => {
        let idiomafiltrado = item.Idiomas.filter(x => x.CodIdioma == idioma.CodIdioma);
        if (idiomafiltrado.length > 0) {
          this.editorListaIdiomas.push(<IdiomasconURL>{ CodIdioma: idioma.CodIdioma, Nombre: idiomafiltrado[0].Nombre, Descripcion: idiomafiltrado[0].Descripcion, URL: idiomafiltrado[0].URL });
        }
        else {
          this.editorListaIdiomas.push(<IdiomasconURL>{ CodIdioma: idioma.CodIdioma, Nombre: null, Descripcion: null, URL: null });
        }
      });
    }


    // campos nuevos (los comentados faltan datos)

    this.editorRegistroForm.controls['activa'].setValue(item.Activa === '1' ? true : false);
    this.editorRegistroForm.controls['aforointernet'].setValue(item.MaximoInternet);
    this.editorRegistroForm.controls['aforomaximo'].setValue(item.MaximoAforo);
    this.editorRegistroForm.controls['aforominimo'].setValue(item.AforoInicial);
    
    this.editorRegistroForm.controls['bloqueada'].setValue(item.SesionBloqueada === '1' ? true : false);
    this.editorRegistroForm.controls['duracionhora'].setValue(this.getDuracionHora(editDuracion));
    this.editorRegistroForm.controls['duracionminuto'].setValue(this.getDuracionMinuto(editDuracion));
    this.model.Fecha = item.Fecha.split(' ')[0];
    this.editorRegistroForm.controls['fechaDesde'].setValue(this.getFechaInicioEditar(item.Fecha));
    // this.editorRegistroForm.controls['fechaHasta'].setValue();
    this.editorRegistroForm.controls['horafin'].setValue(this.getHoraInicioEditar(item.HoraFin));
    this.editorRegistroForm.controls['horainicio'].setValue(this.getHoraInicioEditar(item.HoraInicio));
    this.editorRegistroForm.controls['idiomaActividad'].setValue(item.CodIdioma);
    // this.editorRegistroForm.controls['intervaloAforo'].setValue();
    this.editorRegistroForm.controls['nombre'].setValue(item.Descripcion);
    this.editorRegistroForm.controls['numerogrupos'].setValue(item.ControlaHorario === '0' ? item.ControlaHorario : (parseInt(item.ControlaHorario) - 1).toString());
    
    this.itemRecinto = item.Recinto;
    this.editorRegistroForm.controls['recinto'].setValue(item.Recinto);
    this.editorRegistroForm.controls['recinto'].disable();
    this.editorRegistroForm.controls['tiposesion'].setValue(tipoSesionPkId);
    this.seleccionaTipoSesion(tipoSesionPkId);

    //

    this.LabelDisponibleOnlinetxt = this.showDisponible(item).split('/')[1];
    this.LabelDisponibleTotaltxt = this.showDisponible(item).split('/')[0];
    this.AforoInicial = item.AforoInicial;
    this.editando = true;
    this.alertaServicio.limpiar();

    Object.keys(this.editorRegistroForm.controls).forEach(field => { // {1}
      const control = this.editorRegistroForm.get(field);            // {2}
      control.markAsTouched({ onlySelf: true });       // {3}
    });
  }

  getDuracionHora(time) {
    var horas = 0;
    var minutos = parseInt(time);
    while (minutos >= 60) {
      horas = horas + 1;
      minutos = minutos - 60;
    }
    return horas.toString();
  }

  getDuracionMinuto(time) {
    var minutos = parseInt(time);
    while (minutos >= 60) {
      minutos = minutos - 60;
    }
    return minutos.toString();
  }

  cambiaNombre(evento, idioma) {
    this.editorListaIdiomas.find(x => x.CodIdioma == idioma).Nombre = evento;
  }
  cambiaDescripcion(evento, idioma) {
    this.editorListaIdiomas.find(x => x.CodIdioma == idioma).Descripcion = evento;
  }
  cambiaURL(evento, idioma) {
    this.editorListaIdiomas.find(x => x.CodIdioma == idioma).URL = evento;
  }

  calcularDuracion(min, hor) {
    
    
    let m = +min;
    let h = +hor;
    let ans = m + (h * 60);
    
    return ans.toString();
  }
}
