import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { EditClientGroupComponent } from "./edit-client-group/edit-client-group.component";
import { SuperClassComponent } from "@sharedV11/super-class-edit/super-class/super-class.component";

@Component({
  selector: "app-client-group",
  templateUrl: "./client-group.component.html",
  styleUrls: ["./client-group.component.scss"],
})
export class ClientGroupComponent
  extends SuperClassComponent
{
  constructor(
    protected panelService: PanelService,
    protected cdRef: ChangeDetectorRef
  ) {
    super(panelService, cdRef);
  }

  openPanel() {
    this.customanpanel = EditClientGroupComponent;
    this.editChangeCss();
    this.cdRef.detectChanges();
  }
}
