import { CLienteAPi } from "../../client_api";

//insUpdTiposUsuario
export class Request_insertion_upd_userTypes {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    pkId: string;
    Nombre: string;
    Descripcion: string;
    Privilegios: string;
    Funcion: string;
    CentrosIds: string;
}