import {CLienteAPi} from "./cliente-api.model";
import {IdiomaGR} from "./grupos-recintos-Respuesta.model"

//getGruposRecintos
export class peticion_GruposRecintos_Lista {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    Nombre: string;
    Activo: string;
    Subgrupo: string;
}

//insUpdGruposRecintos 
export class peticion_Insercion_Upd_GruposRecintos {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    PkId: string;
    Nombre: string;
    Activo: string;
    CentroId: string;
    subGrupo: string;
    Idiomas: IdiomaGR[];
    funcion: string;
}

//delGruposRecintos //TODO
export class peticion_Del_GruposRecintos {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    PkId: string;
}