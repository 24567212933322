import {Deserializable} from "./deserializable.model";

export class CalendariosTiposControl_Respuesta implements Deserializable {
    DatosResult?: DatosResult_CalendariosTiposControl;
    Mensajes: Mensaje[];

    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}

export class CalendariosTipoControl implements Deserializable {
  PkId: string;
  TipoControlId: string;
  CodigoTipoControl: string;
  NombreTipoControl: string;
  FechaAcceso: string;
  FechaAccesoFin: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class DatosResult_CalendariosTiposControl implements Deserializable {
  customanCalendariosTiposControl: CalendariosTipoControl[];

    deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Mensaje implements Deserializable {
  CodigoMensaje: string;
  DescripcionMensaje: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}