<div id="container">
  <section id="subdiv">

    <div *ngIf="contextual" class="d-flex justify-content-right align-items-center" id="contextualzone">
      <div class="btn-map2" style="cursor: pointer;" >

        <mat-button-toggle-group name="favoriteColor" aria-label="Favorite Color">
          <mat-button-toggle value="red" id="popuprender">              
          <mat-icon>menu</mat-icon>
          </mat-button-toggle>
            <mat-button-toggle value="red" id="popuprendermenos">              
                <mat-icon>zoom_out</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="green"  id="popuprendermas">
                <mat-icon>zoom_in</mat-icon>  
            </mat-button-toggle>
         </mat-button-toggle-group>
      </div>
    </div>
  </section>
  <svg></svg>
</div>
<div
  (window:resize)="onResize($event)"
></div>