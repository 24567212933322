import { Deserializable } from "@sharedV11/classes/deserializable";
import { CLienteAPi } from "@sharedV11/classes/userRequest/client_api";
import { IdiomaGR } from '../../tarifaResponse/idiomaGR';

export class Request_GrupoActividadTaller_list {
  //ConexionIacpos: number;
  clienteAPI: CLienteAPi;
  Nombre: string;
  pkId: string;
  Descripcion: string;
}
export class Request_GrupoActividadTaller_InsUp extends Request_GrupoActividadTaller_list {
  Funcion: string;
}

export class grupos_actividad_taller_Respuesta implements Deserializable {
    DatosResult?: DatosResult_grupos_actividad_taller;
    Mensajes: Mensaje[];

    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}

export class Grupos_actividad_taller implements Deserializable {
  pkId: string;
  Nombre: string;
  Descripcion: string;
  CentrosIds: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class DatosResult_grupos_actividad_taller implements Deserializable {
  customanGrupoActividadTaller: Grupos_actividad_taller[];

    deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Mensaje implements Deserializable {
  CodigoMensaje: string;
  DescripcionMensaje: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class peticion_Del_GruposActividadTaller {
  clienteAPI: CLienteAPi;
  pkId: string;
  PkId: string;
  ConexionIacpos: number;
}