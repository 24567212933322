<app-buttons  (open)="openPanel()" [buttonsWrap]="'add'" [addbut]="addbut"></app-buttons>

<!-- <button (click)="customanpanel='AlertComponent'" class="btn btn-success btn-magento-naranja">Añadir Perfil De Usuario</button> -->


<!-- <button (click)="customanpanel='AlertComponent'">Panel</button> -->
<app-filter [tableName]="'itemsMotivosGrupos'" ></app-filter>

<app-table [tableName]="'itemsMotivosGrupos'" (addbut)="addbut=$event"></app-table>
<app-panelcustoman 
*ngIf="customanpanel!==undefined" 
[ConfigPanel]="customanpanel"
[mode]="'I'" >
</app-panelcustoman> 