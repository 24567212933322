import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from "../../services/authentication/authentication.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  params;
  constructor(private auth: AuthenticationService, private router: Router) {
    this.params = router
      .getCurrentNavigation()
      .extractedUrl.queryParamMap.get("auth");
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let logged = this.auth.verificar();

    if (!logged) {
      if (this.params) {
        this.router.navigate(["/login"], { queryParams: { as: "asdasd" } });
      } else {
        this.router.navigate(["/login"]);
      }
      return false;
    }

    return true;
  }
}
