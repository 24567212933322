import { SelectionModel } from "@angular/cdk/collections";
import {
  Component,
  HostBinding,
  OnInit,
  ViewChild
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

import { first } from "rxjs/operators";

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

import {
  TipoUsuario,
  tipos_usuario_Respuesta,
} from "../../_modelos/tipos-usauario-Respuesta.model";
import {
  CustomanUsuario,
  usuarios_Respuesta,
} from "../../_modelos/usuarios-Respuesta.model";

//import {AlertaService} from '../../_otrosproveedores/alerta.service';
import { ActivatedRoute, Router } from "@angular/router";
import { AutentificacionService } from "../../_otrosproveedores/autentificacion.service";
import { AlertaService } from "../../shared/_alerta/alerta.service";
//import { DialogoPopUp } from '../../_otrosproveedores/popup.service';
import { HttpErrorsService } from "@sharedV11/services/alerts/errors/http-errors.service";
import { ConectarApiService } from "@sharedV11/services/api/connection/conectar-api.service";
import { Globales } from "src/app/V6/_clases/globales";
import { Translator } from "src/app/shared/services/translator/translator.service";
import { DialogoPopUp } from "../../shared/_alerta/popup.service";

export interface DialogData {
  titulo: string;
  texto: string;
  eleccion: string;
  ok: string;
  ko: string;
}

export interface AccionesDialogo {
  accion: string;
  texto: string;
  funcion: string;
}

export class Usuarios_conTipoUsuario extends CustomanUsuario {
  public tipoUsuario: TipoUsuario;
}

export class traspasomodelo {
  pkId: string;
  Personal: string;
  Nombre: string;
  Clave: string;
  Tipo: string;
  Baja: boolean;
  Conectado: boolean;
  Empleado: string;
  tipoUsuario: TipoUsuario;
  deserialize: null;
}

export class filtrosActivos {
  Nombre: string;
  TipoUsuario: TipoUsuario;
  personaldesde: string;
  personalhasta: string;
  Conectado: string;
  Baja: string;
  textoGeneral: string;
}

@Component({
  selector: "app-usuarios",
  templateUrl: "./usuarios.component.html",
  styleUrls: ["./usuarios.component.css"],
})
export class UsuariosComponent implements OnInit {
  displayedColumns: string[] = [
    "Acciones",
    "Nombre",
    "Clave",
    "Tipo",
    "Personal",
    "Empleado",
    "Conectado",
    "Baja",
  ];
  displayedFilters: string[] = [
    "Nombre",
    "Tipo Usuario",
    "Personal",
    "Conectado",
    "Baja",
  ];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  filtersToDisplay: string[] = this.displayedFilters.slice();
  dataSource: MatTableDataSource<Usuarios_conTipoUsuario>;
  seleccion = new SelectionModel<Usuarios_conTipoUsuario>(true, []);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  //@ViewChild('filtrogeneral') filtrogeneral: ElementRef;
  usuarios;
  respuestaAPI: usuarios_Respuesta;
  editando: boolean = false;
  leyendo: boolean = true;
  mensaje_dialogo: string;
  eleccion_dialogo: string;
  titulo_dialogo: string;
  ok_dialogo: string;
  ko_dialogo: string;
  editorRegistroForm: FormGroup;
  editorFiltrosForm: FormGroup;
  tiposUsuarioAPI: tipos_usuario_Respuesta;
  tiposUsuario: Array<TipoUsuario>;
  tipoControl = new FormControl("", [Validators.required]);
  permisoFormulario: boolean = true;
  permisobtnNuevo: boolean = false;
  permisobtnGuardar: boolean = false;
  permisobtnEliminar: boolean = false;
  permisobtnDuplicar: boolean = false;
  Acciones: Array<AccionesDialogo>;
  accionselecionada: string = null;
  panelSelectorFiltrosvisible: boolean = false;
  panelColumnasvisible: boolean = false;
  formControlListaColumnas: FormControl;
  formControlListaFiltros: FormControl;
  filtrosactivosModelo: filtrosActivos = new filtrosActivos();
  filtrosactivos: filtrosActivos = Object.assign({}, this.filtrosactivosModelo);
  panelFiltrosvisible = "";

  translation;

  constructor(
    private servicioAPI: ConectarApiService,
    private alertaServicio: AlertaService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private autentificador: AutentificacionService,
    private router: Router,
    private _ruta: ActivatedRoute,
    private globales: Globales,
    private translator: Translator,
    private httpErrorService: HttpErrorsService
  ) {}

  @HostBinding("class.col") true;

  async ngOnInit() {
    this.translation = this.translator.GetTranslations();

    this.displayedColumns = [
      "Acciones",
      "Nombre",
      "Clave",
      "Tipo",
      "Personal",
      "Empleado",
      "Conectado",
      "Baja",
    ];
    this.displayedFilters = [
      this.translation.Nombre,
      this.translation.TipoUsuario,
      this.translation.Personal,
      this.translation.Conectado,
      this.translation.Baja,
    ];
    this.columnsToDisplay = this.displayedColumns.slice();
    this.filtersToDisplay = this.displayedFilters.slice();

    //Comprobamos logueo en QueryString
    this.autentificador.verificarAccesoQueryString(this._ruta).then(
      //montamos los segmentos de la Ruta uno a uno porque la URL contiene los parametros y no nos sirve
      (res) => {
        let ruta = "/";
        this._ruta.snapshot.pathFromRoot[
          this._ruta.snapshot.pathFromRoot.length - 1
        ].url.forEach((elemento) => {
          ruta = ruta + elemento.path + "/";
        });
        ruta = ruta.substring(0, ruta.length - 1);
        this.permisoFormulario = this.autentificador.visualizacionAccion(
          ruta,
          "VER"
        );
        //
        if (!this.permisoFormulario) {
          this.router.navigate(["/portada"]);
        }
        this.permisobtnNuevo = this.autentificador.visualizacionAccion(
          ruta,
          "NUEVO"
        );
        this.permisobtnGuardar = this.autentificador.visualizacionAccion(
          ruta,
          "MODIFICAR"
        );
        this.permisobtnEliminar = this.autentificador.visualizacionAccion(
          ruta,
          "ELIMINAR"
        );
        this.permisobtnDuplicar = this.autentificador.visualizacionAccion(
          ruta,
          "DUPLICAR"
        );
        //Creamos el desplegable de acciones
        this.Acciones = new Array<AccionesDialogo>();
        if (this.permisobtnEliminar) {
          this.Acciones.push({
            accion: "borrar",
            texto: this.translation.Borrar,
            funcion: "BorrarSeleccionados",
          });
        }
      }
    );
    //Seguimos
    this.cargarDatos();
    this.resetearFiltrosActivos();

    this.editorRegistroForm = this.formBuilder.group({
      nombre: ["", Validators.required],
      clave: ["", [Validators.required, Validators.minLength(6)]],
      personal: [""],
      empleado: [""],
      conectado: [""],
      baja: [""],
    });

    this.editorFiltrosForm = this.formBuilder.group({
      nombre: [""],
      tipoControl: [""],
      personaldesde: [""],
      personalhasta: [""],
      conectado: [""],
      baja: [""],
    });

    this.formControlListaColumnas = new FormControl(this.displayedColumns);
    this.formControlListaFiltros = new FormControl(this.displayedFilters);

    this.columnsToDisplay.splice(
      this.columnsToDisplay.findIndex((x) => x === "Clave"),
      1
    );
    this.columnsToDisplay.splice(
      this.columnsToDisplay.findIndex((x) => x === "Personal"),
      1
    );
  }

  // un getter para facilitar el acceso a los campos del form
  get f() {
    return this.editorRegistroForm.controls;
  } //Editor
  get fFiltro() {
    return this.editorFiltrosForm.controls;
  } //Filtros

  model = new traspasomodelo();

  AnadirRegistro() {
    this.model = new traspasomodelo();
    this.editando = true;
    this.alertaServicio.limpiar();
    //Ponemos los booleanos a false, pqu si no se modifican el check no les da valor
    this.model.Conectado = false;
    this.model.Baja = false;
  }

  editar(item) {
    let objtraspaso = new traspasomodelo();
    objtraspaso.pkId = item.pkId;
    objtraspaso.Nombre = item.Nombre;
    objtraspaso.Clave = item.Clave;
    objtraspaso.tipoUsuario = item.tipoUsuario;
    objtraspaso.Tipo = item.tipoUsuario.pkId;
    objtraspaso.Conectado = this.globales.aBooleano(item.Conectado);
    objtraspaso.Baja = this.globales.aBooleano(item.Baja);
    objtraspaso.Personal = item.Personal;
    objtraspaso.Empleado = item.Empleado;
    this.model = objtraspaso;
    this.editando = true;
    //
    this.alertaServicio.limpiar();
  }

  guardar() {
    let insertando = this.model.pkId == null;
    let globales: Globales = new Globales();
    if (!this.editorRegistroForm.invalid) {
      //Comprobamos que el pkId no sea null (estariamos insertando)
      let objtraspaso = new traspasomodelo();
      if (this.model.pkId != null) {
        objtraspaso = this.dataSource.data
          .map<traspasomodelo>(function (item) {
            return {
              pkId: item.pkId,
              Nombre: item.Nombre,
              Clave: item.Clave,
              tipoUsuario: item.tipoUsuario,
              Tipo: item.tipoUsuario.pkId,
              Conectado: globales.aBooleano(item.Conectado),
              Baja: globales.aBooleano(item.Baja),
              Personal: item.Personal,
              Empleado: item.Empleado,
              deserialize: null,
            };
          })
          .find((item) => item.pkId == this.model.pkId);
      }
      objtraspaso.Nombre = this.model.Nombre;
      objtraspaso.Clave = this.model.Clave;
      objtraspaso.tipoUsuario = this.model.tipoUsuario;
      objtraspaso.Tipo = this.model.tipoUsuario.pkId;
      objtraspaso.Conectado = this.model.Conectado;
      objtraspaso.Baja = this.model.Baja;
      objtraspaso.Personal = this.model.Personal;
      objtraspaso.Empleado = this.model.Empleado;
      //Salvar a API
      this.servicioAPI
        .insUpdUsuarios(
          this.model.pkId,
          this.model.Personal,
          this.model.Nombre,
          this.model.Clave,
          this.model.tipoUsuario.pkId,
          this.model.Baja,
          this.model.Conectado,
          this.model.Empleado
        )
        .pipe(first())
        .subscribe(
          (data) => {
            this.respuestaAPI = new usuarios_Respuesta().deserialize(data);
            if (this.respuestaAPI.DatosResult != null) {
              this.alertaServicio.success("Datos Guardados Correctamente");
              //
              let max = Math.max.apply(
                Math,
                this.dataSource.data.map(function (item) {
                  return item.pkId;
                })
              );
              //
              //Hacemos un push al data para que se muestre
              if (insertando) {
                this.model.pkId = "" + (max + 1);
                let pusheddata = this.dataSource.data;
                pusheddata.push(this.modeloaclaseAPI(this.model));
                this.dataSource.data = pusheddata;
                this.cargarDatos();
              } else {
                this.cargarDatos();
              }
              this.editando = false;
            } else {
              this.alertaServicio.error(
                this.respuestaAPI.Mensajes[0].DescripcionMensaje
              );
              this.editando = false;
            }
          },
          (error) => {
            this.httpErrorService.identificarErrores(error);
            this.editando = false;
          }
        );
    }
  }

  duplicarRegistro() {
    //this.model = new CustomanUsuario();
    if (this.model.pkId != null) {
      this.model.pkId = null; //si no estamos ya con uno nuevo, ponemos el pkId a null y lo trataremos como insercion.
    }
  }

  pregborrarRegistro() {
    //Borrar
    if (this.model.pkId != null) {
      //Si no estamos añadiendo
      this.titulo_dialogo = this.translation.Borrar;
      this.mensaje_dialogo =
        this.translation.EstaSeguroQueDeseaBorrarEsteRegistro;
      this.ok_dialogo = this.translation.Si;
      this.ko_dialogo = this.translation.Cancelar;
      this.openDialog();
    }
  }

  async applyFilter(filterValue: string) {
    this.alertaServicio.limpiar();
    await this.AplicarFiltros();
    this.dataSource.filter = filterValue.trim().toLowerCase();

    this.filtrosactivosModelo.textoGeneral = filterValue.trim();
    this.filtrosactivos = Object.assign({}, this.filtrosactivosModelo);

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.alertaServicio.limpiar();
  }

  //Acciones
  HacerAccion(any) {
    this.alertaServicio.limpiar();
    if (any != null && any != undefined) {
      if (
        this.seleccion.selected == null ||
        this.seleccion.selected.length == 0
      ) {
        this.titulo_dialogo = this.translation.Error;
        this.mensaje_dialogo =
          this.translation.NoHayRegistrosSeleccionadosParaEstaAccion;
        this.ok_dialogo = this.translation.Ok;
        this.openDialogSeleccionVacia();
        //
      } else {
        this.titulo_dialogo = this.translation.ConfirmarBorrado;
        this.mensaje_dialogo =
          this.translation.VaAProcederAlBorradoDe +
          this.seleccion.selected.length.toString() +
          this.translation.RegistrosDeseaConfirmarElBorrado;
        this.ok_dialogo = this.translation.Si;
        this.ko_dialogo = this.translation.No;
        this.openDialogBorradoSeleccionadas();
      }
    }
  }

  MostrarFiltros() {
    this.alertaServicio.limpiar();
    this.panelSelectorFiltrosvisible = !this.panelSelectorFiltrosvisible;
    this.panelColumnasvisible = false;
    this.formControlListaFiltros = new FormControl(this.filtersToDisplay);
  }

  MostrarColumnas() {
    this.alertaServicio.limpiar();
    this.panelColumnasvisible = !this.panelColumnasvisible;
    this.panelSelectorFiltrosvisible = false;
    //Cambiar el boton
    this.formControlListaColumnas = new FormControl(this.columnsToDisplay);
  }

  visibilidadColumna(evento, seleccioncolumnas) {
    if (evento != null && seleccioncolumnas != null) {
      //

      if (!evento.option.selected) {
        //Borramos si no nos quedamos sin columnas
        if (this.columnsToDisplay.length) {
          this.columnsToDisplay.splice(
            this.columnsToDisplay.findIndex((x) => x === evento.option.value),
            1
          );
        }
      } else {
        //Añadimos
        this.columnsToDisplay = [];
        for (let columna of this.displayedColumns) {
          if (seleccioncolumnas.findIndex((x) => x.value === columna) >= 0) {
            this.columnsToDisplay.push(columna);
          }
        }
        // for(let a of seleccioncolumnas) {
        //
      }
    }
  }

  visibilidadFiltro(evento, seleccionfiltros) {
    if (evento != null && seleccionfiltros != null) {
      //

      if (!evento.option.selected) {
        //Borramos si no nos quedamos sin filtros
        if (this.filtersToDisplay.length) {
          this.filtersToDisplay.splice(
            this.filtersToDisplay.findIndex((x) => x === evento.option.value),
            1
          );
        }
      } else {
        //Añadimos
        this.filtersToDisplay = [];
        for (let filtro of this.displayedFilters) {
          if (seleccionfiltros.findIndex((x) => x.value === filtro) >= 0) {
            this.filtersToDisplay.push(filtro);
          }
        }
        // for(let a of seleccionfiltros) {
        //
      }
    }
  }

  //Click en el boton de aplicar Filtros a los datos
  async AplicarFiltros(filtro?: filtrosActivos) {
    this.alertaServicio.limpiar();
    //CArgamos los datos de muevo solo porsiacaso
    await this.cargarDatosAsync().then(
      (respuesta) => {
        let siono = new Boolean(respuesta);
        //
        if (siono) {
          let datostemporales = this.dataSource.data;
          //aplicamos los filtros sobre los datos temporales
          if (filtro != null) {
            if (filtro.Nombre != null && filtro.Nombre.trim() != "") {
              datostemporales = datostemporales.filter(
                (x) => x.Nombre.indexOf(filtro.Nombre.trim()) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.Nombre = filtro.Nombre.trim();
            }
            this.fFiltro["nombre"].setValue(filtro.Nombre.trim());

            if (filtro.TipoUsuario != null && filtro.TipoUsuario.pkId != "") {
              datostemporales = datostemporales.filter(
                (x) => x.Tipo == filtro.TipoUsuario.pkId
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.TipoUsuario = filtro.TipoUsuario;
            }
            this.fFiltro["tipoControl"].setValue(filtro.TipoUsuario);

            if (
              filtro.personaldesde != null &&
              filtro.personalhasta != null &&
              (filtro.personaldesde.trim() != "" ||
                filtro.personalhasta.trim() != "")
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  parseInt(x.Personal) >=
                    (filtro.personaldesde.trim() == ""
                      ? -9999999999
                      : filtro.personaldesde.trim()) &&
                  parseInt(x.Personal) <=
                    (filtro.personalhasta.trim() == ""
                      ? 9999999999
                      : filtro.personalhasta.trim())
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.personaldesde =
                filtro.personaldesde.trim();
              this.filtrosactivosModelo.personalhasta =
                filtro.personalhasta.trim();
            }
            this.fFiltro["personaldesde"].setValue(filtro.personaldesde.trim());
            this.fFiltro["personalhasta"].setValue(filtro.personalhasta.trim());

            if (filtro.Conectado != null && filtro.Conectado.trim() != "") {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.Conectado.trim().toLowerCase() ==
                  (filtro.Conectado.trim().toLowerCase() == "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.Conectado = filtro.Conectado.trim();
            }
            this.fFiltro["conectado"].setValue(filtro.Conectado.trim());

            if (filtro.Baja != null && filtro.Baja.trim() != "") {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.Baja.trim().toLowerCase() ==
                  (filtro.Baja.trim().toLowerCase() == "si" ? "true" : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.Baja = filtro.Baja.trim();
            }
            this.fFiltro["baja"].setValue(filtro.Baja.trim());
          } else {
            //
            if (
              this.fFiltro["nombre"].value != null &&
              this.fFiltro["nombre"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.Nombre.indexOf(this.fFiltro["nombre"].value.trim()) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.Nombre = this.fFiltro["nombre"].value;
            }

            //
            if (
              this.fFiltro["tipoControl"].value != null &&
              this.fFiltro["tipoControl"].value != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) => x.Tipo == this.fFiltro["tipoControl"].value.pkId
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.TipoUsuario =
                this.fFiltro["tipoControl"].value;
            }
            //
            //
            if (
              this.fFiltro["personaldesde"].value != null &&
              this.fFiltro["personalhasta"].value != null &&
              (this.fFiltro["personaldesde"].value.trim() != "" ||
                this.fFiltro["personalhasta"].value.trim() != "")
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  parseInt(x.Personal) >=
                    (this.fFiltro["personaldesde"].value.trim() == ""
                      ? -9999999999
                      : this.fFiltro["personaldesde"].value) &&
                  parseInt(x.Personal) <=
                    (this.fFiltro["personalhasta"].value.trim() == ""
                      ? 9999999999
                      : this.fFiltro["personalhasta"].value)
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.personaldesde =
                this.fFiltro["personaldesde"].value;
              this.filtrosactivosModelo.personalhasta =
                this.fFiltro["personalhasta"].value;
            }
            //
            if (
              this.fFiltro["conectado"].value != null &&
              this.fFiltro["conectado"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.Conectado.trim().toLowerCase() ==
                  (this.fFiltro["conectado"].value.trim().toLowerCase() == "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.Conectado =
                this.fFiltro["conectado"].value;
            }
            //
            if (
              this.fFiltro["baja"].value != null &&
              this.fFiltro["baja"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.Baja.trim().toLowerCase() ==
                  (this.fFiltro["baja"].value.trim().toLowerCase() == "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.Baja = this.fFiltro["baja"].value;
            }
          }

          this.dataSource.data = datostemporales;

          if (
            this.filtrosactivosModelo.textoGeneral != null &&
            this.filtrosactivosModelo.textoGeneral.trim() != ""
          ) {
            this.dataSource.filter = this.filtrosactivosModelo.textoGeneral
              .trim()
              .toLowerCase();
          } else {
            //Solo borramos el input text, el filtro se actualiza Ok
            //this.filtrogeneral.nativeElement.value = '';
            //
          }
          this.filtrosactivos = Object.assign({}, this.filtrosactivosModelo);

          if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
          }
        } else {
          //No hemos podido cargar los datos
        }
      },
      (error) => {
        //No hemos podido cargar los datos
      }
    );
  }

  //Para resetear filtros, se pasa el filtro o se ejecuta sin parametro y se resetean todos
  resetearFiltrosActivos(filtro?: string) {
    this.filtrosactivosModelo.Nombre =
      filtro == null || filtro == "Nombre"
        ? ""
        : this.filtrosactivosModelo.Nombre;
    this.filtrosactivosModelo.TipoUsuario =
      filtro == null || filtro == "tipoControl"
        ? null
        : this.filtrosactivosModelo.TipoUsuario;
    this.filtrosactivosModelo.personaldesde =
      filtro == null || filtro == "personal"
        ? ""
        : this.filtrosactivosModelo.personaldesde;
    this.filtrosactivosModelo.personalhasta =
      filtro == null || filtro == "personal"
        ? ""
        : this.filtrosactivosModelo.personalhasta;
    this.filtrosactivosModelo.Baja =
      filtro == null || filtro == "Baja" ? "" : this.filtrosactivosModelo.Baja;
    this.filtrosactivosModelo.Conectado =
      filtro == null || filtro == "Conectado"
        ? ""
        : this.filtrosactivosModelo.Conectado;
    this.filtrosactivosModelo.textoGeneral =
      filtro == null || filtro == "General"
        ? ""
        : this.filtrosactivosModelo.textoGeneral;
    this.filtrosactivos = Object.assign({}, this.filtrosactivosModelo);
    this.AplicarFiltros(this.filtrosactivos);
    if (this.editorFiltrosForm != null) {
      this.editorFiltrosForm.updateValueAndValidity({ onlySelf: true });
      this.editorFiltrosForm.markAsPristine();
      //
    }
  }

  hayFiltros(filtros: filtrosActivos): boolean {
    if (
      (filtros.Nombre != null && filtros.Nombre.trim() != "") ||
      filtros.TipoUsuario != null ||
      (filtros.personaldesde != null && filtros.personaldesde.trim() != "") ||
      (filtros.personalhasta != null && filtros.personalhasta.trim() != "") ||
      (filtros.Conectado != null && filtros.Conectado.trim() != "") ||
      (filtros.Baja != null && filtros.Baja.trim() != "") ||
      (filtros.textoGeneral != null && filtros.textoGeneral.trim() != "")
    )
      return true;
    else return false;
  }

  //Para que funcione le mat select cuando al option le metes objetos en lugar de strings simples
  comparaObjetosSelect(o1: any, o2: any) {
    if ((o1 == null && o2 == null) || o1.pkId == o2.pkId) return true;
    else return false;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.seleccion.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.seleccion.clear()
      : this.dataSource.data.forEach((row) => this.seleccion.select(row));
  }
  ///Validador

  ///Funcionalidades

  cargarDatos() {
    //Cargamos Datos
    this.servicioAPI
      .getTiposUsuario()
      .pipe(first())
      .subscribe(
        (data) => {
          this.tiposUsuarioAPI = new tipos_usuario_Respuesta().deserialize(
            data
          );
          if (this.tiposUsuarioAPI.DatosResult != null) {
            this.tiposUsuario = this.tiposUsuarioAPI.DatosResult.tipoUsuarios;

            //
            var tiposUsuariotmp = this.tiposUsuario;
            this.servicioAPI
              .getUsuarios()
              .pipe(first())
              .subscribe(
                (data) => {
                  this.respuestaAPI = new usuarios_Respuesta().deserialize(
                    data
                  );
                  if (this.respuestaAPI.DatosResult != null) {
                    this.dataSource = new MatTableDataSource(
                      this.respuestaAPI.DatosResult.customanUsuarios.map<Usuarios_conTipoUsuario>(
                        function (x) {
                          return {
                            pkId: x.pkId,
                            Nombre: x.Nombre,
                            Clave: x.Clave,
                            Tipo: x.Tipo,
                            Personal: x.Personal,
                            Empleado: x.Empleado,
                            Conectado: x.Conectado,
                            Baja: x.Baja,
                            tipoUsuario: tiposUsuariotmp.filter(
                              (y) => y.pkId == x.Tipo
                            )[0],
                            deserialize: null,
                          };
                        }
                      )
                    );
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                  } else {
                    if (this.respuestaAPI)
                      this.alertaServicio.error(
                        this.respuestaAPI.Mensajes[0].DescripcionMensaje
                      );
                  }
                },
                (error) => {
                  this.httpErrorService.identificarErrores(error);
                }
              );
          } else {
            if (this.respuestaAPI)
              this.alertaServicio.error(
                this.respuestaAPI.Mensajes[0].DescripcionMensaje
              );
          }
        },
        (error) => {
          this.alertaServicio.error(error);
        }
      );
  }

  async cargarDatosAsync(): Promise<boolean> {
    //Cargamos Datos
    let resultado: boolean = false;
    var tiposUsuariotmp;
    await this.servicioAPI.getTiposUsuarioAsync().then(
      (data) => {
        this.tiposUsuarioAPI = new tipos_usuario_Respuesta().deserialize(data);
        if (this.tiposUsuarioAPI.DatosResult != null) {
          this.tiposUsuario = this.tiposUsuarioAPI.DatosResult.tipoUsuarios;
          //
          tiposUsuariotmp = this.tiposUsuario;
        } else {
          this.alertaServicio.error(
            this.respuestaAPI.Mensajes[0].DescripcionMensaje
          );
          resultado = false;
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
        resultado = false;
      }
    );
    //Lanzamos encadenado
    await this.servicioAPI.getUsuariosAsync().then(
      (data) => {
        this.respuestaAPI = new usuarios_Respuesta().deserialize(data);
        if (this.respuestaAPI.DatosResult != null) {
          this.dataSource = new MatTableDataSource(
            this.respuestaAPI.DatosResult.customanUsuarios.map<Usuarios_conTipoUsuario>(
              function (x) {
                return {
                  pkId: x.pkId,
                  Nombre: x.Nombre,
                  Clave: x.Clave,
                  Tipo: x.Tipo,
                  Personal: x.Personal,
                  Empleado: x.Empleado,
                  Conectado: x.Conectado,
                  Baja: x.Baja,
                  tipoUsuario: tiposUsuariotmp.filter(
                    (y) => y.pkId == x.Tipo
                  )[0],
                  deserialize: null,
                };
              }
            )
          );
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          resultado = true;
        } else {
          this.alertaServicio.error(
            this.respuestaAPI.Mensajes[0].DescripcionMensaje
          );
          resultado = false;
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
        resultado = false;
      }
    );
    return resultado;
  }

  //Cambiar el modelo API (string) a modelo con boolean para los checks
  modeloaclaseAPI(item: traspasomodelo): Usuarios_conTipoUsuario {
    let respuesta = new Usuarios_conTipoUsuario();
    respuesta.Baja = item.Baja ? "True" : "False";
    respuesta.Conectado = item.Conectado ? "True" : "False";
    respuesta.Clave = item.Clave;
    respuesta.Empleado = item.Empleado;
    respuesta.Nombre = item.Nombre;
    respuesta.Personal = item.Personal;
    respuesta.pkId = item.pkId;
    respuesta.Tipo = item.Tipo;
    respuesta.tipoUsuario = item.tipoUsuario;
    return respuesta;
  }

  //Se podria instanciar este openDialog si requiriesemos mas popups (este seria openDialogBorrar)
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: "250px",
      data: {
        titulo: this.titulo_dialogo,
        texto: this.mensaje_dialogo,
        eleccion: this.eleccion_dialogo,
        ok: this.ok_dialogo,
        ko: this.ko_dialogo,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.eleccion_dialogo = result;
      if (result == "SI") {
        //Salvar a API
        this.servicioAPI
          .delUsuarios(this.model.pkId)
          .pipe(first())
          .subscribe(
            (data) => {
              this.respuestaAPI = new usuarios_Respuesta().deserialize(data);
              if (this.respuestaAPI.DatosResult != null) {
                this.alertaServicio.success("Datos Borrados Correctamente");
                //
                //this.dataSource.data.splice(,1)
                this.dataSource.data = this.dataSource.data.filter(
                  (item) => item.pkId !== this.model.pkId
                );
              } else {
                this.alertaServicio.error(
                  this.respuestaAPI.Mensajes[0].DescripcionMensaje
                );
              }
            },
            (error) => {
              this.httpErrorService.identificarErrores(error);
            }
          );
        this.editando = false;
      }
    });
  }

  //Instancia para Accionse sobre seleccion vacia
  openDialogSeleccionVacia(): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: "250px",
      data: {
        titulo: this.titulo_dialogo,
        texto: this.mensaje_dialogo,
        eleccion: this.eleccion_dialogo,
        ok: this.ok_dialogo,
        ko: null,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      //
      this.accionselecionada = null;
    });
  }

  //Instancia Borrado Seleccionadas
  openDialogBorradoSeleccionadas(): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: "250px",
      data: {
        titulo: this.titulo_dialogo,
        texto: this.mensaje_dialogo,
        eleccion: this.eleccion_dialogo,
        ok: this.ok_dialogo,
        ko: this.ko_dialogo,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      //
      this.eleccion_dialogo = result;
      //
      if (result == "SI") {
        var resultado: string = "";
        this.seleccion.selected.forEach((element) => {
          //Recorremos los seleccionados y vamos borrando
          this.servicioAPI.delUsuariosAsync(element.pkId).then(
            (data) => {
              this.respuestaAPI = new usuarios_Respuesta().deserialize(data);
              if (this.respuestaAPI.DatosResult != null) {
                this.dataSource.data = this.dataSource.data.filter(
                  (item) => item.pkId !== element.pkId
                );
              } else {
                resultado +=
                  this.respuestaAPI.Mensajes[0].DescripcionMensaje + " <BR />";
              }
            },
            (error) => {
              resultado += error + " <BR />";
            }
          );
        });
        if (resultado != null && resultado.length > 0) {
          this.alertaServicio.error(resultado);
        } else {
          this.alertaServicio.success("Datos Borrados Correctamente");
        }

        //End For
      }
      //F Si
      this.seleccion.clear();
      this.accionselecionada = null;
    });
    //F Subscribe
  }

  duplicar(row: traspasomodelo) {
    this.model = row; // cambiar por los datos de la fila
    if (this.model.pkId != null) {
      this.model.pkId = null; //si no estamos ya con uno nuevo, ponemos el pkId a null y lo trataremos como insercion.
    }
    this.editando = true;
    this.alertaServicio.limpiar();
    //Ponemos los booleanos a false, pqu si no se modifican el check no les da valor
    this.model.Conectado = false;
    this.model.Baja = false;
  }

  borrar(pkId: string): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: "250px",
      data: {
        titulo: this.translation.BorrarCliente,
        texto: this.translation.EstaSeguroQueDeseaBorrarElRegistro,
        eleccion: this.translation.Patatas,
        ok: this.translation.Confirmar,
        ko: this.translation.Cancelar,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === "SI") {
        this.servicioAPI
          .delUsuarios(pkId)
          .pipe(first())
          .subscribe(
            (data) => {
              this.respuestaAPI = new usuarios_Respuesta().deserialize(data);
              if (this.respuestaAPI.DatosResult != null) {
                this.alertaServicio.success("Datos Borrados Correctamente");
                this.dataSource.data = this.dataSource.data.filter(
                  (item) => item.pkId !== pkId
                );
              } else {
                this.alertaServicio.error(
                  this.respuestaAPI.Mensajes[0].DescripcionMensaje
                );
              }
            },
            (error) => {
              this.alertaServicio.error(error);
            }
          );
        this.editando = false;
      }
    });
  }

  mostrar() {
    const formulario = document.getElementById("formulario");
    const boton = document.getElementById("titulo");
    if (formulario.style.display === "none") {
      formulario.style.display = "block";
      boton.style.backgroundImage = "url(/assets/icon/desplegable2.svg)";
    } else {
      formulario.style.display = "none";
      boton.style.backgroundImage = "url(/assets/icon/desplegable.svg)";
    }
  }

  validacion() {
    const separador = document.getElementsByClassName("separadorLateral");
    const campos = document.getElementsByClassName("editor-campo-registro");
    for (let i = 0; i < campos.length; i++) {
      if (campos[i].classList.contains("ng-valid")) {
        separador[i].setAttribute("style", "border-color:green");
      } else {
        separador[i].setAttribute("style", "border-color:#002c76");
      }
    }
  }
}
