<!--<div id="divForm">
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="example-form" autocomplete="off">
    <mat-form-field class="example-full-width">
      <mat-label>Username</mat-label>
      <input matInput type="text" name="" formControlName="username">
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <mat-label>Password</mat-label>
      <input matInput type="password" name="" formControlName="password">
    </mat-form-field>
  <button mat-raised-button color="primary">Enviar</button>
  </form>
</div>
-->
<div>
  <form [formGroup]="loginForm" autocomplete="off">
    <!-- Restore password -->
    <fieldset class="admin__fieldset">
      <h2 class="center_title">{{ translation.ContrasenaCaducada }}</h2>
      <br /><br />
      <h2>{{ translation.NuevaContrasena }}</h2>
      <alert class="alerta-login"></alert>
      <div class="admin__field _required field-username">
        <label for="username" class="admin__field-label">
          <span *ngIf="translation">{{ translation.Contrasena }}</span>
        </label>
        <div class="admin__field-control">
          <input
            *ngIf="translation"
            type="password"
            #password
            formControlName="password"
            class="form-control admin__control-text"
            placeholder="{{ translation.Contrasena }}"
          />
          <mat-error
            *ngIf="
              loginForm.controls['password'].invalid &&
              loginForm.controls['password'].touched
            "
          >
            <span *ngIf="loginForm.controls['password'].errors.required">{{
              translation.CampoRequerido
            }}</span>
            <span *ngIf="loginForm.controls['password'].errors.minlength"
              >{{ translation.NuevaContrasenaMinimoCaracteres }} 8
              {{ translation.Caracteres }}</span
            >
          </mat-error>
        </div>
      </div>
      <div class="admin__field _required field-password">
        <label for="login" class="admin__field-label">
          <span *ngIf="translation">{{ translation.ComfirmarContrasena }}</span>
        </label>
        <div class="admin__field-control">
          <input
            *ngIf="translation"
            type="password"
            formControlName="confirm"
            class="form-control admin__control-text"
            placeholder="{{ translation.ComfirmarContrasena }}"
          />
          <mat-error
            *ngIf="
              loginForm.controls['confirm'].invalid &&
              loginForm.controls['confirm'].touched
            "
          >
            <span *ngIf="loginForm.controls['confirm'].errors.required"
              >-{{ translation.CampoRequerido }}</span
            >
            <span *ngIf="loginForm.controls['confirm'].errors.minlength"
              >-{{ translation.NuevaContrasenaMinimoCaracteres }} 8
              {{ translation.Caracteres }}</span
            >
          </mat-error>
          <mat-error
            *ngIf="
              loginForm.hasError('areEqualFields') &&
              loginForm.controls['confirm'].touched
            "
          >
            Las contraseñas no coinciden.
          </mat-error>
        </div>
      </div>
      <div class="form-actions">
        <div class="actions">
          <button
            class="btn btn-primary btn-primary-login"
            [disabled]="loginForm.invalid"
            [mat-dialog-close]="loginForm.value"
          >
            <span *ngIf="translation">{{ translation.RestorePassword }}</span>
          </button>
        </div>
        <!-- <div class="links">
						<a class="action-forgotpassword" href="http://museodelprado-pre.demohiberus.com/admin_1kmqqk/admin/auth/forgotpassword/">¿Olvidó su contraseña?</a>
					</div> -->
      </div>
    </fieldset>
  </form>
</div>
