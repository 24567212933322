import {CLienteAPi} from "./cliente-api.model";

//getGruposTiposEntrada
export class peticion_Grupos_Tipos_Entrada_Lista {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    NombreGrupo: string;
    chDescripcion: string;
}

//insUpdGruposTiposEntrada
export class peticion_Insercion_Upd_GruposTiposEntrada {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    PkId: string;
    NombreGrupo: string;
    Descripcion: string;
    ConMotivo: string;
    Funcion: string;
}

//delUsuarios
export class peticion_Del_GruposTiposEntrada {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    PkId: string;
}
