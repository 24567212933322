import { Component, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';



export interface DialogData {
  titulo: string;
  texto: string;
  eleccion: string;
  ok: string;
  ko: string;
}

// @Injectable({
//   providedIn: 'root'
// })

//Especificaaciones del PopUp
@Component({
  selector: 'dialogo-popUp',
  templateUrl: './dialogo-popup-layout.html'
})
export class DialogoPopUp {

  constructor(
    public dialogRef: MatDialogRef<DialogoPopUp>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close("NO");
  }

  onSiClick(): void {
    this.dialogRef.close("SI");
  }
}
// export class PopupService {

//   constructor() { }
// }
