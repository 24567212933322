<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div [ngClass]="{ zonaoculta: loading === true }">
  <app-buttons
    (save)="submit()"
    (close)="destroy()"
    (discard)="discard()"
    [buttonsWrap]="'panel'"
    [modificar]="modificar"
  ></app-buttons>
  <div class="divForm">
    <form [formGroup]="editUserProfileForm">
      <div class="form-group">
        <mat-form-field appearance="outline">
          <mat-label>{{ translation.Nombre }}</mat-label>
          <input matInput formControlName="Nombre" />
          <mat-error *ngIf="validateFields('Nombre')">
            {{ getErrorMessage("Nombre") }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{ translation.Privilegios }}</mat-label>
          <input matInput formControlName="Privilegios" />
          <mat-error *ngIf="validateFields('Privilegios')">
            {{ getErrorMessage("Privilegios") }}
          </mat-error>
        </mat-form-field>
      </div>
      <mat-form-field appearance="outline">
        <mat-label>{{ translation.Descripcion }}</mat-label>
        <textarea
          matInput
          rows="5"
          cols="60"
          formControlName="Descripcion"
        ></textarea>
        <mat-error *ngIf="validateFields('Descripcion')">
          {{ getErrorMessage("Descripcion") }}
        </mat-error>
      </mat-form-field>

      <div style="margin: 20px 5px 0 5px; text-align: center">
        <mat-label>{{ translation.Centros }}</mat-label>

        <customan-dual-list
          *ngIf="centrosSinAsignar"
          [source]="centrosSinAsignar"
          [(destination)]="centrosAsignados"
          key="pkId"
          display="Nombre"
          [format]="format"
          [filter]="true"
        >
        </customan-dual-list>
      </div>
    </form>
  </div>
</div>
