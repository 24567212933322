import {CLienteAPi} from "./cliente-api.model";

//getGruposRecintos
export class peticion_Centros_Lista {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    Nombre: string;
    Activo: string;
    Clave: string;
}

//insUpdGruposRecintos 
export class peticion_Insercion_Upd_Centros {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    // PkId: string; //TODO
    // CodIdioma: string;
    // Nombre: string;
    // Activo: string;
    funcion: string;
}

//delGruposRecintos //TODO
export class peticion_Del_Centros {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    PkId: string;
}