import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { peticion_Del_GrupoTipoBono, peticion_Insercion_Upd_GrupoTipoBono } from '@sharedV11/classes/clients/client/peticion-grupo-tipo-bono.model';
import { GrupoTipoBono } from '@sharedV11/classes/EntradasPromociones/GrupoTipoBono/GrupoTipoBonoRespuesta/grupo-tipo-bono-respuesta.model';
import { ApiTokenService } from '@sharedV11/services/api/token/api-token.service';
import { GlobalService } from '@sharedV11/services/global/global.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class GrupoTipoBonoV11Service {

  constructor(
    private http: HttpClient,
    private tokenService: ApiTokenService,
    private global: GlobalService
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  delGrupoTipoBono(pkId: string): Observable<any> {
    let peticion: peticion_Del_GrupoTipoBono = new peticion_Del_GrupoTipoBono();
    peticion.clienteAPI = this.global.clienteapi; // TODO

    peticion.pkId = pkId;
    // Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/EliminarCustomanCustomanGrupoTipoBono', JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('delGrupoTipoBono'))
    );
  }

/*   insUpdGrupoTipoBono(gtb: GrupoTipoBono): Observable<any> {
    let peticion: peticion_Insercion_Upd_GrupoTipoBono = new peticion_Insercion_Upd_GrupoTipoBono();
    peticion.clienteAPI = this.global.clienteapi; //TODO

    peticion.NombreGrupo = gtb.NombreGrupo;
    peticion.Descripcion = gtb.Descripcion;

    if (gtb.pkId != null && gtb.pkId != "0") {
      peticion.Funcion = "U";
      peticion.pkId = gtb.pkId;
    }
    else {
      peticion.Funcion = "I";
      peticion.pkId = "0"
    }
    //Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarActualizarCustomanGrupoTipoBono', JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('insUpdGrupoTipoBono'))
    );
  } */
  
  insGrupoTipoBono(gtp,mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = gtp;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarCustomanGrupoTipoBono', JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('InsertarCustomanTipoBonoModel'))
    );
  }
  
  updGrupoTipoBono(gtp,mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = gtp;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ActualizarCustomanGrupoTipoBono', JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('ActualizarCustomanTipoBonoModel'))
    );
  }
  insUpdGrupoTipoBono(gtp,mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = gtp;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarActualizarCustomanGrupoTipoBono', JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('InsertarActualizarCustomanGrupoTipoBono'))
    );
  }

  findGrupoTipoBonoByPkId(pkId:string): Observable<any> {
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Nombre = ""; 
    peticion.Descripcion = ""; 
    peticion.pkId =pkId
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanGrupoTipoBono', JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('findGrupoTipoBonoByPkId'))
    )
  }
}
