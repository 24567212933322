import { ChangeDetectorRef, Component } from "@angular/core";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { SuperClassComponent } from "@sharedV11/super-class-edit/super-class/super-class.component";
import { EditItemsMotivosGruposV11Component } from "./edit-items-motivos-grupos-v11/edit-items-motivos-grupos-v11.component";

@Component({
  selector: "app-items-motivos-grupos-v11",
  templateUrl: "./items-motivos-grupos-v11.component.html",
  styleUrls: ["./items-motivos-grupos-v11.component.scss"],
})
export class ItemsMotivosGruposV11Component extends SuperClassComponent {
  constructor(
    protected panelService: PanelService,
    protected cdRef: ChangeDetectorRef
  ) {
    super(panelService, cdRef);
  }

  openPanel() {
    this.customanpanel = EditItemsMotivosGruposV11Component;
    this.editChangeCss();
    this.cdRef.detectChanges();
  }
}
