import { Component, Inject, OnInit, HostBinding, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

import { first } from 'rxjs/operators';

import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Idiomas_Respuesta, Idiomas } from '../../_modelos/Idiomas-Respuesta.model';
import { GruposRecintos_Respuesta, GruposRecintos, IdiomaGR } from '../../_modelos/grupos-recintos-Respuesta.model';
import { TiposControl_Respuesta, TipoControl } from '../../_modelos/tipos-control-Respuesta.model';
import { Recintos_Respuesta, Recintos, IdiomaR } from '../../_modelos/recintos-Respuesta.model';
import { grupos_actividad_taller_Respuesta, Grupos_actividad_taller } from '../../_modelos/grupos-actividad-taller-Respuesta.model';
import { TiposEntrada_Respuesta, TipoEntrada } from '../../_modelos/tipos-entrada-Respuesta.model';
import { Deserializable } from '../../_modelos/deserializable.model';

import { ConectarAPIService } from '../../_API/conectar-api.service';
//import {AlertaService} from '../../_otrosproveedores/alerta.service';
import { AlertaService } from '../../shared/_alerta/alerta.service';
import { AutentificacionService } from '../../_otrosproveedores/autentificacion.service';
import { Router, ActivatedRoute } from '@angular/router';
//import { DialogoPopUp } from '../../_otrosproveedores/popup.service';
import { DialogoPopUp } from '../../shared/_alerta/popup.service';
import { GlobalPositionStrategy } from '@angular/cdk/overlay';
import { Globales } from '@clasesV6/globales';
import { Translator } from 'src/app/shared/services/translator/translator.service';
import { GuardarPopupComponent } from './guardar-popup/guardar-popup.component';


export interface DialogData {
  titulo: string;
  texto: string;
  eleccion: string;
  ok: string;
  ko: string;
}

export interface AccionesDialogo {
  accion: string;
  texto: string;
  funcion: string;
}

export class ColumnaIdiomas {
  textoHeader: string;
  CodIdioma: string;
}

export class IdiomasconURL extends IdiomaGR implements Deserializable {
  URL: string;

  //chCodigo: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class traspasomodelo {
  PkId: string;
  Nombre: string;
  Activo: boolean;
  ControlarAforo: boolean;
  ControlarAforoReserva: boolean;
  TipoAforo: string;
  AforoMaximo: string;
  LimiteAforo: string;
  HoraInicio: string;
  HoraFin: string;
  PorDefecto: boolean;
  MinimoAforo: string;
  Taller: boolean;
  Butacas: boolean;
  MostrarInternet: boolean;
  MaximoInternet: string;
  GrupoActividadId: string;
  CodigoTipoControl: string;
  Secciones: string;
  GrupoRecintosId: string;
  Shape: string;
  Coords: string;
  OrdenFilas: string;
  OrdenButacas: string;
  Numeracion: string;
  NumSesion: boolean;
  TiempoExtra: string;
  subGrupoRecinto: boolean;

  Idiomas: IdiomaR[];

  deserialize: null;
}

export class filtrosActivos {
  Nombre: string;
  Activo: string;
  Butacas: string;
  MostrarInternet: string;
  CodigoTipoControl: string;
  Taller: string;
  TipoAforo: string;
  textoGeneral: string;
}

@Component({
  selector: 'app-recintos',
  templateUrl: './recintos.component.html',
  styleUrls: ['./recintos.component.css']
})
export class RecintosComponent implements OnInit {
  displayedColumns: string[] = ['select', 'Nombre', 'Activo', 'PorDefecto', 'ControlaAforo', 'ControlarAforoReserva', 'TipoAforo', 'AforoMaximo',
    'LimiteAforo', 'HoraInicio', 'HoraFin', 'MinimoAforo', 'Taller', 'Butacas', 'MostrarInternet', 'MaximoInternet', 'GrupoActividadId',
    'CodigoTipoControl', 'Secciones', 'GrupoRecintosId', 'Shape', 'Coords', 'OrdenFilas', 'OrdenButacas', 'Numeracion', 'NumSesion',
    'TiempoExtra', 'subGrupoRecinto'];
  columnsToDisplay: string[] = ['Acciones', 'Nombre', 'Activo', 'ControlaAforo', 'TipoAforo', 'AforoMaximo',
    'HoraInicio', 'HoraFin', 'MinimoAforo', 'Taller', 'Butacas', 'MostrarInternet', 'GrupoRecintosId'];

  displayedFilters: string[] = ['Nombre', 'Tipo Usuario', 'Personal', 'Conectado', 'Baja'];
  filtersToDisplay: string[] = this.displayedFilters.slice();


  dataSource: MatTableDataSource<Recintos>;
  seleccion = new SelectionModel<Recintos>(true, []);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  usuarios;
  respuestaAPI: Recintos_Respuesta;
  editando: boolean = false;
  leyendo: boolean = true;
  mensaje_dialogo: string;
  eleccion_dialogo: string;
  titulo_dialogo: string;
  ok_dialogo: string;
  ko_dialogo: string;
  editorRegistroForm: FormGroup;
  editorFiltrosForm: FormGroup;
  formControlListaColumnas: FormControl;
  editorListaIdiomas: Array<IdiomasconURL>;
  //--
  tiposIdiomasAPI: Idiomas_Respuesta;
  tiposIdiomas: Array<Idiomas>;
  tipoControl = new FormControl('', [Validators.required]);
  tipoControlIdiomas = new FormControl('', [Validators.required]);
  //---
  tiposGruposRecintoAPI: GruposRecintos_Respuesta;
  tiposGruposRecinto: Array<GruposRecintos>;
  tipoControlGruposRecinto = new FormControl('');
  //--
  tiposTiposControlAPI: TiposControl_Respuesta;
  tiposTiposControl: Array<TipoControl>;
  tipoControlTipoControl = new FormControl('');
  //--
  tiposGruposActividadesAPI: grupos_actividad_taller_Respuesta;
  tiposGruposActividades: Array<Grupos_actividad_taller>;
  tipoControlGruposActividades = new FormControl('');
  //--
  tiposTiposEntradaAPI: TiposEntrada_Respuesta;
  tiposTipoEntrada: Array<TipoEntrada>;
  tiposTipoEntradaTaller: Array<TipoEntrada>;
  tiposTipoEntradaRecinto: Array<TipoEntrada>;
  //--
  columnasIdiomas: Array<ColumnaIdiomas>;
  permisoFormulario: boolean = true;
  permisobtnNuevo: boolean = false;
  permisobtnGuardar: boolean = false;
  permisobtnEliminar: boolean = false;
  permisobtnDuplicar: boolean = false;
  Acciones: Array<AccionesDialogo>;
  accionselecionada: string = null;
  panelFiltrosvisible: boolean = false;
  panelColumnasvisible: boolean = false;
  filtrosactivosModelo: filtrosActivos = new filtrosActivos;
  filtrosactivos: filtrosActivos = Object.assign({}, this.filtrosactivosModelo);
  compruebaPorDefectovalor: boolean = true;
  tallerActividad: boolean = false;
  butacasCheck: boolean = false;
  translation: any;
  panelSelectorFiltrosvisible: boolean = false;
  formControlListaFiltros: FormControl;
  entradasList = [];
  itemPkId = '';
  listaEmailsEnvio: any;
  gruposRecnitos: any;
  subGruposRecintos: any;
  gruposRecintos: any[];

  enviandoEmail = false;

  //
  constructor(private servicioAPI: ConectarAPIService,
    private alertaServicio: AlertaService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private autentificador: AutentificacionService,
    private router: Router,
    private _ruta: ActivatedRoute,
    public globales: Globales,
    private translator: Translator) {

  }

  @HostBinding('class.col') true;

  async ngOnInit() {
    this.translation = this.translator.GetTranslations();

    this.displayedColumns = ['select', 'Nombre', 'Activo', 'PorDefecto', 'ControlaAforo', 'ControlarAforoReserva', 'TipoAforo', 'AforoMaximo',
      'LimiteAforo', 'HoraInicio', 'HoraFin', 'MinimoAforo', 'Taller', 'Butacas', 'MostrarInternet', 'MaximoInternet', 'GrupoActividadId',
      'CodigoTipoControl', 'Secciones', 'GrupoRecintosId', 'Shape', 'Coords', 'OrdenFilas', 'OrdenButacas', 'Numeracion', 'NumSesion',
      'TiempoExtra', 'subGrupoRecinto'];
    this.columnsToDisplay = ['Acciones', 'Nombre', 'Activo', 'ControlaAforo', 'TipoAforo', 'AforoMaximo',
      'HoraInicio', 'HoraFin', 'MinimoAforo', 'Taller', 'Butacas', 'MostrarInternet', 'GrupoRecintosId'];

    this.displayedFilters = [this.translation.Nombre, this.translation.Activo, this.translation.Butacas, this.translation.MostrarInternet, this.translation.TipoControlAcceso, this.translation.EsUnTallerActividad, this.translation.TipoAforo];
    this.filtersToDisplay = this.displayedFilters.slice();
    //Comprobamos logueo en QueryString
    this.autentificador.verificarAccesoQueryString(this._ruta).then(
      //montamos los segmentos de la Ruta uno a uno porque la URL contiene los parametros y no nos sirve
      res => {
        let ruta = '/';
        this._ruta.snapshot.pathFromRoot[this._ruta.snapshot.pathFromRoot.length - 1].url.forEach(elemento => {
          ruta = ruta + elemento.path + '/';
        });
        ruta = ruta.substring(0, ruta.length - 1);
        this.permisoFormulario = this.autentificador.visualizacionAccion(ruta, 'VER');
        //
        if (!this.permisoFormulario) {
          this.router.navigate(['/portada']);
        }
        ;
        this.permisobtnNuevo = this.autentificador.visualizacionAccion(ruta, 'NUEVO');
        this.permisobtnGuardar = this.autentificador.visualizacionAccion(ruta, 'MODIFICAR');
        this.permisobtnEliminar = this.autentificador.visualizacionAccion(ruta, 'ELIMINAR');
        this.permisobtnDuplicar = this.autentificador.visualizacionAccion(ruta, 'DUPLICAR');
        //Creamos el desplegable de acciones
        this.Acciones = new Array<AccionesDialogo>();
        if (this.permisobtnEliminar) {
          this.Acciones.push({ accion: 'borrar', texto: 'Borrar', funcion: 'BorrarSeleccionados' });
        }
      }
    );
    //Seguimos
    this.cargarDatos();
    this.resetearFiltrosActivos();


    this.editorRegistroForm = this.formBuilder.group({

      // los siguientes campos son del formulario pero estan en variables sueltas
      // tipoControlTipoControl
      // tipoControlGruposActividades
      // tiposTipoEntradaTaller
      // editorListaIdiomas
      nombre: ['', Validators.required],
      numProyecto: [''],
      activo: [''],
      pordefecto: [''],
      horaInicio: [''],
      horaFin: [''],
      controlaraforo: [''],
      controlarafororeserva: [''],
      tipoaforo: ['', Validators.required],
      aforomaximo: ['', [Validators.pattern('^-?[0-9]*$'), Validators.min(0)]],
      limiteaforo: ['', [Validators.pattern('^-?[0-9]*$')]],
      aforominimo: ['', [Validators.pattern('^-?[0-9]*$'), Validators.min(0)]],
      aforointernet: ['', [Validators.pattern('^-?[0-9]*$'), Validators.min(0)]],
      tiempoextra: ['', [Validators.pattern('^-?[0-9]*$')]],
      mostrarinternet: [''],
      controlsesiones: [''],
      talleractividad: [''],
      enviarEmail: [''],
      controlExterno: [''],
      grupoActividad: [''],
      tipoControlGruposRecinto: [''],
      subGrupoRecinto: [''],
      butacas: [''],
      forma: [''],
      coordenadas: [''],
      ordenfilas: [''],
      ordenbutacas: [''],

    });


    this.editorFiltrosForm = this.formBuilder.group({
      nombre: [''],
      activo: [''],
      butacas: [''],
      mostrarinternet: [''],
      tipoControl: [''],
      tipotaller: [''],
      tipoaforo: ['']
    });

    this.formControlListaColumnas = new FormControl(this.displayedColumns);
    this.formControlListaFiltros = new FormControl(this.displayedFilters);

  }

  // un getter para facilitar el acceso a los campos del form
  get f() {
    return this.editorRegistroForm.controls;
  } //Editor
  get fFiltro() {
    return this.editorFiltrosForm.controls;
  } //Filtros

  model = new traspasomodelo();

  //IdiomasconURL: IdiomasconURL = new IdiomasconURL();

  AnadirRegistro() {
    Object.keys(this.editorRegistroForm.controls).forEach(key => {
      this.editorRegistroForm.get(key).reset();
    });
    this.itemPkId = '';
    //this.IdiomasconURL.
    //Ponemos los idiomas a reset
    this.editorListaIdiomas = new Array<IdiomasconURL>();
    this.tiposIdiomas.forEach(idioma => {
      this.editorListaIdiomas.push(<IdiomasconURL>{ CodIdioma: idioma.CodIdioma, Nombre: null, Descripcion: null, URL: null });
    });
    this.model = new traspasomodelo();
    this.editando = true;
    this.alertaServicio.limpiar();
    this.compruebaPorDefectovalor = this.compruebaPorDefecto('false');
    //Ponemos los booleanos a false, pqu si no se modifican el check no les da valor
    this.model.Activo = false;
    this.model.subGrupoRecinto = false;
    this.model.ControlarAforo = false;
    this.model.ControlarAforoReserva = false;
    this.model.Taller = false;
    this.tiposTipoEntradaRecinto = [];
    this.tiposTipoEntradaTaller = [];
    this.model.Butacas = false;
    this.model.MostrarInternet = false;
    this.model.PorDefecto = false;
    this.model.NumSesion = false;

    this.model.HoraInicio = undefined;
    this.model.HoraFin = undefined;

    this.editorRegistroForm.value.numProyecto = '';
    this.editorRegistroForm.value.controlExterno = '';
    this.editorRegistroForm.value.enviarEmail = '';




  }

  async editar(item) {
    Object.keys(this.editorRegistroForm.controls).forEach(key => {
      this.editorRegistroForm.get(key).reset();
    });
    let objtraspaso = new traspasomodelo();
    this.compruebaPorDefectovalor = this.compruebaPorDefecto(item.PorDefecto);
    objtraspaso.PkId = item.PkId;
    objtraspaso.Nombre = item.Nombre;
    objtraspaso.Activo = this.globales.aBooleano(item.Activo);
    objtraspaso.subGrupoRecinto = this.globales.aBooleano(item.subGrupoRecinto);
    objtraspaso.ControlarAforo = this.globales.aBooleano(item.ControlarAforo);
    objtraspaso.ControlarAforoReserva = this.globales.aBooleano(item.ControlarAforoReserva);
    objtraspaso.TipoAforo = item.TipoAforo;
    objtraspaso.AforoMaximo = item.AforoMaximo;
    objtraspaso.LimiteAforo = item.LimiteAforo;
    if (item.HoraInicio == null || item.HoraInicio.trim() == '') {
      objtraspaso.HoraInicio = null;
    } else {
      objtraspaso.HoraInicio = item.HoraInicio.trim().substring(10, 20).trim().length < 8 ? '0' + item.HoraInicio.trim().substring(10, 20).trim() : item.HoraInicio.trim().substring(10, 20).trim();
    }
    if (item.HoraFin == null || item.HoraFin.trim() == '') {
      objtraspaso.HoraFin = null;
    } else {
      objtraspaso.HoraFin = item.HoraFin.trim().substring(10, 20).trim().length < 8 ? '0' + item.HoraFin.trim().substring(10, 20).trim() : item.HoraFin.trim().substring(10, 20).trim();
    }
    objtraspaso.PorDefecto = this.globales.aBooleano(item.PorDefecto);
    objtraspaso.MinimoAforo = item.MinimoAforo;
    objtraspaso.Taller = this.globales.aBooleano(item.Taller);
    objtraspaso.Butacas = this.globales.aBooleano(item.Butacas);
    objtraspaso.MostrarInternet = this.globales.aBooleano(item.MostrarInternet);
    objtraspaso.MaximoInternet = item.MaximoInternet;
    objtraspaso.GrupoActividadId = item.GrupoActividadId;
    objtraspaso.CodigoTipoControl = item.CodigoTipoControl;
    objtraspaso.Secciones = item.Secciones;
    objtraspaso.GrupoRecintosId = item.GrupoRecintosId;
    objtraspaso.Shape = item.Shape;
    objtraspaso.Coords = item.Coords;
    objtraspaso.OrdenFilas = item.OrdenFilas;
    objtraspaso.OrdenButacas = item.OrdenButacas;
    objtraspaso.Numeracion = item.Numeracion;
    objtraspaso.NumSesion = item.NumSesion;
    objtraspaso.TiempoExtra = item.TiempoExtra;

    //IDIOMAS
    //
    this.editorListaIdiomas = new Array<IdiomasconURL>();
    this.tiposIdiomas.forEach(idioma => {
      let idiomafiltrado = item.Idiomas.filter(x => x.CodIdioma == idioma.CodIdioma);
      if (idiomafiltrado.length > 0) {
        this.editorListaIdiomas.push(<IdiomasconURL>{
          CodIdioma: idioma.CodIdioma,
          Nombre: idiomafiltrado[0].Nombre,
          Descripcion: idiomafiltrado[0].Descripcion,
          URL: idiomafiltrado[0].URL
        });
      } else {
        this.editorListaIdiomas.push(<IdiomasconURL>{ CodIdioma: idioma.CodIdioma, Nombre: null, Descripcion: null, URL: null });
      }
    });
    this.model = objtraspaso;

    //Cargamos entradas especificas de Taller y de Recinto
    await this.CargarDatosTiposEntradas(1, objtraspaso.PkId);
    await this.CargarDatosTiposEntradas(2, objtraspaso.PkId);
    //Last Touches ;)
    this.editando = true;
    //
    this.alertaServicio.limpiar();
  }

  guardar() {
    //TODO, de momento no se guarda hay que cambiar todos los modelos y lo que se pasa
    //y el procedimiento (no es motivo grupo) WOIT
    let insertando = this.model.PkId == null;
    let globales: Globales = new Globales();
    if (!this.editorRegistroForm.invalid) {
      //Comprobamos que el Pkid no sea null (estariamos insertando)
      let objtraspaso = new traspasomodelo;
      if (this.model.PkId != null) {
        objtraspaso = this.dataSource.data.map<traspasomodelo>(function (item) {
          return {
            PkId: item.PkId,
            Nombre: item.Nombre,
            Activo: globales.aBooleano(item.Activo),
            ControlarAforo: globales.aBooleano(item.ControlarAforo),
            ControlarAforoReserva: globales.aBooleano(item.ControlarAforoReserva),
            TipoAforo: item.TipoAforo,
            AforoMaximo: item.AforoMaximo,
            LimiteAforo: item.LimiteAforo,
            HoraInicio: item.HoraInicio,
            HoraFin: item.HoraFin,
            PorDefecto: globales.aBooleano(item.PorDefecto),
            MinimoAforo: item.MinimoAforo,
            Taller: globales.aBooleano(item.Taller),
            Butacas: globales.aBooleano(item.Butacas),
            MostrarInternet: globales.aBooleano(item.MostrarInternet),
            MaximoInternet: item.MaximoInternet,
            GrupoActividadId: item.GrupoActividadId,
            CodigoTipoControl: item.CodigoTipoControl,
            Secciones: item.Secciones,
            GrupoRecintosId: item.GrupoRecintosId,
            Shape: item.Shape,
            Coords: item.Coords,
            OrdenFilas: item.OrdenFilas,
            OrdenButacas: item.OrdenButacas,
            Numeracion: item.Numeracion,
            NumSesion: globales.aBooleano(item.NumSesion),
            TiempoExtra: item.TiempoExtra,
            subGrupoRecinto: globales.aBooleano(item.subGrupoRecinto),

            Idiomas: item.Idiomas,
            deserialize: null
          };
        }).find(item => item.PkId == this.model.PkId);
      }
      objtraspaso.Nombre = this.model.Nombre;
      objtraspaso.subGrupoRecinto = this.model.subGrupoRecinto;
      objtraspaso.Activo = this.model.Activo;
      objtraspaso.GrupoRecintosId = this.model.GrupoRecintosId;

      objtraspaso.ControlarAforo = this.model.ControlarAforo;
      objtraspaso.ControlarAforoReserva = this.model.ControlarAforoReserva;
      objtraspaso.TipoAforo = this.model.TipoAforo;
      objtraspaso.AforoMaximo = this.model.AforoMaximo;
      objtraspaso.LimiteAforo = this.model.LimiteAforo;
      objtraspaso.HoraInicio = this.model.HoraInicio;
      objtraspaso.HoraFin = this.model.HoraFin;
      objtraspaso.PorDefecto = this.model.PorDefecto;
      objtraspaso.MinimoAforo = this.model.MinimoAforo;
      objtraspaso.Taller = this.model.Taller;
      objtraspaso.Butacas = this.model.Butacas;
      objtraspaso.MostrarInternet = this.model.MostrarInternet;
      objtraspaso.MaximoInternet = this.model.MaximoInternet;
      objtraspaso.GrupoActividadId = this.model.GrupoActividadId;
      objtraspaso.CodigoTipoControl = this.model.CodigoTipoControl;
      objtraspaso.Secciones = this.model.Secciones;
      objtraspaso.Shape = this.model.Shape;
      objtraspaso.Coords = this.model.Coords;
      objtraspaso.OrdenFilas = this.model.OrdenFilas;
      objtraspaso.OrdenButacas = this.model.OrdenButacas;
      objtraspaso.Numeracion = this.model.Numeracion;
      objtraspaso.NumSesion = this.model.NumSesion;
      objtraspaso.TiempoExtra = this.model.TiempoExtra;

      objtraspaso.Idiomas = this.editorListaIdiomas;
      let entradas_recinto_taller: Array<string>;
      if (objtraspaso.Taller) {
        entradas_recinto_taller = this.tiposTipoEntradaTaller.map(x => x.EntradaId);
      } else {
        entradas_recinto_taller = this.tiposTipoEntradaRecinto.map(x => x.EntradaId);
      }
      //Salvar a API 
      this.servicioAPI.insUpdRecintos(
        this.model.PkId,
        this.model.Nombre,
        globales.aBit(this.model.Activo).toString(),
        this.model.GrupoRecintosId,
        globales.aBit(this.model.ControlarAforo).toString(),
        globales.aBit(this.model.ControlarAforoReserva).toString(),
        this.model.TipoAforo, this.model.AforoMaximo, this.model.LimiteAforo,
        this.model.HoraInicio, this.model.HoraFin,
        globales.aBit(this.model.PorDefecto).toString(),
        this.model.MinimoAforo,
        globales.aBit(this.model.Taller).toString(),
        globales.aBit(this.model.Butacas).toString(),
        globales.aBit(this.model.MostrarInternet).toString(),
        this.model.MaximoInternet,
        this.model.GrupoActividadId,
        this.model.CodigoTipoControl,
        this.model.Secciones,
        this.model.Shape,
        this.model.Coords, this.model.OrdenFilas, this.model.OrdenButacas, this.model.Numeracion,
        globales.aBit(this.model.NumSesion).toString(),
        this.model.TiempoExtra,
        globales.aBit(this.model.subGrupoRecinto).toString(),
        this.editorListaIdiomas,
        entradas_recinto_taller == null ? [] : entradas_recinto_taller)
        .pipe(first())
        .subscribe(data => {
          this.respuestaAPI = new Recintos_Respuesta().deserialize(data);
          if (this.respuestaAPI.DatosResult != null) {
            this.enviandoEmail = false;
            this.alertaServicio.success('Datos Guardados Correctamente');
            //
            let max = Math.max.apply(Math, this.dataSource.data.map(function (item) {
              return item.PkId;
            }));
            //
            //Hacemos un push al data para que se muestre
            if (insertando) {
              // this.model.PkId = ''+(max + 1);
              // let pusheddata =   this.dataSource.data;
              // pusheddata .push(this.modeloaclaseAPI(this.model));
              // this.dataSource.data = pusheddata;
              this.cargarDatosAsync();
            } else {
              this.cargarDatosAsync();
            }
            this.editando = false;
          } else {
            this.alertaServicio.error(this.respuestaAPI.Mensajes[0].DescripcionMensaje);
            this.enviandoEmail = false;
            this.editando = false;
          }

        },
          error => {
            this.enviandoEmail = false;
            this.alertaServicio.error(error);
            this.editando = false;
          }
        );

    }
  }

  duplicarRegistro(row) {
    //this.model = new CustomanUsuario();
    if (this.model.PkId != null) {
      this.model.PkId = null; //si no estamos ya con uno nuevo, ponemos el Pkid a null y lo trataremos como insercion.
    }
  }

  pregborrarRegistro() {
    //Borrar
    if (this.model.PkId != null) {//Si no estamos añadiendo
      this.titulo_dialogo = this.translation.Borrar;
      this.mensaje_dialogo = this.translation.EstaSeguroQueDeseaBorrarEsteRegistro;
      this.ok_dialogo = this.translation.Si;
      this.ko_dialogo = this.translation.Cancelar;
      this.openDialog();
    }
  }

  async applyFilter(filterValue: string) {
    this.alertaServicio.limpiar();
    await this.AplicarFiltros();
    this.dataSource.filter = filterValue.trim().toLowerCase();

    this.filtrosactivosModelo.textoGeneral = filterValue.trim();
    this.filtrosactivos = Object.assign({}, this.filtrosactivosModelo);


    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.alertaServicio.limpiar();
  }

  //Acciones
  HacerAccion(any) {
    this.alertaServicio.limpiar();
    if (any != null && any != undefined) {
      if (this.seleccion.selected == null || this.seleccion.selected.length == 0) {
        this.titulo_dialogo = this.translation.Error;
        this.mensaje_dialogo = this.translation.NoHayRegistrosSeleccionadosParaEstaAccion;
        this.ok_dialogo = this.translation.Ok;
        this.openDialogSeleccionVacia();
        //
      } else {
        this.titulo_dialogo = this.translation.ConfirmarBorrado;
        this.mensaje_dialogo = this.translation.VaAProcederAlBorradoDe + this.seleccion.selected.length.toString() + this.translation.RegistrosDeseaConfirmarElBorrado;
        this.ok_dialogo = this.translation.Si;
        this.ko_dialogo = this.translation.No;
        this.openDialogBorradoSeleccionadas();
      }
    }

  }

  MostrarFiltros() {
    this.alertaServicio.limpiar();
    this.panelSelectorFiltrosvisible = !this.panelSelectorFiltrosvisible;
    this.panelColumnasvisible = false;
    this.formControlListaFiltros = new FormControl(this.filtersToDisplay);
  }

  MostrarColumnas() {
    this.alertaServicio.limpiar();
    this.panelColumnasvisible = !this.panelColumnasvisible;
    //Cambiar el boton
    this.formControlListaColumnas = new FormControl(this.columnsToDisplay);
  }

  visibilidadColumna(evento, seleccioncolumnas) {
    if (evento != null && seleccioncolumnas != null) {
      //

      if (!evento.option.selected) {
        //Borramos si no nos quedamos sin columnas
        if (this.columnsToDisplay.length) {
          this.columnsToDisplay.splice(this.columnsToDisplay.findIndex(x => x === evento.option.value), 1);
        }
      } else {
        //Añadimos
        this.columnsToDisplay = [];
        for (let columna of this.displayedColumns) {
          if (seleccioncolumnas.findIndex(x => x.value === columna) >= 0) {
            this.columnsToDisplay.push(columna);
          }
        }
        // for(let a of seleccioncolumnas) {
        //   
      }
    }
  }

  traeNombreIdiomaxID(id) {
    let respuesta = this.tiposIdiomas.filter(x => x.PkId == id);
    return respuesta == null || respuesta.length == 0 ? null : respuesta[0].CodIdioma;
  }

  traeNombreGRecintoxID(id) {
    if (!this.tiposGruposRecinto) {
      return null;
    }
    let respuesta = this.tiposGruposRecinto.filter(x => x.PkId == id);
    return respuesta == null || respuesta.length == 0 ? null : respuesta[0].Nombre;
  }

  traeNombreTipoControlxID(id) {
    let respuesta = this.tiposTiposControl.filter(x => x.pkId == id);
    return respuesta == null || respuesta.length == 0 ? null : respuesta[0].Nombre;
  }

  compruebaPorDefecto(valor: string) {
    //
    if (!(valor.trim().toLowerCase() == 'true' || valor.trim().toLowerCase() == '1')) {
      return this.dataSource != null && this.dataSource.data != null && this.dataSource.data.length != 0 &&
        this.dataSource.data.some(x => x.PorDefecto.trim() == '1' || x.PorDefecto.toLocaleLowerCase().trim() == 'true');
    } else {
      return false;
    }
  }

  cambiaNombre(evento, idioma) {
    this.editorListaIdiomas.find(x => x.CodIdioma == idioma).Nombre = evento;
    //
  }

  cambiaDescripcion(evento, idioma) {
    this.editorListaIdiomas.find(x => x.CodIdioma == idioma).Descripcion = evento;
    //
  }

  cambiaURL(evento, idioma) {
    this.editorListaIdiomas.find(x => x.CodIdioma == idioma).URL = evento;
    //
  }

  cambiaActividadTaller(evento) {
    //
    //this.CargarDatosTiposEntradas(evento.target.checked?2:1,this.model.PkId);
    this.tallerActividad = evento.target.checked;
    if (!this.tallerActividad) {
      this.tiposTipoEntradaTaller = this.tiposTipoEntradaRecinto;
      this.tiposTipoEntradaRecinto = [];
    } else {
      this.tiposTipoEntradaRecinto = this.tiposTipoEntradaTaller;
      this.tiposTipoEntradaTaller = [];
    }

  }

  cambiaButacas() {
    this.butacasCheck = this.model.Butacas;
  }

  formateaIdioma(idioma: Array<IdiomaGR>, columna: ColumnaIdiomas) {
    //
    //
    try {
      return columna.textoHeader.trim().toLowerCase() == 'nombre' ? idioma.filter(x => x.CodIdioma == columna.CodIdioma)[0].Nombre : idioma.filter(x => x.CodIdioma == columna.CodIdioma)[0].Descripcion;
    } catch (e) {
      //
      return null
    }

  }

  //Click en el boton de aplicar Filtros a los datos
  async AplicarFiltros(filtro?: filtrosActivos) {
    this.alertaServicio.limpiar();
    //CArgamos los datos de muevo solo porsiacaso
    await this.cargarDatosAsync().then(
      respuesta => {
        let siono = new Boolean(respuesta);
        //
        if (siono) {
          let datostemporales = this.dataSource.data;
          //aplicamos los filtros sobre los datos temporales
          if (filtro != null) {

            if (filtro.Nombre != null && filtro.Nombre.trim() != '') {
              datostemporales = datostemporales.filter(x => x.Nombre.indexOf(filtro.Nombre.trim()) >= 0);
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.Nombre = filtro.Nombre.trim();
            }
            this.fFiltro['nombre'].setValue(filtro.Nombre.trim());

            if (filtro.Activo != null && filtro.Activo.trim() != '') {
              datostemporales = datostemporales.filter(x => x.Activo.trim().toLowerCase() == (filtro.Activo.trim().toLowerCase() == 'si' ? '1' : '0'));
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.Activo = filtro.Activo.trim();
            }
            this.fFiltro['activo'].setValue(filtro.Activo.trim());

            if (filtro.CodigoTipoControl != null && filtro.CodigoTipoControl != '') {
              datostemporales = datostemporales.filter(x => x.CodigoTipoControl == filtro.CodigoTipoControl);
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.CodigoTipoControl = filtro.CodigoTipoControl;
            }
            this.fFiltro['tipoControl'].setValue(filtro.CodigoTipoControl);

            if (filtro.TipoAforo != null && filtro.TipoAforo != '') {
              datostemporales = datostemporales.filter(x => x.TipoAforo == filtro.TipoAforo);
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.TipoAforo = filtro.TipoAforo;
            }
            this.fFiltro['tipoaforo'].setValue(filtro.TipoAforo);

            if (filtro.Butacas != null && filtro.Butacas.trim() != '') {
              datostemporales = datostemporales.filter(x => x.Butacas.trim().toLowerCase() == (filtro.Butacas.trim().toLowerCase() == 'si' ? '1' : '0'));
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.Butacas = filtro.Butacas.trim();
            }
            this.fFiltro['butacas'].setValue(filtro.Butacas.trim());

            if (filtro.MostrarInternet != null && filtro.MostrarInternet.trim() != '') {
              datostemporales = datostemporales.filter(x => x.MostrarInternet.trim().toLowerCase() == (filtro.MostrarInternet.trim().toLowerCase() == 'si' ? '1' : '0'));
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.MostrarInternet = filtro.MostrarInternet.trim();
            }
            this.fFiltro['mostrarinternet'].setValue(filtro.MostrarInternet.trim());

            if (filtro.Taller != null && filtro.Taller.trim() != '') {
              datostemporales = datostemporales.filter(x => x.Taller.trim().toLowerCase() == (filtro.Taller.trim().toLowerCase() == 'si' ? '1' : '0'));
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.Taller = filtro.Taller.trim();
            }
            this.fFiltro['tipotaller'].setValue(filtro.Taller.trim());
          } else {
            //
            if (this.fFiltro['nombre'].value != null && this.fFiltro['nombre'].value.trim() != '') {
              datostemporales = datostemporales.filter(x => x.Nombre.indexOf(this.fFiltro['nombre'].value.trim()) >= 0);
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.Nombre = this.fFiltro['nombre'].value;
            }
            // 
            if (this.fFiltro['activo'].value != null && this.fFiltro['activo'].value.trim() != '') {
              datostemporales = datostemporales.filter(x => x.Activo.trim().toLowerCase() == (this.fFiltro['activo'].value.trim().toLowerCase() == 'si' ? '1' : '0'));
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.Activo = this.fFiltro['activo'].value;
            }
            // 
            if (this.fFiltro['tipoControl'].value != null && this.fFiltro['tipoControl'].value != '') {
              datostemporales = datostemporales.filter(x => x.CodigoTipoControl == this.fFiltro['tipoControl'].value);
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.CodigoTipoControl = this.fFiltro['tipoControl'].value;
            }
            // 
            if (this.fFiltro['tipoaforo'].value != null && this.fFiltro['tipoaforo'].value != '') {
              datostemporales = datostemporales.filter(x => x.TipoAforo == this.fFiltro['tipoaforo'].value);
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.TipoAforo = this.fFiltro['tipoaforo'].value;
            }
            // 
            if (this.fFiltro['butacas'].value != null && this.fFiltro['butacas'].value.trim() != '') {
              datostemporales = datostemporales.filter(x => x.Butacas.trim().toLowerCase() == (this.fFiltro['butacas'].value.trim().toLowerCase() == 'si' ? '1' : '0'));
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.Butacas = this.fFiltro['butacas'].value;
            }
            // 
            if (this.fFiltro['mostrarinternet'].value != null && this.fFiltro['mostrarinternet'].value.trim() != '') {
              datostemporales = datostemporales.filter(x => x.MostrarInternet.trim().toLowerCase() == (this.fFiltro['mostrarinternet'].value.trim().toLowerCase() == 'si' ? '1' : '0'));
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.MostrarInternet = this.fFiltro['mostrarinternet'].value;
            }
            // 
            if (this.fFiltro['tipotaller'].value != null && this.fFiltro['tipotaller'].value.trim() != '') {
              datostemporales = datostemporales.filter(x => x.Taller.trim().toLowerCase() == (this.fFiltro['tipotaller'].value.trim().toLowerCase() == 'si' ? '1' : '0'));
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.Taller = this.fFiltro['tipotaller'].value;
            }
          }

          this.dataSource.data = datostemporales;

          if (this.filtrosactivosModelo.textoGeneral != null && this.filtrosactivosModelo.textoGeneral.trim() != '') {
            this.dataSource.filter = this.filtrosactivosModelo.textoGeneral.trim().toLowerCase();
          } else {
            //Solo borramos el input text, el filtro se actualiza Ok
            //this.filtrogeneral.nativeElement.value = '';
            //
          }
          this.filtrosactivos = Object.assign({}, this.filtrosactivosModelo);

          if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
          }
        } else {
          //No hemos podido cargar los datos
        }

      },
      error => {
        //No hemos podido cargar los datos
      });
  }

  //Para resetear filtros, se pasa el filtro o se ejecuta sin parametro y se resetean todos
  resetearFiltrosActivos(filtro?: string) {
    this.filtrosactivosModelo.Nombre = filtro == null || filtro == 'Nombre' ? '' : this.filtrosactivosModelo.Nombre;
    this.filtrosactivosModelo.Activo = filtro == null || filtro == 'Activo' ? '' : this.filtrosactivosModelo.Activo;
    this.filtrosactivosModelo.Butacas = filtro == null || filtro == 'Butacas' ? '' : this.filtrosactivosModelo.Butacas;
    this.filtrosactivosModelo.MostrarInternet = filtro == null || filtro == 'MostrarInternet' ? '' : this.filtrosactivosModelo.MostrarInternet;
    this.filtrosactivosModelo.CodigoTipoControl = filtro == null || filtro == 'CodigoTipoControl' ? null : this.filtrosactivosModelo.CodigoTipoControl;
    this.filtrosactivosModelo.Taller = filtro == null || filtro == 'Taller' ? '' : this.filtrosactivosModelo.Taller;
    this.filtrosactivosModelo.TipoAforo = filtro == null || filtro == 'TipoAforo' ? '' : this.filtrosactivosModelo.TipoAforo;
    this.filtrosactivosModelo.textoGeneral = filtro == null || filtro == 'General' ? '' : this.filtrosactivosModelo.textoGeneral;
    this.filtrosactivos = Object.assign({}, this.filtrosactivosModelo);
    this.AplicarFiltros(this.filtrosactivos);
    if (this.editorFiltrosForm != null) {
      this.editorFiltrosForm.updateValueAndValidity({ onlySelf: true });
      this.editorFiltrosForm.markAsPristine();
      //
    }

  }

  hayFiltros(filtros: filtrosActivos): boolean {
    if ((filtros.Nombre != null && filtros.Nombre.trim() != '') || (filtros.Activo != null && filtros.Activo.trim() != '') || (filtros.TipoAforo != null && filtros.TipoAforo.trim() != '') ||
      (filtros.Butacas != null && filtros.Butacas.trim() != '') || filtros.CodigoTipoControl != null || (filtros.MostrarInternet != null && filtros.MostrarInternet.trim() != '') ||
      (filtros.Taller != null && filtros.Taller.trim() != '') ||
      (filtros.textoGeneral != null && filtros.textoGeneral.trim() != '')) {
      return true;
    } else {
      return false;
    }
  }

  //Para que funcione le mat select cuando al option le metes objetos en lugar de strings simples
  comparaObjetosSelect(o1: any, o2: any) {
    return o1 && o2 ? o1.id === o2.id || o1 === o2.id || o1.id === o2 : o1 === o2;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.seleccion.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.seleccion.clear() :
      this.dataSource.data.forEach(row => this.seleccion.select(row));
  }

  ///Validador


  ///Funcionalidades

  cargarDatos() {
    //Cargamos Datos
    this.servicioAPI.getGruposActividadTaller().pipe(first())
      .subscribe(
        data => {
          this.tiposGruposActividadesAPI = new grupos_actividad_taller_Respuesta().deserialize(data);
          if (this.tiposGruposActividadesAPI.DatosResult != null) {
            this.tiposGruposActividades = this.tiposGruposActividadesAPI.DatosResult.customanGrupoActividadTaller;
            //--2-->
            this.servicioAPI.getTiposControl().pipe(first())
              .subscribe(
                data => {
                  this.tiposTiposControlAPI = new TiposControl_Respuesta().deserialize(data);
                  if (this.tiposTiposControlAPI.DatosResult != null) {
                    this.tiposTiposControl = this.tiposTiposControlAPI.DatosResult.customanTiposControl;
                    //--1-->
                    this.servicioAPI.getIdiomas(null, null, '1').pipe(first())
                      .subscribe(
                        data => {
                          this.tiposIdiomasAPI = new Idiomas_Respuesta().deserialize(data);
                          if (this.tiposIdiomasAPI.DatosResult != null) {
                            this.tiposIdiomas = this.tiposIdiomasAPI.DatosResult.ListaIdiomas;
                            //
                            //this.tiposIdiomasConMotivo = this.tiposIdiomas.filter(x => x.Activo.trim().toLowerCase() == 'true' || x.Activo.trim().toLowerCase() == '1')
                            //var tiposUsuariotmp = this.tiposIdiomas;
                            this.servicioAPI.getGruposRecintos(null, null, '1').pipe(first())
                              .subscribe(
                                data => {
                                  this.tiposGruposRecintoAPI = new GruposRecintos_Respuesta().deserialize(data);
                                  if (this.tiposGruposRecintoAPI.DatosResult != null) {
                                    
                                    this.tiposGruposRecinto = this.tiposGruposRecintoAPI.DatosResult.ListaGrupoRecintos;
                                    //this.tiposGruposActivos = this.tiposGrupos.filter(x=> this.dataSource.data.findIndex(y => y.GrupoId === x.PkId) >=0 )
                                    this.servicioAPI.getRecintos().pipe(first())
                                      .subscribe(
                                        data => {
                                          this.respuestaAPI = new Recintos_Respuesta().deserialize(data);
                                          if (this.respuestaAPI.DatosResult != null) {
                                            this.dataSource = new MatTableDataSource(this.respuestaAPI.DatosResult.ListaRecintos);
                                            this.dataSource.paginator = this.paginator;
                                            this.dataSource.sort = this.sort;
                                            //
                                            //vamos a asociar las columnas de Idiomas que tenemos que añadir.
                                            this.columnasIdiomas = new Array<ColumnaIdiomas>();
                                            this.displayedColumns = ['select', 'Nombre', 'Activo', 'PorDefecto', 'ControlaAforo', 'ControlarAforoReserva', 'TipoAforo', 'AforoMaximo',
                                              'LimiteAforo', 'HoraInicio', 'HoraFin', 'MinimoAforo', 'Taller', 'Butacas', 'MostrarInternet', 'MaximoInternet', 'GrupoActividadId',
                                              'CodigoTipoControl', 'GrupoRecintosId', 'Shape', 'Coords', 'OrdenFilas', 'OrdenButacas', 'Numeracion', 'NumSesion',
                                              'TiempoExtra', 'subGrupoRecinto'];
                                            //No sabemos si todos los elementos tiene todos los idiomas, ni siquiera si los comparten, nos vemos obligados a recorrerlos
                                            //   para saber las columnas que necesitamos
                                            this.respuestaAPI.DatosResult.ListaRecintos.forEach(elementoGR => {
                                              elementoGR.Idiomas.forEach(elementoI => {
                                                if (!(this.columnasIdiomas.filter(x => x.CodIdioma == elementoI.CodIdioma).length > 0)) {
                                                  this.columnasIdiomas.push(<ColumnaIdiomas>({
                                                    textoHeader: 'Nombre',
                                                    CodIdioma: elementoI.CodIdioma
                                                  }));
                                                  this.displayedColumns.push(elementoI.CodIdioma + '_Nombre');
                                                  this.columnsToDisplay.push(elementoI.CodIdioma + '_Nombre');
                                                  this.columnasIdiomas.push(<ColumnaIdiomas>({
                                                    textoHeader: 'Descripcion',
                                                    CodIdioma: elementoI.CodIdioma
                                                  }));
                                                  this.displayedColumns.push(elementoI.CodIdioma + '_Descripcion');
                                                  this.columnsToDisplay.push(elementoI.CodIdioma + '_Descripcion');
                                                }
                                              });
                                            });
                                            //
                                            //this.columnsToDisplay = this.displayedColumns.slice();
                                            //return true;
                                          } else {
                                            this.alertaServicio.error(this.respuestaAPI.Mensajes[0].DescripcionMensaje);
                                            //return false;
                                          }

                                        },
                                        error => {
                                          this.alertaServicio.error(error);
                                          //return false;
                                        }
                                      );
                                  } else {
                                    this.alertaServicio.error(this.tiposGruposRecintoAPI.Mensajes[0].DescripcionMensaje);
                                    //return false;
                                  }

                                },
                                error => {
                                  this.alertaServicio.error(error);
                                  //return false;
                                }
                              );
                          } else {
                            this.alertaServicio.error(this.tiposIdiomasAPI.Mensajes[0].DescripcionMensaje);
                            //return false;
                          }

                        },
                        error => {
                          this.alertaServicio.error(error);
                          //return false;
                        }
                      );
                    //<--1--
                  } else {
                    this.alertaServicio.error(this.tiposTiposControlAPI.Mensajes[0].DescripcionMensaje);
                    //return false;
                  }

                },
                error => {
                  this.alertaServicio.error(error);
                  //return false;
                }
              );
            //<--2--
          } else {
            //this.alertaServicio.error(this.tiposGruposActividadesAPI.Mensajes[0].DescripcionMensaje);
            //return false;
          }

        },
        error => {
          this.alertaServicio.error(error);
          //return false;
        }
      );
  }

  async cargarDatosAsync(): Promise<boolean> {
    //Cargamos Datos
    let resultado: boolean = false;
    var tiposUsuariotmp;
    await this.servicioAPI.getIdiomasAsync(null, null, '1')
      .then(
        data => {
          this.tiposIdiomasAPI = new Idiomas_Respuesta().deserialize(data);
          if (this.tiposIdiomasAPI.DatosResult != null) {
            this.tiposIdiomas = this.tiposIdiomasAPI.DatosResult.ListaIdiomas;
            //
            //this.tiposGruposConMotivo = this.tiposGrupos.filter(x => x.ConMotivo.trim().toLowerCase() == 'true' || x.ConMotivo.trim().toLowerCase() == '1')
            //tiposUsuariotmp = this.tiposGrupos;
          } else {
            this.alertaServicio.error(this.tiposIdiomasAPI.Mensajes[0].DescripcionMensaje);
            resultado = false;
          }

        },
        error => {
          this.alertaServicio.error(error);
          resultado = false;
        }
      );
    //Lanzamos encadenado
    await this.servicioAPI.getGruposRecintosAsync(null, null, '1')
      .then(
        data => {
          this.tiposGruposRecintoAPI = new GruposRecintos_Respuesta().deserialize(data);
          
          if (this.tiposGruposRecintoAPI.DatosResult != null) {
            this.tiposGruposRecinto = this.tiposGruposRecintoAPI.DatosResult.ListaGrupoRecintos;
            //

            this.gruposRecintos = [];
            this.subGruposRecintos = [];

            this.tiposGruposRecinto.forEach(item => {
              if (item.subGrupo === '0') {
                this.gruposRecintos.push(item);
              }
              if (item.subGrupo === '1') {
                this.subGruposRecintos.push(item);
              }
            });


          } else {
            this.alertaServicio.error('GruposRecintos: ' + this.tiposGruposRecintoAPI.Mensajes[0].DescripcionMensaje);
            resultado = false;
          }

        },
        error => {
          this.alertaServicio.error(error);
          resultado = false;
        }
      );
    //Lanzamos encadenado
    await this.servicioAPI.getTiposControlAsync()
      .then(
        data => {
          this.tiposTiposControlAPI = new TiposControl_Respuesta().deserialize(data);
          if (this.tiposTiposControlAPI.DatosResult != null) {
            this.tiposTiposControl = this.tiposTiposControlAPI.DatosResult.customanTiposControl;
          } else {
            this.alertaServicio.error(this.tiposTiposControlAPI.Mensajes[0].DescripcionMensaje);
            resultado = false;
          }

        },
        error => {
          this.alertaServicio.error(error);
          resultado = false;
        }
      );
    //Lanzamos encadenado
    await this.servicioAPI.getGruposActividadTallerAsync()
      .then(
        data => {
          this.tiposGruposActividadesAPI = new grupos_actividad_taller_Respuesta().deserialize(data);
          if (this.tiposGruposActividadesAPI.DatosResult != null) {
            this.tiposGruposActividades = this.tiposGruposActividadesAPI.DatosResult.customanGrupoActividadTaller;
          } else {
            //this.alertaServicio.error(this.tiposGruposActividadesAPI.Mensajes[0].DescripcionMensaje);
            this.tiposGruposActividades = [];
          }

        },
        error => {
          this.alertaServicio.error(error);
          resultado = false;
        }
      );
    //Lanzamos encadenado
    await this.servicioAPI.getRecintosAsync()
      .then(
        data => {
          this.respuestaAPI = new Recintos_Respuesta().deserialize(data);
          if (this.respuestaAPI.DatosResult != null) {
            this.dataSource = new MatTableDataSource(this.respuestaAPI.DatosResult.ListaRecintos);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            //
            resultado = true;
          } else {
            this.alertaServicio.error(this.respuestaAPI.Mensajes[0].DescripcionMensaje);
            resultado = false;
          }

        },
        error => {
          this.alertaServicio.error(error);
          resultado = false;
        }
      );
    await this.CargarDatosTiposEntradas();
    return resultado;
  }

  async CargarDatosTiposEntradas(tipo?: number, recinto?: string): Promise<boolean> //tipo 0-null:Todas, 1:Recinto, 2:Taller/actividad
  {
    let resultado: boolean = false;
    await this.servicioAPI.getTiposEntradasAsync(null, null, tipo == null || tipo == 0 || recinto == null ? null : recinto, tipo == null || tipo == 0 ? null : (tipo - 1).toString())
      .then(
        data => {
          this.tiposTiposEntradaAPI = new TiposEntrada_Respuesta().deserialize(data);
          if (this.tiposTiposEntradaAPI.DatosResult != null) {
            switch (tipo) {
              case 1:
                this.tiposTipoEntradaRecinto = this.tiposTiposEntradaAPI.DatosResult.Lista;
                break;

              case 2:
                this.tiposTipoEntradaTaller = this.tiposTiposEntradaAPI.DatosResult.Lista;
                break;

              default:
                this.tiposTipoEntrada = this.tiposTiposEntradaAPI.DatosResult.Lista;
                break;
            }


            resultado = true;
          } else {
            switch (tipo) {
              case 1:
                this.tiposTipoEntradaRecinto = [];
                break;

              case 2:
                this.tiposTipoEntradaTaller = [];
                break;

              default:
                this.tiposTipoEntrada = [];
                break;
            }
            resultado = false;
          }

        },
        error => {
          this.alertaServicio.error(error);
          resultado = false;
        }
      );

    return resultado;
  }

  filtrarTiposEntrada(tipo?: boolean): TipoEntrada[] {
    let respuesta = [];
    let entradasRecinto = this.tiposTipoEntradaRecinto;
    let entradasTaller = this.tiposTipoEntradaTaller;
    if (tipo != null) {
      if (tipo) {
        if (entradasTaller != null && entradasTaller.length > 0) {
          respuesta = this.tiposTipoEntrada.filter(function (item) {
            return entradasTaller.findIndex(function (itemSub) {
              return itemSub.EntradaId == item.EntradaId;
            }) === -1;
          });
        } else {
          respuesta = this.tiposTipoEntrada;
        }
      } else {
        if (entradasRecinto != null && entradasRecinto.length > 0) {
          respuesta = this.tiposTipoEntrada.filter(function (item) {
            return entradasRecinto.findIndex(function (itemSub) {
              return itemSub.EntradaId == item.EntradaId;
            }) === -1;
          });
        } else {
          respuesta = this.tiposTipoEntrada;
        }
      }
    }
    return respuesta;
  }

  daTiposEntrada(tipo?: boolean): TipoEntrada[] {
    let respuesta = [];
    if (tipo != null) {
      if (!tipo) {
        respuesta = this.tiposTipoEntradaRecinto;
      } else {
        respuesta = this.tiposTipoEntradaTaller;
      }
    }
    return respuesta;
  }

  asignarTipoentrada(item, evento) {
    if (this.model.Taller) {
      this.tiposTipoEntradaTaller.push(item);
    } else {
      this.tiposTipoEntradaRecinto.push(item);
    }
  }

  desasignarTipoentrada(item, evento) {
    let arrtemp = [];
    if (this.model.Taller) {
      arrtemp = this.tiposTipoEntradaTaller.filter(function (x) {
        return x.EntradaId != item.EntradaId;
      });
      //
      this.tiposTipoEntradaTaller = arrtemp;
    } else {
      arrtemp = this.tiposTipoEntradaRecinto.filter(function (x) {
        return x.EntradaId != item.EntradaId;
      });
      //
      this.tiposTipoEntradaRecinto = arrtemp;
    }
  }

  deasignarTodas() {
    if (this.model.Taller) {
      this.tiposTipoEntradaTaller = [];
    } else {
      this.tiposTipoEntradaRecinto = [];
    }
  }

  asignarTodas() {
    if (this.model.Taller) {
      this.tiposTipoEntradaTaller = this.tiposTipoEntrada;
    } else {
      this.tiposTipoEntradaRecinto = this.tiposTipoEntrada;
    }
  }

  //Cambiar el modelo API (string) a modelo con boolean para los checks
  modeloaclaseAPI(item: traspasomodelo): Recintos {
    let respuesta = new Recintos;
    respuesta.Activo = item.Activo ? 'True' : 'False';
    respuesta.Nombre = item.Nombre;
    respuesta.subGrupoRecinto = item.subGrupoRecinto ? 'True' : 'False';
    respuesta.PkId = item.PkId;
    respuesta.GrupoRecintosId = item.GrupoRecintosId;
    respuesta.ControlarAforo = item.ControlarAforo ? 'True' : 'False';
    respuesta.ControlarAforoReserva = item.ControlarAforoReserva ? 'True' : 'False';
    respuesta.TipoAforo = item.TipoAforo;
    respuesta.AforoMaximo = item.AforoMaximo;
    respuesta.LimiteAforo = item.LimiteAforo;
    respuesta.HoraInicio = item.HoraInicio;
    respuesta.HoraFin = item.HoraFin;
    respuesta.PorDefecto = item.PorDefecto ? 'True' : 'False';
    respuesta.MinimoAforo = item.MinimoAforo;
    respuesta.Taller = item.Taller ? 'True' : 'False';
    respuesta.Butacas = item.Butacas ? 'True' : 'False';
    respuesta.MostrarInternet = item.MostrarInternet ? 'True' : 'False';
    respuesta.MaximoInternet = item.MaximoInternet;
    respuesta.GrupoActividadId = item.GrupoActividadId;
    respuesta.CodigoTipoControl = item.CodigoTipoControl;
    respuesta.Secciones = item.Secciones;
    respuesta.Shape = item.Shape;
    respuesta.Coords = item.Coords;
    respuesta.OrdenFilas = item.OrdenFilas;
    respuesta.OrdenButacas = item.OrdenButacas;
    respuesta.Numeracion = item.Numeracion;
    respuesta.NumSesion = item.NumSesion ? 'True' : 'False';
    respuesta.TiempoExtra = item.TiempoExtra;

    respuesta.Idiomas = this.editorListaIdiomas;
    return respuesta;
  }

  //Se podria instanciar este openDialog si requiriesemos mas popups (este seria openDialogBorrar)
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: '250px',
      data: {
        titulo: this.titulo_dialogo,
        texto: this.mensaje_dialogo,
        eleccion: this.eleccion_dialogo,
        ok: this.ok_dialogo,
        ko: this.ko_dialogo
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      //
      this.eleccion_dialogo = result;
      //
      if (result == 'SI') {
        //Salvar a API
        this.servicioAPI.delRecintos(
          this.model.PkId)
          .pipe(first())
          .subscribe(
            data => {
              //this.respuestaAPI = new ItemMotGrupo_Respuesta().deserialize(data); WOIT
              if (this.respuestaAPI.DatosResult != null) {
                this.alertaServicio.success('Datos Borrados Correctamente');
                //
                //this.dataSource.data.splice(,1)
                this.dataSource.data = this.dataSource.data.filter(item => item.PkId !== this.model.PkId);
              } else {
                this.alertaServicio.error(this.respuestaAPI.Mensajes[0].DescripcionMensaje);
              }

            },
            error => {
              this.alertaServicio.error(error);
            }
          );
        this.editando = false;
      }
    });
  }

  //Instancia para Accionse sobre seleccion vacia
  openDialogSeleccionVacia(): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: '250px',
      data: { titulo: this.titulo_dialogo, texto: this.mensaje_dialogo, eleccion: this.eleccion_dialogo, ok: this.ok_dialogo, ko: null }
    });

    dialogRef.afterClosed().subscribe(result => {
      //
      this.accionselecionada = null;
    });
  }

  //Instancia Borrado Seleccionadas
  openDialogBorradoSeleccionadas(): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: '250px',
      data: {
        titulo: this.titulo_dialogo,
        texto: this.mensaje_dialogo,
        eleccion: this.eleccion_dialogo,
        ok: this.ok_dialogo,
        ko: this.ko_dialogo
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      //
      this.eleccion_dialogo = result;
      //
      if (result == 'SI') {
        var resultado: string = '';
        this.seleccion.selected.forEach(
          element => {
            //Recorremos los seleccionados y vamos borrando
            this.servicioAPI.delRecintosAsync(
              element.PkId)
              .then(
                data => {
                  //this.respuestaAPI = new ItemMotGrupo_Respuesta().deserialize(data); WOIT
                  if (this.respuestaAPI.DatosResult != null) {
                    this.dataSource.data = this.dataSource.data.filter(item => item.PkId !== element.PkId);
                  } else {
                    resultado += this.respuestaAPI.Mensajes[0].DescripcionMensaje + ' <BR />';
                  }
                },
                error => {
                  resultado += error + ' <BR />';
                }
              );
          });
        if (resultado != null && resultado.length > 0) {
          this.alertaServicio.error(resultado);
        } else {
          this.alertaServicio.success('Datos Borrados Correctamente');
        }

        //End For
      }
      //F Si
      this.seleccion.clear();
      this.accionselecionada = null;
    });
    //F Subscribe
  }

  duplicar(row: traspasomodelo) {
    this.model = row; // cambiar por los datos de la fila
    if (this.model.PkId != null) {
      this.model.PkId = null; //si no estamos ya con uno nuevo, ponemos el Pkid a null y lo trataremos como insercion.
    }
    this.editando = true;
    this.alertaServicio.limpiar();
    this.newEditar(row)
  }

  borrar(pkId: string): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: '250px',
      data: {
        titulo: this.translation.BorrarCliente, texto: this.translation.EstaSeguroQueDeseaBorrarElRegistro,
        eleccion: this.translation.Patatas, ok: this.translation.Confirmar, ko: this.translation.Cancelar
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'SI') {
        this.servicioAPI.delRecintos(
          pkId)
          .pipe(first())
          .subscribe(
            data => {
              //this.respuestaAPI = new ItemMotGrupo_Respuesta().deserialize(data); WOIT
              if (this.respuestaAPI.DatosResult != null) {
                this.alertaServicio.success('Datos Borrados Correctamente');
                //
                //this.dataSource.data.splice(,1);
                this.dataSource.data = this.dataSource.data.filter(item => item.PkId !== pkId);
              } else {
                this.alertaServicio.error(this.respuestaAPI.Mensajes[0].DescripcionMensaje);
              }

            },
            error => {
              this.alertaServicio.error(error);
            }
          );
        this.editando = false;
      }
    });
  }

  visibilidadFiltro(evento, seleccionfiltros) {
    
    
    
    if (evento != null && seleccionfiltros != null) {
      //

      if (!evento.option.selected) {
        //Borramos si no nos quedamos sin filtros
        if (this.filtersToDisplay.length) {
          this.filtersToDisplay.splice(this.filtersToDisplay.findIndex(x => x === evento.option.value), 1);
        }
      } else {
        //Añadimos
        this.filtersToDisplay = [];
        for (let filtro of this.displayedFilters) {
          if (seleccionfiltros.findIndex(x => x.value === filtro) >= 0) {
            this.filtersToDisplay.push(filtro);
          }
        }
        // for(let a of seleccionfiltros) {
        //   
      }
    }
  }

  guardarPopup() {
    
    
    if (this.editorRegistroForm.value.enviarEmail !== '') {
      const dialogRef = this.dialog.open(GuardarPopupComponent, {
        // height: '220px',
        data: {
          mensaje: 'test',
          name: 'test',
          eMails: this.editorRegistroForm.value.enviarEmail
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        
        // 1 = Cancelar
        // 2 = No Enviar
        // 3 = Modificar y Enviar
        // 4 = Enviar

        this.listaEmailsEnvio = [];

        switch (result.action) {
          case 1: // Cancelar
            break;
          case 2: // No Enviar
            this.newGuardar();
            break;
          case 3: // Modificar y Enviar
            this.listaEmailsEnvio = this.stringToEmailList(result.data);
            this.editorRegistroForm.controls['enviarEmail'].setValue(result.data);
            this.newGuardar();
            break;
          case 4: // Enviar
            this.listaEmailsEnvio = this.stringToEmailList(this.editorRegistroForm.value.enviarEmail);
            this.enviandoEmail = true;
            this.newGuardar();
            break;
        }
      });
    } else {
      this.newGuardar();
    }
  }

  newGuardar() {
    
    
    
    
    
    
    

    this.entradasList = [];
    if (this.tiposTipoEntradaTaller.length !== 0) {
      this.tiposTipoEntradaTaller.forEach(item => {
        this.entradasList.push(item.EntradaId);
      });
    } else if (this.tiposTipoEntradaRecinto.length !== 0) {
      this.tiposTipoEntradaRecinto.forEach(item => {
        this.entradasList.push(item.EntradaId);
      });
    }


    let body = {
      "PkId": this.itemPkId,
      "Nombre": this.editorRegistroForm.value.nombre,
      "ControlarAforo": this.editorRegistroForm.value.controlaraforo === true ? '1' : '0',
      "ControlarAforoReserva": this.editorRegistroForm.value.controlarafororeserva === true ? '1' : '0',
      "TipoAforo": this.editorRegistroForm.value.tipoaforo,
      "AforoMaximo": this.editorRegistroForm.value.aforomaximo,
      "LimiteAforo": this.editorRegistroForm.value.limiteaforo,
      "HoraInicio": this.model.HoraInicio,
      "HoraFin": this.model.HoraFin,
      "PorDefecto": this.editorRegistroForm.value.pordefecto === true ? '1' : '0',
      "Activo": this.editorRegistroForm.value.activo === true ? '1' : '0',
      "MinimoAforo": this.editorRegistroForm.value.aforominimo,
      "Taller": this.editorRegistroForm.value.talleractividad === true ? '1' : '0',
      "Butacas": this.editorRegistroForm.value.butacas === true ? '1' : '0',
      "MostrarInternet": this.editorRegistroForm.value.mostrarinternet === true ? '1' : '0',
      "MaximoInternet": this.editorRegistroForm.value.aforointernet,
      "GrupoActividadId": this.editorRegistroForm.value.grupoActividad,
      "CodigoTipoControl": this.tipoControlTipoControl.value,
      "Secciones": "1",
      "GrupoRecintosId": this.editorRegistroForm.value.tipoControlGruposRecinto,
      "Shape": this.editorRegistroForm.get('forma').value,
      "Coords": this.editorRegistroForm.value.coordenadas,
      "OrdenFilas": this.editorRegistroForm.value.ordenfilas,
      "OrdenButacas": this.editorRegistroForm.value.ordenbutacas,
      "Numeracion": "1",
      "NumSesion": "0",
      "TiempoExtra": this.editorRegistroForm.value.tiempoextra,
      "SubGrupoRecinto": this.editorRegistroForm.value.subGrupoRecinto,
      "Idiomas": this.editorListaIdiomas,
      "Entradas_Recinto_Taller": this.entradasList,
      "EmailEnvio": this.listaEmailsEnvio,
      "EmailEnvioBD": this.replaceEnterToSemicolon(this.editorRegistroForm.value.enviarEmail),
      "NumProj": this.editorRegistroForm.value.numProyecto,
      "Funcion": this.itemPkId === '' ? 'I' : 'U', // vacio
      "ControlExterno": this.editorRegistroForm.value.controlExterno, // vacio
    }

    

    this.servicioAPI.insUpdRecintosByBody(body).subscribe(res => {
      if (res.DatosResult != null) {
        this.editando = false;
        this.alertaServicio.success("Datos Guardados Correctamente")
      } else {
        this.alertaServicio.error(res.Mensajes[0].DescripcionMensaje);
        //this.editando = false;
      }
    });
  }

  async newEditar(item) {
    
    this.itemPkId = item.PkId;

    let objtraspaso = new traspasomodelo();
    this.compruebaPorDefectovalor = this.compruebaPorDefecto(item.PorDefecto);
    objtraspaso.PkId = item.PkId;
    objtraspaso.Nombre = item.Nombre;
    objtraspaso.Activo = this.globales.aBooleano(item.Activo);
    objtraspaso.subGrupoRecinto = this.globales.aBooleano(item.subGrupoRecinto);
    objtraspaso.ControlarAforo = this.globales.aBooleano(item.ControlarAforo);
    objtraspaso.ControlarAforoReserva = this.globales.aBooleano(item.ControlarAforoReserva);
    objtraspaso.TipoAforo = item.TipoAforo;
    objtraspaso.AforoMaximo = item.AforoMaximo;
    objtraspaso.LimiteAforo = item.LimiteAforo;
    if (item.HoraInicio == null || item.HoraInicio.trim() == '') {
      objtraspaso.HoraInicio = null;
    } else {
      objtraspaso.HoraInicio = item.HoraInicio.trim().substring(10, 20).trim().length < 8 ? '0' + item.HoraInicio.trim().substring(10, 20).trim() : item.HoraInicio.trim().substring(10, 20).trim();
    }
    if (item.HoraFin == null || item.HoraFin.trim() == '') {
      objtraspaso.HoraFin = null;
    } else {
      objtraspaso.HoraFin = item.HoraFin.trim().substring(10, 20).trim().length < 8 ? '0' + item.HoraFin.trim().substring(10, 20).trim() : item.HoraFin.trim().substring(10, 20).trim();
    }
    objtraspaso.PorDefecto = this.globales.aBooleano(item.PorDefecto);
    objtraspaso.MinimoAforo = item.MinimoAforo;
    objtraspaso.Taller = this.globales.aBooleano(item.Taller);
    objtraspaso.Butacas = this.globales.aBooleano(item.Butacas);
    objtraspaso.MostrarInternet = this.globales.aBooleano(item.MostrarInternet);
    objtraspaso.MaximoInternet = item.MaximoInternet;
    objtraspaso.GrupoActividadId = item.GrupoActividadId;
    objtraspaso.CodigoTipoControl = item.CodigoTipoControl;
    objtraspaso.Secciones = item.Secciones;
    objtraspaso.GrupoRecintosId = item.GrupoRecintosId;
    objtraspaso.Shape = item.Shape;
    objtraspaso.Coords = item.Coords;
    objtraspaso.OrdenFilas = item.OrdenFilas;
    objtraspaso.OrdenButacas = item.OrdenButacas;
    objtraspaso.Numeracion = item.Numeracion;
    objtraspaso.NumSesion = item.NumSesion;
    objtraspaso.TiempoExtra = item.TiempoExtra;

    //IDIOMAS
    //
    this.editorListaIdiomas = new Array<IdiomasconURL>();
    this.tiposIdiomas.forEach(idioma => {
      let idiomafiltrado = item.Idiomas.filter(x => x.CodIdioma == idioma.CodIdioma);
      if (idiomafiltrado.length > 0) {
        this.editorListaIdiomas.push(<IdiomasconURL>{
          CodIdioma: idioma.CodIdioma,
          Nombre: idiomafiltrado[0].Nombre,
          Descripcion: idiomafiltrado[0].Descripcion,
          URL: idiomafiltrado[0].URL
        });
      } else {
        this.editorListaIdiomas.push(<IdiomasconURL>{ CodIdioma: idioma.CodIdioma, Nombre: null, Descripcion: null, URL: null });
      }
    });
    this.model = objtraspaso;

    this.editorRegistroForm.controls['numProyecto'].setValue(item.NumProj);
    this.editorRegistroForm.controls['enviarEmail'].setValue(this.replaceSemicolonToEnter(item.EmailEnvioBD));
    this.editorRegistroForm.controls['controlExterno'].setValue(item.ControlExterno);
    this.editorRegistroForm.controls['grupoActividad'].setValue(item.GrupoActividadId);
    this.editorRegistroForm.controls['subGrupoRecinto'].setValue(item.SubGrupoRecinto);
    this.editorRegistroForm.controls['forma'].setValue(item.Shape);
    this.editorRegistroForm.controls['ordenfilas'].setValue(item.OrdenFilas);
    this.editorRegistroForm.controls['ordenbutacas'].setValue(item.OrdenButacas);

    this.editorRegistroForm.controls['tipoaforo'].setValue(item.TipoAforo);
    this.tipoControlTipoControl.setValue(item.CodigoTipoControl);

    this.butacasCheck = item.Butacas === '1' ? true : false;

    //Cargamos entradas especificas de Taller y de Recinto
    await this.CargarDatosTiposEntradas(1, objtraspaso.PkId);
    await this.CargarDatosTiposEntradas(2, objtraspaso.PkId);
    //Last Touches ;)
    this.editando = true;
    //
    this.alertaServicio.limpiar();
    Object.keys(this.editorRegistroForm.controls).forEach(field => { // {1}
      const control = this.editorRegistroForm.get(field);            // {2}
      control.markAsTouched({ onlySelf: true });       // {3}
    });
  }

  intToBool(num) {
    if (num === '0') {
      return 'No';
    }
    if (num === '1') {
      return 'Si';
    }
    return 'asdf';
  }

  stringToEmailList(dataDB: string) {
    
    var emilios = dataDB.split('\n');
    
    let ans = [];
    emilios.forEach(email => {
      ans.push({
        email: email
      });
    });
    
    return ans;
  }

  replaceEnterToSemicolon(item: string) {
    
    if (item !== '' && item !== undefined) {
      return item.replace('\n', ',');
    } else {
      return '';
    }

  }

  replaceSemicolonToEnter(item: string) {
    
    if (item !== '' && item !== undefined) {
      return item.replace(',', '\n');
    } else {
      return '';
    }

  }

  toggleAforoMaximo() {
    const controlAforo = !this.editorRegistroForm.get('controlaraforo').value;
    if (controlAforo) {
      this.editorRegistroForm.controls['aforomaximo'].setValidators([Validators.required, Validators.pattern('^-?[0-9]*$'), Validators.min(0)]);
      this.editorRegistroForm.controls['aforomaximo'].updateValueAndValidity();
    } else {
      this.editorRegistroForm.controls['aforomaximo'].clearValidators();
      this.editorRegistroForm.controls['aforomaximo'].setValidators([Validators.pattern('^-?[0-9]*$'), Validators.min(0)]);
      this.editorRegistroForm.controls['aforomaximo'].updateValueAndValidity();
    }
  }

  toggleAforoInternet() {
    const controlAforo = !this.editorRegistroForm.get('controlarafororeserva').value;
    if (controlAforo) {
      this.editorRegistroForm.controls['aforointernet'].setValidators([Validators.required, Validators.pattern('^-?[0-9]*$'), Validators.min(0)]);
      this.editorRegistroForm.controls['aforointernet'].updateValueAndValidity();
    } else {
      this.editorRegistroForm.controls['aforointernet'].clearValidators();
      this.editorRegistroForm.controls['aforointernet'].setValidators([Validators.pattern('^-?[0-9]*$'), Validators.min(0)]);
      this.editorRegistroForm.controls['aforointernet'].updateValueAndValidity();
    }
  }

  validarAforoInternet() {
    const aforoMaximo = this.editorRegistroForm.get('aforomaximo').value;
    this.editorRegistroForm.controls['aforointernet'].setValidators([Validators.max(aforoMaximo), Validators.pattern('^-?[0-9]*$'), Validators.min(0)]);
    this.editorRegistroForm.controls['aforointernet'].updateValueAndValidity();
  }

  formStatus() {
    
  }

}

