<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div [ngClass]="{zonaoculta: loading===true}">
<app-buttons (save)="submit()" (close)="destroy()" (discard)="discard()" [buttonsWrap]="'panel'" [modificar]="modificar"></app-buttons>
<div class="divForm">
    <form [formGroup]="editItemsMotivosGroupForm">
        <div class="form-group">
            <mat-form-field appearance="outline">
                <mat-label>{{translation.Nombre}}</mat-label>
                <input matInput formControlName="Nombre">
                <mat-error *ngIf="validateFields('Nombre')">
                    {{getErrorMessage("Nombre")}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>{{translation.Descripcion}}</mat-label>
                <input matInput formControlName="Descripcion">
                <mat-error *ngIf="validateFields('Descripcion')">
                    {{getErrorMessage("Descripcion")}}
                </mat-error>
            </mat-form-field>
        </div>

        <app-input-filter *ngIf="itemsMotivosGroupProfile && grupos" formControlText="GrupoId" [disabled]="grupos.length<1"
        [label]="translation.iGrupoId" [placeHolderTranslation]="translation.iGrupoId"
        [list]="grupos" textShow="NombreGrupo" dataInput="pkId" required="true"
        [data]="itemsMotivosGroupProfile.GrupoId" (close)="closeInputFilter($event)"></app-input-filter>

        <!-- <mat-form-field appearance="outline">
            <mat-label>{{translation.iGrupoId}}</mat-label>
            <mat-select formControlName="GrupoId">
                <mat-option *ngFor="let grupo of grupos" [value]="grupo.pkId">
                    {{grupo.NombreGrupo}}
                </mat-option>
            </mat-select>            
            <mat-error *ngIf="validateFields('GrupoId')">
                {{getErrorMessage("GrupoId")}}
            </mat-error>
        </mat-form-field> -->

        <div class="form-group">            
            <mat-label>Seleccionable <mat-slide-toggle formControlName="Seleccionable"></mat-slide-toggle>
            </mat-label>
        </div>

        <div style="margin: 20px 5px 0 5px; text-align: center;">
            <mat-label>{{translation.Centros}}</mat-label>
      
            <customan-dual-list [source]="centrosSinAsignar"
            [(destination)]="centrosAsignados" key="pkId" display="Nombre" [format]="format" [filter]='true'>
            </customan-dual-list>
        </div>

        <!-- -->
        <h2>{{translation.MotivoIdioma}}</h2>
        <mat-accordion *ngFor="let idioma of editorListaIdiomas; let i = index" >
            <mat-expansion-panel [expanded]="i==0">
                <mat-expansion-panel-header>
                <mat-panel-title>
                    {{idioma.Nombre}}
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
                </mat-expansion-panel-header>
            
                <mat-form-field appearance="outline">
                <mat-label>{{translation.Nombre}} ({{idioma.CodIdioma}})</mat-label>
                    <input matInput type="text" placeholder="{{translation.Nombre}} en {{idioma.Nombre}}"
                    [value]="idioma.Nombre" (change)="cambiaNombre($any($event.target).value, idioma.CodIdioma)"
                    formControlName="{{'nombre' + idioma.CodIdioma}}">
                </mat-form-field>
    
                <mat-form-field appearance="outline">
                <mat-label>{{translation.Descripcion}} ({{idioma.CodIdioma}})</mat-label>
                    <textarea matInput type="text" placeholder="{{translation.Descripcion}} {{idioma.Nombre}}"
                    [value]="idioma.Descripcion" formControlName="{{'descripcion' + idioma.CodIdioma}}"></textarea>
                </mat-form-field>
            
            </mat-expansion-panel>
        
            </mat-accordion>

        <!-- -->

    </form>
</div>
</div>