<app-buttons
  (open)="openPanel()"
  [buttonsWrap]="'add'"
  [addbut]="addbut"
></app-buttons>

<app-filter [tableName]="'espectaculos'"></app-filter>

<app-table [tableName]="'espectaculos'" (addbut)="addbut = $event"></app-table>

<app-panelcustoman
  *ngIf="customanpanel !== undefined"
  [ConfigPanel]="customanpanel"
  [mode]="'I'"
></app-panelcustoman>
