<app-buttons
  (open)="openPanel()"
  [buttonsWrap]="'add'"
  [addbut]="addbut"
></app-buttons>
<app-filter [tableName]="'gruporecintos'"></app-filter>

<app-table [tableName]="'gruporecintos'" (addbut)="addbut = $event"></app-table>
<app-panelcustoman
  *ngIf="customanpanel !== undefined"
  [ConfigPanel]="customanpanel"
  [mode]="'I'"
>
</app-panelcustoman>
